import { Modifier, EditorState } from "draft-js";

const DEFAULT_INDENTATION = "\t";

// References: https://github.com/SamyPesse/draft-js-code/tree/master/lib

const IndentationUtils = {
  // @TODO: Try to detect the indentation or fall back to a default
  getIndentation(text) {
    return DEFAULT_INDENTATION;
  },

  indent(editorState) {
    var contentState = editorState.getCurrentContent();
    var selection = editorState.getSelection();
    var startKey = selection.getStartKey();
    var currentBlock = contentState.getBlockForKey(startKey);

    // Do not indent lists
    if (
      currentBlock.getType() === "unordered-list-item" ||
      currentBlock.getType() === "ordered-list-item"
    )
      return editorState;

    var indentation = this.getIndentation(currentBlock.getText());
    var newContentState;

    // We insert tab only if selectionstate is collapsed so that we do not replace highlighted text with tab
    if (selection.isCollapsed()) {
      newContentState = Modifier.insertText(
        contentState,
        selection,
        indentation
      );
    } else {
      return editorState;
    }

    return EditorState.push(editorState, newContentState, "insert-characters");
  }
};

export default IndentationUtils;
