import React, { Component } from "react";
import "./EmptyState.css";

import { get } from "../../../utils/BeeApi";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class EmptyState extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.subscribedPromises = [];
    this.contentId = this.props.contentId;
  }

  componentDidMount() {
    this.fetchContent();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  fetchContent() {
    const contentPromise = get("contents/" + this.contentId, {});
    contentPromise.promise
      .then(response => {
        this.setState({
          content: response.data,
          error: ""
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error,
            content: ""
          });
      });
    this.subscribedPromises.push(contentPromise);
  }

  render() {
    const { content, error } = this.state;

    if (error) return <ErrorHandler error={error} />;
    if (!content) return <Loader />;
    return (
      <div className="Content">
        <div
          className="body-section"
          dangerouslySetInnerHTML={{ __html: content.contentBody }}
        />
      </div>
    );
  }
}

export default EmptyState;
