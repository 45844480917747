import React, { Component } from "react";
import "./WordCountDistribution.css";
import echarts from "echarts";

/**
 * Charts implemented using https://ecomfe.github.io/echarts-doc/public/en/index.html
 * https://ecomfe.github.io/echarts-examples/public/editor.html?c=bar-gradient */
class WordCountDistribution extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
      isMobile: /mobi/i.test(navigator.userAgent.toLowerCase()),
      xAxis: 0,
      yAxis: 0
    };
  }

  componentDidMount() {
    this.chart = echarts.init(this.el);
    this.renderBarChart();
  }

  render() {
    const { data } = this.props;
    return (
      <div className="WordCountDistribution">
        <div
          className={`bar-chart-container center-block ${
            data.length > 50 ? "bar-chart-container-lg" : ""
          }`}
          ref={el => (this.el = el)}
        />
      </div>
    );
  }

  renderBarChart() {
    const { data } = this.props;

    if (!data || !Array.isArray(data)) return <div />;

    const labels = data.map(dataPoint => {
      return dataPoint.label;
    });

    const values = data.map(dataPoint => {
      return dataPoint.value || 0;
    });

    const sumOfValues = values.reduce(function(memo, value) {
      return memo + value;
    }, 0);

    const option = {
      tooltip: {
        show: "true",
        formatter: params => {
          if (params && params.length)
            return `${++params[0].dataIndex}. ${
              params[0].name
            }: ${new Intl.NumberFormat().format(params[0].value)} words`;
        },
        trigger: "axis",
        textStyle: {
          fontSize: 12,
          fontFamily: "Arial"
        }
      },
      grid: {
        top: 0,
        bottom: sumOfValues > 0 ? 0 : 0.5,
        left: 0,
        right: 0
      },
      xAxis: {
        data: labels,
        axisLabel: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#dfe1f6"
          }
        },
        z: 10
      },
      yAxis: {
        show: false
      },
      series: [
        {
          type: "bar",
          itemStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgb(160, 124, 255)" }
                //   { offset: 0.5, color: "#D5D4FF" },
                //   { offset: 0.7, color: "#E4E8F0" }
              ])
            },
            emphasis: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#8c63ea" }
                // { offset: 0.7, color: "#D5D4FF" },
                // { offset: 1, color: "#E4E8F0" }
              ])
            }
          },
          data: values
        }
      ]
    };
    this.chart.setOption(option);

    var touchtime = 0;

    if (!this.state.isMobile) {
      this.chart.getZr().on("click", params => {
        var pointInPixel = [params.offsetX, params.offsetY];
        var pointInGrid = this.chart.convertFromPixel("grid", pointInPixel);
        this.props.redirectToSelectedScene(data[pointInGrid[0]] || {});
      });
    } else {
      this.chart.getZr().on("click", params => {
        if (touchtime === 0) {
          touchtime = new Date().getTime();
        } else {
          if (new Date().getTime() - touchtime < 800) {
            var pointInPixel = [params.offsetX, params.offsetY];
            var pointInGrid = this.chart.convertFromPixel("grid", pointInPixel);
            this.props.redirectToSelectedScene(data[pointInGrid[0]] || {});
            touchtime = 0;
          } else {
            touchtime = new Date().getTime();
          }
        }
      });
    }
  }
}

export default WordCountDistribution;
