import React, { Component } from "react";
import { Grid, Col, Row, Button, Modal } from "react-bootstrap";
import { Redirect, Link } from "react-router-dom";
import snakeCase from "lodash/snakeCase";
import Scrollspy from "react-scrollspy";

import { get, download } from "../../../utils/DeApi";
import "./Manuscript.css";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import SceneReadOnly from "../SceneReadOnly/SceneReadOnly";
import EmptyState from "../EmptyState/EmptyState";

/**
 * Handles manuscript view.
 * Writer sees list of scenes body back to back.
 */
class Manuscript extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};

    this.handleDownloadManuscript = this._handleDownloadManuscript.bind(this);
    this.handleToggleModal = this._handleToggleModal.bind(this);
  }

  _handleDownloadManuscript() {
    const { project } = this.props;
    this.setState({ manuscriptDownloading: true, downloadError: "" });

    const draftPromise = download(
      `manuscript/${project.id}/draft?pillar=scenes`
    );

    const fileName = `Manuscript-${snakeCase(project.name)}.docx`;

    draftPromise.promise
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute("download", fileName);
        document.body.appendChild(link);

        link.click();
        this.setState({
          manuscriptDownloading: false,
          downloadError: "",
          show: false
        });
      })
      .catch(error => {
        this.setState({
          manuscriptDownloading: false,
          downloadError: error
        });
      });
    this.subscribedPromises.push(draftPromise);
  }

  _handleToggleModal() {
    this.setState({ show: !this.state.show });
  }
  componentDidMount() {
    this.fetchScenes();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  componentDidUpdate(prevProps, prevState) {
    this.scrollIntoView();
  }

  fetchScenes() {
    let scenesPromise = get("/scenes?project=" + this.props.project.id);

    scenesPromise.promise
      .then(response => {
        this.setState({
          scenes: response.data
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error
          });
      });
    this.subscribedPromises.push(scenesPromise);
  }

  scrollIntoView() {
    const { location } = this.props;
    const id = location.hash;

    if (!id) return;

    const element = document.querySelector(id);

    if (element) {
      element.scrollIntoView({
        behavior: "auto",
        block: "start",
        inline: "start"
      });
    }
  }

  render() {
    const { error, scenes } = this.state;
    const { project } = this.props;

    if (error) return <ErrorHandler error={error} />;
    if (!scenes) return <Loader />;

    const words = scenes.reduce(function(memo, scene) {
      let sceneWords = scene.sceneStats ? scene.sceneStats.words : 0;
      return memo + (sceneWords || 0);
    }, 0);

    return (
      <Grid className="ManuscriptView">
        {!!scenes.length ? (
          <Row>
            <Col md={3} xs={12} className="ManuscriptAside">
              <div className="mt-md mb-md pt-sm pb-sm">
                {this.renderExportTips()}
                <Button
                  onClick={this.handleToggleModal}
                  bsStyle="success"
                  block
                >
                  Export Manuscript
                </Button>
                <hr />

                <h3>
                  <span className="text-muted pull-right">
                    {`${new Intl.NumberFormat().format(words)} words`}
                  </span>
                  <span className="text-uppercase">Manuscript</span>
                </h3>

                <div className="mt-sm md-sm">{this.renderScenesOutline()}</div>
              </div>
            </Col>

            <Col md={9} xs={12} className="ManuscriptMain">
              <div className="ManuscriptContent mt-md mb-md pt-sm pb-sm">
                <div className="card pr-lg pl-lg ">
                  <h1 className="mt-lg mb-lg text-muted text-center">
                    <big>{project.name}</big>
                  </h1>
                  {this.renderScenes()}
                </div>
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col md={8} mdOffset={2}>
              <div className="mt-md mb-md">
                <h1 className="pt-md pb-md text-muted text-center">
                  {project.name}
                </h1>
              </div>
              <div className="card card-info">
                <EmptyState contentId={"5c8937522c361"} />
              </div>
            </Col>
          </Row>
        )}
      </Grid>
    );
  }

  renderScenesOutline() {
    const { scenes, redirectTo } = this.state;
    const { project } = this.props;

    if (redirectTo) return <Redirect to={redirectTo} push />;

    const items = scenes.map((scene, index) => {
      return "ms-scene-" + scene.id;
    });

    return (
      <Scrollspy
        items={items}
        currentClassName="active"
        className="list-group numbered-list-group"
        id="scenes-outline"
      >
        {scenes.map((scene, index) => {
          return (
            <Link
              key={scene.id}
              to={"#ms-scene-" + scene.id}
              className="list-group-item"
            >
              <Button
                bsSize="xs"
                bsStyle="default"
                onClick={() =>
                  this.setState({
                    redirectTo:
                      "/projects/" + project.id + "/scenes/" + scene.id
                  })
                }
                className="pull-right edit-scene ml-xs"
              >
                Write
              </Button>
              {scene.title}
            </Link>
          );
        })}
      </Scrollspy>
    );
  }

  renderScenes() {
    const { scenes } = this.state;
    return scenes && scenes.length ? (
      <div>
        {scenes.map((scene, index) => {
          return this.renderScene(scene);
        })}
      </div>
    ) : (
      <div className="card">
        <EmptyState contentId={"5c8937522c361"} />
      </div>
    );
  }

  renderScene(scene) {
    const { project } = this.props;
    return (
      <div key={scene.id} id={"ms-scene-" + scene.id} className="pt-md pb-md">
        <hr />
        <h1 className="text-center mt-lg mb-md ">{scene.title}</h1>
        <SceneReadOnly
          scene={scene}
          key={scene.id}
          project={project}
          className="SceneContent"
        />
      </div>
    );
  }

  renderExportTips() {
    const { show, manuscriptDownloading, downloadError } = this.state;
    return (
      <Modal
        show={show}
        onHide={this.handleToggleModal}
        className="ProjectCreateModal"
        bsSize="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="mt-0">Export Guide</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {manuscriptDownloading ? (
            <Loader />
          ) : (
            <EmptyState contentId={"604fae064f95d"} />
          )}
          {downloadError && !manuscriptDownloading && (
            <ErrorHandler error={downloadError} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="link" onClick={this.handleToggleModal}>
            Cancel
          </Button>
          <Button bsStyle="success" onClick={this.handleDownloadManuscript}>
            Export
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default Manuscript;
