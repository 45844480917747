import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import Moment from "moment";

import { put } from "../../../utils/BeeApi";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class SubscriptionCancel extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = { show: false };

    this.handleShowModal = this._handleShowModal.bind(this);
    this.handleCloseModal = this._handleCloseModal.bind(this);
    this.handlePlanCanceled = this._handlePlanCanceled.bind(this);
  }

  _handleCloseModal() {
    this.setState({ show: false });
  }

  _handleShowModal() {
    this.setState({ show: true });
  }

  _handlePlanCanceled(subscription) {
    this.cancelSubscription();
  }

  cancelSubscription() {
    const { subscription, onSubscriptionUpdated } = this.props;

    this.setState({ isLoading: true, error: "" });
    const putSubscriptionPromise = put(
      `/subscriptions/${subscription.subscriptionId}`,
      { status: "cancel" }
    );
    putSubscriptionPromise.promise
      .then(response => {
        this.setState({ show: false, subscription: response.data }, () =>
          onSubscriptionUpdated(response.data)
        );
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error,
            isLoading: false
          });
      });
    this.subscribedPromises.push(putSubscriptionPromise);
  }

  componentDidMount() {}

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  render() {
    const { plan, subscription } = this.props;
    const { show, isLoading, error } = this.state;

    return (
      <div className="SubscriptionCancel">
        <Button bsStyle="link" bsSize="sm" onClick={this.handleShowModal}>
          <span className="text-capitalize">{`Cancel ${
            plan.nickname
          } Plan Subscription`}</span>
        </Button>
        <Modal show={show} onHide={this.handleCloseModal} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title className="mt-0 text-capitalize">
              {`Cancel your ${plan.nickname} Plan subscription`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mt-xs mb-xs">
              <p>Are you sure?</p>
              {error && <ErrorHandler error={error} />}
              {isLoading && <Loader />}
              <h5>
                If you opt to cancel your subscription, you can still access all
                features of Bookflow until{" "}
                <span>
                  {Moment(subscription.currentPeriodEnd).format("LL")}
                </span>
                . Afterwards, you may use Bookflow in Read-Only Mode and export
                your work at any time. However, you will need an active
                subscription plan to access editing features on Bookflow.
              </h5>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={this.handleCloseModal}
              bsStyle="default"
              bsSize="sm"
            >
              Back to safety
            </Button>
            <Button
              onClick={this.handlePlanCanceled}
              bsStyle="danger"
              bsSize="sm"
            >
              Confirm Cancelation
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default SubscriptionCancel;
