import React, { Component } from "react";
import { Button } from "react-bootstrap";

import { put } from "../../../utils/BeeApi";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class SubscriptionResume extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};
    this.handleResumeSubscription = this._handleResumeSubscription.bind(this);
  }

  _handleResumeSubscription(token) {
    this.resumeSubscription(token);
  }

  resumeSubscription() {
    const { subscription, onSubscriptionUpdated } = this.props;
    this.setState({ isLoading: true, error: "" });
    const putSubscriptionPromise = put(
      `/subscriptions/${subscription.subscriptionId}`,
      { status: "resume" }
    );
    putSubscriptionPromise.promise
      .then(response => {
        onSubscriptionUpdated(response.data);
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error,
            isLoading: false
          });
      });
    this.subscribedPromises.push(putSubscriptionPromise);
  }

  componentDidMount() {}

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  render() {
    const { plan } = this.props;
    const { isLoading, error } = this.state;

    if (error) return <ErrorHandler error={error} />;
    if (isLoading) return <Loader />;

    return (
      <div className="SubscriptionResume">
        <Button
          bsStyle="link"
          bsSize="sm"
          onClick={this.handleResumeSubscription}
        >
          {`Reactivate your ${plan.nickname} Plan`}
        </Button>
      </div>
    );
  }
}

export default SubscriptionResume;
