import React, { Component } from "react";

class PageVisibility extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleVisibilityChange = this._handleVisibilityChange.bind(this);
  }

  componentDidMount() {
    const el = document;
    // Set the name of the hidden property and the change event for visibility
    var hidden, visibilityChange;
    if (typeof el.hidden !== "undefined") {
      // Opera 12.10 and Firefox 18 and later support
      hidden = "hidden";
      visibilityChange = "visibilitychange";
    } else if (typeof el.msHidden !== "undefined") {
      hidden = "msHidden";
      visibilityChange = "msvisibilitychange";
    } else if (typeof el.webkitHidden !== "undefined") {
      hidden = "webkitHidden";
      visibilityChange = "webkitvisibilitychange";
    }
    this.setState(
      {
        hiddenParam: hidden,
        visibilityParam: visibilityChange
      },
      () => this.registerPageVisibility()
    );
  }

  componentWillUnmount() {
    this.unRegisterPageVisibility();
  }

  _handleVisibilityChange() {
    const el = document;
    const { hiddenParam } = this.state;
    const { onVisibilityChange } = this.props;
    // if the page is changes to shown, reload.
    if (el[hiddenParam]) {
      onVisibilityChange && onVisibilityChange(true);
    }
  }

  registerPageVisibility() {
    const el = document;
    const { visibilityParam } = this.state;
    el.addEventListener(visibilityParam, this.handleVisibilityChange, false);
  }

  unRegisterPageVisibility() {
    const el = document;
    const { visibilityParam } = this.state;
    el.removeEventListener(visibilityParam, this.handleVisibilityChange, false);
  }

  render() {
    return <div />;
  }
}

export default PageVisibility;
