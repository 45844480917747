export const Config = {
  title: "Bookflow",
  defaultSiteTitle: "Bookflow: Control your story",
  defaultSiteDescription:
    "Improve your craft, increase your productivity, and join our growing community of outstanding writers.",
  defaultSocialImage:
    "http://dev.bookflow.com.s3-website-us-east-1.amazonaws.com/bookflow-social.png",

  baseURL: "https://www.bookflow.com",

  facebookAppID: "2110187555735979",
  twitterHandle: "@bookflowpub",

  facebookPageLink: "https://www.facebook.com/bookflowpub",
  twitterPageLink: "https://twitter.com/bookflowpub",
  twitterMessage: "Check out this article!",
  emailMessage: ""
};
