import { Config } from "../Config";
import React, { Component } from "react";
import "./Facebook.css";

class Facebook extends Component {
  constructor(props) {
    super(props);
    this.handleFacebookShare = this._handleFacebookShare.bind(this);
  }

  componentDidMount() {
    this.initFacebookSDK();
  }

  initFacebookSDK() {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId: Config.facebookAppID,
        autoLogAppEvents: true,
        xfbml: true,
        version: "v3.1"
      });
    };

    (function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }

  _handleFacebookShare() {
    const { link } = this.props;

    if (window.FB) {
      window.FB.ui(
        {
          method: "feed",
          link: encodeURI(link || document.URL)
        },
        function(response) {}
      );
    }
  }

  render() {
    return (
      <li onClick={this.handleFacebookShare}>
        <span className="icon-facebook">{}</span>
      </li>
    );
  }
}

export default Facebook;
