import React from "react";
import { CompositeDecorator } from "draft-js";

const generateDecorator = (highlightTerm, config = {}, selectionState = {}) => {
  const { matchCase, wholeWord } = config;
  const regex = new RegExp(
    wholeWord ? `\\b${highlightTerm}\\b` : highlightTerm,
    matchCase ? "g" : "ig"
  );

  window.counter = 0;

  return new CompositeDecorator([
    {
      strategy: handleReplaceStrategy.bind(this, selectionState),
      component: SelectionHighlight
    },
    {
      strategy: (contentBlock, callback, contentState) => {
        if (highlightTerm !== "") {
          findWithRegex(regex, contentBlock, callback);
        }
      },
      component: SearchHighlight
    }
  ]);
};

const findWithRegex = (regex, contentBlock, callback) => {
  const text = contentBlock.getText();
  let matchArr, start, end;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    end = start + matchArr[0].length;
    callback(start, end);
  }
};

const SearchHighlight = props => (
  <span className="search-and-replace-highlight">{props.children}</span>
);

const handleReplaceStrategy = (selectionState = {}, contentBlock, callback) => {
  const { anchorOffset, focusOffset, anchorKey } = selectionState;

  if (anchorKey === contentBlock.getKey()) {
    let container = document.querySelector(".RichEditor-editor");
    let matches = container.querySelectorAll(
      `span[data-offset-key='${anchorKey}-0-0']`
    );

    if (window.counter === 1)
      matches.forEach(node => {
        node.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "start"
        });
      });

    callback(anchorOffset, focusOffset);

    window.counter++;
  }
};

const SelectionHighlight = props => (
  <span className="search-and-replace-index">{props.children}</span>
);

export { generateDecorator, findWithRegex };
