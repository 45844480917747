import { Config } from "../Config";
import React, { Component } from "react";
import "./Twitter.css";

class Twitter extends Component {
  constructor(props) {
    super(props);
    this.handleTwitterShare = this._handleTwitterShare.bind(this);
  }

  _handleTwitterShare() {
    const { summary, link } = this.props;

    let message = summary ? summary.substring(0, 80) : Config.twitterMessage;
    message = Config.twitterHandle
      ? `Check out my progress - ${link} via ${Config.twitterHandle}`
      : `Check out my progress - ${link}`;

    window.open(
      `http://twitter.com/share?text=${encodeURIComponent(message)}
        &url=${encodeURIComponent(link)}
        &count=none/`,
      "tweet",
      "height=300,width=550,resizable=1"
    );
  }

  render() {
    return (
      <li onClick={this.handleTwitterShare}>
        <span className="icon-twitter">{}</span>
      </li>
    );
  }
}

export default Twitter;
