import React, { Component, Fragment } from "react";
import "./ProjectDashboard.css";
import {
  Grid,
  Col,
  Row,
  Button,
  ButtonToolbar,
  DropdownButton,
  Tooltip,
  OverlayTrigger
} from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import snakeCase from "lodash/snakeCase";
import { get, download } from "../../../utils/DeApi";

import Loader from "../../Loader/Loader";
import ProjectUpdate from "../ProjectUpdate/ProjectUpdate";
import ProjectGoalUpdate from "../ProjectGoalUpdate/ProjectGoalUpdate";
import ProjectDelete from "../ProjectDelete/ProjectDelete";
import ProjectRewards from "../ProjectRewards/ProjectRewards";
import ProjectProgress from "../ProjectProgress/ProjectProgress";
import Contributions from "../../Activity/Contributions/Contributions";
import WordCountDistribution from "../../Activity/WordCountDistribution/WordCountDistribution";
import ProjectClone from "../ProjectClone/ProjectClone";
import NaNoWriMo from "../NaNoWriMo/NaNoWriMo";

/**
 * Handles project level dashboard.
 */
class ProjectDashboard extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
      isMobile: /mobi/i.test(navigator.userAgent.toLowerCase())
    };

    this.handleDownloadProject = this._handleDownloadProject.bind(this);
    this.redirectToSelectedScene = this._redirectToSelectedScene.bind(this);
  }

  componentDidMount() {
    this.fetchScenes();
    this.fetchThemes();
    this.fetchCharacters();
    this.fetchProjectStats();
    this.fetchContributions();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  fetchScenes() {
    const scenesPromise = get("/scenes?project=" + this.props.project.id);

    scenesPromise.promise
      .then(response => {
        this.setState({
          scenes: response.data
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error
          });
      });
    this.subscribedPromises.push(scenesPromise);
  }

  fetchProjectStats() {
    const statsPromise = get("/projects/" + this.props.project.id + "/stats");

    statsPromise.promise
      .then(response => {
        this.setState({
          stats: response.data
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error
          });
      });
    this.subscribedPromises.push(statsPromise);
  }

  fetchThemes() {
    const themesPromise = get("/themes?project=" + this.props.project.id);
    themesPromise.promise
      .then(response => {
        this.setState({
          themes: response.data,
          error: ""
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error
          });
      });
    this.subscribedPromises.push(themesPromise);
  }

  fetchCharacters() {
    const charactersPromise = get(
      "/characters?project=" + this.props.project.id
    );
    charactersPromise.promise
      .then(response => {
        this.setState({
          characters: response.data,
          error: ""
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error
          });
      });
    this.subscribedPromises.push(charactersPromise);
  }

  fetchContributions() {
    let contributionsPromise = get("/contributions", {
      params: { project: this.props.project.id }
    });

    contributionsPromise.promise
      .then(response => {
        this.setState({ contributions: response.data });
      })
      .catch(error => {
        !error.isCanceled && this.setState({ contributions: [] });
      });

    this.subscribedPromises.push(contributionsPromise);
  }

  _handleDownloadProject() {
    let manuscriptPromise = download(
      "/manuscript?project=" + this.props.project.id
    );

    this.setState({ manuscriptDownloading: true });

    const fileName = snakeCase(this.props.project.name) + ".docx";

    manuscriptPromise.promise
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute("download", fileName);
        document.body.appendChild(link);

        link.click();
        this.setState({
          manuscriptDownloading: false
        });
      })
      .catch(error => {
        this.setState({
          manuscriptDownloading: false
        });
      });
    this.subscribedPromises.push(manuscriptPromise);
  }

  render() {
    const { project } = this.props;
    const { themes, characters, scenes } = this.state;

    return (
      <Grid className="ProjectDashboard">
        <div className="mt-sm md-sm pb-md DashboardCanvas">
          <Row>
            <Col xs={12} md={7} className="mt-sm mb-sm">
              <h2 className="mb-0">{project.name}</h2>
            </Col>
            <Col xs={12} md={5}>
              {this.renderProjectActions()}
            </Col>
          </Row>
          {scenes &&
          !scenes.length &&
          characters &&
          !characters.length &&
          themes &&
          !themes.length ? (
            <div className="ProjectEmptyState card card-emphasis mt-sm mb-sm text-center">
              <h2 className="mt-md mb-sm">Where would you like to start?</h2>
              <Row className="mr-md ml-md">
                <Col xs={12} sm={4} className="mt-md mb-md pl-md pr-md">
                  <p>
                    Start here if you want to craft an outline or jump into
                    writing.
                  </p>
                  <Button
                    bsStyle="primary"
                    componentClass={Link}
                    to={"/projects/" + project.id + "/scenes"}
                    href={"/projects/" + project.id + "/scenes"}
                  >
                    Scenes <i className="material-icons md-18">arrow_forward</i>
                  </Button>
                </Col>
                <Col xs={12} sm={4} className="mt-md mb-md pl-md pr-md">
                  <p>
                    Sketch characters, settings, and objects, or take a deep
                    dive.
                  </p>
                  <Button
                    bsStyle="primary"
                    componentClass={Link}
                    to={"/projects/" + project.id + "/characters"}
                    href={"/projects/" + project.id + "/characters"}
                  >
                    Characters+{" "}
                    <i className="material-icons md-18">arrow_forward</i>
                  </Button>
                </Col>
                <Col xs={12} sm={4} className="mt-md mb-md pl-md pr-md">
                  <p>There's more to writing than just telling a story.</p>
                  <Button
                    bsStyle="primary"
                    componentClass={Link}
                    to={"/projects/" + project.id + "/themes"}
                    href={"/projects/" + project.id + "/themes"}
                  >
                    Themes <i className="material-icons md-18">arrow_forward</i>
                  </Button>
                </Col>
              </Row>
            </div>
          ) : (
            <Row>
              <Col xs={12} sm={scenes && scenes.length > 50 ? 12 : 6}>
                <div className="mt-sm mb-sm">{this.renderOutline()}</div>
              </Col>
              <Col xs={12} sm={scenes && scenes.length > 50 ? 12 : 6}>
                <div className="mt-sm mb-sm">
                  {this.renderOutlineSummaries()}
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={12} sm={4}>
              <div className="mt-sm mb-sm">{this.renderProgress()}</div>
            </Col>
            <Col xs={12} sm={5}>
              <div className="mt-sm mb-sm">
                <div className="mt-sm mb-sm">{this.renderContributions()}</div>
              </div>
            </Col>
            <Col xs={12} sm={3}>
              <div className="mt-sm mb-sm">{this.renderRewards()}</div>
            </Col>
          </Row>
        </div>
      </Grid>
    );
  }

  getRecentActiveScene() {
    const { scenes } = this.state;

    const scenesWithActivity = scenes.filter(scene => {
      return !Array.isArray(scene.sceneStats) && scene.sceneStats.updatedAt;
    });

    if (scenesWithActivity.length > 0) {
      return scenesWithActivity.reduce((a, b) => {
        const updateA = a.sceneStats.updatedAt;
        const updateB = b.sceneStats.updatedAt;
        return updateA > updateB ? a : b;
      })["id"];
    }

    return scenes.slice(-1)[0].id;
  }

  renderProjectActions() {
    const { project } = this.props;
    const { scenes, manuscriptDownloading } = this.state;

    return (
      <ButtonToolbar className="pull-right">
        {!!scenes && !!scenes.length && (
          <Button
            className="mt-sm mb-sm"
            componentClass={Link}
            href={
              "/projects/" +
              project.id +
              "/scenes/" +
              this.getRecentActiveScene()
            }
            to={
              "/projects/" +
              project.id +
              "/scenes/" +
              this.getRecentActiveScene()
            }
          >
            Resume Writing
          </Button>
        )}
        <DropdownButton
          bsStyle="primary"
          title={"Manage"}
          id={"project-settings"}
          className="mt-sm mb-sm"
          pullRight
        >
          <Button onClick={this.handleDownloadProject} bsStyle="link">
            {manuscriptDownloading ? "Exporting...." : "Export Project File"}
          </Button>
          <NaNoWriMo
            project={project}
            onProjectUpdated={this.props.onProjectUpdated}
            stats={this.state.stats}
          />
          <ProjectUpdate
            project={project}
            onProjectUpdated={this.props.onProjectUpdated}
          />
          <ProjectClone
            project={project}
            onProjectCloned={this.props.onProjectCloned}
          />
          <ProjectDelete
            project={project}
            onProjectDeleted={this.props.onProjectDeleted}
          />
        </DropdownButton>
      </ButtonToolbar>
    );
  }

  renderProgress() {
    const { project } = this.props;
    const { stats } = this.state;
    const goal = project.words;
    const deadline = project.deadline;

    if (!stats) return <Loader />;
    const words = stats.sceneStats.reduce(function(memo, sceneStats) {
      return memo + (sceneStats.words || 0);
    }, 0);
    return (
      <div className="card card-info">
        <h3 className="text-uppercase">Project Progress</h3>
        <hr />

        <div className="ProjectProgress">
          <ul className="list-styled mb-0">
            <li>
              <i className="material-icons mr-xs">flag</i>
              <p className="body-1">
                {goal ? (
                  <Fragment>
                    <strong>{`${new Intl.NumberFormat().format(
                      words
                    )}`}</strong>
                    <span>{` words of ${new Intl.NumberFormat().format(
                      goal
                    )} words`}</span>
                  </Fragment>
                ) : (
                  <span style={{ display: "block", minWidth: 115 }}>
                    <strong>{`${new Intl.NumberFormat().format(
                      words
                    )}`}</strong>{" "}
                    words
                  </span>
                )}
              </p>
            </li>
          </ul>

          {!goal ? (
            <div>
              <p>
                What's your goal? See suggested project goals, set one, and
                track your progress!
              </p>
              <p className="mt-sm mb-sm">
                <ProjectGoalUpdate
                  project={project}
                  onProjectUpdated={this.props.onProjectUpdated}
                />
              </p>
            </div>
          ) : (
            <Fragment>
              <ProjectProgress
                project={project}
                stats={stats}
                showSubText={true}
              />

              <div className="text-center mt-md">
                <ProjectGoalUpdate
                  project={project}
                  onProjectUpdated={this.props.onProjectUpdated}
                  text={"Update Goal"}
                  words={goal}
                  deadline={deadline}
                />
              </div>
            </Fragment>
          )}
        </div>
      </div>
    );
  }

  renderContributions() {
    const { project } = this.props;
    const { scenes, characters, themes } = this.state;
    return (
      <Fragment>
        {(scenes && scenes.length) ||
        (characters && characters.length) ||
        (themes && themes.length) ? (
          <div className="card card-warning">
            <h3 className="text-uppercase mb-0">Project Activity</h3>
            <hr />
            <Contributions project={project} />
          </div>
        ) : (
          <div />
        )}
      </Fragment>
    );
  }

  renderRewards() {
    const { project } = this.props;
    const { scenes, characters, themes } = this.state;
    return (
      <Fragment>
        {(scenes && scenes.length) ||
        (characters && characters.length) ||
        (themes && themes.length) ? (
          <div className="card card-success">
            <h3 className="text-uppercase mb-0">Project Rewards</h3>
            <ProjectRewards project={project} />
          </div>
        ) : (
          <div />
        )}
      </Fragment>
    );
  }

  renderOutline() {
    const { scenes } = this.state;
    const { project } = this.props;

    if (!scenes) return <Loader />;
    const data = scenes.map(scene => {
      return {
        label: scene.title,
        value: scene.sceneStats.words,
        id: scene.id
      };
    });

    const wordCount = data.reduce((accumulator, currentValue) => {
      return accumulator + (currentValue.value || 0);
    }, 0);

    const scenesWithActivity = data.filter(item => item.value);
    const avgWordsPerScene = wordCount / scenesWithActivity.length;

    return (
      <div className="card card-emphasis">
        <Link
          className="hyperlink-1 pull-right"
          to={"/projects/" + project.id + "/manuscript"}
        >
          Manuscript <i className="material-icons md-18">arrow_forward</i>
        </Link>
        <h4 className="stats-block">
          MANUSCRIPT <small>(Scenes)</small>
        </h4>

        {data && Array.isArray(data) ? (
          <div className="mt-md mb-0">
            <WordCountDistribution
              data={data || []}
              redirectToSelectedScene={this.redirectToSelectedScene}
            />
            <p className="mt-sm mb-sm text-center sub-text">
              Word count distribution by scene
            </p>
            <p className="sub-text">
              <strong>{new Intl.NumberFormat().format(scenes.length)}</strong>{" "}
              <span className="text-muted">scenes</span>
            </p>
            <p className="sub-text">
              <strong>{new Intl.NumberFormat().format(wordCount)}</strong>{" "}
              <span className="text-muted">words</span>
            </p>
            <p className="sub-text">
              <strong>
                {new Intl.NumberFormat().format(
                  Math.round(!isNaN(avgWordsPerScene) ? avgWordsPerScene : 0)
                )}
              </strong>{" "}
              <span className="text-muted">words per scene</span>
            </p>
          </div>
        ) : (
          <div />
        )}
      </div>
    );
  }

  _handleMobileRouting(URL) {
    if (this.props.location.pathname !== URL) this.props.history.push(URL);
  }

  _redirectToSelectedScene(scene) {
    const sceneURL = `/projects/${this.props.project.id}/scenes/${scene.id}`;
    if (this.props.location.pathname !== sceneURL)
      this.props.history.push(sceneURL);
  }

  renderActivity(pillar, pillarType) {
    const activity = pillar.description || "";
    const compactArray = activity
      .trim()
      .split(" ")
      .filter(item => item);

    return (
      <OverlayTrigger
        key={pillar.id}
        trigger={["hover", "focus"]}
        placement="bottom"
        overlay={
          <Tooltip id={pillar.id} title="Open scene">
            {pillar.orderId}. {pillar.title} | {compactArray.length} words
          </Tooltip>
        }
      >
        <div
          className={`pillar-summary ${
            compactArray.length > 0 ? "pillar-active" : ""
          }`}
        ></div>
      </OverlayTrigger>
    );
  }

  renderOutlineSummaries() {
    const { scenes, characters, themes } = this.state;
    const { project } = this.props;

    if (!scenes || !characters || !themes) return <Loader />;

    return (
      <div className="card card-emphasis summary-card">
        <h4 className="stats-block">
          OUTLINE <small>(Summaries)</small>
        </h4>
        <Row>
          <Col xs={12}>
            <h3 className="mb-xs">
              <Link
                className="hyperlink-1"
                to={"/projects/" + project.id + "/scenes"}
              >
                Scenes <i className="material-icons md-18">arrow_forward</i>
              </Link>
            </h3>

            <div style={{ minHeight: 100 }}>
              {scenes &&
                scenes.map(scene => this.renderActivity(scene, "scenes"))}
            </div>
          </Col>
          <Col xs={6}>
            <h3 className="mb-xs">
              <Link
                className="hyperlink-1"
                to={"/projects/" + project.id + "/characters"}
              >
                Characters+{" "}
                <i className="material-icons md-18">arrow_forward</i>
              </Link>
            </h3>
            <div style={{ minHeight: 100 }}>
              {characters &&
                characters.map(character =>
                  this.renderActivity(character, "characters")
                )}
            </div>
          </Col>
          <Col xs={6}>
            <h3 className="mb-xs">
              <Link
                className="hyperlink-1"
                to={"/projects/" + project.id + "/themes"}
              >
                Themes <i className="material-icons md-18">arrow_forward</i>
              </Link>
            </h3>
            <div style={{ minHeight: 100 }}>
              {themes &&
                themes.map(theme => this.renderActivity(theme, "themes"))}
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  renderCharacters() {
    const { characters } = this.state;
    const { project } = this.props;

    if (!characters) return <Loader />;

    return (
      <div className="card card-emphasis">
        <div>
          <h1 className="stats-block">
            <big>{characters.length}</big> <small>characters+</small>
          </h1>
          <p className="sub-text">
            Total number of characters+ for this project.
          </p>
          <Link
            className="hyperlink-1"
            to={"/projects/" + project.id + "/characters"}
          >
            Characters+ <i className="material-icons md-18">arrow_forward</i>
          </Link>
        </div>
      </div>
    );
  }

  renderThemes() {
    const { themes } = this.state;
    const { project } = this.props;

    if (!themes) return <Loader />;
    return (
      <div className="card card-emphasis">
        <div>
          <h1 className="stats-block">
            <big>{themes.length}</big> <small>themes</small>
          </h1>
          <p className="sub-text">Total number of themes for this project.</p>
          <Link
            className="hyperlink-1"
            to={"/projects/" + project.id + "/themes"}
          >
            Themes <i className="material-icons md-18">arrow_forward</i>
          </Link>
        </div>
      </div>
    );
  }

  renderManuscript() {
    const { project } = this.props;
    const { stats } = this.state;

    if (!stats) return <Loader />;
    const words = stats.sceneStats.reduce(function(memo, sceneStats) {
      return memo + (sceneStats.words || 0);
    }, 0);

    return (
      <div className="card card-emphasis">
        <div>
          <h1 className="stats-block">
            <big>{`${new Intl.NumberFormat().format(words)} `}</big>
            <small>words</small>
          </h1>
          <p className="sub-text">
            Total number of words in the manuscript for this project.
          </p>
          <Link
            className="hyperlink-1"
            to={"/projects/" + project.id + "/manuscript"}
          >
            Manuscript <i className="material-icons md-18">arrow_forward</i>
          </Link>
        </div>
      </div>
    );
  }
}

export default withRouter(ProjectDashboard);
