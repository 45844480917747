import React, { Component, Fragment } from "react";
import { Button, Modal } from "react-bootstrap";
import { Redirect } from "react-router-dom";

import { post, get } from "../../../utils/DeApi";
import { FieldGroup } from "../../../utils/BootstrapUtils";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

/**
 * Handles creation of a theme using a modal.
 */
class ThemeCreate extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
      show: false,
      title: "",
      description: "",
      themeType: "5bb22abc1f8f9",
      created: false
    };

    this.handleShowModal = this._handleShowModal.bind(this);
    this.handleCloseModal = this._handleCloseModal.bind(this);
    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleSubmitAndNav = this._handleSubmitAndNav.bind(this);
    this.handleInputChange = this._handleInputChange.bind(this);
  }

  _handleCloseModal() {
    this.setState({ show: false });
  }

  _handleShowModal() {
    this.setState({ show: true });
  }

  _handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  _handleSubmit(event, callback) {
    event.preventDefault();
    const { title, description, themeType } = this.state;
    const { project } = this.props;
    if (title && project) {
      this.setState({ isLoading: true });
      const createPromise = post("/themes", {
        project: project.id,
        title: title,
        description: description,
        themeType: themeType
      });
      createPromise.promise
        .then((response) => {
          if (callback) {
            callback(response.data);
          } else {
            this.setState({
              show: false,
              isLoading: false,
              error: "",
              title: "",
              description: "",
              themeType: ""
            });
            this.props.onThemeCreated(response.data);
          }
        })
        .catch((error) => {
          !error.isCanceled &&
            this.setState({
              error: error,
              isLoading: false
            });
        });
      this.subscribedPromises.push(createPromise);
    }
  }

  _handleSubmitAndNav(event) {
    let redirectToPillar = function(pillar) {
      this.setState({
        error: "",
        title: "",
        description: "",
        created: pillar
      });
    };
    this.handleSubmit(event, redirectToPillar.bind(this));
  }

  fetchThemeTypes() {
    let themeTypesPromise = get("/theme-types");
    themeTypesPromise.promise
      .then((response) => {
        this.setState({
          themeTypes: response.data
        });
      })
      .catch((error) => {
        !error.isCanceled &&
          this.setState({
            error: error
          });
      });
    this.subscribedPromises.push(themeTypesPromise);
  }

  componentDidMount() {
    this.fetchThemeTypes();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  render() {
    const {
      show,
      error,
      created,
      themeTypes,
      title,
      description,
      themeType,
      isLoading,
      validate
    } = this.state;
    const { project } = this.props;
    if (created) {
      return (
        <Redirect
          to={"/projects/" + project.id + "/themes/" + created.id}
          push
        />
      );
    }

    const form =
      themeTypes && !isLoading ? (
        <form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className="mt-0">
              Start with a title, type, and summary
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FieldGroup
              id="themeTitle"
              name="title"
              label="Theme Title"
              type="text"
              onChange={this.handleInputChange}
              value={title}
              placeholder="Enter theme title"
              autoFocus
              required
              maxLength={81}
              validationState={
                title.length > 80 || (validate && !title) ? "error" : null
              }
            />
            <FieldGroup
              id="themeType"
              name="themeType"
              label="Theme Type"
              type="select"
              componentClass="select"
              placeholder="Select theme type"
              onChange={this.handleInputChange}
              validationState={validate && !themeType ? "error" : null}
              value={themeType}
              required
            >
              <option value="" defaultValue hidden>
                Select theme type
              </option>
              {themeTypes
                .sort((a, b) => {
                  if (a.id < b.id) return -1;
                  if (a.id > b.id) return 1;
                  return 0;
                })
                .map((themeType) => {
                  return (
                    <option value={themeType.id} key={themeType.id}>
                      {themeType.name}
                    </option>
                  );
                })}
            </FieldGroup>
            <FieldGroup
              id="themeDescription"
              name="description"
              label="Theme Summary"
              componentClass="textarea"
              rows="8"
              onChange={this.handleInputChange}
              value={description}
              placeholder="Enter theme summary (optional)"
              maxLength={5100}
              validationState={description.length > 5000 ? "error" : null}
            />

            {error && <ErrorHandler error={error} />}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleCloseModal} bsStyle="link">
              Cancel
            </Button>
            <Button type="submit" bsStyle="default">
              Save
            </Button>
            <Button onClick={this.handleSubmitAndNav} bsStyle="primary">
              Save and Write
            </Button>
          </Modal.Footer>
        </form>
      ) : (
        <Loader />
      );

    return (
      <Fragment>
        <Button
          className={this.props.className}
          bsStyle="success"
          onClick={this.handleShowModal}
          block
        >
          {this.props.label || "Create Theme"}
        </Button>
        <Modal show={show} onHide={this.handleCloseModal} backdrop="static">
          {form}
        </Modal>
      </Fragment>
    );
  }
}

export default ThemeCreate;
