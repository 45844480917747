import React, { Component } from "react";
import Moment from "moment";
import { Link, Redirect } from "react-router-dom";
import { Button, Label, Row, Col } from "react-bootstrap";
import "./PromptItem.css";

import TextExtractionUtil from "../../RichTextEditor/Controls/TextExtractionUtil";
import { get, post } from "../../../utils/DeApi";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class PromptItem extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};
  }

  componentDidMount() {
    this.getPromptResponse();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  getPromptResponse() {
    this.setState({
      error: "",
      isLoading: true
    });
    const prompt = this.props.prompt;

    let promptResponsePromise = get("/prompts", {
      params: {
        contentIds: [prompt.contentId]
      }
    });

    promptResponsePromise.promise
      .then(response => {
        // @TODO us the api to fetch by contentId
        let promptResponse = response.data.find(item => {
          return item.contentId === prompt.contentId;
        });
        this.setState({
          promptResponse: promptResponse,
          error: "",
          isLoading: false
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error,
            isLoading: false
          });
      });

    this.subscribedPromises.push(promptResponsePromise);
  }

  createPromptResponse() {
    this.setState({
      createResponseError: "",
      responseIsCreating: false
    });

    const { prompt } = this.props;
    const createResponsPromise = post("/prompts", {
      contentId: prompt.contentId
    });

    createResponsPromise.promise
      .then(response => {
        this.setState({
          createdPromptResponse: response.data,
          createResponseError: "",
          responseIsCreating: false
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            createResponseError: error,
            responseIsCreating: false
          });
      });

    this.subscribedPromises.push(createResponsPromise);
  }

  render() {
    const { isLoading, error, promptResponse } = this.state;

    if (error) return <ErrorHandler error={error} />;
    if (isLoading) return <Loader />;
    if (!promptResponse) return this.renderPromptWithoutResponse();
    return this.renderPromptWithResponse();
  }

  renderPromptWithoutResponse() {
    const {
      createResponseError,
      responseIsCreating,
      createdPromptResponse
    } = this.state;

    const { prompt } = this.props;

    if (createResponseError)
      return <ErrorHandler error={createResponseError} />;
    if (responseIsCreating) return <Loader />;
    if (createdPromptResponse) {
      return (
        <Redirect
          to={`/prompts/${createdPromptResponse.id}/${Moment(
            prompt.rankDate
          ).format("YYYY-MM-DD")}`}
          push
        />
      );
    }

    return (
      <Row
        key={prompt.id}
        id={`prompt-of-${Moment(prompt.rankDate)
          .startOf("day")
          .valueOf()}`}
      >
        <Col xs={12} sm={2}>
          <p className="body-2" title="Published Date">
            {Moment(prompt.rankDate).format("ll")}
          </p>
        </Col>
        <Col xs={12} sm={6}>
          <p className="mb-0">{prompt.title}</p>
          <Button
            className="pl-0"
            bsStyle="link"
            onClick={() => this.createPromptResponse()}
          >
            Take a look <i className="material-icons md-18">arrow_forward</i>
          </Button>
        </Col>
        <Col xs={12} sm={4}>
          <p className="text-right">
            <span>
              {prompt.categories.map((category, index) => {
                return (
                  <Label
                    key={category.categoryId}
                    className="label-emphasis tag ml-xs"
                  >
                    {category.categoryName}
                  </Label>
                );
              })}
            </span>
          </p>
        </Col>
      </Row>
    );
  }

  renderPromptWithResponse() {
    const { promptResponse } = this.state;
    const { prompt } = this.props;

    let plainText = TextExtractionUtil.extractPlainText(
      promptResponse.body
    ).substring(0, 72);
    plainText =
      plainText.length > 72 ? plainText : plainText.substring(0, 70) + "...";

    return (
      <Row
        key={prompt.id}
        id={`prompt-of-${Moment(prompt.rankDate)
          .startOf("day")
          .valueOf()}`}
      >
        <Col xs={12} sm={2}>
          <p title="Published Date" className="body-2">
            {Moment(prompt.rankDate).format("ll")}
          </p>
        </Col>
        <Col xs={12} sm={6}>
          <p className="mb-0">
            <Link
              href={`/prompts/${promptResponse.id}/${Moment(
                prompt.rankDate
              ).format("YYYY-MM-DD")}`}
              to={`/prompts/${promptResponse.id}/${Moment(
                prompt.rankDate
              ).format("YYYY-MM-DD")}`}
            >
              {prompt.title}
            </Link>
          </p>
          <p className="mb-0">
            <small>{plainText}</small>
          </p>
        </Col>
        <Col xs={12} sm={4}>
          <p className="text-right">
            <span>
              {prompt.categories.map((category, index) => {
                return (
                  <Label
                    key={category.categoryId}
                    className="label-emphasis tag ml-xs"
                  >
                    {category.categoryName}
                  </Label>
                );
              })}
            </span>
          </p>
          <p className="text-right sub-text">
            Updated{" "}
            {Moment.utc(promptResponse.updatedAt)
              .local()
              .fromNow()}
          </p>
        </Col>
      </Row>
    );
  }
}

export default PromptItem;
