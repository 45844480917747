import React, { Fragment, useEffect, useRef, useState } from "react";
import { Alert } from "react-bootstrap";
import { isLoggedIn, get } from "../../../../utils/NaNoWriMoApi";
import Loader from "../../../Loader/Loader";
import NaNoWriMoErrors from "../NaNoWriMoErrors/NaNoWriMoErrors";
import NaNoWriMoLink from "../NaNoWriMoLink/NaNoWriMoLink";

const NaNoWriMoEvent = ({
  wordCount = 0,
  success,
  projectName,
  projectId,
  bookflowNaNoWrimoId,
  onProjectUpdated
}) => {
  const subscribedPromises = useRef([]);
  const [error, setError] = useState(null);
  const [projectEvent, setProjectEvent] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    function fetchNaNoWriMo() {
      setError(null);
      setIsLoading(true);
      const NaNoWriMoPromise = get("/event-project");
      NaNoWriMoPromise.promise
        .then(res => setProjectEvent(res))
        .catch(err => setError(err))
        .finally(() => setIsLoading(false));

      subscribedPromises.current.push(NaNoWriMoPromise);
    }

    if (isLoggedIn() || success) fetchNaNoWriMo();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(promise => {
        promise.cancel();
      });
    };
  }, [success]);

  if (!isLoggedIn()) return <Fragment />;
  if (isLoading) return <Loader />;
  if (error) return <NaNoWriMoErrors error={error} />;
  if (projectEvent?.alert)
    return (
      <Alert bsStyle="warning">
        <small>{projectEvent?.alert}</small>
      </Alert>
    );

  return (
    <>
      <strong className="mt-md">Event</strong>
      <h3>
        {projectEvent?.event?.title}{" "}
        <span
          className={`pull-right ${
            projectEvent?.event?.status === "active" ? "text-success" : ""
          }`}
        >
          {projectEvent?.event?.status}
        </span>
      </h3>
      <strong>Project</strong>
      <h3>{projectEvent?.project?.title}</h3>

      <h3>
        NaNoWriMo Word count{" "}
        <span className="pull-right">{projectEvent?.project?.wordcount}</span>
      </h3>

      <h3>
        Bookflow Word count <span className="pull-right">{wordCount}</span>
      </h3>

      <NaNoWriMoLink
        projectId={projectId}
        projectName={projectName}
        bookflowNaNoWrimoId={bookflowNaNoWrimoId}
        nanoWrimoId={projectEvent?.project?.id}
        onProjectUpdated={data => onProjectUpdated(data)}
      />
    </>
  );
};

export default NaNoWriMoEvent;
