import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import moment from "moment";
import "./ContentEvent.css";

const ContentEvent = ({
  image,
  title,
  eventStartDate,
  eventEndDate,
  address1,
  address2,
  city,
  state,
  zipCode,
  phoneNumber,
  email,
  contentBody
}) => (
  <div>
    <Col xs={12} className="pt-0 pr-sm pb-0 pl-sm">
      <Row className="pt-0 pr-xs pb-0 pl-xs">
        <div className="image-container">
          <Image src={image} className="img-responsive center-block" />
        </div>
      </Row>
    </Col>
    <Col xs={12} className="pt-sm pr-sm pb-sm pl-sm">
      <h1 className=" text-center">{title}</h1>
      <hr />
      <h4 className="text-uppercase text-center">
        {moment(eventStartDate).format("lll")} -{" "}
        {moment(eventEndDate).format("lll")}
      </h4>
      <hr />
      <address className="text-center text-capitalize">
        {address1}
        <br />
        {address2}
        <br />
        <br />
        {city}
        {state}, {zipCode}
        <br />
        <abbr title="Phone">Phone:</abbr> {phoneNumber}
        <br />
        <a href="mailto:#">{email}</a>
      </address>
      <span className=" text-center" id="social-sharing-location" />
      <hr />
      <div
        className="body-section"
        dangerouslySetInnerHTML={{ __html: contentBody }}
      />
      <hr />
    </Col>
  </div>
);

export default ContentEvent;
