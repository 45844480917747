export function setLayout(name, layout = "layout") {
  localStorage.setItem(layout, name);
}

export function getLayout(layout = "layout") {
  return localStorage.getItem(layout);
}

export function clearLayout(layout = "layout") {
  localStorage.removeItem(layout);
}
