import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { get, post } from "../../../utils/BeeApi";

const LOCALSTORAGE_KEY = "utm_source";

/**
 * Handles writers page.
 */
class AcquisitionSource extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};
  }

  componentDidMount() {
    this.setState(
      {
        localSource: window.localStorage.getItem(LOCALSTORAGE_KEY)
      },
      () => {
        if (this.state.localSource) this.fetchSubscriber();
      }
    );
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  updateAcquisitionSource() {
    const { subscriber, localSource } = this.state;

    if (subscriber.firstName && subscriber.lastName) {
      console.log("begin upate");
      const formData = new FormData();
      formData.append("firstName", subscriber.firstName);
      formData.append("lastName", subscriber.lastName);
      formData.append("about", subscriber.about);
      formData.append("acquisitionSource", localSource);
      formData.append("_method", "PUT");

      const updatePromise = post("/user/anon", formData);
      updatePromise.promise
        .then(response => {
          console.info("successfully added source ...");
          window.localStorage.removeItem(LOCALSTORAGE_KEY);
        })
        .catch(error => {
          !error.isCanceled && console.error("Failed to update ...", error);
        });
      this.subscribedPromises.push(updatePromise);
    }
  }

  fetchSubscriber() {
    console.info("fetching info...");
    let subscriberPromise = get("/user/anon");
    subscriberPromise.promise
      .then(response => {
        console.info("Info available...");
        this.setState(
          {
            subscriber: response.data,
            error: ""
          },
          () => {
            const { subscriber, localSource } = this.state;

            if (!subscriber.acquisitionSource && localSource) {
              console.log("proceed");
              this.updateAcquisitionSource();
            } else window.localStorage.removeItem(LOCALSTORAGE_KEY);
          }
        );
      })
      .catch(error => {
        if (!error.isCanceled) {
          console.error("Info error...", error);
        }
      });
    this.subscribedPromises.push(subscriberPromise);
  }

  render() {
    const { subscriber } = this.state;
    const { user } = this.props;

    if (!subscriber) return <div />;
    if (subscriber.userId !== user.sub) return <Redirect to="/" />;

    return <div />;
  }
}

export default AcquisitionSource;
