import React, { Component } from "react";
import { get } from "../../../../utils/BeeApi";
import { Label, Modal } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Moment from "moment";
import Loader from "../../../Loader/Loader";
import ErrorHandler from "../../../ErrorHandler/ErrorHandler";
import "./Content.css";

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.subscribedPromises = [];
  }

  toggleModal = event => {
    this.setState(
      {
        show: !this.state.show
      },
      () => {
        if (this.state.show) {
          let modalImage = document.getElementById("expandedImage");
          modalImage.src = event.target.src;
          modalImage.alt = event.target.alt;
        }
      }
    );
  };

  componentDidMount() {
    this.getContent();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  expandImage = () => {
    let contentImage = document.querySelector(".Content img");
    if (contentImage) {
      contentImage.onclick = this.toggleModal;
    }
  };

  expandImageModal() {
    const { show, content } = this.state;
    return (
      <Modal
        size="lg"
        className="modal-width"
        aria-labelledby="contained-modal-title-vcenter"
        show={show}
        onHide={this.toggleModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>{content.title}</Modal.Title>
        </Modal.Header>
        <img id="expandedImage" alt="" />
      </Modal>
    );
  }

  getContent() {
    this.setState({
      error: "",
      isLoading: true
    });

    const contentPromise = get("contents/" + this.props.contentId, {});
    contentPromise.promise
      .then(response => {
        this.setState(
          {
            content: response.data,
            error: "",
            isLoading: false
          },
          () => {
            //attach events to image
            this.expandImage();
          }
        );
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error,
            isLoading: false
          });
      });

    this.subscribedPromises.push(contentPromise);
  }

  render() {
    const { content, isLoading, error } = this.state;

    if (error) return <ErrorHandler error={error} />;
    if (isLoading) return <Loader />;
    if (!content) return <Loader />;

    return (
      <div className="Article-Container mt-sm mb-sm">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`Bookflow - Prompts: ${content.title}`}</title>
        </Helmet>
        <p className="pt-xs">
          {content.categories &&
            content.categories.map((category, index) => {
              return (
                <Label key={category.categoryId} className="mr-xs tag">
                  {category.categoryName}
                </Label>
              );
            })}
          <small>
            {content.categories &&
              content.categories.map((category, index) => {
                return category.categoryDescription;
              })}
          </small>
        </p>
        <hr />
        <div className="mt-sm mb-sm">
          <h2>{content.title}</h2>
          <div
            className="Content mt-sm mb-sm"
            dangerouslySetInnerHTML={{ __html: content.contentBody }}
          />
          <p className="body-2" title="Published Date">
            <i className="material-icons mr-xs">today</i>
            {Moment(content.rankDate).format("ll")}
          </p>
          {this.expandImageModal()}
        </div>
      </div>
    );
  }
}

export default Content;
