import React, { useState } from "react";
import { Button, Col, Image, Modal, Row } from "react-bootstrap";
import NaNoWriMoEvent from "./NaNoWriMoEvent/NaNoWriMoEvent";
import NaNoWriMoLogin from "./NaNoWriMoLogin/NaNoWriMoLogin";
import SyncWordCount from "./SyncWordCount/SyncWordCount";
import "./NaNoWriMo.css";

const NaNoWriMo = ({ project, stats, onProjectUpdated }) => {
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);

  const toggleModal = () => setShow(prevStatus => !prevStatus);

  const wordCount = stats?.sceneStats.reduce(function(memo, sceneStats) {
    return memo + (sceneStats.words || 0);
  }, 0);

  return (
    <div>
      <Button onClick={toggleModal} bsStyle="link">
        NaNoWriMo <span className="badge">Beta</span>
      </Button>
      <Modal show={show} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>Connect NaNoWriMo Project</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-md">
          <Row>
            <Col xs={5} className="text-right">
              <Image src="/NaNoWriMo-logo.png" className="NaNoWriMoLogo" />
            </Col>
            <Col xs={2} className="text-center">
              <i className="material-icons md-32 mt-md">sync_alt</i>
            </Col>
            <Col xs={5} className="text-left">
              <Image src="/bookflow-logo.png" className="bookflowLogo mt-xs" />
            </Col>
            <Col xs={12} className="mt-sm pl-lg pr-lg">
              <p className="text-center">
                <small>
                  Log in using your NaNoWriMo credentials, and we will connect a
                  Bookflow project to a NaNoWriMo Project to automatically track
                  your word count in both places!
                </small>
              </p>
            </Col>
          </Row>
          <hr />
          <NaNoWriMoLogin onLoginSuccess={() => setSuccess(true)} />
          {project?.nanoWrimoId && (
            <SyncWordCount
              stats={stats}
              success={success}
              wordCount={wordCount}
            />
          )}

          <NaNoWriMoEvent
            wordCount={wordCount}
            success={success}
            projectId={project?.id}
            projectName={project?.name}
            bookflowNaNoWrimoId={project?.nanoWrimoId}
            onProjectUpdated={data => onProjectUpdated(data)}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NaNoWriMo;
