import React, { Component } from "react";
import "./ContentWhatIsNewSummaryList.css";
import { Link } from "react-router-dom";

import { get } from "../../../../utils/BeeApi";
import { slugify } from "../../../../utils/StringUtils";

import Loader from "../../../Loader/Loader";
import ErrorHandler from "../../../ErrorHandler/ErrorHandler";
import moment from "moment";

class ContentWhatIsNewSummaryList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.subscribedPromises = [];
  }

  componentDidMount() {
    this.fetchContents();
  }

  fetchContents() {
    const { limit, offset, operand, rank, category } = this.props;

    this.setState({
      error: "",
      isLoading: true
    });

    const options = {
      limit: limit || 50,
      offset: offset || 0,
      operand: operand || "or",
      rank: rank || null,
      category: category || null
    };
    const createPromise = get("/contents", { params: options });
    createPromise.promise
      .then(response => {
        this.setState({
          contentSummaryList: response.data,
          error: "",
          isLoading: false
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            isLoading: false,
            error: error
          });
      });

    this.subscribedPromises.push(createPromise);
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  render() {
    const { error, isLoading, contentSummaryList } = this.state;

    if (error) return <ErrorHandler error={error} />;
    if (isLoading) return <Loader />;

    return (
      <div className="ContentWhatIsNewSummaryList">
        <ul className="list-styled">
          {contentSummaryList &&
            contentSummaryList.map(content => {
              return this.props.contentId === content.contentId ? (
                <span key={content.contentId} />
              ) : (
                <li key={content.contentId} className="mt-xs mb-xs">
                  <small>{moment(content.rankDate).format("ll")}</small>
                  <p className="mb-0">
                    <strong>
                      <Link
                        className="hyperlink-1"
                        to={
                          "/what-is-new/" +
                          content.contentId +
                          "/" +
                          slugify(content.title)
                        }
                      >
                        {content.title}
                      </Link>
                    </strong>
                  </p>
                  <p className="description mb-0">
                    {content.description.length < 50
                      ? content.description
                      : content.description.substring(0, 47) + "..."}
                  </p>
                </li>
              );
            })}
        </ul>
      </div>
    );
  }
}

export default ContentWhatIsNewSummaryList;
