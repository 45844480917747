import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";

const NaNoWriMoErrors = ({ error, message, className }) => {
  const [status, setStatus] = useState("");
  const [statusText, setStatusText] = useState("Oh snap! You got an error!");
  const [errorMsg, setErrorMsg] = useState(
    "Something unexpected happened. Please try again."
  );

  useEffect(() => {
    if (error) {
      if (error.status) {
        setStatus(error.status);
      }
      if (error.statusText) {
        setStatusText(error.statusText);
      }

      if (error?.data?.error) {
        setErrorMsg(error?.data?.error);
      }
    }
  }, [error]);

  return (
    <Alert bsStyle="warning" className={`mt-3 mb-3 ${className}`}>
      <h4>{statusText}</h4>
      <p>
        <small>{message || errorMsg}</small>
      </p>
      <p>
        <small>{status}</small>
      </p>
    </Alert>
  );
};

export default NaNoWriMoErrors;
