import React, { Fragment, useEffect, useRef, useState } from "react";
import { Alert } from "react-bootstrap";
import { put, isLoggedIn } from "../../../../utils/NaNoWriMoApi";
import Loader from "../../../Loader/Loader";
import NaNoWriMoErrors from "../NaNoWriMoErrors/NaNoWriMoErrors";

function SyncWordCount({ wordCount, success }) {
  const subscribedPromises = useRef([]);
  const [error, setError] = useState(null);
  const [countEvent, setCountEvent] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    function updateNaNoWriMoWordCount(wordCount) {
      setIsLoading(true);
      const NaNoWriMoPromise = put("/event-project", {
        wordcount: wordCount
      });

      NaNoWriMoPromise.promise
        .then(response => setCountEvent(response))
        .catch(error => setError(error))
        .finally(() => setIsLoading(false));

      subscribedPromises.current.push(NaNoWriMoPromise);
    }
    if (isLoggedIn() || success) updateNaNoWriMoWordCount(wordCount);
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach(promise => {
        promise.cancel();
      });
    };
  }, [wordCount, success]);

  if (isLoading) return <Loader />;
  if (error) return <NaNoWriMoErrors error={error} />;
  if (countEvent?.alert)
    return (
      <Alert bsStyle="warning">
        <small>{countEvent?.alert}</small>
      </Alert>
    );
  return <Fragment />;
}

export default SyncWordCount;
