import React, { Component } from "react";
import { Grid, Col, Row } from "react-bootstrap";
import { get as getDE, put } from "../../../utils/DeApi";
import "./UpdatePrompt.css";
import debounce from "lodash/debounce";
import RichTextEditor from "../../RichTextEditor/RichTextEditor";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import Content from "./Content/Content";
import ReAuth from "../../App/ReAuth/ReAuth";

class UpdatePrompt extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
      isLoading: false,
      error: "",
      isMobile: /mobi/i.test(navigator.userAgent.toLowerCase()),
      reAuthenticate: false
    };

    this.handleUpdatePromptUpdated = debounce((body, success, error) => {
      this.updatePromptResponse(body, success, error);
    }, 1000);

    this.handlePromptStatsUpdated = debounce((stats, success, error) => {
      this.updatePromptStats(stats, success, error);
    }, 1000);

    this.handleReauthenticated = this._handleReauthenticated.bind(this);
  }

  updatePromptResponse(body, successCallback, errorCallback) {
    let updateBodyPromise = put("/prompts/" + this.props.promptId, {
      body: JSON.stringify(body)
    });

    this.setState({ reAuthenticate: false });
    updateBodyPromise.promise
      .then(response => {
        successCallback(response);
      })
      .catch(error => {
        if (!error.isCanceled) {
          if (error.status === 401) this.setState({ reAuthenticate: true });
          errorCallback(error);
        }
      });
    this.subscribedPromises.push(updateBodyPromise);
  }

  updatePromptStats(stats, successCallback, errorCallback) {
    if (stats) {
      let updateStatsPromise = put(
        "/prompts/" + this.props.promptId + "/stats",
        stats
      );
      this.setState({ reAuthenticate: false });
      updateStatsPromise.promise
        .then(response => {
          successCallback(response);
        })
        .catch(error => {
          if (!error.isCanceled) {
            if (error.status === 401) this.setState({ reAuthenticate: true });
            errorCallback(error);
          }
        });
      this.subscribedPromises.push(updateStatsPromise);
    }
  }

  _handleReauthenticated() {
    this.setState({ reAuthenticate: false });
  }

  componentDidMount() {
    this.getPrompt();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  getPrompt() {
    this.setState({
      error: "",
      isLoading: true
    });

    const promptPromise = getDE("prompts/" + this.props.promptId, {});

    promptPromise.promise
      .then(response => {
        this.setState({
          prompt: response.data,
          error: "",
          isLoading: false
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error,
            isLoading: false
          });
      });
    this.subscribedPromises.push(promptPromise);
  }

  render() {
    const { prompt, isLoading, error, reAuthenticate, isMobile } = this.state;

    if (error) return <ErrorHandler error={error} />;
    if (isLoading) return <Loader />;
    if (!prompt) return <Loader />;

    return (
      <Grid fluid className="PillarView">
        <Row className="PillarCanvas">
          <Col xs={12} md={3} className="PillarLeft">
            <div className="mt-md mb-md">
              <Content contentId={prompt.contentId} />
            </div>
          </Col>
          <Col xs={12} md={6} className="PillarCenter">
            <div className="mt-md mb-xl">
              <Row className="PillarEditor">
                <Col xs={12}>
                  <RichTextEditor
                    richText={prompt.body}
                    controls={!isMobile}
                    counter={true}
                    placeholder="Respond to prompt ..."
                    onRichTextUpdated={this.handleUpdatePromptUpdated}
                    onStatsUpdated={this.handlePromptStatsUpdated}
                    isPrompt={true}
                  />
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={12} md={3} className="PillarRight" />
          {!!reAuthenticate && (
            <ReAuth onReauthenticated={this.handleReauthenticated} />
          )}
        </Row>
      </Grid>
    );
  }
}

export default UpdatePrompt;
