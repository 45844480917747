import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  Grid,
  Col,
  Row,
  Button,
  Image,
  Checkbox,
  Panel,
  Alert
} from "react-bootstrap";
import FacebookLogin from "react-facebook-login";

import "./Signup.css";
import { FieldGroup } from "../../../utils/BootstrapUtils";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

import { isLoggedIn, post, facebookLogin } from "../../../utils/BeeApi";
import {
  isLoggedIn as isLoggedInDE,
  login as loginDE
} from "../../../utils/DeApi";

class Signup extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];

    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleEmailChange = this._handleEmailChange.bind(this);
    this.handlePasswordChange = this._handlePasswordChange.bind(this);
    this.handleInputChange = this._handleInputChange.bind(this);
    this.recaptchaRef = React.createRef();
    const loggedIn = isLoggedInDE() && isLoggedIn();

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      emailValid: "",
      passwordValid: "",
      formError: "",
      loggedIn: loggedIn
    };
  }

  componentDidMount() {
    this.initReCaptcha();
  }

  _handleEmailChange(event) {
    let email = event.target.value;
    this.setState({
      email: email,
      emailValid: email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
        ? true
        : false
    });
  }

  _handlePasswordChange(event) {
    let password = event.target.value;
    this.setState({
      password: password,
      passwordValid: password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/)
        ? true
        : false
    });
  }

  _handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  _handleSubmit(event) {
    event.preventDefault();

    try {
      let grecaptcha = window.grecaptcha;
      var captcha = grecaptcha.getResponse();
    } catch (error) {
      console.warn(error);
      captcha = null;
    }

    this.setState({ formSubmitted: true, captcha: captcha }, () => {
      const {
        firstName,
        lastName,
        emailValid,
        passwordValid,
        captcha
      } = this.state;
      if (!emailValid || !passwordValid || !firstName || !lastName || !captcha)
        console.info("Invalid params");
      else this.registUser();
    });
  }

  registUser() {
    const { firstName, lastName, email, password, captcha } = this.state;
    this.setState({ error: "", isLoading: true });

    const userPromise = post(`user`, {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      password_confirmation: password,
      captcha: captcha
    });
    userPromise.promise
      .then(response => {
        this.setState(
          { registered: true, isLoading: false, error: false },
          () => this.resetCAPTCHA()
        );
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({ error: error, isLoading: false }, () =>
            this.resetCAPTCHA()
          );
      });

    this.subscribedPromises.push(userPromise);
  }

  responseFacebook = response => {
    let token = response.accessToken;
    if (token) {
      this.setState({ error: "", isLoading: true });
      facebookLogin({ token: token })
        .then(data => {
          return loginDE();
        })
        .then(data => {
          this.setState({ loggedIn: true, isLoading: false });
        })
        .catch(error => {
          this.setState({ error: error, isLoading: false });
        });
    }
  };

  componentClicked = () => {
    console.log("clicked");
  };

  renderMaintenanceMode() {
    return (
      <Panel>
        <Panel.Body className="text-center">
          <h1>Sorry, we are down for maintenance</h1>
          <hr />
          <p>We'll be back up shortly.</p>
          <p>
            Contact support at{" "}
            <span className="text-primary">team@bookflow.pub</span> for further
            assistance.
          </p>

          <h5>
            <Link to="/login">Login</Link>
          </h5>
        </Panel.Body>
      </Panel>
    );
  }

  renderRegistrationForm() {
    const APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
    const {
      error,
      firstName,
      lastName,
      email,
      emailValid,
      password,
      passwordValid,
      formSubmitted,
      captcha
    } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="mt-sm mb-md text-center">
          <h2>Start your FREE 28-day trial now!</h2>
        </div>
        <hr />
        <Row>
          <Col sm={6}>
            <FieldGroup
              id="firstName"
              name="firstName"
              label="First name"
              type="text"
              value={firstName}
              onChange={this.handleInputChange}
              placeholder="Enter first name"
              autoFocus
              validationState={formSubmitted && !firstName ? "error" : null}
              required
            />
          </Col>
          <Col sm={6}>
            <FieldGroup
              id="lastName"
              name="lastName"
              label="Last name"
              type="text"
              value={lastName}
              onChange={this.handleInputChange}
              placeholder="Enter last name"
              validationState={formSubmitted && !lastName ? "error" : null}
              required
            />
          </Col>
        </Row>
        <FieldGroup
          id="signUpEmail"
          label="Email address"
          type="email"
          value={email}
          onChange={this.handleEmailChange}
          validationState={formSubmitted && !emailValid ? "error" : null}
          placeholder="Enter email"
          required
          autoComplete="off"
          help={<small>We'll send an email to confirm your account.</small>}
        />
        <FieldGroup
          id="signUpPassword"
          label="Password"
          type="password"
          value={password}
          onChange={this.handlePasswordChange}
          validationState={formSubmitted && !passwordValid ? "error" : null}
          help={
            <small>
              The password must contain at least 6 characters, including
              UPPER/lowercase and numbers.
            </small>
          }
          placeholder="Create Password"
          autoComplete="off"
          required
        />
        <Checkbox required id="agreeToTerms">
          <small>
            I agree to the{" "}
            <a href="http://about.bookflow.pub/terms">Terms of Service</a> and
            have read and acknowledged the{" "}
            <a href="http://about.bookflow.pub/privacy">Privacy Statement</a>.
          </small>
        </Checkbox>
        <div className="form-group">
          <div
            ref={this.recaptchaRef}
            className="g-recaptcha"
            data-sitekey={process.env.REACT_APP_RECAPTCHA}
          />
          {formSubmitted && !captcha && (
            <small className="text-danger">
              Please complete the security check above.
            </small>
          )}
        </div>
        {error && this.renderError()}
        <Button type="submit" bsStyle="primary" block>
          Create Account
        </Button>

        <p className="text-center pt-xs">or</p>
        {/* <FacebookLogin
          appId={APP_ID}
          autoLoad={false}
          fields="name,email,picture"
          onClick={this.componentClicked}
          callback={this.responseFacebook}
          disableMobileRedirect={true}
          textButton="Sign up with Facebook"
          icon={<i className="material-icons">facebook</i>}
          cssClass="btn btn-default btn-block fb-btn"
        /> */}

        <Button
          block
          className="fb-btn"
          onClick={() =>
            this.setState({
              showInfo: true
            })
          }
        >
          <i className="material-icons">facebook</i> Sign up with Facebook
        </Button>
        {this.state.showInfo && (
          <Alert className="mt-xs">
            Facebook's protocols have changed and we are in the process of
            updating this integration. In the meantime, please use{" "}
            <Link to="/login-help">forgot password</Link>. We apologize for the
            inconvenience!
          </Alert>
        )}

        <hr />
        <div className="mt-sm mb-sm text-center">
          <h5>
            <Link to="/login">Login</Link>
          </h5>
          <h5>
            <Link to="/login-help">Forgot Password?</Link>
          </h5>
          <p className="sub-text">Contact Support at team@bookflow.pub</p>
        </div>
      </form>
    );
  }

  render() {
    const { loggedIn, isLoading, registered } = this.state;
    const { location } = this.props;

    if (loggedIn) {
      let { from } = location.state || {
        from: { pathname: "/" }
      };
      return <Redirect to={from} />;
    }

    if (isLoading) return <Loader />;

    return (
      <Grid fluid className="SignUp mt-lg mb-lg">
        <Row>
          <Col xs={12} sm={6} smOffset={3} md={4} mdOffset={4}>
            <div className="mb-md text-center">
              <a href="http://about.bookflow.pub">
                <Image src="/bookflow-logo.png" width="300px" />
              </a>
            </div>

            <div className="card card-neutral">
              {registered ? (
                <div className="SignUpSuccess mt-sm mb-sm text-center">
                  <h2 className="mt-sm mb-sm text-center">Success!</h2>
                  <hr />
                  <p>
                    Thank you for joining Bookflow! For account security, please
                    log in to continue.
                  </p>
                  <Button
                    componentClass={Link}
                    to="/login"
                    bsStyle="primary"
                    size="sm"
                  >
                    Continue to Login
                  </Button>
                  <hr />
                  <p className="sub-text">
                    Contact Support at team@bookflow.pub
                  </p>
                </div>
              ) : (
                this.renderRegistrationForm()
              )}
            </div>

            <ul className="list-inline text-center mt-xs mb-xs">
              <li>
                <a href="http://about.bookflow.pub">About</a>
              </li>
              <li>&copy; Bookflow 2021</li>
            </ul>
          </Col>
        </Row>
      </Grid>
    );
  }

  renderError() {
    const { error } = this.state;

    let message = "";
    if (error.status === 409) {
      error.statusText = "Account has already been registered.";
      message =
        "The email address is already associated with a Bookflow account. Please login in again.";
    } else if (error.status === 410) {
      error.statusText = "Account cannot be created.";
      message =
        "Please contact support at team@bookflow.pub about your account.";
    }

    return <ErrorHandler error={error} message={message} />;
  }

  initReCaptcha() {
    const script = document.createElement("script");

    script.src = `https://www.google.com/recaptcha/api.js`;
    script.async = true;
    script.defer = true;
    script.onload = () => {};
    document.body.appendChild(script);
  }

  resetCAPTCHA() {
    try {
      var grecaptcha = window.grecaptcha;
      grecaptcha.render(this.recaptchaRef.current);
    } catch (error) {
      console.warn(error);
    }
  }
}

export default Signup;
