import React, { Component } from "react";
import { Grid, Col, Row, Button, Image } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";

import { get, post } from "../../../utils/BeeApi";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

/**
 * Handles writers page.
 */
class Paywall extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};

    this.handleUserUpdated = this._handleUserUpdated.bind(this);
  }

  _handleUserUpdated(subscriber) {
    this.setState({
      subscriber: subscriber
    });
  }

  componentDidMount() {
    this.fetchSubscriber();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  fetchSubscriber() {
    const { user } = this.props;
    let subscriberPromise = get(`/user/${user.sub}`);
    this.setState({
      isLoading: true,
      error: ""
    });
    subscriberPromise.promise
      .then((response) => {
        let subscriber = response.data;
        if (!subscriber.trialEndsAt && !subscriber.isSubscribed)
          this.createSubscriptionTrial();
        else
          this.setState({
            subscriber: response.data,
            isLoading: false,
            error: ""
          });
      })
      .catch((error) => {
        if (!error.isCanceled) {
          this.setState({
            isLoading: false,
            error: error
          });
        }
      });
    this.subscribedPromises.push(subscriberPromise);
  }

  createSubscriptionTrial() {
    const { user } = this.props;
    let trialPromise = post(`/user/${user.sub}/trial`);
    trialPromise.promise
      .then((response) => {
        this.setState(
          {
            subscriber: response.data,
            isLoading: false,
            error: ""
          },
          () => {}
        );
      })
      .catch((error) => {
        if (!error.isCanceled) {
          this.setState({
            isLoading: false,
            error: error
          });
        }
      });
    this.subscribedPromises.push(trialPromise);
  }

  render() {
    const { destination } = this.props;
    const { error, isLoading, subscriber } = this.state;

    if (error) return <ErrorHandler error={error} />;
    if (isLoading) return <Loader />;
    if (subscriber && subscriber.trialEndsAt && subscriber.isSubscribed)
      return <Redirect to={{ pathname: "/" }} />;

    return (
      <Grid className="Paywall mt-lg mb-lg">
        <Row>
          <Col xs={12} sm={6} smOffset={3} md={4} mdOffset={4}>
            <div className="mb-md text-center">
              <a href="http://about.bookflow.pub">
                <Image src="/bookflow-logo.png" width="300px" />
              </a>
            </div>
          </Col>
          <Col xs={12} md={8} mdOffset={2} className="mt-md mb-md">
            <div className="card card-neutral text-center">
              <h2>Please subscribe to unlock editing features</h2>
              <hr />
              <p className="mb-md mt-md">
                You may use Bookflow in Read-Only mode and export your work
                anytime. However, you will need an active subscription plan to
                access editing features on Bookflow.
              </p>
              <p>
                <Button componentClass={Link} to="/account" bsStyle="primary">
                  Subscribe Now
                </Button>
              </p>
              <p>
                <Button
                  componentClass={Link}
                  to={destination || "/"}
                  bsStyle="link"
                >
                  Continue to Read-Only mode
                </Button>
              </p>
              <hr />
              <p className="sub-text text-center">
                If you have any questions regarding pricing, payment, or
                features, please reach out to us at{" "}
                <a href="mailto:team@bookflow.pub">team@bookflow.pub</a>.{" "}
              </p>
            </div>
            <ul className="list-inline mt-sm mb-sm text-center">
              <li>
                <Link to={"/contents/5ca50577ad02f/faq"}>FAQ</Link>
              </li>
              <li>
                <Link to={"/contact"}>Contact Bookflow</Link>
              </li>
            </ul>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default Paywall;
