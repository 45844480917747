import React, { Component } from "react";
import "./RewardDector.css";

import { get } from "../../../../utils/BeeApi";
import { get as getDE, post } from "../../../../utils/DeApi";
import RewardSharer from "../RewardSharer/RewardSharer";

class RewardDetector extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.subscribedPromises = [];

    this.handleWordCountChange = this._handleWordCountChange.bind(this);
  }

  componentDidMount() {
    this.fetchProjectStats();
    this.fetchProjectRewards();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sceneStats?.words !== this.props.sceneStats?.words) {
      this.handleWordCountChange();
    }
  }

  fetchProjectStats() {
    const { project } = this.props;
    const projectStatsPromise = getDE("/projects/" + project.id + "/stats");
    projectStatsPromise.promise
      .then((response) => {
        this.setState({ projectStats: response.data, error: "" });
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error });
      });
    this.subscribedPromises.push(projectStatsPromise);
  }

  fetchProjectRewards() {
    const { project } = this.props;

    const projectRewardsPromise = getDE("/projects/" + project.id + "/rewards");
    projectRewardsPromise.promise
      .then((response) => {
        this.setState({ projectRewards: response.data, error: "" });
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error });
      });
    this.subscribedPromises.push(projectRewardsPromise);
  }

  postReward() {
    const { project } = this.props;
    const { reward } = this.state;

    const projectRewardsPromise = post("/projects/" + project.id + "/rewards", {
      content: reward,
    });
    projectRewardsPromise.promise
      .then((response) => {
        this.setState(
          (prevState, props) => {
            prevState.projectRewards.push(response.data);
            return { projectRewards: prevState.projectRewards };
          },
          () => this.fetchRewardContent()
        );
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error });
      });
    this.subscribedPromises.push(projectRewardsPromise);
  }

  fetchRewardContent() {
    const { reward } = this.state;
    if (!reward) return;
    const rewardContentPromise = get("/contents/" + reward);
    rewardContentPromise.promise
      .then((response) => {
        this.setState({ rewardContent: response.data });
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error });
      });
    this.subscribedPromises.push(rewardContentPromise);
  }

  _handleWordCountChange() {
    const { projectStats, projectRewards } = this.state;
    const { project, sceneStats } = this.props;

    if (!projectStats || !sceneStats || !projectRewards) return;

    const prevSceneStats = projectStats.sceneStats.find((stats) => {
      return stats.sceneId === sceneStats.sceneId;
    });

    const prevWords = prevSceneStats ? prevSceneStats.words : 0;

    const wordChange = sceneStats.words - prevWords;
    const words = projectStats.sceneStats.reduce((memo, stats) => {
      return memo + (stats.words || 0);
    }, wordChange || 0);

    const milestoneMap = {
      250: "5c928ef338dce",
      1000: "5c928dd087a3c",
      3933: "5c8ac23077ad2",
      5000: "5dc5b33e0f105",

      10000: "5c8bb9bab4e93",
      15000: "5dc5b3f3c67af",
      17084: "5dc5b467aa35f",

      20000: "5dc5b49a4a1fd",
      22416: "5dc5b4e680342",
      25000: "5dc5b5141e001",
      26601: "5dc5b56298e05",

      30000: "5dc5b598a22dd",
      36363: "5dc5b5c3584f6",

      46118: "5dc5b5f63af81",
      48523: "5dc5b62300117",

      50000: "5c92901773fc1",
      59635: "5dc5b6844a9db",

      63422: "5dc5b6e00dfea",
      66556: "5dc5b764dc6d2",

      70957: "5dc5b791e87d0",
    };

    //Completion Reward
    var completionReward = "5e986e587d1a6";
    if (project.words && project.words <= words) {
      if (
        !projectRewards.find((projectReward) => {
          return projectReward.contentId === "5e986e587d1a6";
        })
      ) {
        this.setState(
          { wordChange: wordChange, reward: completionReward },
          () => this.postReward()
        );
      }
    }

    // Milestone Reward
    var newReward = null;
    for (const key in milestoneMap) {
      const marker = parseInt(key, 10);
      if (words >= marker && words < marker + 50) {
        newReward = milestoneMap[key];
        break;
      }
    }

    if (!newReward) return;

    let alreadyRewarded = projectRewards.find((projectReward) => {
      return projectReward.contentId === newReward;
    });

    if (!alreadyRewarded) {
      this.setState({ wordChange: wordChange, reward: newReward }, () =>
        this.postReward()
      );
    }
  }

  showReward() {
    this.setState({ showReward: true });
  }

  hideReward() {
    this.setState({ showReward: false, rewardContent: null });
  }

  render() {
    const { showReward, rewardContent } = this.state;

    return rewardContent ? (
      <div className="RewardDector">
        <span className="label label-warning" onClick={() => this.showReward()}>
          {rewardContent.contentId === "5e986e587d1a6" ? (
            <i className="material-icons">auto_awesome</i>
          ) : (
            <i className="material-icons">auto_awesome</i>
          )}{" "}
          {rewardContent.title}
        </span>
        <RewardSharer
          rewardContent={rewardContent}
          show={showReward}
          onHide={() => this.hideReward()}
        />
      </div>
    ) : (
      <span />
    );
  }
}

export default RewardDetector;
