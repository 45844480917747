import React, { Fragment, useState } from "react";
import { Button } from "react-bootstrap";
import { FieldGroup } from "../../../../utils/BootstrapUtils";
import { login, isLoggedIn } from "../../../../utils/NaNoWriMoApi";
import Loader from "../../../Loader/Loader";
import NaNoWriMoErrors from "../NaNoWriMoErrors/NaNoWriMoErrors";

function NaNoWriMoLogin({ onLoginSuccess }) {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [submit, setSubmit] = useState(false);

  const handleSubmit = event => {
    if (event) event.preventDefault();
    if (!emailValid || !passwordValid) {
      setSubmit(true);
      return;
    }

    setError(null);
    setIsLoading(true);
    login({
      identifier: email,
      password: password
    })
      .then(data => onLoginSuccess(data))
      .catch(error => setError(error))
      .finally(() => setIsLoading(false));
  };

  if(isLoggedIn()) return <Fragment />
  if (isLoading) return <Loader />;

  return (
    <>
      <form className="LoginForm" onSubmit={handleSubmit}>
        <FieldGroup
          id="loginEmail"
          label="Email address"
          type="email"
          value={email}
          onChange={event => {
            let email = event.target.value;
            setEmail(email);
            setEmailValid(
              !!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
            );
          }}
          validationState={submit && !emailValid ? "error" : null}
          placeholder="Enter email"
          autoFocus
        />
        <FieldGroup
          id="loginPassword"
          label="Password"
          type="password"
          value={password}
          onChange={event => {
            let password = event.target.value;
            setPassword(password);
            setPasswordValid(!!password.length);
          }}
          validationState={submit && !passwordValid ? "error" : null}
          placeholder="Password"
        />
        {error && <NaNoWriMoErrors error={error} />}
        <Button type="submit" bsStyle="primary" block>
          Login
        </Button>
      </form>
    </>
  );
}

export default NaNoWriMoLogin;
