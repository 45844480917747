import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

import { post } from "../../../utils/BeeApi";
import { FieldGroup } from "../../../utils/BootstrapUtils";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import "./UserUpdate.css";

/**
 * Handles updating of writer's profile.
 */
class UserUpdate extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    const user = this.props.user;
    this.state = {
      show: false,
      firstName: user.firstName,
      lastName: user.lastName || "",
      about: user.about || ""
    };

    this.handleShowModal = this._handleShowModal.bind(this);
    this.handleCloseModal = this._handleCloseModal.bind(this);

    this.handleInputChange = this._handleInputChange.bind(this);
    this.handleSubmit = this._handleSubmit.bind(this);
  }

  _handleCloseModal() {
    this.setState({ show: false });
  }

  _handleShowModal() {
    this.setState({ show: true });
  }

  _handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  _handleSubmit(event) {
    event.preventDefault();
    const { firstName, lastName, about } = this.state;

    if (firstName && lastName) {
      this.setState({ isLoading: true });

      const formData = new FormData();
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);
      formData.append("about", about);
      formData.append("_method", "PUT");
      const updatePromise = post("/user/anon", formData);
      updatePromise.promise
        .then(response => {
          this.setState({
            show: false,
            error: "",
            isLoading: false
          });
          this.props.onUserUpdated(response.data);
        })
        .catch(error => {
          !error.isCanceled &&
            this.setState({
              error: error,
              isLoading: false
            });
        });
      this.subscribedPromises.push(updatePromise);
    }
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  render() {
    const { firstName, lastName, about, error, isLoading, show } = this.state;
    if (isLoading) return <Loader />;

    const form = (
      <form onSubmit={this.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title className="mt-0">Edit Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={this.state.showEditor ? "hidden" : ""}>
            <FieldGroup
              id="firstName"
              name="firstName"
              label="First Name"
              type="text"
              onChange={this.handleInputChange}
              value={firstName}
              placeholder="First Name"
              required
              maxLength={36}
              validationState={
                firstName.length > 32 || !firstName ? "error" : null
              }
              autoFocus
            />
            <FieldGroup
              id="lastName"
              name="lastName"
              label="Last Name"
              type="text"
              onChange={this.handleInputChange}
              value={lastName}
              placeholder="Last Name"
              required
              maxLength={36}
              validationState={
                lastName.length > 32 || !lastName ? "error" : null
              }
            />
            <FieldGroup
              id="about"
              name="about"
              label="About"
              componentClass="textarea"
              rows="5"
              onChange={this.handleInputChange}
              value={about}
              placeholder="Add bio"
              maxLength={75}
              validationState={about.length > 70 ? "error" : null}
            />
            {error && <ErrorHandler error={error} />}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button onClick={this.handleCloseModal} bsStyle="link">
              Cancel
            </Button>
            <Button type="submit" bsStyle="primary">
              Update
            </Button>
          </div>
        </Modal.Footer>
      </form>
    );

    return (
      <span>
        <Button bsStyle="primary" bsSize="sm" onClick={this.handleShowModal}>
          Update Profile
        </Button>
        <Modal show={show} onHide={this.handleCloseModal} backdrop="static">
          {form}
        </Modal>
      </span>
    );
  }
}

export default UserUpdate;
