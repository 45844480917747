import React, { Component } from "react";
import "./Prompt.css";
import { Route, Switch } from "react-router-dom";
import PromptNav from "./PromptNav/PromptNav";
import PromptDashboard from "./PromptDashboard/PromptDashboard";
import UpdatePrompt from "./UpdatePrompt/UpdatePrompt";

/**
 * Handles prompt level routing.
 */
class Prompt extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};
  }

  render() {
    let user = this.props.user;
    return (
      <div className="Prompt">
        <PromptNav user={user} location={this.props.location} />
        <Switch>
          <Route
            exact
            path="/prompts"
            render={props => <PromptDashboard location={this.props.location} />}
          />
          <Route
            exact
            path="/prompts/:promptId"
            component={props => (
              <UpdatePrompt promptId={props.match.params.promptId} />
            )}
          />
          <Route
            exact
            path="/prompts/:promptId/:promptDate"
            component={props => (
              <UpdatePrompt promptId={props.match.params.promptId} />
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default Prompt;
