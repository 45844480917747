import React, { Component } from "react";
import { Grid, Col, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Moment from "moment";
import "./InspirationDashboard.css";

import { get } from "../../../utils/BeeApi";
import { slugify } from "../../../utils/StringUtils";

import Header from "../../App/Header/Header";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class InspirationDashboard extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
      limit: 12,
      offset: 0,
      category: "5ba5d8327f832",
      rank: Moment().format("YYYY-MM-DD HH:mm:ss")
    };

    this.handleBookend = this._handleBookend.bind(this);
  }

  componentDidMount() {
    this.fetchInspirations();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  fetchInspirations() {
    this.setState({
      error: "",
      isLoading: true
    });

    const { offset, limit, category, rank } = this.state;
    const options = {
      limit: limit,
      offset: offset,
      operand: "or",
      category: category,
      rank: rank
    };
    const createPromise = get("/contents", { params: options });
    createPromise.promise
      .then(response => {
        const contentSummaryList = response.data;
        this.setState({
          inspirations: contentSummaryList,
          isEmpty: contentSummaryList.length <= 0,
          error: "",
          isLoading: false
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            isLoading: false,
            error: error
          });
      });

    this.subscribedPromises.push(createPromise);
  }

  expandInspirations() {
    this.setState({
      expandingError: "",
      isExpanding: true
    });

    const { offset, limit, category, rank } = this.state;
    const options = {
      limit: limit,
      offset: offset,
      operand: "or",
      category: category,
      rank: rank
    };
    const inspirationsPromise = get("/contents", { params: options });
    inspirationsPromise.promise
      .then(response => {
        this.setState(prevState => {
          const contentSummaryList = response.data;
          return {
            inspirations: prevState.inspirations
              ? prevState.inspirations.concat(...contentSummaryList)
              : contentSummaryList,
            isEmpty: contentSummaryList.length <= 0,
            expandingError: "",
            isExpanding: false
          };
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            isExpanding: false,
            expandingError: error
          });
      });

    this.subscribedPromises.push(inspirationsPromise);
  }

  _handleBookend() {
    const { inspirations, limit } = this.state;
    if (!inspirations || !inspirations.length) return;
    this.setState(
      prevState => {
        return { offset: prevState.offset + limit };
      },
      () => this.expandInspirations()
    );
  }

  render() {
    const { user } = this.props;

    return (
      <div className="Main InspirationDashboard">
        <Header user={user} title={"Dashboard"} />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Bookflow - Inspirations</title>
        </Helmet>
        <Grid className="MainContent">
          <Row className="mt-md mb-md">
            <Col xs={12}>{this.renderInspirations()}</Col>
          </Row>
        </Grid>
      </div>
    );
  }

  renderInspirations() {
    const { isLoading, error, inspirations } = this.state;

    if (error) return <ErrorHandler error={error} />;
    if (isLoading) return <Loader />;
    if (!inspirations || !inspirations.length) return <div />;

    return (
      <div className="card card-info mt-sm mb-sm">
        <h3 className="text-uppercase">STAY INSPIRED</h3>
        <h5>
          Every week, subscribers receive an email on a topic related to writing
          and growth mindset, mindfulness, dedication, and persistence. Talent
          is over-valued; the real secret to reaping the benefits of writing
          comes from consistent effort. Even if users haven’t logged in, the
          inspiration is in their inbox and it remains on Bookflow throughout
          the week.
        </h5>
        <hr />
        <Row>
          {inspirations &&
            inspirations.map((item, index) => {
              return (
                <Col xs={12} sm={6} md={4} key={item.contentId}>
                  <div className="ListContent">
                    <Link
                      className="hyperlink-3"
                      to={
                        "/inspiration/" +
                        item.contentId +
                        "/" +
                        slugify(item.title)
                      }
                    >
                      <div className="img-wrapper">
                        <img src={item.contentThumbnail} alt={item.title} />
                      </div>
                      <h4>
                        {item.title &&
                          (item.title.length < 40
                            ? item.title
                            : item.title.substring(0, 36) + "...")}
                      </h4>
                    </Link>
                    <hr />
                  </div>
                </Col>
              );
            })}
        </Row>
        {this.renderBookend()}
      </div>
    );
  }

  renderBookend() {
    const {
      isExpanding,
      expandingError,
      inspirations,
      limit,
      isEmpty
    } = this.state;

    if (expandingError) return <ErrorHandler error={expandingError} />;
    if (isExpanding) return <Loader />;
    if (inspirations.length < 0 || isEmpty) return <div />;
    return (
      inspirations.length % limit === 0 && (
        <div className="text-center mt-lg mb-lg">
          <Button onClick={this.handleBookend}>Show More Articles</Button>
        </div>
      )
    );
  }
}

export default InspirationDashboard;
