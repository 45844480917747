import React, { Component } from "react";
import "./ProjectGoalUpdate.css";
import { Button, Modal, Alert } from "react-bootstrap";
import moment from "moment";
// import DatePicker from "react-datepicker";
import { FieldGroup } from "../../../utils/BootstrapUtils";

import { put } from "../../../utils/DeApi";
import EmptyState from "../EmptyState/EmptyState";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import Loader from "../../Loader/Loader";

class ProjectGoalUpdate extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];

    this.state = {
      show: false,
      projectWords: this.props.words || 50000,
      projectDeadline:
        this.props.deadline ||
        moment()
          .add(3, "months")
          .format("YYYY-MM-DD")
    };

    this.handleShowModal = this._handleShowModal.bind(this);
    this.handleCloseModal = this._handleCloseModal.bind(this);
    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleInputChange = this._handleInputChange.bind(this);
    this.handleDeadlineChange = this._handleDeadlineChange.bind(this);
  }

  _handleCloseModal() {
    this.setState({ show: false });
  }

  _handleShowModal() {
    this.setState({ show: true });
  }

  _handleDeadlineChange(deadline) {
    console.log(deadline);
    this.setState({ projectDeadline: moment(deadline).format("YYYY-MM-DD") });
  }

  _handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  _handleSubmit(event) {
    event.preventDefault();
    const words = this.state.projectWords;
    const deadline = this.state.projectDeadline;
    const projectId = this.props.project.id;
    if (words && deadline) {
      this.setState({ isLoading: true });
      const updatePromise = put("/projects/" + projectId + "/goal", {
        words: words,
        deadline: deadline
      });
      updatePromise.promise
        .then(response => {
          this.setState({
            show: false,
            isLoading: false,
            error: ""
          });
          this.props.onProjectUpdated(response.data);
        })
        .catch(error => {
          !error.isCanceled &&
            this.setState({
              error: error,
              isLoading: false
            });
        });
      this.subscribedPromises.push(updatePromise);
    }
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  render() {
    const {
      projectWords,
      // projectDeadline,
      isLoading,
      error,
      show
    } = this.state;
    const { text } = this.props;
    const form = isLoading ? (
      <Loader />
    ) : (
      <form onSubmit={this.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title className="mt-0">
            Set your word count target.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert bsStyle="info">
            <EmptyState contentId={"5ced41ab8e8ee"} />
          </Alert>
          <FieldGroup
            id="projectWords"
            name="projectWords"
            label="Words"
            type="number"
            onChange={this.handleInputChange}
            value={projectWords}
            placeholder="How many words are you targeting?"
            required
          />
          {error && <ErrorHandler error={error} />}{" "}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleCloseModal}>Cancel</Button>
          <Button type="submit" bsStyle="success">
            Update
          </Button>
        </Modal.Footer>
      </form>
    );
    return (
      <span>
        <Button bsStyle="default" onClick={this.handleShowModal} bsSize="sm">
          {text || "Set my goal"}
        </Button>
        <Modal
          show={show}
          onHide={this.handleCloseModal}
          className="ProjectGoalUpdate"
          backdrop="static"
        >
          {form}
        </Modal>
      </span>
    );
  }
}

export default ProjectGoalUpdate;
