import React, { Component } from "react";
import "./ProjectNav.css";
import { Helmet } from "react-helmet";

import { Link } from "react-router-dom";
import { Navbar, Nav, NavItem } from "react-bootstrap";

import MonitorTimer from "../../Timer/MonitorTimer/MonitorTimer";
import Feedback from "../../App/Feedback/Feedback";
import HeaderAccount from "../../App/HeaderAccount/HeaderAccount";
import WhatIsNew from "../../App/WhatIsNew/WhatIsNew";

class ProjectNav extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleTimerChange = this._handlerTimerChange.bind(this);
  }

  _handlerTimerChange(countDown) {
    this.setState({ timerChange: countDown });
  }

  render() {
    const { user, project, location } = this.props;
    const { timerChange } = this.state;

    const projectURL = "/projects/" + project.id;

    const sceneURL = "/projects/" + project.id + "/scenes";
    const themeURL = "/projects/" + project.id + "/themes";
    const characterURL = "/projects/" + project.id + "/characters";
    const manuscriptURL = "/projects/" + project.id + "/manuscript";

    const pathname = location ? location.pathname : "";

    return (
      <div className="MainHeader">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{"Bookflow - " + project.name}</title>
        </Helmet>
        <Navbar default fluid fixedTop collapseOnSelect>
          <Navbar.Header>
            <Navbar.Brand>
              <Link to="/">
                <img className="logo-icon" alt="B" src="/bookflow-icon.png" />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav>
              <NavItem
                componentClass={Link}
                href={projectURL}
                to={projectURL}
                active={pathname === projectURL}
                className="nav-title"
              >
                {project.name.length > 15
                  ? `${project.name.substring(0, 15)}...`
                  : project.name}
              </NavItem>
              <NavItem
                componentClass={Link}
                href={sceneURL}
                to={sceneURL}
                active={pathname.includes(sceneURL)}
              >
                Scenes
              </NavItem>
              <NavItem
                componentClass={Link}
                href={characterURL}
                to={characterURL}
                active={pathname.includes(characterURL)}
              >
                Characters+
              </NavItem>
              <NavItem
                componentClass={Link}
                href={themeURL}
                to={themeURL}
                active={pathname.includes(themeURL)}
              >
                Themes
              </NavItem>
              <NavItem
                componentClass={Link}
                href={manuscriptURL}
                to={manuscriptURL}
                active={pathname.includes(manuscriptURL)}
              >
                Manuscript
              </NavItem>
            </Nav>
            <Nav pullRight>
              <Feedback user={user} />
              <MonitorTimer
                onTimerChange={this.handleTimerChange}
                timerChange={timerChange}
              />
              <WhatIsNew user={user} />
              <HeaderAccount
                user={user}
                onTimerChange={this.handleTimerChange}
                timerChange={timerChange}
              />
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

export default ProjectNav;
