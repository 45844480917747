import React, { Component } from "react";
import { ButtonGroup, Button } from "react-bootstrap";
import moment from "moment";
import "./Contributions.css";

import { get } from "../../../utils/DeApi";

import ContributionActivity from "../ContributionActivity/ContributionActivity";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class Contributions extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
      contributions: [],
      before: moment()
        .endOf("day")
        .format(),
      after: moment()
        .subtract(29, "days")
        .startOf("day")
        .format()
    };
  }

  componentDidMount() {
    this.fetchContributions();
    if (!this.props.project) this.fetchPromptsContributions();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  fetchContributions() {
    const { project } = this.props;
    const { before, after } = this.state;

    let contributionsPromise = get("/contributions", {
      params: { project: project && project.id, before: before, after: after }
    });

    this.setState({ isLoading: true });

    contributionsPromise.promise
      .then(response => {
        this.setState(prevState => {
          let contributions = prevState.contributions;
          return {
            contributions: contributions.concat(response.data),
            isLoading: false,
            error: ""
          };
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            isLoading: false,
            error: error
          });
      });

    this.subscribedPromises.push(contributionsPromise);
  }

  fetchPromptsContributions() {
    const { before, after } = this.state;
    let promptsContributionsPromise = get("/prompt-contributions", {
      params: { before: before, after: after }
    });

    this.setState({ isLoadingPrompts: true });

    promptsContributionsPromise.promise
      .then(response => {
        this.setState(prevState => {
          let contributions = prevState.contributions;
          return {
            contributions: contributions.concat(response.data),
            isLoadingPrompts: false,
            errorPrompts: ""
          };
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            isLoadingPrompts: false,
            errorPrompts: error
          });
      });

    this.subscribedPromises.push(promptsContributionsPromise);
  }

  showPreviousActivity() {
    this.setState(
      state => ({
        before: moment(state.before)
          .subtract(30, "days")
          .endOf("day")
          .format(),
        after: moment(state.after)
          .subtract(30, "days")
          .startOf("day")
          .format()
      }),
      () => this.fetchContributions()
    );
  }

  showNextActivity() {
    this.setState(
      state => ({
        before: moment(state.before)
          .add(30, "days")
          .endOf("day")
          .format(),
        after: moment(state.after)
          .add(30, "days")
          .startOf("day")
          .format()
      }),
      () => this.fetchContributions()
    );
  }

  render() {
    const {
      contributions,
      before,
      after,
      isLoading,
      isLoadingPrompts,
      error,
      errorPrompts
    } = this.state;
    const { project } = this.props;

    if (error) return <ErrorHandler error={error} />;
    if (errorPrompts) return <ErrorHandler error={errorPrompts} />;

    return (
      <div className="Contributions">
        {/* {this.renderStreak()} */}
        {isLoading || isLoadingPrompts ? (
          <Loader />
        ) : (
          <ContributionActivity
            contributions={contributions}
            before={before}
            after={after}
            project={project}
          />
        )}
        <h5 className="mt-md">
          <ButtonGroup className="pull-right">
            <Button
              bsSize="xs"
              className="btn-secondary pl-0 pr-0"
              onClick={() => this.showPreviousActivity()}
              title="Show activity for previous 30 days"
            >
              <i className="material-icons md-24">chevron_left</i>
            </Button>

            <Button
              bsSize="xs"
              className="btn-secondary pl-0 pr-0"
              onClick={() => this.showNextActivity()}
              title="Show activity for next 30 days"
            >
              <i className="material-icons md-24">chevron_right</i>
            </Button>
          </ButtonGroup>
          {`${moment(after).format("LL")} - ${moment(before).format("LL")}`}
        </h5>
      </div>
    );
  }

  renderStreak() {
    const streak = this.parseStreak();

    return (
      <div>
        <ul className="list-styled mb-0 ">
          <li>
            <i className="material-icons">whatshot</i>
            <p>
              {streak && streak.length ? (
                <span>
                  You're on a <strong>{streak.length}</strong> day streak, great
                  job!
                </span>
              ) : (
                <span>Write every day to get your streak going!</span>
              )}
            </p>
          </li>
        </ul>
      </div>
    );
  }

  parseStreak() {
    const { contributions } = this.state;
    if (!contributions || !contributions.length) return [];

    let streak = [];
    let onStreak = true;
    let currDate = moment();
    while (onStreak) {
      let currStreak = contributions.find(contribution => {
        return contribution.date === currDate.format("YYYY-MM-DD");
      });

      if (!!currStreak) {
        streak.push(currStreak);
        currDate.subtract(1, "days");
      } else {
        onStreak = false;
      }
    }
    return streak;
  }
}
export default Contributions;
