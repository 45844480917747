import React, { Component, Fragment } from "react";
import { Button, Modal, HelpBlock } from "react-bootstrap";

import { destroy } from "../../../utils/DeApi";
import { FieldGroup } from "../../../utils/BootstrapUtils";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import Loader from "../../Loader/Loader";

class CharacterDelete extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
      show: false,
      characterTitle: ""
    };

    this.handleShowModal = this._handleShowModal.bind(this);
    this.handleCloseModal = this._handleCloseModal.bind(this);
    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleInputChange = this._handleInputChange.bind(this);
  }

  _handleCloseModal() {
    this.setState({ show: false });
  }

  _handleShowModal() {
    this.setState({ show: true });
  }

  _handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  _handleSubmit(event) {
    event.preventDefault();
    const charcterTitle = this.props.character.title;
    const characterId = this.props.character.id;
    const title = this.state.characterTitle;
    if (title === charcterTitle) {
      this.setState({ isLoading: true, formError: "" });
      const destroyPromise = destroy("/characters/" + characterId);
      destroyPromise.promise
        .then((response) => {
          this.setState({
            characterTitle: "",
            show: false,
            isLoading: false,
            error: ""
          });
          this.props.onCharacterDeleted();
        })
        .catch((error) => {
          !error.isCanceled &&
            this.setState({
              error: error,
              isLoading: false
            });
        });
      this.subscribedPromises.push(destroyPromise);
    } else {
      this.setState({
        formError: "Character titles do not match."
      });
    }
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  render() {
    const helpBlock = this.state.formError ? (
      <HelpBlock>{this.state.formError}</HelpBlock>
    ) : (
      ""
    );

    const form = this.state.isLoading ? (
      <Loader />
    ) : (
      <form onSubmit={this.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title className="mt-0">
            Are you sure you want to delete this character+?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FieldGroup
            id="characterTitle"
            name="characterTitle"
            label="Please type in the name of the character+ to confirm."
            type="text"
            onChange={this.handleInputChange}
            value={this.state.characterTitle}
            validationState="warning"
            placeholder="Enter the name for the character+ to delete"
            autoFocus
            required
          />
          {helpBlock && helpBlock}
          {this.state.error && <ErrorHandler error={this.state.error} />}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleCloseModal}>Cancel</Button>
          <Button type="submit" bsStyle="danger">
            Delete
          </Button>
        </Modal.Footer>
      </form>
    );

    return (
      <Fragment>
        <Button
          className="btn-secondary"
          bsSize="xs"
          onClick={this.handleShowModal}
        >
          Delete
        </Button>
        <Modal
          show={this.state.show}
          onHide={this.handleCloseModal}
          backdrop="static"
        >
          {form}
        </Modal>
      </Fragment>
    );
  }
}

export default CharacterDelete;
