import React, { Component } from "react";
import { Grid, Col, Row } from "react-bootstrap";
import Moment from "moment";
import DatePicker from "react-datepicker";
import "./PromptDashboard.css";

import DailyPrompt from "../DailyPrompt/DailyPrompt";
import PromptList from "../PromptList/PromptList";

import "react-datepicker/dist/react-datepicker.css";

class PromptDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rank: Moment().format(),
      limit: Moment().diff(Moment().startOf("month"), "days") + 1
    };
    this.handleDateChange = this._handleDateChange.bind(this);
  }

  _handleDateChange(selectedDate) {
    let rank = Moment().format();

    if (Moment(selectedDate).isBefore(Moment(), "month")) {
      rank = Moment(selectedDate)
        .endOf("month")
        .format();
    }
    this.setState({
      selectedDate: selectedDate,
      rank: rank,
      limit: Moment(rank).diff(Moment(rank).startOf("month"), "days") + 1
    });
  }

  render() {
    const { limit, rank, selectedDate } = this.state;

    return (
      <Grid className="PromptDashboard">
        <Row>
          <Col xs={12} md={4} className="PromptDashboardSidebar">
            <div className="pt-md pb-md">
              <div className="card card-neutral mt-sm mb-sm">
                <h3 className="text-uppercase">Today's Prompt</h3>
                <hr />
                <DailyPrompt />
              </div>
              <div className="card card-emphasis  mt-sm mb-sm">
                <h3 className="text-uppercase">Prompts Calendar</h3>
                <hr />
                {this.renderDateSelector()}
              </div>
            </div>
          </Col>
          <Col xs={12} md={8} className="mt-md mb-md">
            <div className="card card-emphasis  mt-sm mb-sm">
              <h3 className="text-uppercase">
                {Moment(rank).format("MMMM YYYY")} Prompts
              </h3>
              <hr />
              <PromptList
                limit={limit}
                offset={0}
                rank={rank}
                selectedDate={selectedDate}
              />
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }

  renderDateSelector() {
    const { rank, selectedDate } = this.state;
    return (
      <div className="PromptDatePicker">
        <div className="PromptDatePickerWrapper">
          <DatePicker
            className="PromptDatePickerWrapper center-block"
            inline
            selected={Moment(selectedDate || rank).toDate()}
            onChange={this.handleDateChange}
            minDate={Moment("2019-02-13").toDate()}
            maxDate={Moment().toDate()}
            showDisabledMonthNavigation
          />
        </div>
      </div>
    );
  }
}

export default PromptDashboard;
