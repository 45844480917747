import React, { Component } from "react";
import { Grid, Col, Row, Button, Alert } from "react-bootstrap";
import "./Contact.css";

import { get, post } from "../../../utils/BeeApi";
import { FieldGroup } from "../../../utils/BootstrapUtils";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

/**
 * Handles rendering contact us page
 */
class Contact extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
      name: "",
      email: "",
      subject: "",
      body: "",
      error: ""
    };

    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleInputChange = this._handleInputChange.bind(this);
    this.handleEmailChange = this._handleEmailChange.bind(this);
  }

  _handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  _handleEmailChange(event) {
    let email = event.target.value;
    this.setState({
      email: email,
      emailValid: email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
        ? true
        : false
    });
  }

  componentDidMount() {
    this.fetchContent();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  fetchContent() {
    const contentPromise = get("contents/5bc784eecf48b");
    contentPromise.promise
      .then(response => {
        this.setState({
          content: response.data,
          error: ""
        });
      })
      .catch(error => {
        this.setState({
          error: error,
          content: ""
        });
      });
    this.subscribedPromises.push(contentPromise);
  }

  _handleSubmit(event) {
    event.preventDefault();

    const { name, email, subject, body } = this.state;

    this.setState({
      formError: "",
      isLoading: true
    });

    const contactPromise = post("/forms", {
      name: name,
      email: email,
      subject: subject,
      body: body
    });

    contactPromise.promise
      .then(data => {
        this.setState({
          success: true,
          name: "",
          email: "",
          subject: "",
          body: "",
          formError: "",
          isLoading: false
        });
      })
      .catch(error => {
        this.setState({
          formError: error,
          isLoading: false
        });
      });
  }

  render() {
    const { user } = this.props;

    return (
      <div className="Main">
        <Header user={user} title={"Contact"} />
        <Grid className="MainContent">
          <Row className="ContactForm">
            <Col xs={12} md={8} className="mt-md mb-md">
              {this.renderContent()}
              {this.renderContactForm()}
            </Col>
          </Row>
        </Grid>
        <Footer />
      </div>
    );
  }

  renderContent() {
    const { content, error } = this.state;

    if (error) return <ErrorHandler error={error} />;
    if (!content) return <Loader />;

    return (
      <div
        className="pt-sm pb-sm"
        dangerouslySetInnerHTML={{ __html: content.contentBody }}
      />
    );
  }

  renderContactForm() {
    const {
      name,
      email,
      subject,
      body,
      formError,
      isLoading,
      success
    } = this.state;
    if (isLoading) return <Loader />;

    return (
      <form onSubmit={this.handleSubmit}>
        <FieldGroup
          id="name"
          name="name"
          label="Name"
          type="text"
          value={name}
          onChange={this.handleInputChange}
          placeholder="Enter name"
          autoFocus
        />
        <FieldGroup
          id="email"
          name="email"
          label="Email address"
          type="email"
          value={email}
          onChange={this.handleEmailChange}
          placeholder="Enter address"
          required
        />

        <FieldGroup
          id="subject"
          name="subject"
          label="Subject"
          type="text"
          value={subject}
          onChange={this.handleInputChange}
          placeholder="Enter subject"
          required
        />

        <FieldGroup
          id="body"
          name="body"
          label="Message"
          componentClass="textarea"
          rows="5"
          onChange={this.handleInputChange}
          value={body}
          placeholder="Message"
          required
        />
        <Button type="submit">Submit</Button>
        {formError && <ErrorHandler error={formError} />}
        {success && (
          <Alert bsStyle="success" className="mt-md mb-md">
            <h4>Thanks</h4>
            <p>
              We appreciate that you’ve taken the time to write us. We’ll get
              back to you very soon.
            </p>
          </Alert>
        )}
      </form>
    );
  }
}

export default Contact;
