import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import "./ContentGuideList.css";
import { get } from "../../../utils/BeeApi";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import { slugify } from "../../../utils/StringUtils";

class ContentGuideList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.subscribedPromises = [];

    this.handleShowGuide = this._handleShowGuide.bind(this);
    this.handleHideGuide = this._handleHideGuide.bind(this);
  }

  _handleHideGuide() {
    this.setState({ selectedGuide: false, guide: null });
  }

  _handleShowGuide() {
    this.fetchGuide();
  }

  componentDidMount() {
    this.fetchContentGuides();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  fetchContentGuides() {
    const { type, operand, category, offset, limit } = this.props;

    this.setState({
      error: "",
      isLoading: true
    });

    const params = {
      type: type,
      operand: operand,
      category: category,
      offset: offset || 0,
      limit: limit || 10
    };

    const contentGuidesPromise = get("/contents", { params: params });

    contentGuidesPromise.promise
      .then(response => {
        this.setState({
          contentGuides: response.data,
          error: "",
          isLoading: false
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            isLoading: false,
            error: error
          });
      });

    this.subscribedPromises.push(contentGuidesPromise);
  }

  fetchGuide() {
    const selectedGuide = this.state.selectedGuide;
    this.setState({
      guideError: "",
      guideLoading: true
    });

    const guidePromise = get("contents/" + selectedGuide.contentId, {});
    guidePromise.promise
      .then(response => {
        this.setState({
          guide: response.data,
          guideError: "",
          guideLoading: false
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            guideError: error,
            guideLoading: false
          });
      });

    this.subscribedPromises.push(guidePromise);
  }

  render() {
    const { isLoading, error, contentGuides, selectedGuide } = this.state;

    if (error) return <ErrorHandler error={error} />;
    if (isLoading) return <Loader />;
    return (
      <div className="ContentGuideList">
        <ul className="list-styled">
          {contentGuides &&
            contentGuides.map(guide => {
              return this.renderGuide(guide);
            })}
          {selectedGuide && this.renderSelectedGuide()}
        </ul>
      </div>
    );
  }

  renderGuide(guide) {
    return (
      <li key={guide.contentId}>
        <p
          className="text-link hyperlink-1"
          onClick={() =>
            this.setState({ selectedGuide: guide }, this.handleShowGuide)
          }
        >
          <i className="material-icons">bookmark</i>
          {guide.title}
        </p>
      </li>
    );
  }

  renderSelectedGuide() {
    const { selectedGuide, guide, guideError, guideLoading } = this.state;

    let guideBody = <div />;

    if (guideLoading) guideBody = <Loader />;
    if (guideError) guideBody = <ErrorHandler error={guideError} />;
    if (guide)
      guideBody = (
        <div
          className="GuideContent"
          dangerouslySetInnerHTML={{
            __html: guide.contentBody
          }}
        />
      );

    return (
      <Modal
        show={!!selectedGuide}
        onHide={this.handleHideGuide}
        bsSize="lg"
        className="right"
        backdrop={"static"}
        aria-labelledby={selectedGuide.title}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title">
            <i
              className="material-icons md-18 pull-right ml-xs"
              onClick={this.handleHideGuide}
            >
              close
            </i>
            <Link
              to={
                "/guides/" +
                selectedGuide.contentId +
                "/" +
                slugify(selectedGuide.title)
              }
            >
              <i className="material-icons md-18 pull-right ml-xs">
                open_in_new
              </i>
            </Link>

            {selectedGuide.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{guideBody}</Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleHideGuide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ContentGuideList;
