import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { Grid, Col, Row, Button, Image } from "react-bootstrap";

import "./ResetPassword.css";
import { isLoggedIn, login, put } from "../../../utils/BeeApi";
import { FieldGroup } from "../../../utils/BootstrapUtils";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

import {
  isLoggedIn as isLoggedInDE,
  login as loginDE
} from "../../../utils/DeApi";

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this._handleSubmit.bind(this);
    this.handlePasswordChange = this._handlePasswordChange.bind(this);
    this.handleConfirmPasswordChange = this._handleConfirmPasswordChange.bind(
      this
    );
    this.handleEmailChange = this._handleEmailChange.bind(this);

    const loggedIn = isLoggedInDE() && isLoggedIn();
    let email = loggedIn ? loggedIn.email : "";
    let validationToken = this.parseURLParams(props.location.search).get(
      "verification"
    );

    this.state = {
      email: email,
      emailValid: !!email || "",
      password: "",
      confirmPassword: "",
      passwordValid: "",
      confirmPasswordValid: "",
      error: "",
      loggedIn: loggedIn,
      validationToken: validationToken || "",
      formSubmitted: false
    };
  }

  parseURLParams(params) {
    return new URLSearchParams(params || "");
  }

  _handleEmailChange(event) {
    let email = event.target.value;
    this.setState({
      email: email,
      emailValid: email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    });
  }

  _handlePasswordChange(event) {
    let password = event.target.value;
    this.setState({
      password: password,
      passwordValid: password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/)
        ? true
        : false
    });
  }

  _handleConfirmPasswordChange(event) {
    let confirmPassword = event.target.value;
    this.setState({
      confirmPassword: confirmPassword,
      confirmPasswordValid: confirmPassword === this.state.password
    });
  }

  _handleSubmit(event) {
    event.preventDefault();
    const {
      email,
      emailValid,
      password,
      passwordValid,
      confirmPassword,
      confirmPasswordValid,
      validationToken
    } = this.state;

    this.setState(
      {
        formSubmitted: true,
        confirmPasswordValid: confirmPassword === password
      },
      (state, props) => {
        if (emailValid && passwordValid && confirmPasswordValid) {
          this.setState({
            error: "",
            isLoading: true
          });

          const createPromise = put("/user/anon/password", {
            userId: "anon",
            email: email,
            token: validationToken,
            password: password,
            password_confirmation: confirmPassword
          });
          createPromise.promise
            .then(response => {
              this.setState(
                {
                  isLoading: false,
                  error: ""
                },
                () => {
                  this.login();
                }
              );
            })
            .catch(error => {
              this.setState({
                error: error,
                isLoading: false
              });
            });
        }
      }
    );
  }

  login() {
    const { email, password } = this.state;
    this.setState({
      error: "",
      isLoading: true
    });
    login({
      email: email,
      password: password
    })
      .then(data => {
        return loginDE();
      })
      .then(data => {
        this.setState({
          loggedIn: true,
          isLoading: false
        });
      })
      .catch(error => {
        this.setState({
          error: error,
          isLoading: false
        });
      });
  }

  render() {
    const { isLoading, loggedIn } = this.state;
    if (loggedIn) {
      let { from } = this.props.location.state || {
        from: { pathname: "/" }
      };
      return <Redirect to={from} />;
    }

    if (isLoading) return <Loader />;

    return (
      <Grid fluid className="mt-lg mb-lg">
        <Row className="ForgotPassword">
          <Col xs={12} sm={6} smOffset={3} md={4} mdOffset={4}>
            <div className="mb-md text-center">
              <a href="http://about.bookflow.pub">
                <Image src="/bookflow-logo.png" width="300px" />
              </a>
            </div>
            <div className="card card-neutral">
              {this.renderResetPasswordForm()}
            </div>
            <ul className="list-inline text-center mt-xs mb-xs">
              <li>
                <a href="http://about.bookflow.pub">About</a>
              </li>
              <li>&copy; Bookflow 2021</li>
            </ul>
          </Col>
        </Row>
      </Grid>
    );
  }

  renderResetPasswordForm() {
    const {
      email,
      emailValid,
      password,
      passwordValid,
      confirmPassword,
      confirmPasswordValid,
      error,
      formSubmitted
    } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="mt-sm mb-md text-center">
          <h2>Set Password</h2>
          <p>Enter your email and a new password for your account.</p>
          <hr />
        </div>
        <FieldGroup
          label="Email address"
          name="email"
          type="email"
          value={email}
          onChange={this.handleEmailChange}
          validationState={formSubmitted && !emailValid ? "error" : null}
          placeholder="Enter email"
          autoFocus
          required
          help={
            <small>
              This should be the email address associated with your account.
            </small>
          }
        />
        <FieldGroup
          label="Password"
          type="password"
          name="password"
          value={password}
          onChange={this.handlePasswordChange}
          validationState={formSubmitted && !passwordValid ? "error" : null}
          placeholder="Password"
          help={
            <small>
              The password must contain at least 6 characters, including
              UPPER/lowercase and numbers.
            </small>
          }
        />
        <FieldGroup
          label="Confirm Password"
          type="password"
          value={confirmPassword}
          onChange={this.handleConfirmPasswordChange}
          validationState={
            confirmPasswordValid || confirmPasswordValid === "" ? null : "error"
          }
          placeholder="Confirm Password"
          help={<small>Password confirmation should match password.</small>}
        />

        {error && this.renderError()}

        <Button type="submit" bsStyle="primary" block>
          Continue
        </Button>
        <hr />
        <div className="text-center mt-sm mb-sm">
          <h5>
            <Link to="/login-help">Request for a new password reset link.</Link>
          </h5>
          <h5>
            <Link to="/login">Login</Link>
          </h5>
          <p className="sub-text">Contact Support at team@bookflow.pub</p>
        </div>
      </form>
    );
  }

  renderError() {
    const { error } = this.state;

    let message = "";
    if (error.status === 403) {
      error.statusText = "Oops! Something went wrong.";
      message =
        "Sorry, your link to set a new password has expired or is invalid. Please request for another link.";
    }

    return <ErrorHandler error={error} message={message} />;
  }
}

export default ResetPassword;
