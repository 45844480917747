import React from "react";
import "./Footer.css";
import { Grid, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
/**
 * Handles rendering of the global footer.
 */
const Footer = () => (
  <div className="MainFooter">
    <Grid className="MainFooterContent">
      <Row>
        <Col xs={12}>
          <ul className="pull-right list-inline">
            <li>
              <a href="http://about.bookflow.pub/terms">Terms</a>
            </li>
            <li>
              <a href="http://about.bookflow.pub/privacy">Privacy</a>
            </li>
            <li>&copy; Bookflow 2021</li>
          </ul>
          <ul className="list-inline">
            <li>
              <a href="http://about.bookflow.pub">About</a>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </Col>
      </Row>
    </Grid>
  </div>
);

export default Footer;
