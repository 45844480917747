import React, { Component } from "react";
import {
  Grid,
  Col,
  Row,
  ButtonGroup,
  ButtonToolbar,
  Button,
  Label,
  Tooltip,
  OverlayTrigger
} from "react-bootstrap";
import DnDWrapper from "../DnDWrapper/DnDWrapper";
import { Link } from "react-router-dom";
import snakeCase from "lodash/snakeCase";
import moment from "moment";

import { get, put, download } from "../../../utils/DeApi";

import SceneCreate from "../SceneCreate/SceneCreate";
import SceneEdit from "../SceneEdit/SceneEdit";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import ContentGuideList from "../../Content/ContentGuideList/ContentGuideList";
import EmptyState from "../EmptyState/EmptyState";
import { setLayout, getLayout } from "../../../utils/LayoutUtils";

/**
 * Handles rendering of scenes outline view
 * Uses drag and drop
 */
class ScenesOutline extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
      layout: getLayout() || "grid"
    };

    this.handleSceneCreated = this._handleSceneCreated.bind(this);
    this.handleSceneUpdated = this._handleSceneUpdated.bind(this);
    this.handleOrderChange = this._handleOrderChange.bind(this);
    this.handleViewList = this._handleViewList.bind(this);
    this.handleViewGrid = this._handleViewGrid.bind(this);
    this.handleViewTable = this._handleViewTable.bind(this);
    this.handleDownloadOutline = this._handleDownloadOutline.bind(this);

    this.renderTableItem = this._renderTableItem.bind(this);
    this.renderGridItem = this._renderGridItem.bind(this);
  }

  _handleSceneCreated(scene) {
    this.setState((prevState, props) => {
      prevState.scenes.splice(parseInt(scene.insertIndex), 0, scene);
      return {
        scenes: [...prevState.scenes]
      };
    });
  }

  _handleSceneUpdated(scene) {
    this.setState(function(prevState, props) {
      return {
        scenes: prevState.scenes.map(item => {
          if (item.id === scene.id) return { ...item, ...scene };
          else return item;
        })
      };
    });
  }

  _handleViewTable() {
    this.setState({ layout: "table" }, () => setLayout(this.state.layout));
  }

  _handleViewList() {
    this.setState({ layout: "list" }, () => setLayout(this.state.layout));
  }

  _handleViewGrid() {
    this.setState({ layout: "grid" }, () => setLayout(this.state.layout));
  }

  _handleDownloadOutline() {
    const { project } = this.props;
    this.setState({ outlineDownloading: true });

    const outlinePromise = download(
      `manuscript/${project.id}/outline?pillar=scenes`
    );
    const fileName = `outline-scenes-${snakeCase(project.name)}.docx`;

    outlinePromise.promise
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute("download", fileName);
        document.body.appendChild(link);

        link.click();
        this.setState({
          outlineDownloading: false
        });
      })
      .catch(error => {
        this.setState({
          outlineDownloading: false
        });
      });
    this.subscribedPromises.push(outlinePromise);
  }

  componentDidMount() {
    this.fetchScenes();
    this.fetchSceneTypes();
    this.setMobileLayout();
  }

  setMobileLayout() {
    const isMobile = /mobi/i.test(navigator.userAgent.toLowerCase());

    if (isMobile && !getLayout())
      this.setState({
        layout: "list"
      });
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  fetchScenes() {
    let scenesPromise = get("/scenes?project=" + this.props.project.id);
    this.setState({ isLoading: true });

    scenesPromise.promise
      .then(response => {
        this.setState({
          scenes: response.data,
          isLoading: false,
          error: ""
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error,
            isLoading: false
          });
      });
    this.subscribedPromises.push(scenesPromise);
  }

  fetchSceneTypes() {
    let sceneTypesPromise = get("/scene-types");
    this.setState({ isLoading: true });
    sceneTypesPromise.promise
      .then(response => {
        this.setState({
          sceneTypes: response.data,
          isLoading: false
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error,
            isLoading: false
          });
      });
    this.subscribedPromises.push(sceneTypesPromise);
  }

  render() {
    const {
      scenes,
      sceneTypes,
      layout,
      outlineDownloading,
      error
    } = this.state;
    const { project } = this.props;

    const sceneOptions = {
      category: "5ba5d8120c1af" // Scene categoryId
    };

    const sceneList = scenes && sceneTypes ? this.renderScenes() : <Loader />;

    return (
      <Grid>
        <Row className="PillarOutline">
          <Col md={3} mdPush={9} xs={12} className="PillarOutlineSidebar">
            <div className="mt-md mb-md pt-sm pb-sm">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip">Create a new scene</Tooltip>}
              >
                <span className="mt-sm mb-sm">
                  <SceneCreate
                    scenes={scenes}
                    project={project}
                    onSceneCreated={this.handleSceneCreated}
                  />
                </span>
              </OverlayTrigger>

              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip">Export scenes outline</Tooltip>}
              >
                <Button
                  onClick={this.handleDownloadOutline}
                  className="btn-secondary mt-sm mb-sm"
                  block
                >
                  {outlineDownloading ? "Exporting...." : "Export"}
                </Button>
              </OverlayTrigger>

              <div className="card">
                <h3 className="text-uppercase">Scenes Guides</h3>
                <hr />
                <ContentGuideList {...sceneOptions} />
              </div>
            </div>
          </Col>
          <Col md={9} mdPull={3} xs={12}>
            <Row className="mt-md mb-md">
              <Col xs={12} sm={4}>
                <h3 className="text-uppercase mt-md">{this.renderTitle()}</h3>
              </Col>
              <Col xs={12} sm={8}>
                <ButtonToolbar className="pull-right">
                  <ButtonGroup>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip">
                          View outline in list layout
                        </Tooltip>
                      }
                    >
                      <Button
                        className="btn-secondary mt-sm mb-sm"
                        onClick={this.handleViewTable}
                        active={layout === "table"}
                      >
                        <i className="material-icons md-24">list</i>
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip">
                          View outline in grid layout
                        </Tooltip>
                      }
                    >
                      <Button
                        className="btn-secondary mt-sm mb-sm"
                        onClick={this.handleViewGrid}
                        active={layout === "grid"}
                      >
                        <i className="material-icons md-24">view_module</i>
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip">
                          View outline with full summaries
                        </Tooltip>
                      }
                    >
                      <Button
                        className="btn-secondary mt-sm mb-sm"
                        onClick={this.handleViewList}
                        active={layout === "list"}
                      >
                        <i className="material-icons md-24">view_stream</i>
                      </Button>
                    </OverlayTrigger>
                  </ButtonGroup>
                </ButtonToolbar>
              </Col>
              <Col xs={12}>
                {error && <ErrorHandler error={error} />}
                {sceneList}
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid>
    );
  }

  renderTitle() {
    const { layout, scenes } = this.state;

    if (!scenes) return "";
    else if (!scenes.length) return <span>OUTLINE</span>;
    else if (layout === "list") return <span>OUTLINE: Summary View</span>;
    else if (layout === "grid") return <span>OUTLINE: Grid View</span>;
    else if (layout === "table") return <span>OUTLINE: List View</span>;
    return "";
  }

  renderScenes() {
    const { layout, scenes } = this.state;
    if (scenes && scenes.length) {
      if (layout === "grid") {
        return this.renderGrid();
      } else if (layout === "list") {
        return this.renderList();
      } else {
        return this.renderTable();
      }
    } else {
      return (
        <div className="card card-info pt-sm pb-md">
          <EmptyState contentId={"60c7aa91b69c0"} />
        </div>
      );
    }
  }

  renderTable() {
    const { scenes } = this.state;

    return (
      <div className="card">
        <Row className="mb-xs">
          <Col xs={2} md={1} className="text-right" />
          <Col xs={4} md={6}>
            <h5 className="mt-0 mb-0">Name</h5>
          </Col>
          <Col xs={3} md={2}>
            <h5 className="mt-0 mb-0">Word Count</h5>
          </Col>
          <Col xs={3} md={3}>
            <h5 className="mt-0 mb-0">Last Modified</h5>
          </Col>
        </Row>
        <DnDWrapper
          items={scenes}
          onOrderChange={this.handleOrderChange}
          renderItem={this.renderTableItem}
          dragHandle={true}
        />
      </div>
    );
  }

  renderGrid() {
    const { scenes } = this.state;
    return (
      <DnDWrapper
        items={scenes}
        onOrderChange={this.handleOrderChange}
        renderItem={this.renderGridItem}
      />
    );
  }

  renderList() {
    const { scenes, sceneTypes } = this.state;
    const { project } = this.props;

    return (
      <div className="card">
        {scenes.map((scene, index) => {
          let sceneURL = "/projects/" + project.id + "/scenes/" + scene.id;

          return (
            <div key={scene.id}>
              <div className="mt-sm mb-md">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip">Edit title and summary</Tooltip>
                  }
                >
                  <span className="pl-xs pull-right">
                    <SceneEdit
                      scene={scene}
                      sceneTypes={sceneTypes}
                      onSceneUpdated={this.handleSceneUpdated}
                    />
                  </span>
                </OverlayTrigger>
                <h4>
                  <span className="text-muted body-2 pr-xs">{++index}</span>
                  <Link to={sceneURL}>{scene.title}</Link>
                </h4>
              </div>
              <p className="PillarDescription">{scene.description}</p>
              <hr className="mt-md mb-md" />
            </div>
          );
        })}
      </div>
    );
  }

  _renderTableItem(scene, index, dragHandle) {
    const { project } = this.props;

    const sceneURL = "/projects/" + project.id + "/scenes/" + scene.id;
    return (
      <Col xs={12} className="DnDRowContent pb-0">
        <div className="card card-table movable">
          <Row>
            <Col xs={2} md={1} className="text-right ">
              {dragHandle && dragHandle}
              <small className="text-muted">{++index}</small>
            </Col>
            <Col xs={4} md={6}>
              <p className="mt-0 mb-0">
                <Link to={sceneURL}>
                  {scene.title &&
                    (scene.title.length < 35
                      ? scene.title
                      : scene.title.substring(0, 32) + "...")}
                </Link>
              </p>
            </Col>
            <Col xs={3} md={2}>
              {scene.sceneStats ? (
                <Label>
                  {new Intl.NumberFormat().format(scene.sceneStats.words || 0)}{" "}
                  words
                </Label>
              ) : (
                <Label>0 words</Label>
              )}
            </Col>
            <Col xs={3} md={3}>
              <small className="text-muted mb-0">
                {moment
                  .utc(scene.updatedAt)
                  .local()
                  .calendar()}
              </small>
            </Col>
          </Row>
        </div>
      </Col>
    );
  }

  _renderGridItem(scene, index) {
    const { sceneTypes } = this.state;
    const { project } = this.props;

    const sceneURL = "/projects/" + project.id + "/scenes/" + scene.id;

    return (
      <Col xs={12} sm={6} md={4} className="DnDColContent">
        <div className="card card-grid movable">
          <div className="body">
            <h4>
              <Link to={sceneURL}>
                {scene.title &&
                  (scene.title.length < 35
                    ? scene.title
                    : scene.title.substring(0, 32) + "...")}
              </Link>
            </h4>
            <hr className="mt-xs mb-xs" />
            <p>
              {scene.description &&
                (scene.description.length < 110
                  ? scene.description
                  : scene.description.substring(0, 108) + "...")}
            </p>
          </div>
          <div className="footer">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip">Edit title and summary</Tooltip>}
            >
              <span className="pl-xs pull-right">
                <SceneEdit
                  scene={scene}
                  sceneTypes={sceneTypes}
                  onSceneUpdated={this.handleSceneUpdated}
                />
              </span>
            </OverlayTrigger>
            <p className="body-2">
              <span>{++index}</span>
            </p>
          </div>
        </div>
      </Col>
    );
  }

  _handleOrderChange(item, newIndex, newList) {
    this.setState({ scenes: newList });
    this.reOrderList(item, newIndex, newList);
  }

  reOrderList(item, newIndex, newList) {
    let reOrderPromise = put("/scenes/" + item.id + "/order?curr=" + newIndex);

    reOrderPromise.promise
      .then(response => {
        this.setState({ error: "" });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error
          });
      });
    this.subscribedPromises.push(reOrderPromise);
  }
}

export default ScenesOutline;
