import React, { Component, useEffect, Fragment } from "react";
import {
  Link,
  Redirect,
  Route,
  Switch,
  withRouter,
  useLocation
} from "react-router-dom";

import "./App.css";
import Login from "./Login/Login";
import Logout from "./Logout/Logout";
import Dashboard from "../Dashboard/Dashboard";
import Project from "../Project/Project";
import Account from "../Account/Account";
import { isLoggedIn } from "../../utils/BeeApi";
import { isLoggedIn as isLoggedInDE } from "../../utils/DeApi";
import Content from "../Content/Content";
import Contact from "./Contact/Contact";
import ContentDashboard from "../Content/ContentDashboard/ContentDashboard";
import Signup from "./Signup/Signup";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import ResetPassword from "./ResetPassword/ResetPassword";
import VerifyAccount from "./VerifyAccount/VerifyAccount";
import Prompt from "../Prompt/Prompt";
import Reward from "../SocialSharing/Reward/Reward";
import ContentCraftDashboard from "../Content/ContentCraftDashboard/ContentCraftDashboard";
import Craft from "../Content/Craft/Craft";
import ProjectList from "../Project/ProjectList/ProjectList";
import WhatIsNewDashboard from "../Content/WhatIsNewDashboard/WhatIsNewDashboard";
import ContentWhatIsNew from "../Content/ContentWhatIsNew/ContentWhatIsNew";
import InspirationDashboard from "../Content/InpirationDashboard/InspirationDashboard";
import Inspiration from "../Content/Inspiration/Inspiration";
import Guide from "../Content/Guide/Guide";

/**
 * Handles application level routing.
 * It sets a middleware for private and public routes.
 */
class App extends Component {
  constructor(props) {
    super(props);
    this.getUTLSource();
  }
  componentDidMount() {
    let user = isLoggedIn();
    if (user && window.dataLayer) {
      window.dataLayer.push({ user_id: user.sub });
    }
    this.unlisten = this.props.history.listen((location, action) => {
      if (user && window.dataLayer) {
        window.dataLayer.push({ user_id: user.sub });
      }
    });
  }
  componentWillUnmount() {
    this.unlisten();
  }

  getUTLSource() {
    // http://bookflow.pub/?utm_source=youtube&utm_medium=banner&utm_campaign=spring_sales&&redirect=http://about.bookflow.pub
    const URL_OBJECT = new URL(window.location);
    const redirect = URL_OBJECT.searchParams.get("redirect");

    if (
      URL_OBJECT.searchParams.has("utm_source") ||
      URL_OBJECT.searchParams.has("utm_medium") ||
      URL_OBJECT.searchParams.has("utm_campaign")
    ) {
      window.localStorage.setItem("utm_source", URL_OBJECT.toString());
    }

    if (URL_OBJECT.searchParams.has("redirect") && isValidURL(redirect)) {
      window.location = redirect;
    }
  }

  render() {
    return (
      <Fragment>
        <ScrollToTop />
        <Switch>
          <PrivateRoute exact path="/" component={Dashboard} />
          <PrivateRoute path="/projects/:id" component={Project} />
          <PrivateRoute path="/contact" component={Contact} />
          <PrivateRoute path="/guides/:id" component={Guide} />
          <PrivateRoute path="/contents/:id" component={Content} />
          <PrivateRoute path="/contents" component={ContentDashboard} />
          <PrivateRoute path="/inspiration/:id" component={Inspiration} />
          <PrivateRoute path="/inspiration" component={InspirationDashboard} />
          <PrivateRoute path="/writing-craft/:id" component={Craft} />
          <PrivateRoute
            path="/writing-craft"
            component={ContentCraftDashboard}
          />
          <PrivateRoute path="/what-is-new/:id" component={ContentWhatIsNew} />
          <PrivateRoute path="/what-is-new" component={WhatIsNewDashboard} />
          <PrivateRoute path="/prompts/" component={Prompt} />
          <PrivateRoute path="/account" component={Account} />
          <PrivateRoute path="/projects" component={ProjectList} />
          <Route path="/logout" component={Logout} />
          <Route path="/login" component={Login} />
          <Route path="/signup" component={Signup} />
          <Route path="/login-help" component={ForgotPassword} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/verify-account" component={VerifyAccount} />

          <Route
            exact
            path="/rewards/:rewardId"
            render={props => <Reward rewardId={props.match.params.rewardId} />}
          />

          <Route component={NoMatch} />
        </Switch>
      </Fragment>
    );
  }
}

/**
 * Middleware for private routes.
 */
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      let user = isLoggedIn();
      if (user && isLoggedInDE()) {
        return <Component {...props} user={user} />;
      } else {
        if (props.location.pathname === "/") {
          const url = new URL(window.location);
          const redirect = url.searchParams.get("redirect");

          if (url.searchParams.has("redirect") && isValidURL(redirect)) {
            window.location = redirect;
          } else {
            window.location = "http://about.bookflow.pub";
          }
          return null;
        } else
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          );
      }
    }}
  />
);

/**
 * 404: No match route
 */
const NoMatch = ({ location }) => (
  <div>
    <div className="text-center pt-lg pb-lg">
      <p>Oops! We couldn't find this page!</p>
      <code>
        404
        <br />
        <small>No match for {location.pathname}</small>
      </code>
      <hr />
      <h3 className="mt-md mb-md">
        <Link to={"/"}>
          Go to Dashboard
          <span className="material-icons md-18">arrow_forward</span>
        </Link>
      </h3>
    </div>
  </div>
);

// Scroll To Top on Navigation
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const isValidURL = value => {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    value
  );
};

export default withRouter(App);
