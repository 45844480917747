import React, { Component } from "react";

import { get as getDE } from "../../../utils/DeApi";
import RewardContent from "./RewardContent/RewardContent";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class ProjectRewards extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};
  }

  componentDidMount() {
    this.fetchProjectRewards();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  fetchProjectRewards() {
    const { project } = this.props;

    this.setState({
      isLoading: true,
      error: ""
    });

    const projectRewardsPromise = getDE("/projects/" + project.id + "/rewards");
    projectRewardsPromise.promise
      .then(response => {
        let rewards = response.data || [];
        rewards.sort((a, b) => a.createdAt - b.createdAt);

        this.setState({
          projectRewards: rewards.reverse(),
          isLoading: false,
          error: ""
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            isLoading: false,
            error: error
          });
      });
    this.subscribedPromises.push(projectRewardsPromise);
  }

  render() {
    const { projectRewards, error, isLoading } = this.state;

    if (error) return <ErrorHandler error={error} />;
    if (isLoading) return <Loader />;

    return (
      <div className="ProjectRewards">
        <hr />
        {projectRewards && projectRewards.length ? (
          this.renderRewards()
        ) : (
          <div className="mb-sm mb-sm">
            <p>Write to collect shareable rewards!</p>
          </div>
        )}
      </div>
    );
  }

  renderRewards() {
    const { projectRewards } = this.state;
    return (
      <div>
        <ul className="list-styled">
          {projectRewards.map(reward => {
            return (
              <li key={reward.contentId}>
                <RewardContent contentId={reward.contentId} />
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default ProjectRewards;
