import React from "react";
import { Modifier, EditorState } from "draft-js";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const ALIGNMENTS_STYLES = [
  {
    label: (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="left-align-tooltip">Left align</Tooltip>}
      >
        <i className="material-icons">format_align_left</i>
      </OverlayTrigger>
    ),
    alignment: "LEFT"
  },
  {
    label: (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="center-align-tooltip">Center align</Tooltip>}
      >
        <i className="material-icons">format_align_center</i>
      </OverlayTrigger>
    ),
    alignment: "CENTER"
  },
  {
    label: (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="right-align-tooltip">Right align</Tooltip>}
      >
        <i className="material-icons">format_align_right</i>
      </OverlayTrigger>
    ),
    alignment: "RIGHT"
  },
  {
    label: (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="justify-align-tooltip">Justify</Tooltip>}
      >
        <i className="material-icons">format_align_justify</i>
      </OverlayTrigger>
    ),
    alignment: "JUSTIFY"
  }
];

// Copy pasting
// Spliting blocks using rich utils.

export const ALIGNMENT_DATA_KEY = "textAlignment";

class AlignmentControls extends React.Component {
  constructor(props) {
    super(props);

    this.handleAlign = this._handleAlign.bind(this);
  }

  render() {
    const { currentBlock } = AlignmentUtils.getCurrentlySelectedBlock(
      this.props.editorState
    );
    const currentBlockAlignment = currentBlock
      .getData()
      .get(ALIGNMENT_DATA_KEY);
    return (
      <span className="RichEditor-controls">
        {ALIGNMENTS_STYLES.map(type => {
          let className = "RichEditor-styleButton";
          if (currentBlockAlignment === type.alignment) {
            className += " RichEditor-activeButton";
          }
          return (
            <span
              key={type.alignment}
              className={className}
              onMouseDown={e => this.handleAlign(e, type.alignment)}
            >
              {type.label}
            </span>
          );
        })}
      </span>
    );
  }

  _handleAlign(e, control) {
    e.preventDefault();
    const editorState = this.props.editorState;
    const newEditorState = AlignmentUtils.toggleAlignment(editorState, control);
    this.props.onToggle(newEditorState);
  }
}

const AlignmentUtils = {
  // Reference: https://gist.github.com/joshdover/7c5e61ed68cc5552dc8a25463e357960
  getCurrentlySelectedBlock(editorState) {
    const selection = editorState.getSelection();
    const startKey = selection.getStartKey();
    let endKey = selection.getEndKey();
    const content = editorState.getCurrentContent();
    let target = selection;

    // Triple-click can lead to a selection that includes offset 0 of the
    // following block. The `SelectionState` for this case is accurate, but
    // we should avoid toggling block type for the trailing block because it
    // is a confusing interaction.
    if (startKey !== endKey && selection.getEndOffset() === 0) {
      const blockBefore = content.getBlockBefore(endKey);
      if (!blockBefore) {
        throw new Error("Got unexpected null or undefined");
      }

      endKey = blockBefore.getKey();
      target = target.merge({
        anchorKey: startKey,
        anchorOffset: selection.getStartOffset(),
        focusKey: endKey,
        focusOffset: blockBefore.getLength(),
        isBackward: false
      });
    }

    const hasAtomicBlock = content
      .getBlockMap()
      .skipWhile((_, k) => k !== startKey)
      .takeWhile((_, k) => k !== endKey)
      .some(v => v.getType() === "atomic");

    const currentBlock = content.getBlockForKey(startKey);

    return {
      content,
      currentBlock,
      hasAtomicBlock,
      target
    };
  },

  toggleAlignment(editorState, control) {
    const {
      content,
      currentBlock,
      hasAtomicBlock,
      target
    } = this.getCurrentlySelectedBlock(editorState);

    if (hasAtomicBlock) {
      return;
    }

    // console.log(currentBlock.getType(), currentBlock.getText());
    const blockData = currentBlock.getData();
    const alignmentToSet =
      blockData && blockData.get(ALIGNMENT_DATA_KEY) === control
        ? undefined
        : control;

    return EditorState.push(
      editorState,
      Modifier.mergeBlockData(content, target, {
        [ALIGNMENT_DATA_KEY]: alignmentToSet
      }),
      "change-block-data"
    );
  },

  // Preserve alignment on block splitting - when adding a new line.
  preserveAlignment(editorState) {
    const { currentBlock, hasAtomicBlock } = this.getCurrentlySelectedBlock(
      editorState
    );

    if (hasAtomicBlock) return editorState;

    const contentState = Modifier.splitBlock(
      editorState.getCurrentContent(),
      editorState.getSelection()
    );

    const splitState = EditorState.push(
      editorState,
      contentState,
      "split-block"
    );

    // Assign alignment if previous block has alignment. Note that `currentBlock` is the block that was selected
    // before the split.
    const alignment = currentBlock.getData().get(ALIGNMENT_DATA_KEY);
    if (!alignment) return splitState;
    return this.toggleAlignment(splitState, alignment);
  },

  // Clear alignment.
  clearAlignment(editorState) {
    const { currentBlock, hasAtomicBlock } = this.getCurrentlySelectedBlock(
      editorState
    );
    if (hasAtomicBlock) return;

    // Do not remove style if beggining of new line.
    if (editorState.getSelection().getEndOffset()) return;

    // Assume no alignment if left aligned.
    if (currentBlock.getData().get(ALIGNMENT_DATA_KEY) === "LEFT") return;

    // Do not clear alignment if no alignment set.
    if (!currentBlock.getData().get(ALIGNMENT_DATA_KEY)) return;
    return this.toggleAlignment(editorState, "");
  }
};

export { AlignmentControls, AlignmentUtils };
