import React, { Component } from "react";
import "./ContributionActivity.css";
import echarts from "echarts";
import moment from "moment";

/**
 * Handles rendering project activity heat map.
 * Charts implemented using https://ecomfe.github.io/echarts-doc/public/en/index.html
 * Heatmap: https://ecomfe.github.io/echarts-examples/public/editor.html?c=calendar-graph
 */
class ContributionActivity extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};
  }

  componentDidMount() {
    this.renderHeatMap();
  }

  componentDidUpdate(prevProps) {
    if (this.props.before !== prevProps.before) this.renderHeatMap();
  }

  render() {
    const { contributions, before, after } = this.props;
    const { chartImageData } = this.state;

    return (
      <div className="ContributionHeatmap">
        {contributions && contributions.length ? (
          <div>
            <div
              className="heat-map-container center-block"
              ref={el => (this.el = el)}
            />
            {chartImageData && (
              <div className="text-right chart-downloader">
                <a
                  href={chartImageData}
                  className="text-muted"
                  download={`${moment(before).format("LL")} - Contributions`}
                  title="Download and Share."
                >
                  <i className="material-icons md-24">save_alt</i>
                </a>
              </div>
            )}
          </div>
        ) : (
          <h5 className="text-center mt-lg mb-lg text-muted">
            {`No activity to show for the period between ${moment(after).format(
              "LL"
            )} and ${moment(before).format("LL")}`}
          </h5>
        )}
      </div>
    );
  }

  renderHeatMap() {
    const { contributions, before, after, project } = this.props;
    if (!contributions || !contributions.length) return;

    this.chart = echarts.init(this.el);
    let periodData = {};

    var start = moment(after).subtract(1, "days");
    var end = moment(before);

    while (start.isBefore(end)) {
      periodData[start.format("YYYY-MM-DD")] = 0;
      start.add(1, "days");
    }

    let data = {};
    contributions.forEach(contribution => {
      if (contribution.date in data)
        data[contribution.date] += contribution.words;
      else data[contribution.date] = contribution.words;
    });

    data = Object.entries(data);
    periodData = Object.entries(periodData);

    const title = project ? project.name : "My Activity on Bookflow";

    const option = {
      title: [
        {
          show: true,
          text: title.length < 36 ? title : title.substring(0, 37) + "...",
          textStyle: {
            color: "#8798ad",
            fontSize: 10,
            fontWeight: "normal",
            fontFamily: "Open Sans",
            align: "center"
          },
          bottom: "5%",
          x: "center"
        },
        {
          show: false,
          text: "www.bookflow.pub",
          textStyle: {
            color: "#8798ad",
            fontSize: 8,
            fontFamily: "Open Sans",
            fontWeight: "normal"
          },
          bottom: "5%",
          x: "center"
        }
      ],
      calendar: {
        orient: "vertical",
        left: "29%",
        top: 40,
        cellSize: [25, 25],
        range: [
          moment(after)
            .subtract(1, "days")
            .format(),
          moment(before).format()
        ],
        itemStyle: {
          color: "#f5f6fb",
          borderColor: "#fff",
          borderWidth: 5
        },
        dayLabel: {
          textStyle: {
            fontSize: 12,
            fontFamily: "Open Sans",
            color: "#8798ad"
          }
        },
        monthLabel: {
          show: true,
          textStyle: {
            fontSize: 12,
            fontFamily: "Open Sans",
            color: "#8798ad"
          }
        },
        splitLine: {
          lineStyle: {
            color: "#fff",
            width: 2
          }
        },
        yearLabel: false
      },

      visualMap: {
        type: "continuous",
        min: -1000,
        max: 1000,
        text: ["Writing", "Editing"],
        orient: "horizontal",
        bottom: "13%",
        x: "center",
        seriesIndex: [0],
        textStyle: {
          fontSize: 10,
          fontFamily: "Open Sans",
          color: "#8798ad"
        },
        itemWidth: 12,
        itemHeight: 108,
        realtime: false,
        calculable: false,
        color: ["#d667cd", "#D5D4FF", "#8c63ea"],
        borderColor: "#fff",
        borderWidth: 0
      },

      tooltip: {
        show: true
      },
      series: [
        {
          type: "heatmap",
          coordinateSystem: "calendar",
          data: data,
          tooltip: {
            formatter: function(params, ticket, callback) {
              return `${moment(params.value[0]).format("MMM D")}`;
            },
            textStyle: {
              fontSize: 10,
              fontFamily: "Open Sans"
            },
            backgroundColor: "rgba(50,50,50,0.5)"
          }
        },
        {
          type: "scatter",
          coordinateSystem: "calendar",
          symbolSize: 0.001,
          color: "#f5f6fb",
          data: periodData,
          label: {
            normal: {
              show: true,
              formatter: function(params) {
                return `${moment(params.value[0]).format("D")}`;
              },
              textStyle: {
                fontSize: 14,
                fontFamily: "Open Sans",
                lineHeight: 16,
                textVerticalAlign: "bottom",
                color: "#2e384d"
              }
            }
          }
        }
      ]
    };
    //BEAACA
    this.chart.on("rendered", () => {
      const chartImageData = this.chart.getDataURL({
        pixelRatio: 10,
        backgroundColor: "#fff",
        type: "png"
      });
      this.setState({
        chartImageData: chartImageData
      });
    });

    this.chart.setOption(option);
  }
}

export default ContributionActivity;
