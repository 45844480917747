import React from "react";
import StyleButton from "./StyleButton";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const INLINE_STYLES = [
  {
    label: (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="bold-tooltip">Bold</Tooltip>}
      >
        <i className="material-icons">format_bold</i>
      </OverlayTrigger>
    ),
    style: "BOLD"
  },
  {
    label: (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="italic-tooltip">Italic</Tooltip>}
      >
        <i className="material-icons">format_italic</i>
      </OverlayTrigger>
    ),
    style: "ITALIC"
  },
  {
    label: (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="underline-tooltip">Underline</Tooltip>}
      >
        <i className="material-icons">format_underlined</i>
      </OverlayTrigger>
    ),
    style: "UNDERLINE"
  },
  {
    label: (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="underline-tooltip">Strikethrough</Tooltip>}
      >
        <i className="material-icons">format_strikethrough</i>
      </OverlayTrigger>
    ),
    style: "STRIKETHROUGH"
  }
  // { label: "Monospace", style: "CODE" }
];

class InlineStyleControls extends React.Component {
  constructor(props) {
    super(props);
    this.onToggle = e => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }

  render() {
    const currentStyle = this.props.editorState.getCurrentInlineStyle();
    return (
      <span className="RichEditor-controls">
        {INLINE_STYLES.map(type => (
          <StyleButton
            key={type.style}
            active={currentStyle.has(type.style)}
            label={type.label}
            onToggle={this.props.onToggle}
            style={type.style}
          />
        ))}
      </span>
    );
  }
}

export default InlineStyleControls;
