import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Grid, Col, Row, Button, Image, Alert } from "react-bootstrap";

import "./VerifyAccount.css";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

import { put } from "../../../utils/BeeApi";

class VerifyAccount extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];

    this.state = {};
  }

  componentDidMount() {
    this.putVerification();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  putVerification() {
    const { location } = this.props;
    const verificationToken = new URLSearchParams(location.search).get("token");

    this.setState({
      error: "",
      isLoading: true
    });

    const putVerificationPromise = put(
      `/login/verification/${verificationToken}`
    );
    putVerificationPromise.promise
      .then(response => {
        this.setState({
          isLoading: false,
          verified: true,
          error: ""
        });
      })
      .catch(error => {
        this.setState({
          error: error,
          isLoading: false
        });
      });
    this.subscribedPromises.push(putVerificationPromise);
  }

  render() {
    const { isLoading, verified, error } = this.state;

    return (
      <Grid fluid className="VerifyAccount mt-lg mb-lg">
        <Row>
          <Col xs={12} sm={6} smOffset={3} md={4} mdOffset={4}>
            <div className="mb-md text-center">
              <a href="http://about.bookflow.pub">
                <Image src="/bookflow-logo.png" width="300px" />
              </a>
            </div>
            <div className="card card-neutral text-center">
              {verified && (
                <div>
                  <Alert bsStyle="success mt-sm mb-sm">
                    <p className="">Success! Your email has been verified.</p>
                  </Alert>
                  <Button
                    bsStyle="primary"
                    componentClass={Link}
                    to={"/login"}
                    href={"/login"}
                  >
                    Continue to Login
                  </Button>
                </div>
              )}
              {isLoading && <Loader />}
              {error && this.renderError()}
              <hr />
              <div className="text-center mt-sm mb-sm">
                <h5>
                  Already verified your email? <Link to="/login">Login</Link>
                </h5>
                <p className="sub-text">Contact Support at team@bookflow.pub</p>
              </div>
            </div>
            <ul className="list-inline text-center">
              <li>
                <a href="http://about.bookflow.pub">About</a>
              </li>
              <li>&copy; Bookflow 2021</li>
            </ul>
          </Col>
        </Row>
      </Grid>
    );
  }

  renderError() {
    const { error } = this.state;

    let message = "";
    if (error.status === 403) {
      error.statusText =
        "Sorry, your link to verify your account has expired or is invalid.";
      message =
        "We have sent you an email with a new verification link. Please click the verification link in the email to complete your registration. Note that it may take a few minutes for the email to arrive.";
    }
    return <ErrorHandler error={error} message={message} />;
  }
}

export default VerifyAccount;
