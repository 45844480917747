import React, { Component } from "react";
import "./ContributionActivity.css";
import echarts from "echarts";
import moment from "moment";

/**
 * Handles rendering project activity heat map.
 * Charts implemented using https://ecomfe.github.io/echarts-doc/public/en/index.html
 * Heatmap: https://ecomfe.github.io/echarts-examples/public/editor.html?c=calendar-graph
 */
class ContributionActivity extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};
  }

  componentDidMount() {
    this.renderHeatMap();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.before !== prevProps.before ||
      this.props.after !== prevProps.after
    )
      this.renderHeatMap();
  }

  render() {
    const { before } = this.props;
    const { chartImageData } = this.state;

    return (
      <div className="ContributionHeatmap">
        <div>
          <div
            className="heat-map-container center-block"
            ref={el => (this.el = el)}
          />
          {chartImageData && (
            <div className="text-right chart-downloader" id="chart-downloader">
              <a
                href={chartImageData}
                className="text-muted"
                download={`${moment(before).format("LL")} - Contributions`}
                title="Download and Share."
              >
                <i className="material-icons md-24">save_alt</i>
              </a>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderHeatMap() {
    const { before, after, project } = this.props;
    const contributions = this.props.contributions || [];

    this.chart = echarts.init(this.el);
    let periodData = {};

    var start = moment(after);
    var end = moment(before);

    while (start.isBefore(end)) {
      periodData[start.format("YYYY-MM-DD")] = 0;
      start.add(1, "days");
    }

    periodData[end.format("YYYY-MM-DD")] = 0;

    let data = {};
    contributions.forEach(contribution => {
      if (contribution.date in data)
        data[contribution.date] += contribution.words;
      else data[contribution.date] = contribution.words;
    });

    data = Object.entries(data);
    periodData = Object.entries(periodData);
    let today = data.filter(array => moment().isSame(array[0], "day"));

    today = today.length > 0 ? today : [[moment().format("YYYY-MM-DD"), 0]];
    const title = project ? project.name : "My week of writing on Bookflow";

    const option = {
      title: [
        {
          show: true,
          text: title.length < 36 ? title : title.substring(0, 37) + "...",
          textStyle: {
            color: "#8798ad",
            fontSize: 10,
            fontWeight: "normal",
            fontFamily: "Open Sans",
            align: "center"
          },
          bottom: "25%",
          x: "center"
        },
        {
          show: false,
          text: "www.bookflow.pub",
          textStyle: {
            color: "#8798ad",
            fontSize: 8,
            fontFamily: "Open Sans",
            fontWeight: "normal"
          },
          bottom: "5%",
          x: "center"
        }
      ],
      calendar: {
        orient: "vertical",
        left: "10%",
        top: 70,
        cellSize: ["auto", 50],
        range: [moment(after).format(), moment(before).format()],
        itemStyle: {
          color: "#f5f6fb",
          borderColor: "#fff",
          borderWidth: 4
        },
        dayLabel: {
          textStyle: {
            fontSize: 14,
            fontFamily: "Open Sans",
            color: "#8798ad"
          }
        },
        monthLabel: {
          show: true,
          position: "start",
          align: "center",
          margin: 4,
          textStyle: {
            fontSize: 13,
            fontFamily: "Open Sans",
            color: "#8798ad"
          },
          formatter: function(param) {
            return moment(after).isSame(before, "month")
              ? param.nameMap
              : moment(after)
                  .subtract(1, "days")
                  .format("MMM");
          }
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: "#fff",
            width: 2
          }
        },
        yearLabel: false
      },

      visualMap: {
        show: false,
        type: "piecewise",
        min: -1000000,
        max: 1000000,
        text: ["Writing", "Editing"],
        orient: "horizontal",
        bottom: "13%",
        x: "center",
        seriesIndex: [0],
        textStyle: {
          fontSize: 14,
          fontFamily: "Open Sans",
          color: "#8798ad"
        },
        itemWidth: 10,
        itemHeight: 10,
        realtime: false,
        calculable: false,
        color: ["#d667cd", "#d667cd"],
        borderColor: "#fff",
        borderWidth: 0,
        itemSymbol: "roundRect"
      },

      tooltip: {
        show: true
      },
      series: [
        {
          type: "heatmap",
          coordinateSystem: "calendar",
          data: data,
          tooltip: {
            formatter: function(params, ticket, callback) {
              return `${params.value[1]} words`;
            },
            textStyle: {
              fontSize: 12,
              fontFamily: "Open Sans"
            },
            backgroundColor: "rgba(50,50,50,0.5)"
          }
        },
        {
          type: "scatter",
          coordinateSystem: "calendar",
          symbolSize: 30,
          symbolOffset: [0, 0],
          color: "#fcab53",
          Symbol: "circle",
          label: {
            normal: {
              show: true,
              offset: [0.5, 1],
              formatter: function(params) {
                return `${moment(params.value[0]).format("D")}`;
              },
              textStyle: {
                fontSize: 14,
                fontFamily: "Open Sans",
                lineHeight: 15,
                textVerticalAlign: "bottom",
                color: "#000"
              }
            }
          },
          data: today,
          tooltip: {
            formatter: function(params, ticket, callback) {
              return `${params.value[1]} words`;
            },
            textStyle: {
              fontSize: 12,
              fontFamily: "Open Sans"
            },
            backgroundColor: "rgba(50,50,50,0.5)"
          }
        },
        {
          type: "scatter",
          coordinateSystem: "calendar",
          symbolSize: 0.001,
          symbolOffset: [0, 0],
          color: "#f5f6fb",
          data: periodData,
          label: {
            normal: {
              show: true,
              offset: [0.5, 1],
              formatter: function(params) {
                return `${moment(params.value[0]).format("D")}`;
              },
              textStyle: {
                fontSize: 14,
                fontFamily: "Open Sans",
                lineHeight: 16,
                textVerticalAlign: "bottom",
                color: "#2e384d"
              }
            }
          }
        }
      ]
    };
    //BEAACA
    this.chart.on("rendered", () => {
      const chartImageData = this.chart.getDataURL({
        pixelRatio: 10,
        backgroundColor: "#fff",
        type: "png"
      });
      this.setState({
        chartImageData: chartImageData
      });
    });

    this.chart.setOption(option);
  }
}

export default ContributionActivity;
