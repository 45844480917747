import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

class UndoRedoControls extends React.Component {
	constructor(props) {
		super(props);
		this.onUndo = this._handleUndo.bind(this);
		this.onRedo = this._handleRedo.bind(this);
	}

	_handleRedo(event) {
		event.preventDefault();
		this.props.onToggle("REDO");
	}

	_handleUndo(event) {
		event.preventDefault();
		this.props.onToggle("UNDO");
	}

	render() {
		const { editorState } = this.props;
		const undoStack = editorState.getUndoStack();
		const redoStack = editorState.getRedoStack();
		return (
			<span className="RichEditor-controls">
				<span
					className={
						"RichEditor-styleButton " +
						(undoStack.size ? "" : "RichEditor-disabledButton")
					}
					onMouseDown={this.onUndo}
				>
					<OverlayTrigger
						placement="bottom"
						overlay={<Tooltip id="undo-tooltip">Undo</Tooltip>}
					>
						<i className="material-icons">undo</i>
					</OverlayTrigger>
				</span>
				<span
					className={
						"RichEditor-styleButton " +
						(redoStack.size ? "" : "RichEditor-disabledButton")
					}
					onMouseDown={this.onRedo}
				>
					<OverlayTrigger
						placement="bottom"
						overlay={<Tooltip id="redo-tooltip">Redo</Tooltip>}
					>
						<i className="material-icons">redo</i>
					</OverlayTrigger>
				</span>
			</span>
		);
	}
}

export default UndoRedoControls;
