import React from "react";
import "./ContentArticle.css";

const ContentArticle = ({ title, categories, contentBody, prompt }) => (
  <div className="mt-sm mb-sm">
    <h1>
      <big>{title}</big>
    </h1>
    <div
      className="body-section mt-md mb-md"
      dangerouslySetInnerHTML={{ __html: contentBody }}
    />
  </div>
);

export default ContentArticle;
