import { Config } from "../Config";
import React, { Component } from "react";
import "./Email.css";

class Email extends Component {
  constructor(props) {
    super(props);
    this.handleEmailShare = this._handleEmailShare.bind(this);
  }

  _handleEmailShare() {
    const { title, summary, link } = this.props;
    const message = `Check out my progress on ${
      Config.title
    }: %0A %0A ${summary || "..."} %0A %0A ${link || document.URL}`;

    window.location.href = `mailto:?subject=${title ||
      Config.defaultSiteTitle} &body=${message} %0A`;
  }

  render() {
    return (
      <li onClick={this.handleEmailShare}>
        <span className="icon-email">{}</span>
      </li>
    );
  }
}

export default Email;
