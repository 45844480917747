import React, { Component, Fragment } from "react";
import { Button, Modal, Row, Col } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import "./ProjectImport.css";

import { post } from "../../../utils/DeApi";
import { FieldGroup } from "../../../utils/BootstrapUtils";

import ProjectImportList from "../ProjectImportList/ProjectImportList";
import ProjectImportSummary from "../ProjectImportSummary/ProjectImportSummary";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import EmptyState from "../EmptyState/EmptyState";
import Loader from "../../Loader/Loader";

class ProjectImport extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
      show: false,
      projectName: "",
      projectFile: null
    };

    this.handleShowModal = this._handleShowModal.bind(this);
    this.handleCloseModal = this._handleCloseModal.bind(this);
    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleInputChange = this._handleInputChange.bind(this);
    this.handleFileChange = this._handleFileChange.bind(this);
    this.handleProjectImportDelete = this._handleProjectImportDelete.bind(this);
    this.handleProjectImportSelected = this._handleProjectImportSelected.bind(
      this
    );
  }

  _handleCloseModal() {
    this.setState(
      {
        show: false,
        projectImport: null,
        projectName: "",
        projectFile: null,
        error: ""
      },
      () => {
        if (this.props.onCloseModal) this.props.onCloseModal();
      }
    );
  }

  _handleShowModal() {
    this.setState({ show: true });
  }

  _handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  _handleFileChange(event) {
    event.preventDefault();
    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {
      this.setState({
        projectFile: file
      });
    };

    reader.readAsDataURL(file);
  }

  _handleSubmit(event) {
    event.preventDefault();
    const { projectName, projectFile } = this.state;

    if (!projectName) this.setState({ projectNameInvalid: true });
    if (!projectFile) this.setState({ projectFileInvalid: true });
    if (projectName && projectFile) this.createProjectFromFile();
  }

  _handleCompleteImport(event) {
    event.preventDefault();
    const { projectName, projectImport } = this.state;

    if (!projectName) {
      this.setState({ projectNameInvalid: true });
    }

    if (!projectImport) {
      this.setState({ projectImportInvalid: true });
    }

    this.createProjectFromImport();
  }

  _handleProjectImportDelete(projectImport) {
    this.setState((prevState, props) => {
      return {
        projectImport: null,
        projectName: "",
        projectFile: null,
        error: ""
      };
    });
  }

  _handleProjectImportSelected(projectImport) {
    this.setState({
      projectImport: projectImport
    });
  }

  componentDidMount() {}

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  createProjectFromFile() {
    const { projectName, projectFile } = this.state;

    this.setState({ isLoading: true, projectNameInvalid: false, error: "" });

    const formData = new FormData();
    formData.append("projectName", projectName);
    formData.append("projectFile", projectFile);
    const projectImportPromise = post("/project-imports", formData);
    projectImportPromise.promise
      .then(response => {
        this.setState({
          projectImport: response.data,
          error: "",
          isLoading: false
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error,
            isLoading: false
          });
      });
    this.subscribedPromises.push(projectImportPromise);
  }

  render() {
    const { created, projectImport, show } = this.state;

    if (created) return <Redirect to={"/projects/" + created.id} push />;

    return (
      <Fragment>
        <Button bsStyle="link" onClick={this.handleShowModal}>
          Import Project
        </Button>
        <Modal
          show={show}
          onHide={this.handleCloseModal}
          className="ProjectCreateModal"
          bsSize="lg"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title className="mt-0">Import Word file</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EmptyState contentId={"5cbf7388a7576"} />
            <hr />
            {projectImport
              ? this.renderProjectImportSummary()
              : this.renderImportForm()}
          </Modal.Body>
        </Modal>
      </Fragment>
    );
  }

  renderImportForm() {
    const {
      isLoading,
      projectName,
      projectNameInvalid,
      projectFileInvalid,
      error
    } = this.state;

    if (isLoading) return <Loader />;

    return (
      <form onSubmit={this.handleSubmit}>
        <Row className="mt-sm mb-xs">
          <Col xs={12} md={6}>
            <FieldGroup
              id="projectName"
              name="projectName"
              label="Project Name"
              type="text"
              onChange={this.handleInputChange}
              value={projectName}
              placeholder="Enter a name for your project"
              required
              maxLength={81}
              validationState={
                projectName.length > 80 || projectNameInvalid ? "error" : null
              }
              autoFocus
            />
          </Col>
          <Col xs={12} md={6} className="secondary-field-group">
            <FieldGroup
              id="projectFile"
              name="projectFile"
              label="Project File"
              type="file"
              validationState={projectFileInvalid ? "error" : null}
              onChange={this.handleFileChange}
              help="Select a Word document file from your device."
            />
          </Col>
        </Row>
        {error && this.renderError()}
        <div className="text-right">
          <Button onClick={this.handleCloseModal}>Cancel</Button>
          <Button type="submit" bsStyle="success" className="ml-xs">
            Review Import
          </Button>
        </div>
        <ProjectImportList
          onProjectImportSelected={this.handleProjectImportSelected}
        />
      </form>
    );
  }

  renderProjectImportSummary() {
    const { projectImport } = this.state;

    return (
      <ProjectImportSummary
        projectImport={projectImport}
        onProjectImportDelete={this.handleProjectImportDelete}
      />
    );
  }

  renderError() {
    const { error } = this.state;
    let message = "";
    if (error.status === 400 || error.status === 422) {
      error.statusText =
        "The format or content of the file you imported is not supported.";
      message =
        "Clean up your file to make sure the content meet the requirements above.";
    }
    return <ErrorHandler error={error} message={message} />;
  }
}

export default ProjectImport;
