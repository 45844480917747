import React, { useState } from "react";
import {
  FormGroup,
  FormControl,
  HelpBlock,
  ControlLabel,
  InputGroup,
  Glyphicon,
  Button
} from "react-bootstrap";

/**
 * Provides utilities to build reusable bootstrap components.
 */
export function FieldGroup({ id, label, help, validationState, ...props }) {
  const [type, setType] = useState("password");

  return (
    <FormGroup controlId={id} validationState={validationState}>
      {label && <ControlLabel>{label}</ControlLabel>}
      {props.type === "password" ? (
        <InputGroup>
          <FormControl {...props} type={type} />
          <InputGroup.Button
            onClick={() => setType(type === "password" ? "text" : "password")}
          >
            <Button>
              <Glyphicon
                glyph={type === "password" ? "eye-close" : "eye-open"}
              />
            </Button>
          </InputGroup.Button>
        </InputGroup>
      ) : (
        <FormControl {...props} />
      )}

      {help && <HelpBlock>{help}</HelpBlock>}
    </FormGroup>
  );
}
