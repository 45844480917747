import React, { Component } from "react";
import "./SubscriptionStatus.css";

import Moment from "moment";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import { post, get } from "../../../utils/BeeApi";

import {
  getSubscriptionStatus,
  getSubscriptionViewType
} from "../../../utils/SubscriptionUtils";

class SubscriptionStatus extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};
  }

  componentDidMount() {
    this.fetchSubscriptions();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  fetchSubscriptions() {
    let subscriptionsPromise = get("/subscriptions");
    subscriptionsPromise.promise
      .then(response => {
        this.setState(
          {
            subscriptions: response.data,
            error: ""
          },
          () => this.fetchSubscriber()
        );
      })
      .catch(error => {
        if (!error.isCanceled) {
          this.setState({
            error: error
          });
        }
      });
    this.subscribedPromises.push(subscriptionsPromise);
  }

  fetchSubscriber() {
    const subscriptions = this.state.subscriptions[0];

    let subscriberPromise = get("/user/anon");
    subscriberPromise.promise
      .then(response => {
        let subscriber = response.data;
        if (!subscriber.trialEndsAt && !subscriptions)
          this.createSubscriptionTrial();
        else
          this.setState({
            subscriber: response.data,
            error: ""
          });
      })
      .catch(error => {
        if (!error.isCanceled) {
          this.setState({
            error: error
          });
        }
      });
    this.subscribedPromises.push(subscriberPromise);
  }

  createSubscriptionTrial() {
    const { user } = this.props;
    let trialPromise = post(`/user/${user.sub}/trial`);
    trialPromise.promise
      .then(response => {
        this.setState({
          subscriber: response.data,
          error: ""
        });
      })
      .catch(error => {
        if (!error.isCanceled) {
          this.setState({
            error: error
          });
        }
      });
    this.subscribedPromises.push(trialPromise);
  }

  render() {
    const { subscriptions, subscriber, error } = this.state;

    if (!subscriber || !subscriptions) return <span />;

    let subscriptionStatus = getSubscriptionStatus(subscriptions, subscriber);
    let subscriptionViewType = getSubscriptionViewType(
      subscriptions,
      subscriptionStatus
    );
    if (error) subscriptionStatus = "Error";

    let stripeTrialEndsAt =
      subscriptions[0] && subscriptions[0].trialEndsAt
        ? subscriptions[0].trialEndsAt
        : "2020-01-01";

    let subscriberTrialEndsAt = subscriber.trialEndsAt
      ? subscriber.trialEndsAt
      : "2020-01-01";

    let trialEndsAt = Moment(subscriberTrialEndsAt).isAfter(stripeTrialEndsAt)
      ? subscriberTrialEndsAt
      : stripeTrialEndsAt;

    // Active
    if (subscriptionViewType === "active") {
      return <span className="PaidUser" />;
    }

    if (Moment(trialEndsAt).diff(Moment(), "days") > 10) return <span />;

    // Trialing
    if (subscriptionViewType === "trialing") {
      return (
        <Button
          componentClass={Link}
          className="SubscriptionStatus TrialingUser btn-nav btn-secondary"
          to="/account"
          bsSize="sm"
        >
          {`Trialing, ${Moment(trialEndsAt).diff(
            Moment(),
            "days"
          )} Days Left. Subscribe Now!`}
        </Button>
      );
    }

    //  Early Adopter
    if (subscriptionViewType === "early-adopter") {
      return (
        <Button
          componentClass={Link}
          className="SubscriptionStatus EarlyAdopter btn-nav btn-secondary"
          to="/account"
          bsSize="sm"
        >
          {`Early Adopter, ${Moment(trialEndsAt).diff(
            Moment(),
            "days"
          )} Days Left. Subscribe Now!`}
        </Button>
      );
    }

    // In active
    if (subscriptionViewType === "inactive") {
      return (
        <Button
          componentClass={Link}
          className="SubscriptionStatus InactiveUser btn-nav"
          to="/account"
          bsStyle="danger"
          bsSize="sm"
        >
          {`Subscribe now to edit your work!`}
        </Button>
      );
    }

    return <span />;
  }
}

export default SubscriptionStatus;
