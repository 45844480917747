import React, { Component } from "react";
import Moment from "moment";

import { get } from "../../../utils/BeeApi";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import SubscriptionCancel from "../SubscriptionCancel/SubscriptionCancel";
import SubscriptionPayment from "../SubscriptionPayment/SubscriptionPayment";

class SubscriptionActivePlan extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};

    this.handleSubscriptionUpdated = this._handleSubscriptionUpdated.bind(this);
  }

  _handleSubscriptionUpdated(subscription) {
    this.props.onSubscriptionUpdated(subscription);
  }

  componentDidMount() {
    this.fetchCoupon();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  fetchCoupon() {
    const { subscription } = this.props;
    if (!subscription.discountCoupon) {
      this.setState({ coupon: {} });
      return;
    }

    this.setState({ isLoading: true, error: "" });
    const couponsPromise = get(`/coupons/${subscription.discountCoupon}`);
    couponsPromise.promise
      .then(response => {
        this.setState({ show: false, coupon: response.data, isLoading: false });
      })
      .catch(error => {
        if (error.status === 404) {
          this.setState({ show: false, coupon: {}, isLoading: false });
        } else
          !error.isCanceled &&
            this.setState({
              error: error,
              isLoading: false
            });
      });
    this.subscribedPromises.push(couponsPromise);
  }

  render() {
    const { plan, subscription, subscriber } = this.props;
    const { coupon, isLoading, error } = this.state;

    if (isLoading) return <Loader />;
    if (error) return <ErrorHandler error={error} />;
    if (!coupon) return <div />;

    let activeCoupon = coupon.couponId;
    const planSavings = activeCoupon
      ? coupon.amountOff
        ? coupon.amountOff
        : (coupon.percentOff * plan.amount) / 100
      : 0;
    const planTotal = planSavings ? plan.amount - planSavings : plan.amount;

    return (
      <div className="SubscriptionActivePlan card card-success text-center bg-info">
        <h3 className="text-uppercase">{`${plan.nickname} plan`}</h3>
        <hr />
        <h5>
          {`You are on the ${plan.nickname} Plan. Your
          payment of $${planTotal / 100} is due on ${Moment(
            subscription.currentPeriodEnd
          ).format("LL")}.`}
        </h5>

        {activeCoupon && (
          <h5 className="text-success">
            {coupon.couponId} Savings{" "}
            {coupon.amountOff
              ? `$${coupon.amountOff}`
              : `${coupon.percentOff}%`}{" "}
            (-${planSavings / 100})
          </h5>
        )}

        <h5>Please make sure your payment method is up to date.</h5>
        <ul className="list-unstyled text-center mt-sm mb-sm">
          <li className="mt-xs mb-xs">
            <SubscriptionPayment
              plan={plan}
              subscriber={subscriber}
              onPaymentUpdated={this.handlePaymentUpdated}
            />
          </li>
          <li className="mt-xs mb-xs">
            <SubscriptionCancel
              plan={plan}
              subscription={subscription}
              subscriber={subscriber}
              onSubscriptionUpdated={this.handleSubscriptionUpdated}
            />
          </li>
        </ul>
      </div>
    );
  }
}

export default SubscriptionActivePlan;
