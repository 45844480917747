import React, { Component } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import { Helmet } from "react-helmet";

import MonitorTimer from "../../Timer/MonitorTimer/MonitorTimer";
import Feedback from "../Feedback/Feedback";
import HeaderAccount from "../HeaderAccount/HeaderAccount";
import SubscriptionStatus from "../../Account/SubscriptionStatus/SubscriptionStatus";
import WhatIsNew from "../WhatIsNew/WhatIsNew";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleTimerChange = this._handlerTimerChange.bind(this);
  }

  _handlerTimerChange(countDown) {
    this.setState({ timerChange: countDown });
  }

  render() {
    const { user, title } = this.props;
    const { timerChange } = this.state;

    return (
      <div className="MainHeader">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{"Bookflow - " + title}</title>
        </Helmet>
        <Navbar default fluid fixedTop collapseOnSelect>
          <Navbar.Header>
            <Navbar.Brand>
              <Link to="/">
                <img alt="Bookflow" src="/bookflow-logo.png" className="logo" />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav pullRight>
              <Feedback user={user} />
              <MonitorTimer
                onTimerChange={this.handleTimerChange}
                timerChange={timerChange}
              />
              <WhatIsNew user={user} />
              <HeaderAccount
                user={user}
                onTimerChange={this.handleTimerChange}
                timerChange={timerChange}
              />
            </Nav>
            <Navbar.Text pullRight className="mt-0 mb-3">
              <SubscriptionStatus user={user} />
            </Navbar.Text>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

export default Header;
