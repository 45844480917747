import React, { Component } from "react";
import { Grid, Col, Row, Button, Alert } from "react-bootstrap";
import { Redirect, Link } from "react-router-dom";

import "./Account.css";

import { get } from "../../utils/BeeApi";

import Loader from "../Loader/Loader";
import ErrorHandler from "../ErrorHandler/ErrorHandler";
import Footer from "../App/Footer/Footer";

import UserUpdate from "./UserUpdate/UserUpdate";
import Subscription from "./Subscription/Subscription";
import AccountNav from "./AccountNav/AccountNav";

/**
 * Handles writers page.
 */
class Account extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};

    this.handleUserUpdated = this._handleUserUpdated.bind(this);
  }

  _handleUserUpdated(subscriber) {
    this.setState({
      subscriber: subscriber
    });
  }

  componentDidMount() {
    this.fetchSubscriber();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  fetchSubscriber() {
    let subscriberPromise = get("/user/anon");
    subscriberPromise.promise
      .then(response => {
        this.setState({
          subscriber: response.data,
          error: ""
        });
      })
      .catch(error => {
        if (!error.isCanceled) {
          this.setState({
            error: error
          });
        }
      });
    this.subscribedPromises.push(subscriberPromise);
  }

  render() {
    const { error, subscriber } = this.state;
    const { user } = this.props;

    if (!subscriber) return <Loader />;
    if (subscriber.userId !== user.sub) return <Redirect to="/" />;

    return (
      <div className="Main">
        <AccountNav user={user} location={this.props.location} />
        <Grid className="MainContent">
          <div className="mt-md mb-md">
            {error && <ErrorHandler error={error} />}
          </div>
          <Row>
            <Col xs={12} sm={4}>
              <div className="mt-md mb-md">{this.renderUser()}</div>
              <hr />
              <p className="body-2 mt-sm mb-sm text-center">
                <UserUpdate
                  user={subscriber}
                  onUserUpdated={this.handleUserUpdated}
                />
              </p>

              <p className="body-2 mt-sm mb-sm text-center">
                <Button
                  bsStyle="default"
                  bsSize="xs"
                  componentClass={Link}
                  to={"/logout"}
                  href={"/logout"}
                >
                  Logout
                </Button>
              </p>
              <ul className="list-inline mt-sm mb-sm text-center">
                <li>
                  <Link to={"/contents/5ca50577ad02f/faq"}>FAQ</Link>
                </li>
                <li>
                  <Link to={"/contact"}>Contact Bookflow</Link>
                </li>
              </ul>
            </Col>
            <Col xs={12} sm={8}>
              <div className="mt-md mb-md">
                {!subscriber.verified && (
                  <Alert bsStyle="info" className="text-center">
                    <p>Your security is important to us.</p>
                    <p>
                      In order to ensure that your work remains secure, we have
                      sent an email to <strong>{subscriber.email}</strong>.
                      Please click the activation link in the email to verify
                      your account.
                    </p>
                  </Alert>
                )}
              </div>
              <div className="mt-md mb-md">
                <Subscription subscriber={subscriber} />
              </div>
              <hr />
              <p className="sub-text text-center">
                If you have any questions regarding pricing, payment, or
                features, please reach out to us at{" "}
                <a href="mailto:team@bookflow.pub">team@bookflow.pub</a>.
              </p>
            </Col>
          </Row>
        </Grid>
        <Footer />
      </div>
    );
  }

  renderUser() {
    const { subscriber } = this.state;

    return (
      <div className="UserProfile mt-sm mb-sm">
        <div className="pt-sm pb-sm ProfilePictureDefault">
          <div className="pic">{`${subscriber.firstName.charAt(
            0
          )}${subscriber.lastName.charAt(0)}`}</div>
        </div>
        <div className="text-center">
          <h2>{`${subscriber.firstName} ${subscriber.lastName}`}</h2>
          <p>{subscriber.email}</p>
          <p>{subscriber.about}</p>
        </div>
      </div>
    );
  }
}

export default Account;
