import React, { Component, Fragment } from "react";
import { Button, Modal } from "react-bootstrap";

import { put } from "../../../utils/DeApi";
import { FieldGroup } from "../../../utils/BootstrapUtils";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class CharacterEdit extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
      show: false,
      title: this.props.character.title || "",
      description: this.props.character.description || "",
      characterType: this.props.character.characterType.id
    };

    this.handleShowModal = this._handleShowModal.bind(this);
    this.handleCloseModal = this._handleCloseModal.bind(this);
    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleInputChange = this._handleInputChange.bind(this);
  }

  _handleCloseModal() {
    this.setState({ show: false });
    this.props.onCharacterEditing && this.props.onCharacterEditing(false);
  }

  _handleShowModal() {
    this.setState({ show: true });
    this.props.onCharacterEditing && this.props.onCharacterEditing(true);
  }

  _handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value }, this.handleUpdated);
  }

  _handleSubmit(event) {
    event.preventDefault();
    const { title, description, characterType } = this.state;
    const { character, onCharacterUpdated } = this.props;

    const updatePromise = put("/characters/" + character.id, {
      title: title,
      description: description,
      characterType: characterType
    });
    this.setState({ isUpdating: true, error: "" });

    updatePromise.promise
      .then((response) => {
        this.setState({
          show: false,
          error: "",
          isUpdating: false
        });
        onCharacterUpdated(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          this.setState({
            error: error,
            isUpdating: false
          });
        }
      });
    this.subscribedPromises.push(updatePromise);
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  render() {
    const {
      show,
      isUpdating,
      error,
      characterType,
      title,
      description
    } = this.state;

    const { characterTypes } = this.props;

    const form =
      !characterTypes || isUpdating ? (
        <Loader />
      ) : (
        <form onSubmit={this.handleSubmit} autoComplete="off">
          <Modal.Header closeButton>
            <Modal.Title className="mt-0">
              Update title, type, and summary
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FieldGroup
              id="characterTitle"
              name="title"
              type="text"
              label="Character+ Name"
              placeholder="Enter character name"
              value={title}
              onChange={this.handleInputChange}
              required
              maxLength={81}
              validationState={title.length > 80 || !title ? "error" : null}
            />
            <FieldGroup
              id="characterType"
              name="characterType"
              type="select"
              label="Character+ Type"
              onChange={this.handleInputChange}
              componentClass="select"
              placeholder="Select character type"
              value={characterType}
              required
            >
              {characterTypes
                .sort((a, b) => {
                  if (a.id < b.id) return -1;
                  if (a.id > b.id) return 1;
                  return 0;
                })
                .map((type) => {
                  return (
                    <option value={type.id} key={type.id}>
                      {type.name}
                    </option>
                  );
                })}
            </FieldGroup>
            <FieldGroup
              id="characterDescription"
              name="description"
              componentClass="textarea"
              label="Character+ Summary"
              rows={"15"}
              placeholder="Enter character summary (optional)"
              value={description || ""}
              onChange={this.handleInputChange}
              maxLength={5100}
              validationState={description.length > 5000 ? "error" : null}
            />

            {error && <ErrorHandler error={error} />}
          </Modal.Body>
          <Modal.Footer>
            <Button bsStyle="link" onClick={this.handleCloseModal}>
              Cancel
            </Button>
            <Button type="submit" bsStyle="success">
              Save
            </Button>
          </Modal.Footer>
        </form>
      );

    return (
      <Fragment>
        <Button
          className="btn-secondary"
          bsSize="xs"
          onClick={this.handleShowModal}
        >
          Edit
        </Button>
        <Modal show={show} onHide={this.handleCloseModal} backdrop="static">
          {form}
        </Modal>
      </Fragment>
    );
  }
}

export default CharacterEdit;
