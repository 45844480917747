import React, { Component } from "react";
import { Button, Table, Label, ButtonToolbar, Alert } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import "./ProjectImportSummary.css";

import { get, post, destroy } from "../../../utils/DeApi";

import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import Loader from "../../Loader/Loader";
import TextExtractionUtil from "../../RichTextEditor/Controls/TextExtractionUtil";

class ProjectImportSummary extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};
  }

  componentDidMount() {
    this.fetchProjectImport();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  fetchProjectImport() {
    const { projectImport } = this.props;
    this.setState({ isLoading: true });

    let projectImportPromise = get("/project-imports/" + projectImport.id);
    projectImportPromise.promise
      .then(response => {
        this.setState({
          projectImport: response.data,
          scenes: this.extractScenes(response.data),
          error: "",
          isLoading: false
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error,
            isLoading: false
          });
      });

    this.subscribedPromises.push(projectImportPromise);
  }

  extractScenes(imported) {
    let fileHTML = imported.body || "";
    var fileContents = fileHTML.match(/<body[^>]*>[\s\S]*<\/body>/gi);
    var HtmlSections = fileContents[0].split(/<br>|<br \/>|<br\/>/);
    var scenes = [];

    for (
      var sectionIndex = 0;
      sectionIndex < HtmlSections.length;
      sectionIndex++
    ) {
      var section = HtmlSections[sectionIndex];
      var contentState = TextExtractionUtil.htmlToContentState(section);

      if (!contentState.hasText()) {
        continue;
      }

      var blocks = contentState.getBlocksAsArray();
      var title = `Scene: ${sectionIndex + 1}`;

      for (var blockIndex = 0; blockIndex < blocks.length; blockIndex++) {
        var currBlock = blocks[blockIndex];
        var text = currBlock.getText().trim();

        if (text) {
          title = text.length < 81 ? text : text.substring(0, 80) + "...";
          break;
        }
      }

      var body = TextExtractionUtil.convertToRaw(contentState);
      var bodySnippet = contentState.hasText()
        ? contentState.getPlainText().substring(0, 150) + "..."
        : "";

      var sceneStats = TextExtractionUtil.getWordCount(contentState);

      scenes.push({
        orderId: 1 + sectionIndex,
        title: title,
        body: JSON.stringify(body),
        bodySnippet: bodySnippet,
        words: sceneStats.words,
        characters: sceneStats.characters,
        blocks: sceneStats.blocks
      });
    }

    return scenes;
  }

  createProjectFromImport() {
    const { projectImport, scenes } = this.state;

    this.setState({ isLoading: true });

    const createFromImportPromise = post(
      `/project-imports/${projectImport.id}/projects`,
      {
        scenes: scenes
      }
    );
    createFromImportPromise.promise
      .then(response => {
        this.setState({
          error: "",
          created: response.data,
          isLoading: false
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error,
            isLoading: false
          });
      });
    this.subscribedPromises.push(createFromImportPromise);
  }

  deleteProjectImport() {
    const { onProjectImportDelete, projectImport } = this.props;
    this.setState({ isLoading: true });

    const destroyPromise = destroy("/project-imports/" + projectImport.id);
    destroyPromise.promise
      .then(response => {
        this.setState({ projectImport: null }, () => {
          onProjectImportDelete && onProjectImportDelete(projectImport);
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error,
            isLoading: false
          });
      });
    this.subscribedPromises.push(destroyPromise);
  }

  render() {
    const { projectImport, scenes, isLoading, error, created } = this.state;

    if (isLoading) return <Loader />;
    if (error) return this.renderError();
    if (created) return <Redirect to={"/projects/" + created.id} push />;

    if (!projectImport) return <div />;

    const words = scenes
      ? scenes.reduce(function(memo, scene) {
          return memo + (scene.words || 0);
        }, 0)
      : 0;

    return (
      <div className="ProjectImportSummary">
        <p className="pull-right mb-0">
          <Label>
            {new Intl.NumberFormat().format(words || 0)} total words
          </Label>
        </p>
        <h4>{projectImport.projectName} </h4>
        {this.renderOutline()}
        <hr />
        <ButtonToolbar>
          <Button
            onClick={() => this.createProjectFromImport()}
            bsStyle="success"
            className="pull-right"
          >
            Complete Importing Project
          </Button>
          <Button
            onClick={() => this.deleteProjectImport()}
            className="pull-right"
          >
            Discard
          </Button>
        </ButtonToolbar>
      </div>
    );
  }

  renderOutline() {
    const { scenes } = this.state;

    return scenes.length ? (
      <div className="table-responsive">
        <Table striped hover>
          <tbody>
            {scenes.map((scene, index) => {
              return (
                <tr key={index}>
                  <td>
                    <small>{scene.orderId}</small>
                  </td>
                  <td>
                    <small>
                      {scene.title &&
                        (scene.title.length < 35
                          ? scene.title
                          : scene.title.substring(0, 32) + "...")}
                    </small>
                  </td>
                  <td>
                    <small>{scene.bodySnippet}</small>
                  </td>
                  <td>
                    <p className="pull-right mb-0">
                      <Label>
                        {scene.words
                          ? new Intl.NumberFormat().format(scene.words)
                          : 0}{" "}
                        words
                      </Label>
                    </p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    ) : (
      <div />
    );
  }

  renderError() {
    const { error } = this.state;
    const { projectImport } = this.props;

    if (error.status === 500 || error.status === 422) {
      let statusText =
        "The format or content of the file you imported is not supported.";
      let message =
        "Please discard and import a supported file. Clean up your file to make sure the content meet the requirements above.";

      return (
        <div>
          <h4>{projectImport.projectName}</h4>
          <Alert bsStyle="warning" className="mt-xs mb-xs">
            <h4>{statusText}</h4>
            <p>{message}</p>
          </Alert>
          <hr />
          <p className="text-right mb-0">
            <Button
              bsStyle="primary"
              onClick={() => this.deleteProjectImport()}
            >
              Discard
            </Button>
          </p>
        </div>
      );
    } else {
      return <ErrorHandler error={error} />;
    }
  }
}

export default ProjectImportSummary;
