import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { Grid, Col, Row, Image, Button, Alert } from "react-bootstrap";

import "./Login.css";
import { FieldGroup } from "../../../utils/BootstrapUtils";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import Paywall from "../Paywall/Paywall";

import { isLoggedIn, login, facebookLogin } from "../../../utils/BeeApi";
import {
  isLoggedIn as isLoggedInDE,
  login as loginDE
} from "../../../utils/DeApi";

import FacebookLogin from "react-facebook-login";

/**
 * Handles logging in to the application.
 * Handles single sign on.
 */
class Login extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleEmailChange = this._handleEmailChange.bind(this);
    this.handlePasswordChange = this._handlePasswordChange.bind(this);

    const loggedIn = isLoggedInDE() && isLoggedIn();
    var email = loggedIn ? loggedIn.email : "";
    this.state = {
      email: email,
      password: "",
      emailValid: !!email || "",
      passwordValid: "",
      error: "",
      loggedIn: loggedIn
    };

    this.subscribedPromises = [];
  }

  componentDidMount() {}

  _handleEmailChange(event) {
    let email = event.target.value;
    this.setState({
      email: email,
      emailValid: email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
        ? true
        : false
    });
  }

  _handlePasswordChange(event) {
    let password = event.target.value;
    this.setState({
      password: password,
      passwordValid: password.length ? true : false
    });
  }

  _handleSubmit(event) {
    if (event) event.preventDefault();
    const { emailValid, passwordValid, email, password } = this.state;

    if (!emailValid || !passwordValid) {
      this.setState({ formSubmitted: true });
      return;
    }
    this.setState({
      error: "",
      isLoading: true
    });
    login({
      email: email,
      password: password
    })
      .then(data => {
        return loginDE();
      })
      .then(data => {
        this.setState({
          loggedIn: true,
          isLoading: false
        });
      })
      .catch(error => {
        this.setState({
          error: error,
          isLoading: false
        });
      });
  }

  responseFacebook = response => {
    let token = response.accessToken;
    if (token) {
      this.setState({
        error: "",
        isLoading: true
      });

      facebookLogin({
        token: token
      })
        .then(data => {
          return loginDE();
        })
        .then(data => {
          this.setState({
            loggedIn: true,
            isLoading: false
          });
        })
        .catch(error => {
          this.setState({
            error: error,
            isLoading: false
          });
        });
    }
  };

  componentClicked = () => {
    console.log("clicked");
  };

  renderLoginForm() {
    const APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
    const {
      email,
      emailValid,
      password,
      passwordValid,
      error,
      formSubmitted
    } = this.state;
    return (
      <form className="LoginForm" onSubmit={this.handleSubmit}>
        <div className="mt-sm mb-md text-center">
          <h2>Login to continue</h2>
        </div>
        <hr />
        <FieldGroup
          id="loginEmail"
          label="Email address"
          type="email"
          value={email}
          onChange={this.handleEmailChange}
          validationState={formSubmitted && !emailValid ? "error" : null}
          placeholder="Enter email"
          autoFocus
        />
        <FieldGroup
          id="loginPassword"
          label="Password"
          type="password"
          value={password}
          onChange={this.handlePasswordChange}
          validationState={formSubmitted && !passwordValid ? "error" : null}
          placeholder="Password"
        />
        {error && this.renderError()}
        <Button type="submit" bsStyle="primary" block>
          Login
        </Button>
        <p className="text-center pt-xs">or</p>
        {/* <FacebookLogin
          appId={APP_ID}
          autoLoad={false}
          fields="first_name,last_name,email,picture"
          onClick={this.componentClicked}
          callback={this.responseFacebook}
          disableMobileRedirect={true}
          textButton="Continue with Facebook"
          icon={<i className="material-icons">facebook</i>}
          cssClass="btn btn-default btn-block fb-btn"
        /> */}

        <Button
          block
          className="fb-btn"
          onClick={() =>
            this.setState({
              showInfo: true
            })
          }
        >
          <i className="material-icons">facebook</i> Continue with Facebook
        </Button>
        {this.state.showInfo && (
          <Alert className="mt-xs">
            Facebook's protocols have changed and we are in the process of
            updating this integration. In the meantime, please use{" "}
            <Link to="/login-help">forgot password</Link>. We apologize for the
            inconvenience!
          </Alert>
        )}
        <p className="text-muted text-center mt-sm mb-sm">
          <small>
            By continuing, you agree to our{" "}
            <a href="http://about.bookflow.pub/terms">Terms of Service</a> and
            have read and acknowledge our{" "}
            <a href="http://about.bookflow.pub/privacy">Privacy Statement</a>.
          </small>
        </p>
        <hr />
        <div className="text-center mt-sm mb-sm">
          <h5>
            <Link to="/login-help">Forgot Password?</Link>
          </h5>
          <h5>
            New to Bookflow? <Link to="/signup">Create an account.</Link>
          </h5>
          <p className="sub-text">Contact Support at team@bookflow.pub</p>
        </div>
      </form>
    );
  }

  render() {
    const { loggedIn, isLoading } = this.state;

    if (loggedIn) {
      return this.renderSuccess();
    }

    if (isLoading) return <Loader />;

    return (
      <Grid fluid className="Login mt-lg mb-lg">
        <Row>
          <Col xs={12} sm={6} smOffset={3} md={4} mdOffset={4}>
            <div className="mb-md text-center">
              <a href="http://about.bookflow.pub">
                <Image src="/bookflow-logo.png" width="300px" />
              </a>
            </div>
            <div className="card card-neutral">{this.renderLoginForm()}</div>

            <ul className="list-inline text-center">
              <li>
                <a href="http://about.bookflow.pub">About</a>
              </li>
              <li>&copy; Bookflow 2021</li>
            </ul>
          </Col>
        </Row>
      </Grid>
    );
  }

  renderSuccess() {
    const { from } = this.props.location.state || {
      from: { pathname: "/" }
    };
    let beeToken = isLoggedIn();
    if (beeToken.isSubscribed) {
      return <Redirect to={from} />;
    } else {
      let user = isLoggedIn();
      return <Paywall user={user} destination={from} />;
    }
  }

  renderError() {
    var { error, email } = this.state;

    let message = "";
    if (error.status === 403) {
      return (
        <Alert bsStyle="info" className="mt-md mb-md text-center">
          <h4>Please Confirm Your E-mail Address</h4>
          <hr />
          <div className="mt-sm mb-sm">
            <p>Your security is important to us.</p>
            <p>
              In order to ensure that your work remains secure, we have sent an
              email to <strong>{email}</strong>. Please click the activation
              link in the email to verify your account.
            </p>
            <p>
              Please note that it may take a few moments for the email to
              arrive. Also please check your spam folder.
            </p>
          </div>
          <hr />
          <h5>
            <Link to="/login-help">Request another verification email.</Link>
          </h5>
          <p className="sub-text">Contact Support at team@bookflow.pub</p>
        </Alert>
      );
    } else if (error.status === 401) {
      error.status = 422;
      error.statusText = "These credentials did not match our records.";
      message =
        "Please check the email and password you provided and try again.";
    }
    return <ErrorHandler error={error} message={message} />;
  }
}

export default Login;
