import Echo from "laravel-echo";
import "pusher-js";
import { getToken } from "./AuthService";

const TOKEN_NAME = "de-token";
const END_POINT = "https://bookflow-de.pixeledge.io/api/v1";

const options = {
  broadcaster: "pusher",
  key: "dd9c7ba507eef916c804",
  cluster: "us3",
  forceTLS: true,
  authEndpoint: END_POINT + "/broadcasting/auth",
  auth: {
    headers: {
      Authorization: `Bearer ${getToken(TOKEN_NAME)}`,
      Accept: "application/json"
    }
  }
};

let echo = "";

function connect() {
  if (!getToken(TOKEN_NAME)) {
    setTimeout(() => connect(), 500);
    return;
  }
  if (!echo) {
    echo = new Echo(options);
    echo.connector.pusher.connection.bind("connected", event => connect(event));
    echo.connector.pusher.connection.bind("disconnected", () => disconnect());
  }
  echo.connector.pusher.config.auth.headers.Authorization = `Bearer ${getToken(
    TOKEN_NAME
  )}`;
  echo.connector.pusher.connect();
}

function disconnect() {
  if (!echo) return;
  echo.disconnect();
}

function publish(channelId, eventId, params) {
  if (!echo) connect();
  echo.private(channelId).whisper(eventId, params);
}

function subscribe(channelId, eventId, callback) {
  if (!echo) connect();
  echo.private(channelId).listenForWhisper(eventId, event => {
    callback(event);
  });
}

function unsubscribe(channelId) {
  if (!echo) connect();
  echo.leave(channelId);
}

function presence(channelId) {
  if (!echo) connect();
  return echo.join(channelId);
}

export { publish, subscribe, unsubscribe, presence };
