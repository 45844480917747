import React, { Component, Fragment } from "react";
import "draft-js/dist/Draft.css";
import "./FindAndReplace.css";
import {
  Modal,
  Button,
  Tooltip,
  OverlayTrigger,
  FormGroup,
  Checkbox,
  ControlLabel,
  FormControl
} from "react-bootstrap";
import Draggable from "react-draggable";
import { FieldGroup } from "../../../utils/BootstrapUtils";
import { SelectionState, EditorState, Modifier } from "draft-js";
import { generateDecorator, findWithRegex } from "./Decorator/Decorator";

const LOCAL_STORAGE_KEY = "find_replace";

class FindAndReplace extends Component {
  constructor(props) {
    super(props);

    this.toggleModal = this._toggleModal.bind(this);
    this.handleFindChange = this._handleFindChange.bind(this);
    this.handleReplaceChange = this._handleReplaceChange.bind(this);
    this.handleOnReplace = this._handleOnReplace.bind(this);
    this.handleOnReplaceAll = this._handleOnReplaceAll.bind(this);
    this.handlePrevSelection = this._handlePrevSelection.bind(this);
    this.handleNextSelection = this._handleNextSelection.bind(this);

    this.handleMatchCase = this._handleMatchCase.bind(this);
    this.handleWholeword = this._handleWholeword.bind(this);

    this.state = {
      matchCase: true,
      wholeWord: true,
      find: "",
      replace: "",
      index: 0,
      selections: []
    };
  }

  removeStorage = () => {
    window.localStorage.removeItem(LOCAL_STORAGE_KEY);
  };

  setStorage = () => {
    window.localStorage.setItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify({
        find: this.state.find,
        replace: this.state.replace,
        matchCase: this.state.matchCase,
        wholeWord: this.state.wholeWord
      })
    );
  };

  getStorage = () => {
    const data = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY));
    if (data) {
      this.setState(
        {
          show: true,
          find: data.find,
          replace: data.replace,
          matchCase: data.matchCase,
          wholeWord: data.wholeWord
        },
        () => {
          this.updateFindAndReplaceStatus(this.props.editorState);
        }
      );
    }
  };

  updateFindAndReplaceStatus = editorState => {
    this.props.findReplaceStatus({
      find: this.state.find,
      matchCase: this.state.matchCase,
      wholeWord: this.state.wholeWord,
      isOpen: this.state.show,
      activePillarSelections: editorState
        ? this.setWordMatchCount(editorState)
        : []
    });
  };

  componentDidMount() {
    this.getStorage();
  }

  _toggleModal() {
    this.setState(
      {
        show: !this.state.show,
        index: 0,
        matchCase: true,
        wholeWord: true,
        find: "",
        replace: "",
        selections: []
      },
      () => {
        const editorState = EditorState.set(this.props.editorState, {
          decorator: generateDecorator("", {
            matchCase: this.state.matchCase,
            wholeWord: this.state.wholeWord
          })
        });
        this.props.updatedEditorState(editorState);
        this.updateFindAndReplaceStatus(editorState);

        if (this.state.show) {
          this.setStorage();
        } else {
          this.removeStorage();
        }
      }
    );
  }

  _handleFindChange(event) {
    let find = event.target.value;

    this.setState(
      {
        find
      },
      () => {
        const editorState = EditorState.set(this.props.editorState, {
          decorator: generateDecorator(this.state.find, {
            matchCase: this.state.matchCase,
            wholeWord: this.state.wholeWord
          })
        });
        this.props.updatedEditorState(editorState);
        this.updateFindAndReplaceStatus(editorState);
        this.setStorage();
      }
    );
  }

  _handleReplaceChange(event) {
    let replace = event.target.value;
    this.setState(
      {
        replace
      },
      () => {
        this.setStorage();
      }
    );
  }

  _handlePrevSelection() {
    const { index, selections, find } = this.state;
    const length = selections.length;
    const prevIndex = index > 0 ? (index - 1) % length : length - 1;

    if (!isNaN(prevIndex)) {
      const editorState = EditorState.set(this.props.editorState, {
        decorator: generateDecorator(
          find,
          {
            matchCase: this.state.matchCase,
            wholeWord: this.state.wholeWord
          },
          selections[prevIndex]
        )
      });
      this.props.updatedEditorState(editorState);
      this.setState({
        index: prevIndex
      });
    }
  }

  _handleNextSelection() {
    const { index, selections, find } = this.state;
    const nextIndex = (index + 1) % selections.length;
    if (!isNaN(nextIndex)) {
      const editorState = EditorState.set(this.props.editorState, {
        decorator: generateDecorator(
          find,
          {
            matchCase: this.state.matchCase,
            wholeWord: this.state.wholeWord
          },
          selections[nextIndex]
        )
      });
      this.props.updatedEditorState(editorState);
      this.setState({
        index: nextIndex
      });
    }
  }

  _handleMatchCase(event) {
    this.setState(
      {
        matchCase: !this.state.matchCase
      },
      () => {
        const editorState = EditorState.set(this.props.editorState, {
          decorator: generateDecorator(this.state.find, {
            matchCase: this.state.matchCase,
            wholeWord: this.state.wholeWord
          })
        });
        this.setStorage();
        this.props.updatedEditorState(editorState);
        this.updateFindAndReplaceStatus(editorState);
      }
    );
  }

  _handleWholeword(event) {
    this.setState(
      {
        wholeWord: !this.state.wholeWord
      },
      () => {
        const editorState = EditorState.set(this.props.editorState, {
          decorator: generateDecorator(this.state.find, {
            matchCase: this.state.matchCase,
            wholeWord: this.state.wholeWord
          })
        });

        this.setStorage();
        this.props.updatedEditorState(editorState);
        this.updateFindAndReplaceStatus(editorState);
      }
    );
  }

  getSelections(editorState) {
    const { wholeWord, find, matchCase } = this.state;
    const regex = new RegExp(
      wholeWord ? `\\b${find}\\b` : find,
      matchCase ? "g" : "ig"
    );
    const selections = [];
    if (find !== "") {
      const blockMap = editorState.getCurrentContent().getBlockMap();

      blockMap.forEach(contentBlock =>
        findWithRegex(regex, contentBlock, (start, end) => {
          const blockKey = contentBlock.getKey();
          const blockSelection = SelectionState.createEmpty(blockKey).merge({
            anchorOffset: start,
            focusOffset: end
          });

          selections.push(blockSelection);
        })
      );
    }

    return selections;
  }

  setWordMatchCount(editorState) {
    const { find } = this.state;
    const selections = this.getSelections(editorState);
    const updatedEditorState = EditorState.set(editorState, {
      decorator: generateDecorator(
        find,
        {
          matchCase: this.state.matchCase,
          wholeWord: this.state.wholeWord
        },
        selections[0]
      )
    });
    this.props.updatedEditorState(updatedEditorState);
    this.setState({
      selections: selections
    });

    return selections;
  }

  _handleOnReplace = () => {
    const { replace, index } = this.state;
    const { editorState } = this.props;
    const selections = this.getSelections(editorState);

    if (selections.length > 0) {
      let contentState = editorState.getCurrentContent();
      let selection = selections[index];

      const inlineStyle = EditorState.forceSelection(
        editorState,
        selection
      ).getCurrentInlineStyle();

      contentState = Modifier.replaceText(
        contentState,
        selection,
        replace,
        inlineStyle
      );

      const updatedEditorState = EditorState.push(editorState, contentState);

      this.props.updatedEditorState(updatedEditorState);
      this.setState(
        {
          selections: this.getSelections(updatedEditorState),
          index: index - 1
        },
        () => {
          this.handleNextSelection();
          if (this.state.selections.length <= 0) {
            const editorState = EditorState.set(this.props.editorState, {
              decorator: generateDecorator(
                this.state.find,
                this.state.wholeWord
              )
            });
            this.props.updatedEditorState(editorState);
          }

          this.updateFindAndReplaceStatus(updatedEditorState);
        }
      );
    }
  };

  _handleOnReplaceAll() {
    const { replace } = this.state;
    let editorState = this.props.editorState;
    let editorStateClone = this.props.editorState;
    let selections = this.getSelections(editorStateClone);
    let contentState = editorStateClone.getCurrentContent();

    selections.forEach((selection, index) => {
      if (selections.length > 0) {
        const inlineStyle = EditorState.forceSelection(
          editorStateClone,
          selections[0]
        ).getCurrentInlineStyle();

        contentState = Modifier.replaceText(
          contentState,
          selections[0],
          replace,
          inlineStyle
        );

        editorStateClone = EditorState.push(editorStateClone, contentState);
      }

      selections = this.getSelections(editorStateClone);
    });

    editorState = EditorState.push(editorState, contentState);

    this.props.updatedEditorState(editorState);
    this.updateFindAndReplaceStatus(editorState);
    setTimeout(() => {
      const editorState = EditorState.set(this.props.editorState, {
        decorator: generateDecorator("", {
          matchCase: this.state.matchCase,
          wholeWord: this.state.wholeWord
        })
      });
      this.props.updatedEditorState(editorState);
    }, 0);
  }

  renderFindReplace() {
    const { selections, index } = this.state;

    if (this.state.show)
      return (
        <div show="show" className="static-modal">
          <Draggable
            defaultPosition={{ x: window.innerWidth - (32 + 370), y: 50 }}
            cancel=".modal-body, .modal-footer"
          >
            <Modal.Dialog>
              <Modal.Header closeButton onHide={this.toggleModal}>
                <Modal.Title>Find and Replace</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormGroup controlId="formControlsFind">
                  <ControlLabel>
                    Find
                    <small className="text-muted match-count">
                      {selections.length > 0 ? index + 1 : 0} of{" "}
                      {selections.length} Matches
                    </small>
                  </ControlLabel>
                  <FormControl
                    type="text"
                    value={this.state.find}
                    onChange={this.handleFindChange}
                  />
                </FormGroup>
                <FieldGroup
                  id="formControlsReplace"
                  type="text"
                  label="Replace With"
                  value={this.state.replace}
                  onChange={this.handleReplaceChange}
                />
                <FormGroup>
                  <Checkbox
                    className="control-label"
                    checked={this.state.matchCase}
                    onChange={this.handleMatchCase}
                  >
                    Match case
                  </Checkbox>
                  <Checkbox
                    className="control-label"
                    checked={this.state.wholeWord}
                    onChange={this.handleWholeword}
                  >
                    Whole word only
                  </Checkbox>
                </FormGroup>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={this.handleOnReplace}>Replace</Button>
                <Button onClick={this.handleOnReplaceAll}>Replace All</Button>
                <Button bsStyle="primary" onClick={this.handlePrevSelection}>
                  Previous
                </Button>
                <Button bsStyle="primary" onClick={this.handleNextSelection}>
                  Next
                </Button>
              </Modal.Footer>
            </Modal.Dialog>
          </Draggable>
        </div>
      );
  }

  render() {
    return (
      <Fragment>
        <span className="RichEditor-styleButton" onClick={this.toggleModal}>
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="left-align-tooltip">Find and Replace</Tooltip>
            }
          >
            <i className="material-icons">search</i>
          </OverlayTrigger>
        </span>
        {this.renderFindReplace()}
      </Fragment>
    );
  }
}

export default FindAndReplace;
