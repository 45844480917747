import React, { Component } from "react";
import debounce from "lodash/debounce";
import { Modal, Button } from "react-bootstrap";

import { put, destroy } from "../../../utils/DeApi";
import "./Note.css";
import RichTextEditor from "../../RichTextEditor/RichTextEditor";

class Note extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
      body: this.props.note.body,
      focused: this.props.focused
    };

    this.noteContainer = React.createRef();

    this.handleOnFocus = this._handleOnFocus.bind(this);
    this.handleOnBlur = this._handleOnBlur.bind(this);
    this.handleConfirmDelete = this._handleConfirmDelete.bind(this);

    this.handleNoteUpdated = debounce((body, success, error) => {
      this.updateNote(body, success, error);
    }, 1000);

    this.handleNoteDeleted = this.deleteNote.bind(this);
  }

  _handleOnFocus() {
    this.setState(function(prevState, props) {
      let focus = prevState.focused ? false : prevState.focused;
      return {
        focused: !focus
      };
    });
  }

  _handleOnBlur() {
    this.setState({ focused: false });
  }

  _handleConfirmDelete() {
    this.setState({ confirmDelete: false });
  }

  deleteNote() {
    const { confirmDelete } = this.state;
    const { note } = this.props;
    if (!confirmDelete) {
      this.setState({ confirmDelete: true });
      return;
    }
    this.setState({ confirmDelete: false });
    let destroyPromise = destroy("/notes/" + note.id);
    destroyPromise.promise
      .then(response => {
        this.props.onNoteDeleted();
      })
      .catch(error => {});
    this.subscribedPromises.push(destroyPromise);
  }

  updateNote(body, successCallback, errorCallback) {
    const { note } = this.props;

    let updatePromise = put("/notes/" + note.id, {
      pillarType: note.pillarType,
      body: JSON.stringify(body)
    });

    updatePromise.promise
      .then(response => {
        this.props.onNoteUpdated(response.data);
        successCallback(response);
      })
      .catch(error => {
        !error.isCanceled && errorCallback(error);
      });
    this.subscribedPromises.push(updatePromise);
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
    this.handleNoteUpdated.cancel();
  }

  render() {
    const { focused, confirmDelete } = this.state;
    const { note } = this.props;
    return (
      <div className="Note">
        <div
          onClick={this.handleOnFocus}
          onFocus={this.handleOnFocus}
          onBlur={this.handleOnBlur}
        >
          <div className={focused ? "card NoteEdit" : "card NoteRead"}>
            <div ref={this.noteContainer}>
              {
                <Modal
                  show={!!confirmDelete}
                  onHide={this.handleConfirmDelete}
                  bsSize="small"
                  enforceFocus={false}
                  container={this.noteContainer.current}
                  backdrop="static"
                >
                  <div className="center-block text-center pt-xs">
                    <h4 className="mb-0">Delete this note?</h4>
                    <p>
                      <Button
                        bsSize="xs"
                        onClick={this.handleNoteDeleted}
                        className="mr-xs"
                      >
                        Delete
                      </Button>
                      <Button bsSize="xs" onClick={this.handleConfirmDelete}>
                        Cancel
                      </Button>
                    </p>
                  </div>
                </Modal>
              }
              {
                <div className="text-right">
                  <Button
                    className="close ml-xs"
                    onClick={this.handleNoteDeleted}
                  >
                    <span aria-hidden="true">&times;</span>
                  </Button>
                </div>
              }
              <RichTextEditor
                richText={note.body}
                placeholder="Add a note..."
                controls={false}
                onRichTextUpdated={this.handleNoteUpdated}
                focused={focused}
                readOnly={confirmDelete}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Note;
