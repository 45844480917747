import React, { Component } from "react";

import { get } from "../../../../utils/BeeApi";

import ErrorHandler from "../../../ErrorHandler/ErrorHandler";
import RewardSharer from "../RewardSharer/RewardSharer";

class RewardContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.subscribedPromises = [];

    this.handleShowReward = this._handleShowReward.bind(this);
    this.handleHideReward = this._handleHideReward.bind(this);
  }

  _handleShowReward() {
    this.setState({ showReward: true });
  }

  _handleHideReward() {
    this.setState({ showReward: false });
  }

  componentDidMount() {
    this.fetchRewardContent();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  fetchRewardContent() {
    this.setState({
      error: "",
      isLoading: true
    });

    const { contentId } = this.props;

    const fetchRewardContent = get(`/contents/${contentId}`);
    fetchRewardContent.promise
      .then(response => {
        this.setState(prevState => {
          return {
            rewardContent: response.data,
            isLoading: false,
            error: ""
          };
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            isLoading: false,
            error: error
          });
      });

    this.subscribedPromises.push(fetchRewardContent);
  }

  render() {
    const { error, rewardContent, showReward } = this.state;

    if (error) return <ErrorHandler error={error} />;
    if (!rewardContent) return <div />;

    return (
      <div className="RewardContent">
        {rewardContent.contentId === "5e986e587d1a6" ? (
          <i className="material-icons goal">stars</i>
        ) : (
          <i className="material-icons">stars</i>
        )}
        <p onClick={this.handleShowReward}>{rewardContent.title}</p>
        <RewardSharer
          rewardContent={rewardContent}
          show={showReward}
          onHide={this.handleHideReward}
        />
      </div>
    );
  }
}

export default RewardContent;
