import React, { Component, Fragment } from "react";
import { Button, Modal } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import "./ProjectCreate.css";

import { post } from "../../../utils/DeApi";
import { FieldGroup } from "../../../utils/BootstrapUtils";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import Loader from "../../Loader/Loader";

class ProjectCreate extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
      show: false,
      projectName: ""
    };

    this.handleShowModal = this._handleShowModal.bind(this);
    this.handleCloseModal = this._handleCloseModal.bind(this);
    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleInputChange = this._handleInputChange.bind(this);
  }

  _handleCloseModal() {
    this.setState({ show: false });
  }

  _handleShowModal() {
    this.setState({ show: true });
  }

  _handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  _handleSubmit(event) {
    event.preventDefault();
    const { projectName } = this.state;

    if (!projectName) {
      this.setState({ projectNameInvalid: true });
    }

    this.createProject();
  }

  createProject() {
    const { projectName } = this.state;

    this.setState({ isLoading: true, projectNameInvalid: false });
    const createPromise = post("/projects", {
      name: projectName
    });
    createPromise.promise
      .then(response => {
        this.setState({
          projectName: "",
          error: "",
          show: false,
          created: response.data,
          isLoading: false
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error,
            isLoading: false
          });
      });
    this.subscribedPromises.push(createPromise);
  }

  componentDidMount() {}

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  render() {
    const { created, isLoading, show } = this.state;

    let modalBody = <div />;
    if (isLoading) {
      modalBody = <Loader />;
    } else if (created) {
      modalBody = <Redirect to={"/projects/" + created.id} push />;
    } else {
      modalBody = this.renderCreateForm();
    }

    return (
      <Fragment>
        <Button bsStyle="link" onClick={this.handleShowModal}>
          Create Project
        </Button>
        <Modal
          show={show}
          onHide={this.handleCloseModal}
          className="ProjectCreateModal"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title className="mt-0">
              Give your new project a name
            </Modal.Title>
          </Modal.Header>
          {modalBody}
        </Modal>
      </Fragment>
    );
  }

  renderCreateForm() {
    const { projectName, projectNameInvalid, error } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <Modal.Body>
          <FieldGroup
            id="projectName"
            name="projectName"
            label="Project Name"
            type="text"
            onChange={this.handleInputChange}
            value={projectName}
            placeholder="Project"
            required
            maxLength={81}
            validationState={
              projectName.length > 80 || projectNameInvalid ? "error" : null
            }
            autoFocus
          />
          {error && <ErrorHandler error={error} />}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleCloseModal}>Cancel</Button>
          <Button type="submit" bsStyle="success">
            Create
          </Button>
        </Modal.Footer>
      </form>
    );
  }
}

export default ProjectCreate;
