import React, { Component } from "react";
import { Grid, Col, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./WhatIsNewDashboard.css";

import { get } from "../../../utils/BeeApi";
import { slugify } from "../../../utils/StringUtils";

import Header from "../../App/Header/Header";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import moment from "moment";

class WhatIsNewDashboard extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
      limit: 12,
      offset: 0,
      category: "60abb2fee9213"
      // rank: Moment().format("YYYY-MM-DD HH:mm:ss")
    };

    this.handleBookend = this._handleBookend.bind(this);
  }

  componentDidMount() {
    this.fetchWhatIsNew();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  fetchWhatIsNew() {
    this.setState({
      error: "",
      isLoading: true
    });

    const { offset, limit, category } = this.state;
    const options = {
      limit: limit,
      offset: offset,
      operand: "or",
      category: category
    };
    const createPromise = get("/contents", { params: options });
    createPromise.promise
      .then(response => {
        this.setState({
          whatIsNew: response.data,
          error: "",
          isLoading: false
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            isLoading: false,
            error: error
          });
      });

    this.subscribedPromises.push(createPromise);
  }

  expandWhatIsNew() {
    this.setState({
      expandingError: "",
      isExpanding: true
    });

    const { offset, limit, category } = this.state;
    const options = {
      limit: limit,
      offset: offset,
      operand: "or",
      category: category
    };
    const whatIsNewPromise = get("/contents", { params: options });
    whatIsNewPromise.promise
      .then(response => {
        this.setState(prevState => {
          return {
            whatIsNew: prevState.whatIsNew
              ? prevState.whatIsNew.concat(...response.data)
              : response.data,
            expandingError: "",
            isExpanding: false
          };
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            isExpanding: false,
            expandingError: error
          });
      });

    this.subscribedPromises.push(whatIsNewPromise);
  }

  _handleBookend() {
    const { whatIsNew } = this.state;
    if (!whatIsNew || !whatIsNew.length) return;
    this.setState(
      prevState => {
        return { offset: prevState.offset + whatIsNew.length };
      },
      () => this.expandWhatIsNew()
    );
  }

  render() {
    const { user } = this.props;

    return (
      <div className="Main WhatIsNewDashboard">
        <Header user={user} title={"Dashboard"} />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Bookflow - WhatIsNew</title>
        </Helmet>
        <Grid className="MainContent">
          <Row className="mt-md mb-md">
            <Col xs={12}>{this.renderWhatIsNew()}</Col>
          </Row>
        </Grid>
      </div>
    );
  }

  renderWhatIsNew() {
    const { isLoading, error, whatIsNew } = this.state;

    if (error) return <ErrorHandler error={error} />;
    if (isLoading) return <Loader />;
    if (!whatIsNew || !whatIsNew.length) return <div />;

    return (
      <div className="card card-info mt-sm mb-sm">
        <h3 className="text-uppercase">WHAT'S NEW</h3>
        <hr />
        <Row>
          {whatIsNew &&
            whatIsNew.map((item, index) => {
              return (
                <Col xs={12} key={item.contentId}>
                  <div className="">
                    <Link
                      className=""
                      to={
                        "/what-is-new/" +
                        item.contentId +
                        "/" +
                        slugify(item.title)
                      }
                    >
                      <h4>
                        {item.title}
                        <small className="pull-right">
                          {moment(item.rankDate).format("ll")}
                        </small>
                      </h4>
                    </Link>
                    <p>{item.description}</p>
                    <hr />
                  </div>
                </Col>
              );
            })}
        </Row>
        {this.renderBookend()}
      </div>
    );
  }

  renderBookend() {
    const { isExpanding, expandingError, whatIsNew, limit } = this.state;

    if (expandingError) return <ErrorHandler error={expandingError} />;
    if (isExpanding) return <Loader />;
    if (whatIsNew.length < 0) return <div />;
    return (
      whatIsNew.length % limit === 0 && (
        <div className="text-center mt-lg mb-lg">
          <Button onClick={this.handleBookend}>Show More Articles</Button>
        </div>
      )
    );
  }
}

export default WhatIsNewDashboard;
