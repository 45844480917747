import React, { Component, Fragment } from "react";
import { Button, Modal, FormGroup, FormControl, Radio } from "react-bootstrap";
import { Redirect } from "react-router-dom";

import { post, get, put } from "../../../utils/DeApi";
import { FieldGroup } from "../../../utils/BootstrapUtils";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import isEqual from "lodash/isEqual";

/**
 * Handles creation of a scene using a modal.
 */
class SceneCreate extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
      show: false,
      title: "",
      scenes: this.props.scenes || [],
      description: "",
      insertAfter: true,
      insertIndex: 1,
      created: false
    };

    this.handleShowModal = this._handleShowModal.bind(this);
    this.handleCloseModal = this._handleCloseModal.bind(this);
    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleSubmitAndNav = this._handleSubmitAndNav.bind(this);
    this.handleInputChange = this._handleInputChange.bind(this);
    this.handleInsertAfterChange = this._handleInsertAfterChange.bind(this);
  }

  _handleCloseModal() {
    this.setState({ show: false });
  }

  _handleShowModal() {
    this.setState({ show: true });
  }

  _handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  _handleInsertAfterChange(event) {
    this.setState({
      insertAfter: !this.state.insertAfter
    });
  }

  getInsertIndex() {
    const { insertAfter, insertIndex, scenes } = this.state;

    if (scenes.length > 0) {
      /** insert before selected scene */
      if (!insertAfter) return parseInt(insertIndex);

      /** insert After selected scene */
      if (insertAfter) return parseInt(insertIndex) + 1;
    }

    return 1;
  }

  _handleSubmit(event, callback) {
    event.preventDefault();
    this.setState({ validate: true });

    const { title, description, sceneTypes } = this.state;
    const project = this.props.project.id;
    const sceneType = sceneTypes[0].id;

    if (title && project) {
      this.setState({ isLoading: true, orderId: "" });
      const createPromise = post("/scenes", {
        project: project,
        title: title,
        description: description,
        sceneType: sceneType
      });
      createPromise.promise
        .then(response => {
          const scene = response.data;
          const insertIndex = this.getInsertIndex();

          this.reOrderList(scene, insertIndex);

          if (callback) {
            callback(scene);
          } else {
            this.props.onSceneCreated({
              ...scene,
              insertIndex: insertIndex > 0 ? insertIndex - 1 : 0
            });

            this.setState({
              error: "",
              title: "",
              description: "",
              insertAfter: true,
              validate: false
            });
          }
        })
        .catch(error => {
          !error.isCanceled &&
            this.setState({
              error: error,
              isLoading: false
            });
        });
      this.subscribedPromises.push(createPromise);
    }
  }

  _handleSubmitAndNav(event) {
    let redirectToPillar = function(pillar) {
      this.setState({
        error: "",
        title: "",
        description: "",
        created: pillar
      });
    };
    this.handleSubmit(event, redirectToPillar.bind(this));
  }

  reOrderList(item, newIndex) {
    let reOrderPromise = put("/scenes/" + item.id + "/order?curr=" + newIndex);

    reOrderPromise.promise
      .then(response => {
        const scene = response.data;
        this.setState({
          error: "",
          show: false,
          isLoading: false,
          orderId: parseInt(scene.orderId)
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error,
            isLoading: false
          });
      });
    this.subscribedPromises.push(reOrderPromise);
  }

  fetchSceneTypes() {
    let sceneTypesPromise = get("/scene-types");
    sceneTypesPromise.promise
      .then(response => {
        this.setState({
          sceneTypes: response.data
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error
          });
      });
    this.subscribedPromises.push(sceneTypesPromise);
  }

  componentDidMount() {
    this.fetchSceneTypes();
  }

  componentDidUpdate(prevProps) {
    const { scenes } = this.props;
    if (!isEqual(scenes, prevProps.scenes)) {
      this.setState({ scenes: scenes, insertIndex: scenes.length });
    }
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  render() {
    const {
      created,
      isLoading,
      sceneTypes,
      title,
      description,
      error,
      show,
      validate,
      insertAfter,
      orderId,
      scenes
    } = this.state;
    const { project } = this.props;

    if (created && orderId) {
      return (
        <Redirect
          to={"/projects/" + project.id + "/scenes/" + created.id}
          push
        />
      );
    }
    const form =
      sceneTypes && !isLoading ? (
        <form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className="mt-0">
              Start with a title and summary
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FieldGroup
              id="sceneTitle"
              name="title"
              label="Scene Title"
              type="text"
              onChange={this.handleInputChange}
              value={title}
              placeholder="Enter scene title"
              autoComplete="off"
              autoFocus
              required
              maxLength={81}
              validationState={
                title.length > 80 || (validate && !title) ? "error" : null
              }
            />
            <FieldGroup
              id="sceneDescription"
              name="description"
              label="Scene Summary"
              componentClass="textarea"
              rows="8"
              onChange={this.handleInputChange}
              value={description}
              placeholder="Enter scene summary (optional)"
              maxLength={5100}
              validationState={description.length > 5000 ? "error" : null}
            />
            {scenes && scenes.length > 0 && (
              <Fragment>
                <FormGroup>
                  <Radio
                    onChange={this.handleInsertAfterChange}
                    name="insertAfter"
                    value={false}
                    checked={!insertAfter}
                    inline
                  >
                    Insert Before
                  </Radio>{" "}
                  <Radio
                    onChange={this.handleInsertAfterChange}
                    name="insertAfter"
                    value={true}
                    checked={insertAfter}
                    inline
                  >
                    Insert After
                  </Radio>{" "}
                </FormGroup>
                <FormGroup controlId="formControlsSelect">
                  <FormControl
                    name="insertIndex"
                    componentClass="select"
                    placeholder="Index"
                    defaultValue={scenes.length}
                    onChange={this.handleInputChange}
                  >
                    {scenes.map((scene, index) => (
                      <option key={scene.id} value={index + 1}>
                        {index + 1}. {scene.title}
                      </option>
                    ))}
                  </FormControl>
                </FormGroup>
              </Fragment>
            )}
            {error && <ErrorHandler error={error} />}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleCloseModal} bsStyle="link">
              Cancel
            </Button>
            <Button type="submit" bsStyle="default">
              Save
            </Button>
            <Button onClick={this.handleSubmitAndNav} bsStyle="primary">
              Save and Write
            </Button>
          </Modal.Footer>
        </form>
      ) : (
        <Loader />
      );

    return (
      <Fragment>
        <Button
          onClick={this.handleShowModal}
          className={this.props.className}
          bsStyle={"success"}
          block
        >
          {this.props.label || "Create Scene"}
        </Button>
        <Modal show={show} onHide={this.handleCloseModal} backdrop="static">
          {form}
        </Modal>
      </Fragment>
    );
  }
}

export default SceneCreate;
