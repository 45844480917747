import React, { Component, Fragment } from "react";
import Moment from "moment";

import { get as getBEE, put, get } from "../../../utils/BeeApi";
import ContentCrafts from "../../Content/ContentCrafts/ContentCrafts";
import ContentFeatured from "../../Content/ContentFeatured/ContentFeatured";
import { Link } from "react-router-dom";
import Loader from "../../Loader/Loader";

const CATEGORIES = ["60d243e9a7ac9", "60d32be9ced36", "60d32c21cd6c8"];
const defaultDate = "2021-7-1";

class OnboardingContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.subscribedPromises = [];
  }

  componentDidMount() {
    this.fetchSubscriber();
    this.fetchCategoriesByGroup();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  fetchCategoriesByGroup() {
    let categoriesPromise = getBEE("/categories", {
      params: {
        group: "5f84ad6d46900"
      }
    });

    categoriesPromise.promise
      .then(response => {
        this.setState({
          categories: response.data,
          error: ""
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error
          });
      });

    this.subscribedPromises.push(categoriesPromise);
  }

  fetchSubscriber() {
    let subscriberPromise = get("/user/anon");
    subscriberPromise.promise
      .then(response => {
        const subscriber = response.data;
        this.setState(
          {
            subscriber: subscriber,
            isNewSubscriber: Moment(
              subscriber.createdAt,
              "YYYY-MM-DD HH:mm:ss"
            ).isAfter(Moment(defaultDate, "YYYY-MM-DD")),
            error: ""
          },
          () => {
            const { user } = this.props;
            console.log(
              "isNew",
              this.state.isNewSubscriber,
              "categoryArray",
              Array.isArray(user?.categories)
            );
            if (Array.isArray(user?.categories) && this.state.isNewSubscriber) {
              const categories = CATEGORIES.filter(item => {
                return user.categories.includes(item);
              });

              /** Default subscriber contegories unrelated to content */
              // const onboardingCategories = user.categories.filter(item => {
              //   return !CATEGORIES.includes(item);
              // });

              switch (categories.length) {
                case 0:
                  this.setState({ session: 1 }, () => {
                    console.log("session", this.state.session);
                  });
                  this.updateSubscriberCategories([
                    ...user?.categories,
                    CATEGORIES[0]
                  ]);
                  break;
                case 1:
                  this.setState({ session: 2 }, () => {
                    console.log("session", this.state.session);
                  });
                  this.updateSubscriberCategories([
                    ...user?.categories,
                    CATEGORIES[1]
                  ]);
                  break;
                case 2:
                  this.setState({ session: 3 }, () => {
                    console.log("session", this.state.session);
                  });
                  this.updateSubscriberCategories([
                    ...user?.categories,
                    CATEGORIES[2]
                  ]);
                  break;
                default:
                  /**
                   * @todo remove function after testing, used to reset profile
                   * back to default.
                   */
                  // this.updateSubscriberCategories(onboardingCategories);
                  break;
              }
            }
          }
        );
      })
      .catch(error => {
        if (!error.isCanceled) {
          this.setState({
            error: error
          });
        }
      });
    this.subscribedPromises.push(subscriberPromise);
  }

  updateSubscriberCategories(categories = []) {
    const { user } = this.props;

    const updatePromise = put(`/user/${user.sub}/categories`, {
      categories: categories
    });
    this.setState({ isUpdating: true, error: null });

    updatePromise.promise
      .then(response => {
        this.setState({
          error: null,
          isUpdating: false
        });
      })
      .catch(error => {
        if (!error.isCanceled) {
          this.setState({ error: error, isUpdating: false });
        }
      });
    this.subscribedPromises.push(updatePromise);
  }

  render() {
    const { categories, session, subscriber, isNewSubscriber } = this.state;

    return (
      <Fragment>
        <div className="card card-info mt-sm mb-sm">
          <h3 className="text-uppercase">STAY INSPIRED</h3>
          {this.renderSubscriberLoader()}
          {subscriber && (
            <Fragment>
              {session && isNewSubscriber ? (
                <Fragment>
                  <p className="hidden">
                    session: {session} , Category {CATEGORIES[session - 1]}
                  </p>
                  <ContentFeatured
                    category={["60d4849131345", CATEGORIES[session - 1]]}
                    operand="and"
                    limit={1}
                    URLPrefix="inspiration"
                  />
                </Fragment>
              ) : (
                <ContentFeatured
                  category={"5ba5d8327f832"}
                  rank={Moment().format("YYYY-MM-DD HH:mm:ss")}
                  limit={1}
                  URLPrefix="inspiration"
                />
              )}
            </Fragment>
          )}
          <Link className="hyperlink-1" to={"/inspiration"}>
            More Articles <i className="material-icons md-18">arrow_forward</i>
          </Link>
        </div>
        <div className="card card-info mt-sm mb-sm">
          <h3 className="text-uppercase">MASTER THE CRAFT</h3>
          {this.renderSubscriberLoader()}
          {subscriber && (
            <Fragment>
              {session && isNewSubscriber ? (
                <Fragment>
                  <p className="hidden">
                    session: {session} , Category {CATEGORIES[session - 1]}
                  </p>
                  <ContentCrafts
                    category={["60d484ce4b0cd", CATEGORIES[session - 1]]}
                    limit={3}
                    operand="and"
                  />
                </Fragment>
              ) : (
                <Fragment>
                  {categories && categories.length && (
                    <ContentCrafts
                      rank={Moment().format("YYYY-MM-DD HH:mm:ss")}
                      category={categories.map(category => category.categoryId)}
                      limit={3}
                    />
                  )}
                </Fragment>
              )}
            </Fragment>
          )}
          <Link className="hyperlink-1" to={`/writing-craft`}>
            More Articles <i className="material-icons md-18">arrow_forward</i>
          </Link>
        </div>
      </Fragment>
    );
  }

  renderSubscriberLoader() {
    const { subscriber } = this.state;
    return !subscriber ? <Loader /> : <div />;
  }
}

export default OnboardingContent;
