import React, { Component } from "react";
import { Button, HelpBlock } from "react-bootstrap";

import { get } from "../../../utils/BeeApi";
import { FieldGroup } from "../../../utils/BootstrapUtils";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class Coupon extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = { couponCode: "" };

    this.handleCouponChange = this._handleCouponChange.bind(this);
    this.handleCouponSubmit = this._handleCouponSubmit.bind(this);
  }

  _handleCouponChange(event) {
    let couponCode = event.target.value;
    this.setState({
      couponCode: couponCode
    });
  }

  _handleCouponSubmit() {
    this.fetchCoupon();
  }

  fetchCoupon() {
    const { couponCode } = this.state;
    const { onCouponSubmitted } = this.props;

    this.setState({ isLoading: true, error: false, couponError: false });
    const couponsPromise = get(`/coupons/${couponCode}`);
    couponsPromise.promise
      .then(response => {
        let coupon = response.data;
        this.setState(
          {
            coupon: coupon,
            couponError: false,
            error: false,
            isLoading: false
          },
          () => onCouponSubmitted(coupon)
        );
      })
      .catch(error => {
        if (error.status === 404) {
          this.setState({ couponError: true, error: false, isLoading: false });
        } else
          !error.isCanceled &&
            this.setState({
              couponError: false,
              error: error,
              isLoading: false
            });
      });
    this.subscribedPromises.push(couponsPromise);
  }

  componentDidMount() {}

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  render() {
    const { plan } = this.props;
    const { coupon, couponCode, couponError, isLoading, error } = this.state;

    const planMonthly =
      plan.interval === "year" ? plan.amount / 12 : plan.amount;

    const planSavings = coupon
      ? coupon.amountOff
        ? coupon.amountOff
        : (coupon.percentOff * plan.amount) / 100
      : 0;
    const planTotal = planSavings ? plan.amount - planSavings : plan.amount;

    if (isLoading) return <Loader />;
    if (error) return <ErrorHandler error={error} />;

    return (
      <div>
        <h4 className="mb-xs">Your Plan</h4>
        <div className="card">
          <h5 className="mb-0">{`${plan.nickname} Plan`}</h5>
          <h5 className="text-muted">{`$${planMonthly / 100}/mo billed once a ${
            plan.interval
          }`}</h5>

          <FieldGroup
            id="coupon"
            label="Coupon Code"
            type="text"
            value={couponCode}
            onChange={this.handleCouponChange}
            validationState={error ? "error" : null}
            placeholder="Coupon Code"
            className="text-success"
            autoFocus
          />
          {couponError && (
            <HelpBlock>Enter a valid coupon or discount code</HelpBlock>
          )}

          <Button
            className="btn-secondary"
            bsSize="sm"
            onClick={this.handleCouponSubmit}
            block
          >
            Apply
          </Button>
          <hr />
          {!!coupon && (
            <h5 className="text-success">
              <span className="pull-right ">
                -${(planSavings / 100).toFixed(2)}
              </span>
              {coupon.couponId} Savings{" "}
              {coupon.amountOff
                ? `$${coupon.amountOff}`
                : `${coupon.percentOff}%`}
            </h5>
          )}
          <h5>
            <span className="pull-right">${(planTotal / 100).toFixed(2)}</span>
            Total
          </h5>
        </div>
      </div>
    );
  }
}

export default Coupon;
