import React, { Component } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { FieldGroup } from "../../utils/BootstrapUtils";
import moment from "moment";

const LOCALSTORE_KEY = "timerCountDownTime";
const PAUSED_LOCALSTORE_KEY = "timerPausedTime";
const DURATION_LOCALSTORE_KEY = "timerDuration";

class Timer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSet: false,
      counter: null,
      minutes: 0,
      hours: 0,
      countDownTime: moment(),
      showModal: false
    };

    this.handleModalHide = this._handleModalHide.bind(this);
    this.handleModalShow = this._handleModalShow.bind(this);

    this.handleHoursChange = this._handleHoursChange.bind(this);
    this.handleMinutesChange = this._handleMinutesChange.bind(this);
    this.handleStartTimer = this._handleStartTimer.bind(this);
  }

  _handleModalHide() {
    this.setState({ showModal: false });
  }

  _handleModalShow(event) {
    let prevDuration = localStorage.getItem(DURATION_LOCALSTORE_KEY);
    let duration = moment.duration({ minutes: Number(prevDuration) });

    this.setState({
      showModal: true,
      minutes: duration ? duration.minutes() : 0,
      hours: duration ? duration.hours() : 0
    });
  }

  _handleMinutesChange(event) {
    event.stopPropagation();
    let minutes = event.target.value;
    this.setState({
      minutes: minutes
    });
  }

  _handleHoursChange(event) {
    event.stopPropagation();
    let hours = event.target.value;
    this.setState({
      hours: hours
    });
  }

  _handleStartTimer(event) {
    event.preventDefault();

    const { hours, minutes } = this.state;
    if (hours > 0 || minutes > 0) {
      let countDownTime = moment()
        .add(hours || 0, "hours")
        .add(minutes || 0, "minutes");

      let duration = moment.duration({
        hours: hours || 0,
        minutes: minutes || 0
      });

      this.setState({
        isSet: true,
        countDownTime: countDownTime,
        showModal: false
      });
      this.setCountDownTime(countDownTime.format("YYYY-MM-DD HH:mm:ss"));
      this.setCountDownPaused("");
      this.setDuration(duration.asMinutes());
      if (this.props.onTimerChange) this.props.onTimerChange(countDownTime);
    }
  }

  getCountDownTime() {
    let countDown = moment(
      localStorage.getItem(LOCALSTORE_KEY),
      "YYYY-MM-DD HH:mm:ss"
    );
    if (countDown.isValid()) {
      this.setState({ countDownTime: countDown, isSet: true });
    } else {
      this.setState({ isSet: false });
    }
  }

  setCountDownTime(countDownTime) {
    countDownTime = countDownTime || "";
    localStorage.setItem(LOCALSTORE_KEY, countDownTime);
  }

  setCountDownPaused(countDownPaused) {
    countDownPaused = countDownPaused || "";
    localStorage.setItem(PAUSED_LOCALSTORE_KEY, countDownPaused);
  }

  setDuration(durationAsMinutes) {
    durationAsMinutes = durationAsMinutes || "";
    localStorage.setItem(DURATION_LOCALSTORE_KEY, durationAsMinutes);
  }

  componentDidMount() {
    this.getCountDownTime();
  }

  componentWillUnmount() {}

  componentDidUpdate(prevProps) {
    if (this.props.timerChange !== prevProps.timerChange) {
      this.getCountDownTime();
    }
  }

  render() {
    const { showModal, minutes, hours } = this.state;
    const { label } = this.props;

    return (
      <div>
        <div onClick={this.handleModalShow} className="timeClass">
          {label ? label : "Timer"}
        </div>
        <Modal
          bsSize="small"
          show={showModal}
          onHide={this.handleModalHide}
          backdrop={"static"}
          dialogClassName="custom-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Timer</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row>
              <Col xs={6}>
                <FieldGroup
                  id="formControlsTimer"
                  type="number"
                  placeholder="hours"
                  min="0"
                  label="Hours"
                  value={hours}
                  onChange={this.handleHoursChange}
                  onKeyDown={event => {
                    event.stopPropagation();
                  }}
                />
              </Col>
              <Col xs={6}>
                <FieldGroup
                  id="formControlsTimer"
                  type="number"
                  min="0"
                  label="Minutes"
                  placeholder="minutes"
                  value={minutes}
                  onChange={this.handleMinutesChange}
                  onKeyDown={event => {
                    event.stopPropagation();
                  }}
                />
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Button onClick={this.handleModalHide}>Close</Button>
            <Button bsStyle="success" onClick={this.handleStartTimer}>
              Start
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default Timer;
