import React, { Component } from "react";
import snakeCase from "lodash/snakeCase";

import { download } from "../../../utils/BeeApi";

/**
 * Handles manuscript view.
 * Writer sees list of scenes body back to back.
 */
class Reward extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  downloadThumbnail() {
    const { content } = this.props;
    let downloadPromise = download(
      "/contents/" + content.contentId + "/thumbnail"
    );

    this.setState({ isLoading: true, error: false });

    const fileName = `Reward-${snakeCase(content.title)}.png`;

    downloadPromise.promise
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute("download", fileName);
        document.body.appendChild(link);

        link.click();
        this.setState({
          downloading: false,
          error: false
        });
      })
      .catch(error => {
        this.setState({
          error: error,
          isLoading: false
        });
      });
    this.subscribedPromises.push(downloadPromise);
  }

  render() {
    return (
      <li onClick={() => this.downloadThumbnail()}>
        <span className="icon-download">
          <i className="material-icons">get_app</i>
        </span>
      </li>
    );
  }
}

export default Reward;
