import React, { useRef, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { put } from "../../../../utils/DeApi";

function NaNoWriMoLink({
  projectId,
  projectName,
  nanoWrimoId,
  bookflowNaNoWrimoId,
  onProjectUpdated
}) {
  const subscribedPromises = useRef([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function handleLinking() {
    setError(null);
    setIsLoading(true);
    const updatePromise = put(`/projects/${projectId}`, {
      name: projectName,
      nanoWrimoId: nanoWrimoId //!bookflowNaNoWrimoId ? nanoWrimoId : ""
    });
    updatePromise.promise
      .then(response => onProjectUpdated(response.data))
      .catch(error => !error.isCanceled && setError(error))
      .finally(() => setIsLoading(false));
    subscribedPromises.current.push(updatePromise);
  }
  if (error)
    return (
      <Alert bsStyle="warning" className="mt-md mb-md">
        <h4>{error?.statusText}</h4>
        <p>You already have a project linked to this event</p>
      </Alert>
    );

  return (
    <Button
      disabled={isLoading}
      className={bookflowNaNoWrimoId ? "btn-secondary" : "btn-primary"}
      block
      onClick={handleLinking}
    >
      {isLoading
        ? "...linking"
        : bookflowNaNoWrimoId
        ? "Sync Word Count"
        : "Link project with NaNoWriMo"}
    </Button>
  );
}

export default NaNoWriMoLink;
