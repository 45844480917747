import React, { Component } from "react";
import { NavDropdown, MenuItem } from "react-bootstrap";
import moment from "moment";
import "./MonitorTimer.css";
import Timer from "../Timer";

const LOCALSTORE_KEY = "timerCountDownTime";
const PAUSED_LOCALSTORE_KEY = "timerPausedTime";

class MonitorTimer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countDownTime: moment(),
      countDownPaused: "",
      duration: ""
    };

    this.handleCancel = this._handleCancel.bind(this);
    this.handlePause = this._handlePause.bind(this);
    this.handleStart = this._handleStart.bind(this);
    this.handleExpired = this._handleExpired.bind(this);
    this.tick = this._tick.bind(this);
  }

  getCountDownTime() {
    let countDownTime = moment(
      localStorage.getItem(LOCALSTORE_KEY),
      "YYYY-MM-DD HH:mm:ss"
    );
    let countDownPaused = moment(
      localStorage.getItem(PAUSED_LOCALSTORE_KEY),
      "YYYY-MM-DD HH:mm:ss"
    );

    let duration = countDownPaused.isValid()
      ? moment.duration(countDownTime.diff(countDownPaused))
      : moment.duration(countDownTime.diff(moment()));

    this.setState(
      {
        isSet: countDownTime.isValid(),
        countDownTime: countDownTime.isValid() && countDownTime,
        countDownPaused: countDownPaused.isValid() && countDownPaused,
        duration: duration,
        timerState: countDownPaused.isValid() ? "paused" : "running"
      },
      () => {
        if (!countDownPaused.isValid() && countDownTime.isValid()) {
          this.tick();
        }
      }
    );
  }

  setCountDownTime(countDownTime) {
    countDownTime = countDownTime || "";
    localStorage.setItem(LOCALSTORE_KEY, countDownTime);
  }

  setCountDownPaused(countDownPaused) {
    countDownPaused = countDownPaused || "";
    localStorage.setItem(PAUSED_LOCALSTORE_KEY, countDownPaused);
  }

  componentDidMount() {
    this.getCountDownTime();
  }

  componentDidUpdate(prevProps) {
    if (this.props.timerChange !== prevProps.timerChange) {
      this.getCountDownTime();
    }
  }

  componentWillUnmount() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
  }

  _tick() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
    this.timerInterval = setInterval(() => {
      this.setState(
        {
          duration: moment.duration(this.state.countDownTime.diff(moment()))
        },
        () => {
          if (this.state.duration.asMilliseconds() <= 0) {
            this.handleExpired();
          }
        }
      );
    }, 1000);
  }

  _handleExpired() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }

    this.setState({ countDownTime: "", timerState: "expired", duration: "" });
  }

  _handleCancel() {
    this.setCountDownTime("");
    this.setCountDownPaused("");

    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }

    this.setState({
      countDownTime: "",
      countDownPaused: "",
      isSet: false,
      timerState: "cancelled",
      duration: ""
    });
    if (this.props.onTimerChange) this.props.onTimerChange(false);
  }

  _handlePause() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
    const pausedTime = moment();
    this.setState({
      countDownPaused: pausedTime,
      timerState: "paused"
    });
    this.setCountDownPaused(pausedTime.format("YYYY-MM-DD HH:mm:ss"));
  }

  _handleStart() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
    const { countDownPaused, timerState } = this.state;

    if (timerState === "paused") {
      const duration = moment.duration(
        this.state.countDownTime.diff(countDownPaused)
      );
      const newCountDownTime = moment().add(duration);

      this.setState(
        {
          timerState: "running",
          countDownTime: newCountDownTime,
          countDownPaused: "",
          duration: duration
        },
        () => {
          this.tick();
        }
      );
      this.setCountDownPaused("");
      this.setCountDownTime(newCountDownTime.format("YYYY-MM-DD HH:mm:ss"));
    }
  }

  render() {
    const { isSet, duration, timerState } = this.state;
    const { timerChange, onTimerChange } = this.props;

    if (!isSet) return <li />;

    const navLabel =
      timerState === "expired" ? (
        <span className="text-danger">0h 0m 0s</span>
      ) : (
        <i className="material-icons mt-0 mb-0">timer</i>
      );

    const timerClass = timerState === "expired" ? "text-danger" : "";

    return (
      <NavDropdown
        title={navLabel}
        id="timer-monitor-dropdown"
        className={`MonitorTimer ${timerClass}`}
        noCaret
      >
        <MenuItem header disabled>
          <div className={`timer mt-md mb-md ${timerClass}`}>
            {duration
              ? `${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`
              : "0h 0m 0s"}

            <ul className="list-inline text-center mt-sm mb-sm">
              <li>
                <Timer
                  onTimerChange={onTimerChange}
                  timerChange={timerChange}
                  label={<i className="material-icons md-24">replay</i>}
                />
              </li>
              {timerState === "running" && (
                <li>
                  <i
                    className="material-icons md-28"
                    onClick={this.handlePause}
                  >
                    pause
                  </i>
                </li>
              )}
              {timerState === "paused" && (
                <li>
                  <i
                    className="material-icons md-28"
                    onClick={this.handleStart}
                  >
                    play_arrow
                  </i>
                </li>
              )}
              <li>
                <i className="material-icons md-28" onClick={this.handleCancel}>
                  close
                </i>
              </li>
            </ul>
          </div>
        </MenuItem>
      </NavDropdown>
    );
  }
}

export default MonitorTimer;
