import React, { Component } from "react";
import { Grid, Col, Row, DropdownButton } from "react-bootstrap";
import { Link } from "react-router-dom";
import Moment from "moment";

import "./Dashboard.css";
import Header from "../App/Header/Header";
import Footer from "../App/Footer/Footer";
import Loader from "../Loader/Loader";
import ErrorHandler from "../ErrorHandler/ErrorHandler";
import ProjectCreate from "../Project/ProjectCreate/ProjectCreate";
import ProjectImport from "../Project/ProjectImport/ProjectImport";
import ProjectSummary from "../Project/ProjectSummary/ProjectSummary";
import DailyPrompt from "../Prompt/DailyPrompt/DailyPrompt";
import Onboarding from "../Account/Onboarding/Onboarding";

import { get } from "../../utils/DeApi";
import Tracker from "../CommitToWriting/Tracker/Tracker";
import AcquisitionSource from "../Account/AcquisitionSource/AcquisitionSource";
import OnboardingContent from "../Account/OnboardingContent/OnboardingContent";

/**
 * Renders the main dashboard for a writer.
 */

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};
  }

  componentDidMount() {
    this.fetchProjects();
    setTimeout(() => {
      this.setState({ showOnboarding: !this.props.user?.categories.length });
    }, 300);
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  componentDidUpdate(prevProps) {}

  fetchProjects() {
    let projectsPromise = get("/projects", {
      params: {
        limit: 10
      }
    });

    projectsPromise.promise
      .then(response => {
        this.setState({
          projects: response.data,
          projectsCount: response.total,
          error: ""
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error
          });
      });

    this.subscribedPromises.push(projectsPromise);
  }

  render() {
    const { error, projects, projectsCount, showOnboarding } = this.state;
    const { user } = this.props;

    if (error) return <ErrorHandler error={error} />;
    if (!projects) return <Loader />;

    return (
      <div className="Main">
        <Header user={user} title={"Dashboard"} />
        <Grid className="Dashboard MainContent">
          {showOnboarding && this.renderOnboarding()}
          <Row className="mt-sm mb-sm">
            <Col xs={12} md={9}>
              <Row>
                <Col xs={12} sm={5} md={5} className="mt-sm mb-sm">
                  <div className="card card-neutral">
                    <h3 className="text-uppercase">SPARK NEW IDEAS</h3>
                    <hr />
                    {this.renderGreeting()}
                    <p>Warm up with today's writing prompt.</p>
                    <div className="pt-md pb-md">
                      <DailyPrompt />
                    </div>
                    <Link to={"/prompts"} className="hyperlink-1">
                      More Prompts{" "}
                      <i className="material-icons md-18">arrow_forward</i>
                    </Link>
                  </div>
                </Col>
                <Col xs={12} sm={7} md={7} className="mt-sm mb-sm">
                  {this.renderContributions()}
                </Col>
                <Col xs={12} md={12} className="mt-sm mb-sm">
                  <div className="card card-emphasis">
                    <div className="pull-right">
                      <DropdownButton
                        bsStyle="success"
                        bsSize="sm"
                        title={"New Project"}
                        id={"new-project"}
                        noCaret
                        pullRight={true}
                      >
                        <li className="text-center">
                          <ProjectCreate />
                        </li>
                        <li className="text-center">
                          <ProjectImport />
                        </li>
                      </DropdownButton>
                    </div>
                    <h3 className="text-uppercase">
                      MY PROJECTS | {projects.length} of {projectsCount}
                    </h3>
                    <hr className="mb-0" />
                    {this.renderProjectList()}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={3}>
              <OnboardingContent user={user} />
            </Col>
            <AcquisitionSource user={user} />
          </Row>
        </Grid>
        <Footer />
      </div>
    );
  }

  renderContributions() {
    return (
      <div className="card card-warning">
        <h3 className="text-uppercase">Commit to Writing</h3>
        <hr />
        <Tracker />
      </div>
    );
  }

  renderProjectList() {
    const { projects } = this.state;
    return projects && projects.length ? (
      <div>
        {projects.map(function(project) {
          return (
            <div className="ProjectListItem pt-sm pb-sm" key={project.id}>
              <ProjectSummary project={project} />
            </div>
          );
        })}
        <p className="pt-sm text-center">
          <Link className="hyperlink-1" to={`/projects`}>
            More Projects <i className="material-icons md-18">arrow_forward</i>
          </Link>
        </p>
      </div>
    ) : (
      <div className="text-center mt-lg mb-lg">
        <h2 className="mt-md mb-sm">Ready to work on a project?</h2>
        <div className="dropdown-block">
          <DropdownButton
            bsStyle="success"
            title={"Create a new project or import your project file"}
            id={"new-project"}
            noCaret
          >
            <li className="text-center">
              <ProjectCreate />
            </li>
            <li className="text-center">
              <ProjectImport />
            </li>
          </DropdownButton>
        </div>
      </div>
    );
  }

  renderGreeting() {
    const { user } = this.props;
    var m = Moment();

    var split_afternoon = 12;
    var split_evening = 17;
    var currentHour = parseFloat(m.format("HH"));
    let greeting = "";
    if (currentHour >= split_afternoon && currentHour <= split_evening) {
      greeting = "afternoon";
    } else if (currentHour >= split_evening) {
      greeting = "evening";
    } else {
      greeting = "morning";
    }

    return <h2 className="mt-md">{`Good ${greeting}, ${user.firstName}!`}</h2>;
  }

  renderOnboarding() {
    const { user } = this.props;

    return <Onboarding subscriber={user} onboard={true} />;
  }
}

export default Dashboard;
