import React, { Component } from "react";
import "./CraftSummaryList.css";
import { Link } from "react-router-dom";

import { get } from "../../../../utils/BeeApi";
import { slugify } from "../../../../utils/StringUtils";

import Loader from "../../../Loader/Loader";
import ErrorHandler from "../../../ErrorHandler/ErrorHandler";

class CraftSummaryList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.subscribedPromises = [];
  }

  componentDidMount() {
    this.fetchCategoriesByGroup();
  }

  fetchCategoriesByGroup() {
    this.setState({
      error: "",
      isLoading: true
    });

    let categoriesPromise = get("/categories", {
      params: {
        group: "5f84ad6d46900"
      }
    });

    categoriesPromise.promise
      .then(response => {
        this.setState(
          {
            isLoading: false,
            error: "",
            category:
              response.data && response.data.length > 0
                ? response.data.map(category => category.categoryId)
                : ""
          },
          () => {
            this.fetchContents();
          }
        );
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            isLoading: false,
            error: error
          });
      });

    this.subscribedPromises.push(categoriesPromise);
  }

  fetchContents() {
    const { limit, offset, operand, rank } = this.props;

    this.setState({
      error: "",
      isLoading: true
    });

    const options = {
      limit: limit || 20,
      offset: offset || 0,
      operand: operand || "or",
      rank: rank || null,
      category: this.state.category || null
    };
    const createPromise = get("/contents", { params: options });
    createPromise.promise
      .then(response => {
        this.setState({
          contentSummaryList: response.data,
          error: "",
          isLoading: false
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            isLoading: false,
            error: error
          });
      });

    this.subscribedPromises.push(createPromise);
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  render() {
    const { error, isLoading, contentSummaryList } = this.state;

    if (error) return <ErrorHandler error={error} />;
    if (isLoading) return <Loader />;

    return (
      <div className="CraftSummaryList">
        <ul className="list-styled">
          {contentSummaryList &&
            contentSummaryList.map(content => {
              return (
                <li key={content.contentId} className="mt-xs mb-xs">
                  <p>
                    <i className="material-icons">bookmark</i>
                    <Link
                      className="hyperlink-1"
                      to={
                        "/writing-craft/" +
                        content.contentId +
                        "/" +
                        slugify(content.title)
                      }
                    >
                      {content.title}
                    </Link>
                  </p>
                </li>
              );
            })}
        </ul>
      </div>
    );
  }
}

export default CraftSummaryList;
