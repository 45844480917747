import React, { Component } from "react";
import { Grid, Row, Col, DropdownButton, Button } from "react-bootstrap";
import { get } from "../../../utils/DeApi";
import Footer from "../../App/Footer/Footer";
import Header from "../../App/Header/Header";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import Loader from "../../Loader/Loader";
import ProjectCreate from "../ProjectCreate/ProjectCreate";
import ProjectImport from "../ProjectImport/ProjectImport";
import ProjectSummary from "../ProjectSummary/ProjectSummary";
import "./ProjectList.css";

class ProjectList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: 25
    };
    this.subscribedPromises = [];

    this.handleBookend = this._handleBookend.bind(this);
  }

  componentDidMount() {
    this.fetchProjects();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  componentDidUpdate(prevProps) {}

  fetchProjects() {
    const options = {
      limit: this.state.limit
    };

    let projectsPromise = get("/projects", {
      params: options
    });

    projectsPromise.promise
      .then(response => {
        this.setState({
          projects: response.data,
          projectsCount: response.total,
          error: ""
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error
          });
      });

    this.subscribedPromises.push(projectsPromise);
  }

  expandProjects(offset) {
    this.setState({
      expandingError: "",
      isExpanding: true,
      offset: offset
    });

    const options = {
      limit: this.state.limit,
      offset: offset || 0
    };

    const projectsPromise = get("/projects", {
      params: options
    });
    projectsPromise.promise
      .then(response => {
        this.setState(prevState => {
          return {
            projects: prevState.projects
              ? prevState.projects.concat(...response.data)
              : response.data,
            error: "",
            isExpanding: false
          };
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            isExpanding: false,
            expandingError: error
          });
      });

    this.subscribedPromises.push(projectsPromise);
  }

  _handleBookend() {
    const { projects } = this.state;
    let offset = this.state.offset
      ? this.state.offset + this.state.limit
      : projects.length;

    this.expandProjects(offset);
  }

  renderBookend() {
    const { isExpanding, expandingError, projects } = this.state;
    let limit = this.state.limit;

    if (expandingError)
      return (
        <div className="mb-5 mt-5">
          <ErrorHandler error={expandingError} />
        </div>
      );

    if (isExpanding)
      return (
        <div className="mb-5">
          <Loader />
        </div>
      );

    if (projects.length <= 0) return <div />;

    return (
      projects.length % limit === 0 && (
        <div className="text-center mt-5">
          <Button block variant="light" onClick={this.handleBookend}>
            Show More Projects
          </Button>
        </div>
      )
    );
  }

  renderProjects() {
    const { projects, projectsCount, error } = this.state;
    if (error) return <ErrorHandler error={error} />;
    if (!projects) return <Loader />;

    return (
      <div className="card card-emphasis  mt-sm mb-sm">
        <div className="pull-right">
          <DropdownButton
            bsStyle="success"
            bsSize="sm"
            title={"New Project"}
            id={"new-project"}
            noCaret
            pullRight={true}
          >
            <li className="text-center">
              <ProjectCreate />
            </li>
            <li className="text-center">
              <ProjectImport />
            </li>
          </DropdownButton>
        </div>
        <h3 className="text-uppercase">
          Projects | {projects.length} of {projectsCount}
        </h3>
        <hr className="mb-0" />
        {projects && projects.length ? (
          <div>
            {projects.map(function(project) {
              return (
                <div className="ProjectListItem pt-sm pb-sm" key={project.id}>
                  <ProjectSummary project={project} />
                </div>
              );
            })}
            {this.renderBookend()}
          </div>
        ) : (
          <div className="text-center mt-lg mb-lg">
            <h2 className="mt-md mb-sm">Ready to work on a project?</h2>
            <div className="dropdown-block">
              <DropdownButton
                bsStyle="success"
                title={"Create a new project or import your project file"}
                id={"new-project"}
                noCaret
              >
                <li className="text-center">
                  <ProjectCreate />
                </li>
                <li className="text-center">
                  <ProjectImport />
                </li>
              </DropdownButton>
            </div>
          </div>
        )}
      </div>
    );
  }

  render() {
    const { user } = this.props;
    return (
      <div className="Main">
        <Header user={user} title={"project list"} />
        <Grid className="Dashboard MainContent">
          <Row className="mt-sm mb-sm">
            <Col xs={12}>{this.renderProjects()}</Col>
          </Row>
        </Grid>
        <Footer />
      </div>
    );
  }
}

export default ProjectList;
