import React, { Component } from "react";
import { Grid, Col, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Moment from "moment";
import "./ContentCraftDashboard.css";

import { get } from "../../../utils/BeeApi";
import { slugify } from "../../../utils/StringUtils";

import Header from "../../App/Header/Header";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class ContentCraftDashboard extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
      limit: 12,
      offset: 0,
      category: "",
      rank: Moment().format("YYYY-MM-DD HH:mm:ss")
    };

    this.handleBookend = this._handleBookend.bind(this);
  }

  componentDidMount() {
    this.fetchCrafts();
    this.fetchCategoriesByGroup();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  fetchCategoriesByGroup() {
    this.setState({
      error: "",
      isLoading: true
    });

    let categoriesPromise = get("/categories", {
      params: {
        group: "5f84ad6d46900"
      }
    });

    categoriesPromise.promise
      .then(response => {
        this.setState(
          {
            isLoading: false,
            error: "",
            category:
              response.data && response.data.length > 0
                ? response.data.map(category => category.categoryId)
                : ""
          },
          () => {
            this.fetchCrafts();
          }
        );
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            isLoading: false,
            error: error
          });
      });

    this.subscribedPromises.push(categoriesPromise);
  }

  fetchCrafts() {
    this.setState({
      error: "",
      isLoading: true
    });

    const { offset, limit, category, rank } = this.state;
    const options = {
      limit: limit,
      offset: offset,
      operand: "or",
      category: category,
      rank: rank
    };
    const createPromise = get("/contents", { params: options });
    createPromise.promise
      .then(response => {
        const contentSummaryList = response.data;
        this.setState({
          crafts: contentSummaryList,
          isEmpty: contentSummaryList.length <= 0,
          error: "",
          isLoading: false
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            isLoading: false,
            error: error
          });
      });

    this.subscribedPromises.push(createPromise);
  }

  expandCrafts() {
    this.setState({
      expandingError: "",
      isExpanding: true
    });

    const { offset, limit, category, rank } = this.state;
    const options = {
      limit: limit,
      offset: offset,
      operand: "or",
      category: category,
      rank: rank
    };
    const craftsPromise = get("/contents", { params: options });
    craftsPromise.promise
      .then(response => {
        this.setState(prevState => {
          const contentSummaryList = response.data;
          return {
            crafts: prevState.crafts
              ? prevState.crafts.concat(...contentSummaryList)
              : contentSummaryList,
            isEmpty: contentSummaryList.length <= 0,
            expandingError: "",
            isExpanding: false
          };
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            isExpanding: false,
            expandingError: error
          });
      });

    this.subscribedPromises.push(craftsPromise);
  }

  _handleBookend() {
    const { crafts, limit } = this.state;
    if (!crafts || !crafts.length) return;

    this.setState(
      prevState => {
        return { offset: prevState.offset + limit };
      },
      () => this.expandCrafts()
    );
  }

  render() {
    const { user } = this.props;

    return (
      <div className="Main ContentCraftDashboard">
        <Header user={user} title={"Dashboard"} />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Bookflow - Crafts</title>
        </Helmet>
        <Grid className="MainContent">
          <Row className="mt-md mb-md">
            <Col xs={12}>{this.renderCrafts()}</Col>
          </Row>
        </Grid>
      </div>
    );
  }

  renderCrafts() {
    const { isLoading, error, crafts } = this.state;

    if (error) return <ErrorHandler error={error} />;
    if (isLoading) return <Loader />;
    if (!crafts || !crafts.length) return <div />;

    return (
      <div className="card card-info mt-sm mb-sm">
        <h3 className="text-uppercase">MASTER THE CRAFT</h3>
        <h5>
          Every week, Bookflow highlights an article on a specific topic about
          the craft of writing. These short articles also come with longer
          writing exercises, meant to help writers with their works-in-progress
          and overall artistic skills.
        </h5>
        <hr />
        <Row>
          {crafts &&
            crafts.map((item, index) => {
              return (
                <Col xs={12} sm={6} md={4} key={item.contentId}>
                  <div className="ListContent">
                    <Link
                      className="hyperlink-3"
                      to={
                        "/writing-craft/" +
                        item.contentId +
                        "/" +
                        slugify(item.title)
                      }
                    >
                      <div className="img-wrapper">
                        <img src={item.contentThumbnail} alt={item.title} />
                      </div>
                      <h4>
                        {item.title &&
                          (item.title.length < 40
                            ? item.title
                            : item.title.substring(0, 36) + "...")}
                      </h4>
                    </Link>
                    <hr />
                  </div>
                </Col>
              );
            })}
        </Row>
        {this.renderBookend()}
      </div>
    );
  }

  renderBookend() {
    const { isExpanding, expandingError, crafts, limit, isEmpty } = this.state;

    if (expandingError) return <ErrorHandler error={expandingError} />;
    if (isExpanding) return <Loader />;
    if (crafts.length < 0 || isEmpty) return <div />;
    return (
      crafts.length % limit === 0 && (
        <div className="text-center mt-lg mb-lg">
          <Button onClick={this.handleBookend}>Show More Articles</Button>
        </div>
      )
    );
  }
}

export default ContentCraftDashboard;
