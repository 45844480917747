import { setToken, getToken, clearToken } from "./AuthService";

const TOKEN_NAME = "refresh-token";

function setRefreshCredentials(email, password) {
  setToken(
    TOKEN_NAME,
    btoa(
      JSON.stringify({
        email: email,
        password: password
      })
    )
  );
}

function getRefreshCredentials() {
  const refreshToken = getToken(TOKEN_NAME) || "";
  const decodedToken = atob(refreshToken) || "";
  return decodedToken.length > 0 ? JSON.parse(decodedToken) : {};
}

function clearRefreshToken() {
  clearToken(TOKEN_NAME);
  return Promise.resolve({ data: { message: "success" } });
}

export { clearRefreshToken, setRefreshCredentials, getRefreshCredentials };
