import { Modifier, EditorState } from "draft-js";

// References: https://github.com/facebook/draft-js/blob/master/docs/APIReference-Editor.md#handlebeforeinput
// https://practicaltypography.com/straight-and-curly-quotes.html

const CHAR_CONVERSIONS = ['"', "'"];

const CharConversionUtils = {
  handleBeforeInput(inputChar, editorState, eventTimeStamp) {
    // if (!CHAR_CONVERSIONS.includes(inputChar)) return editorState;

    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const startKey = selection.getStartKey();
    const currentBlock = contentState.getBlockForKey(startKey);
    const offset = selection.getStartOffset();
    const currentInlineStyle = editorState.getCurrentInlineStyle();

    let convertedChar = "";
    const beginQuoteMarkers = [" ", "\t"];

    // Convert straight quotes to smart quotes
    if (inputChar === '"') {
      convertedChar = "”";
      if (!offset) convertedChar = "“";
      else if (
        beginQuoteMarkers.includes(currentBlock.getText().charAt(offset - 1))
      )
        convertedChar = "“";
    }

    // Convert single quote to apostrophe's
    else if (inputChar === "'") {
      convertedChar = "’";
      if (!offset) convertedChar = "‘";
      else if (
        beginQuoteMarkers.includes(currentBlock.getText().charAt(offset - 1))
      )
        convertedChar = "‘";
    }

    if (convertedChar) {
      return EditorState.push(
        editorState,
        Modifier.replaceText(
          contentState,
          selection,
          convertedChar,
          currentInlineStyle
        ),
        "insert-characters"
      );
    }

    return EditorState.push(
      editorState,
      Modifier.replaceText(
        contentState,
        selection,
        inputChar,
        currentInlineStyle
      ),
      "insert-characters"
    );
  }
};

export default CharConversionUtils;
