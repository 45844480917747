import React, { Component } from "react";
import "./ProjectClone.css";
import { Button, Modal } from "react-bootstrap";
import Moment from "moment";

import { post } from "../../../utils/DeApi";
import { FieldGroup } from "../../../utils/BootstrapUtils";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import Loader from "../../Loader/Loader";

class ProjectClone extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    let projectName = this.props.project.name;
    projectName = projectName.split("@");
    this.state = {
      show: false,
      projectName: `${projectName[0]
        .trim()
        .replace("Copy", "")} Copy @ ${Moment().format("lll")}`
    };

    this.handleToggleModal = this._handleToggleModal.bind(this);
    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleInputChange = this._handleInputChange.bind(this);
  }

  _handleToggleModal() {
    let projectName = this.props.project.name;
    projectName = projectName.split("@");

    this.setState({
      show: !this.state.show,
      projectName: `${projectName[0]
        .trim()
        .replace("Copy", "")} Copy @ ${Moment().format("lll")}`
    });
  }

  _handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  _handleSubmit(event) {
    event.preventDefault();
    const projectName = this.state.projectName;
    const projectId = this.props.project.id;
    if (projectName) {
      this.setState({ isLoading: true, error: "" });
      const updatePromise = post("/projects/" + projectId + "/clone", {
        name: projectName
      });
      updatePromise.promise
        .then(response => {
          this.setState({
            show: false,
            isLoading: false,
            error: ""
          });
          this.props.onProjectCloned(response.data);
        })
        .catch(error => {
          !error.isCanceled &&
            this.setState({
              error: error,
              isLoading: false
            });
        });
      this.subscribedPromises.push(updatePromise);
    }
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  render() {
    const { isLoading, projectName, error, show } = this.state;
    const form = isLoading ? (
      <Loader />
    ) : (
      <form onSubmit={this.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title className="mt-0">
            Duplicate <em>{projectName}</em>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FieldGroup
            id="projectName"
            name="projectName"
            label="Project Name"
            type="text"
            onChange={this.handleInputChange}
            value={projectName}
            maxLength={81}
            placeholder="Enter a name for your project"
            validationState={
              projectName.length > 80 || !projectName ? "error" : null
            }
            required
          />
          {error && <ErrorHandler error={error} />}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleToggleModal}>Cancel</Button>
          <Button type="submit" bsStyle="success">
            Duplicate
          </Button>
        </Modal.Footer>
      </form>
    );
    return (
      <div>
        <Button bsStyle="link" onClick={this.handleToggleModal}>
          Duplicate Project
        </Button>
        <Modal show={show} onHide={this.handleToggleModal} backdrop="static">
          {form}
        </Modal>
      </div>
    );
  }
}

export default ProjectClone;
