import React, { Component } from "react";
import { ButtonGroup, Button } from "react-bootstrap";
import moment from "moment";

import "./Tracker.css";
import Loader from "../../Loader/Loader";
import { get } from "../../../utils/DeApi";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import ContributionActivity from "../ContributionActivity/ContributionActivity";
import { Fragment } from "react";

const dailyAgregation = {
  "0": "Ready to hit that keyboard? Let's write!",
  "1": "Nailed it! You wrote this week—way to go!",
  "2": "Two days of writing in the books: magnificent!",
  "3": "Whoa! Impressive writing commitment this week!",
  "4": "Another day of writing? You’re rocking it! 🎸",
  "5": "Five days? That's some serious gold star work!",
  "6": "You've committed beyond the work week! Amazing!",
  "7": "Outstanding! You crushed this week—we're so proud! *sniffle*"
};

class Tracker extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
      contributions: [],
      before: moment()
        .endOf("week")
        .format("YYYY-MM-DD"),
      after: moment()
        .startOf("week")
        // .add(1, "day")
        .format("YYYY-MM-DD")
    };
  }

  componentDidMount() {
    this.fetchTracker();
    if (!this.props.project) this.fetchPromptsTracker();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  fetchTracker() {
    const { project } = this.props;
    const { before, after } = this.state;

    let contributionsPromise = get("/contributions", {
      params: { project: project && project.id, before: before, after: after }
    });

    this.setState({ isLoading: true });

    contributionsPromise.promise
      .then(response => {
        this.setState(prevState => {
          let contributions = prevState.contributions;
          return {
            contributions: contributions.concat(response.data),
            isLoading: false,
            error: ""
          };
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            isLoading: false,
            error: error
          });
      });

    this.subscribedPromises.push(contributionsPromise);
  }

  fetchPromptsTracker() {
    const { before, after } = this.state;
    let promptsTrackerPromise = get("/prompt-contributions", {
      params: { before: before, after: after }
    });

    this.setState({ isLoadingPrompts: true });

    promptsTrackerPromise.promise
      .then(response => {
        this.setState(prevState => {
          let contributions = prevState.contributions;
          return {
            contributions: contributions.concat(response.data),
            isLoadingPrompts: false,
            errorPrompts: ""
          };
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            isLoadingPrompts: false,
            errorPrompts: error
          });
      });

    this.subscribedPromises.push(promptsTrackerPromise);
  }

  showPreviousActivity() {
    this.setState(
      state => ({
        contributions: [],
        before: moment(state.before)
          .subtract(7, "days")
          .endOf("day")
          .format(),
        after: moment(state.after)
          .subtract(7, "days")
          .startOf("day")
          .format()
      }),
      () => {
        this.fetchTracker();
        if (!this.props.project) this.fetchPromptsTracker();
      }
    );
  }

  showNextActivity() {
    this.setState(
      state => ({
        contributions: [],
        before: moment(state.before)
          .add(7, "days")
          .endOf("day")
          .format(),
        after: moment(state.after)
          .add(7, "days")
          .startOf("day")
          .format()
      }),
      () => {
        this.fetchTracker();
        if (!this.props.project) this.fetchPromptsTracker();
      }
    );
  }

  render() {
    const {
      contributions,
      before,
      after,
      isLoading,
      isLoadingPrompts,
      error,
      errorPrompts
    } = this.state;
    const { project } = this.props;

    if (error) return <ErrorHandler error={error} />;
    if (errorPrompts) return <ErrorHandler error={errorPrompts} />;

    return (
      <div className="Tracker">
        {isLoading || isLoadingPrompts ? (
          <Loader />
        ) : (
          <Fragment>
            {this.renderStreak()}
            <ContributionActivity
              contributions={contributions}
              before={before}
              after={after}
              project={project}
            />
          </Fragment>
        )}
        {/* <hr className="mt-xs" /> */}
        <h5 className="mt-xs">
          <ButtonGroup className="pull-right">
            <Button
              bsSize="xs"
              className="btn-secondary pl-0 pr-0"
              onClick={() => this.showPreviousActivity()}
              title="Show activity for previous 7 days"
            >
              <i className="material-icons md-24">chevron_left</i>
            </Button>

            <Button
              bsSize="xs"
              className="btn-secondary pl-0 pr-0"
              onClick={() => this.showNextActivity()}
              title="Show activity for next 7 days"
            >
              <i className="material-icons md-24">chevron_right</i>
            </Button>
          </ButtonGroup>
          {`${moment(after)
            .subtract(1, "days")
            .format("LL")} - ${moment(before).format("LL")}`}
        </h5>
      </div>
    );
  }

  renderStreak() {
    const uniqueContributions = [
      ...new Set(this.state.contributions.map(item => item.date))
    ];

    return (
      <p className="mt-md list-styled">
        <i className="material-icons">whatshot</i>
        <span>{dailyAgregation[uniqueContributions.length]}</span>
      </p>
    );
  }

  parseStreak() {
    const { contributions } = this.state;
    if (!contributions || !contributions.length) return [];

    let streak = [];
    let onStreak = true;
    let currDate = moment();
    while (onStreak) {
      let currStreak = contributions.find(contribution => {
        return contribution.date === currDate.format("YYYY-MM-DD");
      });

      if (!!currStreak) {
        streak.push(currStreak);
        currDate.subtract(1, "days");
      } else {
        onStreak = false;
      }
    }
    return streak;
  }
}
export default Tracker;
