import React, { Component } from "react";
import { Button, Image, Modal } from "react-bootstrap";
import "./RewardSharer.css";

import SocialSharing from "../../../SocialSharing/SocialSharing";
import Loader from "../../../Loader/Loader";
import { get } from "../../../../utils/BeeApi";

class RewardSharer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.subscribedPromises = [];
  }

  componentDidMount() {
    this.fetchRewardContent();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  fetchRewardContent() {
    const { rewardContent } = this.props;
    this.setState({ error: "", isLoading: true });
    if (rewardContent) {
      this.setState({ rewardContent: rewardContent });
      return;
    }
    const { contentId } = this.props;

    const fetchRewardContent = get(`/contents/${contentId}`);
    fetchRewardContent.promise
      .then((response) => {
        this.setState((prevState) => {
          return { rewardContent: response.data, isLoading: false, error: "" };
        });
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ isLoading: false, error: error });
      });
    this.subscribedPromises.push(fetchRewardContent);
  }

  render() {
    const { onHide, show } = this.props;
    const { rewardContent } = this.state;

    return rewardContent ? (
      <Modal
        className="RewardSharer"
        show={show}
        onHide={onHide}
        backdrop={"static"}
      >
        <Modal.Header closeButton>
          <Modal.Title className="mt-0 text-center">
            {rewardContent.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center pt-0 pb-0 pr-0 pl-0">
          <Image src={rewardContent.contentThumbnail} />
        </Modal.Body>
        <Modal.Footer>
          <div className="mt-xs mb-xs text-center">
            <SocialSharing
              options={{ download: true }}
              content={rewardContent}
            />
          </div>
          <p className="text-center mb-0">
            <Button bsStyle="link" onClick={onHide}>
              Dismiss
            </Button>
          </p>
        </Modal.Footer>
      </Modal>
    ) : (
      <Modal
        className="RewardSharer"
        show={show}
        onHide={onHide}
        backdrop={"static"}
      >
        <Modal.Header closeButton>
          <Modal.Title className="mt-0 text-center" />
        </Modal.Header>
        <Modal.Body className="text-center pt-0 pb-0 pr-0 pl-0">
          <Loader />
        </Modal.Body>
      </Modal>
    );
  }
}

export default RewardSharer;
