import Moment from "moment";

export function getSubscriptionStatus(subscriptions, subscriber) {
  let subscription = subscriptions && subscriptions[0];
  let launchDate = Moment("2019-11-01");
  let stripeTrialEndsAt =
    subscription && subscription.trialEndsAt
      ? subscription.trialEndsAt
      : "2020-01-01";

  let subscriberTrialEndsAt = subscriber.trialEndsAt
    ? Moment(subscriber.trialEndsAt)
    : "2020-01-01";

  let trialEndsAt = Moment(subscriberTrialEndsAt).isAfter(stripeTrialEndsAt)
    ? subscriberTrialEndsAt
    : stripeTrialEndsAt;

  // Subscription Status
  if (
    subscription &&
    subscription.status &&
    subscription.status !== "canceled"
  ) {
    return subscription.status;
  }

  // Early adopter
  if (
    Moment(subscriber.createdAt).isBefore(launchDate, "day") &&
    Moment().isBefore(trialEndsAt)
  )
    return "early-adopter";

  // Trialing BEE
  if (Moment().isBefore(trialEndsAt)) {
    return "trialing";
  }

  // Trialing BEE Expired and no subscription
  if (
    trialEndsAt &&
    Moment(trialEndsAt).isBefore(Moment(), "day") &&
    !subscription
  ) {
    return "unpaid";
  }

  return "unpaid";
}

export function getSubscriptionViewType(subscriptions, status) {
  //Active, Trialing, Early Adopter, InActive

  if (
    subscriptions[0] &&
    subscriptions[0].status !== "canceled" &&
    (status === "trialing" ||
      status === "active" ||
      status === "incomplete" ||
      status === "past_due")
  )
    return "active";
  if (status === "trialing") return "trialing";
  if (status === "early-adopter") return "early-adopter";
  if (
    status === "canceled" ||
    status === "incomplete_expired" ||
    status === "unpaid"
  )
    return "inactive";
}
