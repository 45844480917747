import React, { Component, Fragment } from "react";
import { Elements } from "react-stripe-elements";
import { Button, Modal, Alert } from "react-bootstrap";

import { get, post } from "../../../utils/BeeApi";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import Card from "../Card/Card";

class SubscriptionPayment extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};

    this.handleCCSubmitted = this._handleCCSubmitted.bind(this);
    this.handleShowModal = this._handleShowModal.bind(this);
    this.handleCloseModal = this._handleCloseModal.bind(this);
  }

  _handleCCSubmitted(token) {
    this.postCard(token);
  }

  _handleCloseModal() {
    this.setState({ show: false });
  }

  _handleShowModal() {
    this.setState({ show: true });
  }

  componentDidMount() {
    this.fetchCard();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  fetchCard() {
    this.setState({ isLoading: true, error: "" });
    const getCardsPromise = get("/cards/default");
    getCardsPromise.promise
      .then(response => {
        this.setState({
          card: response.data,
          error: "",
          isLoading: false
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error,
            isLoading: false
          });
      });
    this.subscribedPromises.push(getCardsPromise);
  }

  postCard(token) {
    this.setState({ isLoading: true, error: "" });
    const postCardPromise = post("/cards", {
      stripeToken: token.id
    });
    postCardPromise.promise
      .then(response => {
        this.setState({ error: "", isLoading: false, newCard: response.data });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error,
            isLoading: false
          });
      });
    this.subscribedPromises.push(postCardPromise);
  }

  render() {
    const { plan } = this.props;
    const { show, isLoading, newCard } = this.state;

    return (
      <div className="SubscriptionPayment">
        <Button bsStyle="link" bsSize="sm" onClick={this.handleShowModal}>
          Update Your Payment Method
        </Button>
        <Modal show={show} onHide={this.handleCloseModal} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title className="mt-0 text-capitalize  text-center">
              {`Add Payment Method to your ${plan.nickname} Plan`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isLoading ? (
              <Loader />
            ) : newCard ? (
              this.renderSuccess()
            ) : (
              this.renderCheckout()
            )}
          </Modal.Body>
        </Modal>
      </div>
    );
  }

  renderSuccess() {
    const { newCard } = this.state;

    return (
      <div>
        <Alert bsStyle="success" className="text-center">
          <h3 className="text-uppercase mb-0">
            <strong>Success!</strong>
          </h3>
          <hr className="mt-xs mb-xs" />
          <h5>
            You have updated the payment method associated with your account
            successfully.
          </h5>
          <h5>
            Your payment method on file is now{" "}
            <strong>
              <span className="text-uppercase">{newCard.brand}</span>
              {` ending in ${newCard.cardLastFour}`}
            </strong>
          </h5>
        </Alert>

        <Button bsStyle="primary" onClick={this.handleCloseModal} block>
          Close
        </Button>
      </div>
    );
  }
  renderCheckout() {
    const { plan, subscriber } = this.props;
    const { error, card } = this.state;

    return (
      <Fragment>
        <h5 className="text-center mb-sm">
          Please review your payment details on file. Use the form below to add
          a credit card to your subscription plan.
        </h5>
        {error && <ErrorHandler error={error} />}

        {card && (
          <Fragment>
            <h4 className="mt-xs mb-xs">Payment Method</h4>
            <div className="card card-xs">
              <h5 className="mb-0">
                <span className="text-uppercase">{card.brand}</span>
                {` ending in ${card.cardLastFour}`}
              </h5>
            </div>
          </Fragment>
        )}

        <h4 className="mt-sm mb-xs">Add New Payment Method</h4>
        <div className="card">
          <Elements>
            <Card
              plan={plan}
              subscriber={subscriber}
              onCCSubmitted={this.handleCCSubmitted}
            />
          </Elements>
        </div>
      </Fragment>
    );
  }
}

export default SubscriptionPayment;
