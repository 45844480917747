import React, { Component, Fragment } from "react";
import { Button, Modal } from "react-bootstrap";

import { put } from "../../../utils/DeApi";
import { FieldGroup } from "../../../utils/BootstrapUtils";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class SceneEdit extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
      show: false,
      title: this.props.scene.title || "",
      description: this.props.scene.description || ""
    };

    this.handleShowModal = this._handleShowModal.bind(this);
    this.handleCloseModal = this._handleCloseModal.bind(this);
    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleInputChange = this._handleInputChange.bind(this);
  }

  _handleCloseModal() {
    this.setState({ show: false });
    this.props.onSceneEditing && this.props.onSceneEditing(false);
  }

  _handleShowModal() {
    this.setState({ show: true });
    this.props.onSceneEditing && this.props.onSceneEditing(false);
  }

  _handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value }, this.handleUpdated);
  }

  _handleSubmit(event) {
    event.preventDefault();
    const { title, description } = this.state;
    const { scene, sceneTypes, onSceneUpdated } = this.props;

    const updatePromise = put("/scenes/" + scene.id, {
      title: title,
      description: description,
      sceneType: sceneTypes[0].id
    });
    this.setState({ isUpdating: true, error: "" });
    updatePromise.promise
      .then((response) => {
        this.setState({
          show: false,
          isUpdating: false,
          error: ""
        });
        onSceneUpdated(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          this.setState({
            error: error,
            isUpdating: false
          });
        }
      });
    this.subscribedPromises.push(updatePromise);
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  render() {
    const {
      show,
      isLoading,
      isUpdating,
      error,
      title,
      description
    } = this.state;

    const { sceneTypes } = this.props;

    const form =
      !sceneTypes || isLoading || isUpdating ? (
        <Loader />
      ) : (
        <form onSubmit={this.handleSubmit} autoComplete="off">
          <Modal.Header closeButton>
            <Modal.Title className="mt-0">Update title and summary</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FieldGroup
              id="sceneTitle"
              name="title"
              type="text"
              label="Scene Title"
              placeholder="Enter scene title."
              value={title}
              onChange={this.handleInputChange}
              validationState={title.length > 80 || !title ? "error" : null}
              maxLength={81}
              required
            />
            <FieldGroup
              id="sceneDescription"
              name="description"
              label="Scene Summary"
              componentClass="textarea"
              rows={"15"}
              placeholder="Enter scene summary (optional)"
              value={description || ""}
              onChange={this.handleInputChange}
              maxLength={5100}
              validationState={description.length > 5000 ? "error" : null}
            />
            {error && <ErrorHandler error={error} />}
          </Modal.Body>
          <Modal.Footer>
            <Button bsStyle="link" onClick={this.handleCloseModal}>
              Cancel
            </Button>
            <Button type="submit" bsStyle="success">
              Save
            </Button>
          </Modal.Footer>
        </form>
      );

    return (
      <Fragment>
        <Button
          className="btn-secondary"
          bsSize="xs"
          onClick={this.handleShowModal}
        >
          Edit
        </Button>
        <Modal show={show} onHide={this.handleCloseModal} backdrop="static">
          {form}
        </Modal>
      </Fragment>
    );
  }
}

export default SceneEdit;
