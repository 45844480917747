import React from "react";
import "draft-js/dist/Draft.css";
import "./RichTextEditor.css";
import InlineStyleControls from "./Controls/InlineStyleControls";
import BlockStyleControls from "./Controls/BlockStyleControls";
import UndoRedoControls from "./Controls/UndoRedoControls";
import { AlignmentControls, AlignmentUtils } from "./Controls/AlignmentUtils";
import CharConversionUtils from "./Controls/CharConversionUtils";
import CopyPasteUtils from "./Controls/CopyPasteUtils";
import IndentationUtils from "./Controls/IndentationUtils";
import { Offline } from "react-detect-offline";
import { isLoggedIn } from "../../utils/BeeApi";
import moment from "moment";
import NavigationGuard from "./Controls/NavigationGuard";
import {
  Editor,
  EditorState,
  ContentState,
  convertToRaw,
  convertFromRaw,
  RichUtils,
  getDefaultKeyBinding
} from "draft-js";
import FindAndReplace from "./FindAndReplace/FindAndReplace";
import { FocusModeContext } from "../Project/FocusModeContext/FocusModeContext";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

class RichTextEditor extends React.Component {
  constructor(props) {
    super(props);

    this.handleEditorFocus = this._handleEditorFocus.bind(this);
    this.handleOnChange = this._handleOnChange.bind(this);
    this.handleOnTab = this._handleOnTab.bind(this);
    this.handleKeyCommand = this._handleKeyCommand.bind(this);
    this.handleBeforeInput = this._handleBeforeInput.bind(this);
    this.handlePastedText = this._handlePastedText.bind(this);
    this.mapKeyToEditorCommand = this._mapKeyToEditorCommand.bind(this);
    this.toggleBlockType = this._toggleBlockType.bind(this);
    this.toggleInlineStyle = this._toggleInlineStyle.bind(this);
    this.toggleAlignment = this._toggleAlignment.bind(this);
    this.toggleUndoRedo = this._toggleUndoRedo.bind(this);
    this.findReplaceEditorState = this._findReplaceEditorState.bind(this);
    this.findReplaceStatus = this._findReplaceStatus.bind(this);

    this.focus = () => this.handleEditorFocus();
    this.onChange = editorState => this.handleOnChange(editorState);
    this.onTab = e => this.handleOnTab(e);

    let editorState = EditorState.createEmpty();

    if (this.props.richText) {
      let richText = this.props.richText;

      if (typeof richText === "object") {
        editorState = EditorState.createWithContent(convertFromRaw(richText));
      } else {
        try {
          editorState = EditorState.createWithContent(
            convertFromRaw(JSON.parse(richText))
          );
        } catch (e) {
          editorState = EditorState.createWithContent(
            ContentState.createFromText(richText)
          );
        }
      }
    }

    let stats = this.getContentStats(editorState);
    this.state = {
      editorState: editorState,
      stats: stats,
      isSaving: false,
      isOnline: true,
      isMobile: false, // /mobi/i.test(navigator.userAgent.toLowerCase()),
      isSubscribed:
        isLoggedIn().isSubscribed ||
        moment(parseInt(isLoggedIn().sub?.substr(0, 8), 16) * 1000).isSame(
          moment(),
          "day"
        )
    };
  }

  _handleOnChange(editorState) {
    const contentState = editorState.getCurrentContent();
    const prevContentState = this.state.editorState.getCurrentContent();

    if (contentState !== prevContentState || this.state.savingError) {
      const richText = contentState.hasText()
        ? convertToRaw(contentState)
        : null;
      const stats = this.getContentStats(editorState);
      this.updateRichText(richText, stats);
      this.setState({ editorState: editorState, stats: stats });
    } else {
      this.setState({ editorState: editorState });
    }
  }

  _handleOnTab(e) {
    e.preventDefault();
    let editorState = RichUtils.onTab(e, this.state.editorState, 4);
    editorState = IndentationUtils.indent(editorState);
    this.onChange(editorState);
  }

  updateRichText(richText, stats) {
    const { onRichTextUpdated, onStatsUpdated } = this.props;

    this.setState({ isSaving: true, savingError: false });
    const richTextPromise = new Promise((resolve, reject) => {
      onRichTextUpdated && onRichTextUpdated(richText, resolve, reject);
    });

    richTextPromise
      .then(success => {
        this.setState({ isSaving: false, savingError: false, isOnline: true });
      })
      .catch(error => {
        this.setState({ isSaving: false, savingError: true });
      });

    const statsPromise = new Promise((resolve, reject) => {
      onStatsUpdated && onStatsUpdated(stats, resolve, reject);
    });
    statsPromise.then(success => {}).catch(error => {});
  }

  _handleEditorFocus() {
    this.refs.editor.focus();
  }

  _handleKeyCommand(command, editorState) {
    if (command === "backspace") {
      let clearState = AlignmentUtils.clearAlignment(editorState);
      if (clearState) {
        this.onChange(clearState);
        return true;
      }
    }

    let newState = RichUtils.handleKeyCommand(editorState, command);
    if (command === "split-block") {
      newState = AlignmentUtils.preserveAlignment(newState || editorState);
    }

    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  }

  _handleBeforeInput(inputString, editorState, eventTimeStamp) {
    const newEditorState = CharConversionUtils.handleBeforeInput(
      inputString,
      editorState,
      eventTimeStamp
    );
    if (newEditorState !== editorState) {
      this.onChange(newEditorState);
      return "handled";
    }
  }

  _handlePastedText(text, html, editorState) {
    let newState = CopyPasteUtils.handlePastedText(text, html, editorState);

    if (newState) {
      this.onChange(newState);
      return true;
    }

    return false;
  }

  _mapKeyToEditorCommand(e) {
    if (e.keyCode === 9) {
      const newEditorState = RichUtils.onTab(e, this.state.editorState, 4);
      if (newEditorState !== this.state.editorState) {
        this.onChange(newEditorState);
      }
      return;
    }
    return getDefaultKeyBinding(e);
  }

  _toggleBlockType(blockType) {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  }

  _toggleInlineStyle(inlineStyle) {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle)
    );
  }

  _toggleUndoRedo(control) {
    if (control === "UNDO")
      this.onChange(EditorState.undo(this.state.editorState));
    else if (control === "REDO")
      this.onChange(EditorState.redo(this.state.editorState));
  }

  _toggleAlignment(newEditorState) {
    if (newEditorState !== this.state.editorState) {
      this.onChange(newEditorState);
    }
    return;
  }

  _findReplaceEditorState(updatedEditorState) {
    if (updatedEditorState !== this.state.editorState) {
      this.onChange(updatedEditorState);
    }
    return;
  }

  _findReplaceStatus(status) {
    if (typeof this.props.findReplaceStatus === "function")
      this.props.findReplaceStatus(status);
  }

  componentDidMount() {}

  render() {
    const {
      editorState,
      savingError,
      isOnline,
      isMobile,
      isSubscribed
    } = this.state;
    const { controls, placeholder, readOnly, syncing } = this.props;

    const isReadOnly =
      readOnly ||
      !isSubscribed ||
      savingError ||
      !isOnline ||
      isMobile ||
      syncing;

    return (
      <div className={"RichTextEditor " + (isReadOnly ? "readOnly" : "")}>
        <div className="RichEditor-root">
          {controls && (
            <div className="RichEditorControls-root">
              {isMobile && !readOnly ? (
                <div className="alert alert-warning text-center">
                  <h3>
                    {this.props.isPrompt
                      ? `This field is not yet mobile-ready.`
                      : `This field is not yet mobile-ready. Capture thoughts and
                    ideas in the summary section above.`}
                  </h3>
                </div>
              ) : !isSubscribed ? (
                <div className="alert alert-warning text-center">
                  <h3>Please subscribe to unlock editing features</h3>
                  <p>
                    <a className="btn btn-secondary btn-sm" href="/account">
                      Subscribe Now
                    </a>
                  </p>
                </div>
              ) : (
                <span />
              )}
              <UndoRedoControls
                editorState={editorState}
                onToggle={this.toggleUndoRedo}
              />
              {" | "}
              <BlockStyleControls
                editorState={editorState}
                onToggle={this.toggleBlockType}
              />
              {" | "}
              <InlineStyleControls
                editorState={editorState}
                onToggle={this.toggleInlineStyle}
              />
              {" | "}
              <AlignmentControls
                editorState={editorState}
                onToggle={this.toggleAlignment}
              />
              {" | "}
              <FindAndReplace
                editorState={editorState}
                findReplaceStatus={this.findReplaceStatus}
                updatedEditorState={this.findReplaceEditorState}
              />
            </div>
          )}

          <div className="RichEditor-editor">
            <Editor
              blockStyleFn={this.getBlockStyle}
              editorState={editorState}
              handleKeyCommand={this.handleKeyCommand}
              handleBeforeInput={this.handleBeforeInput}
              handlePastedText={this.handlePastedText}
              keyBindingFn={this.mapKeyToEditorCommand}
              onChange={this.handleOnChange}
              onFocus={this.handleEditorFocus}
              onTab={this.handleOnTab}
              placeholder={placeholder || "Tell a story..."}
              ref="editor"
              spellCheck={true}
              readOnly={isReadOnly}
            />
          </div>
        </div>
        <div className="FooterStickyRight">{this.renderMetadata()}</div>
      </div>
    );
  }

  renderMetadata() {
    const { savingError, editorState, isSaving, stats, isMobile } = this.state;
    const { counter, controls, syncing, readOnly, focusMode } = this.props;

    return (
      <ul className="list-inline text-right">
        <Offline
          polling={{ url: "https://bookflow.pixeledge.io" }}
          onChange={online => {
            this.setState({ isOnline: online });
          }}
        >
          {controls && (
            <li className="pt-xs pb-xs">
              <span className="label label-warning">Offline</span>
            </li>
          )}
        </Offline>
        {isMobile && !readOnly && (
          <li>
            <span className="label label-info">Read-only on Mobile</span>
          </li>
        )}

        {savingError && (
          <li className="pt-xs pb-xs">
            <button
              className="btn btn-danger btn-xs"
              onClick={() => this.handleOnChange(editorState)}
            >
              Saving failed, click to retry
            </button>
          </li>
        )}
        {syncing && (
          <li>
            <span className="label label-success">
              Syncing active editors...
            </span>
          </li>
        )}
        {isSaving && (
          <li>
            <span className="label label-neutral">Saving...</span>
          </li>
        )}
        <NavigationGuard when={isSaving} />
        {focusMode && (
          <li className="pt-xs pb-xs">
            <FocusModeContext.Consumer>
              {({ isFocused, toggleFocusMode }) => (
                <span
                  className="label focus-label label-default"
                  onClick={toggleFocusMode}
                >
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="underline-tooltip">
                        Toggle focus mode
                      </Tooltip>
                    }
                  >
                    {isFocused ? (
                      <i className="material-icons focusMode">
                        center_focus_weak
                      </i>
                    ) : (
                      <i className="material-icons focusMode">
                        center_focus_strong
                      </i>
                    )}
                  </OverlayTrigger>
                </span>
              )}
            </FocusModeContext.Consumer>
          </li>
        )}
        {counter && (
          <li className="pt-xs pb-xs">
            <span className="label label-default">{stats.words} Words</span>
          </li>
        )}
      </ul>
    );
  }

  getBlockStyle(block) {
    let blockAlignment;
    switch (block.getData().get("textAlignment")) {
      case "RIGHT":
        blockAlignment = `text-right`;
        break;
      case "CENTER":
        blockAlignment = `text-center`;
        break;
      case "LEFT":
        blockAlignment = `text-left`;
        break;
      case "JUSTIFY":
        blockAlignment = `text-justify`;
        break;
      default:
        blockAlignment = null;
    }

    let blockTypeStyle;
    switch (block.getType()) {
      case "blockquote":
        blockTypeStyle = "RichEditor-blockquote";
        break;
      case "code-block":
        blockTypeStyle = "RichEditor-code-block";
        break;
      default:
        blockTypeStyle = null;
    }

    return `${blockAlignment} ${blockTypeStyle}`;
  }

  getContentStats(editorState) {
    // https://draftjs.org/docs/api-reference-content-block.html
    // https://github.com/draft-js-plugins/draft-js-plugins/tree/master/docs/client/components/pages/Counter
    const contentState = editorState.getCurrentContent();
    var blocks = contentState.getBlocksAsArray();
    var words = 0;
    var characters = 0;

    blocks.forEach(function(block) {
      var text = block.text || "";
      // new line, carriage return, line feed
      const regex = /(?:\r\n|\r|\n)/g;
      // replace above characters w/ space
      const cleanString = text.replace(regex, " ").trim();
      characters += cleanString.length;
      // matches words according to whitespace
      const wordArray = cleanString.match(/\S+/g);
      words += wordArray ? wordArray.length : 0;
    });
    return {
      characters: characters,
      words: words,
      blocks: blocks.length
    };
  }
}

export default RichTextEditor;
