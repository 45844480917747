import React, { Component } from "react";
import echarts from "echarts";

/**
 * Handles rendering project activity heat map.
 * Charts implemented using https://ecomfe.github.io/echarts-doc/public/en/option.html#series-pie.type
 * Chart Ref: https://gallery.echartsjs.com/editor.html?c=xOg6hAo710

 */
class ProjectProgress extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};
  }

  componentDidMount() {
    this.renderProgress();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.project.words !== prevProps.project.words ||
      this.props.project.deadline !== prevProps.project.deadline
    ) {
      this.renderProgress();
    }
  }

  render() {
    const { project, stats } = this.props;
    const { chartImageData } = this.state;

    if (!project || !stats) return <div />;
    return (
      <div className="ProjectProgress">
        <div
          className="progress-container center-block"
          id="pie-component"
          ref={el => (this.el = el)}
        />
        {chartImageData && (
          <div className="text-right  chart-downloader">
            <a
              href={chartImageData}
              className="text-muted"
              download={`${project.name} - Progress`}
              title="Download and Share."
            >
              <i className="material-icons md-24">save_alt</i>
            </a>
          </div>
        )}
      </div>
    );
  }

  renderProgress() {
    const { project, stats, showSubText } = this.props;
    const name = project.name;
    const goal = project.words;

    const words = stats.sceneStats.reduce(function(memo, sceneStats) {
      return memo + (sceneStats.words || 0);
    }, 0);
    const progress = Math.round((words / goal) * 100);

    let data = stats.sceneStats.map(sceneStat => {
      return {
        name: sceneStat.sceneId,
        value: sceneStat.words
      };
    });

    if (words < goal) {
      data.push({
        name: "end",
        value: goal - words,
        itemStyle: {
          color: "#fff"
        }
      });
    }

    var option = {
      title: [
        {
          text: `${progress}%`,
          textStyle: {
            color: "#2e384d",
            fontFamily: "Trebuchet MS",
            fontSize: showSubText ? 28 : 16,
            fontWeight: "bold",
            align: "center"
          },
          y: "center",
          x: "center"
        },
        {
          show: showSubText,
          text: name.length < 27 ? name : name.substring(0, 24) + "...",
          textStyle: {
            color: "#2e384d",
            fontSize: 14,
            fontFamily: "Open Sans",
            fontWeight: "normal",
            align: "center"
          },
          y: "10%",
          x: "center"
        },
        {
          show: showSubText,
          text: `${new Intl.NumberFormat().format(
            words
          )} of ${new Intl.NumberFormat().format(goal)} words`,
          textStyle: {
            color: "#2e384d",
            fontSize: 14,
            fontFamily: "Open Sans",
            fontWeight: "normal",
            align: "center"
          },
          y: "80%",
          x: "center"
        },
        {
          show: false,
          text: "www.bookflow.pub",
          textStyle: {
            color: "#8798ad",
            fontSize: 8,
            fontFamily: "Open Sans",
            fontWeight: "normal",
            align: "center"
          },
          y: "bottom",
          x: "center"
        }
      ],
      color: ["#D5D4FF"],
      series: [
        {
          name: name,
          type: "pie",
          radius: showSubText ? ["40%", "55%"] : ["50%", "70%"],
          center: ["50%", "50%"],
          label: {
            show: false
          },
          data: data
        }
      ]
    };

    this.chart = echarts.init(this.el);
    this.chart.on("rendered", () => {
      if (showSubText) {
        const chartImageData = this.chart.getConnectedDataURL({
          pixelRatio: 10,
          backgroundColor: "#fff",
          type: "png"
        });
        this.setState({
          chartImageData: chartImageData
        });
      }
    });

    this.chart.setOption(option);
  }
}

export default ProjectProgress;
