import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import pluralize from "pluralize";
import Moment from "moment";
import { Link } from "react-router-dom";
import "./Guide.css";

import { get } from "../../../utils/BeeApi";

import Header from "../../App/Header/Header";
import Footer from "../../App/Footer/Footer";
import Loader from "../../Loader/Loader";

import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import ContentArticle from "../ContentArticle/ContentArticle";
import ContentExternal from "../ContentExternal/ContentExternal";
import ContentEvent from "../ContentEvent/ContentEvent";
import ContentSummaryList from "../ContentSummaryList/ContentSummaryList";

class Guide extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.subscribedPromises = [];

    pluralize.addIrregularRule("5ba5d8232a8f7", "Characters+ Guides");
    pluralize.addIrregularRule("5ba5d8120c1af", "Scenes Guides");
    pluralize.addIrregularRule("5ba5d800d5983", "Themes Guides");
    pluralize.addIrregularRule("5ba5d8327f832", "Inspirations");
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.fetchContent();
    }
  }

  componentDidMount() {
    this.fetchContent();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  fetchContent() {
    this.setState({
      error: "",
      isLoading: true
    });

    const contentId = this.props.match.params.id;

    const createPromise = get("contents/" + contentId, {});
    createPromise.promise
      .then(response => {
        this.setState(
          {
            content: response.data,
            error: "",
            isLoading: false,
            // Categories that have the Craft group
            categories: response.data.categories.filter((category, index) => {
              let groupIds = category.groups.map((group, index) => {
                return group.groupId;
              });
              return groupIds.includes("5ba5d7d65e617");
            })
          },
          () => {
            let iframes = document.querySelectorAll("iframe");
            if (iframes.length > 0)
              iframes.forEach(iframe => {
                iframe.removeAttribute("height");
                iframe.removeAttribute("width");
                iframe.setAttribute("class", "embed-responsive-item");
                let wrapper = document.createElement("div");
                wrapper.className = "embed-responsive embed-responsive-16by9";

                // insert wrapper before el in the DOM tree
                iframe.parentNode.insertBefore(wrapper, iframe);
                wrapper.appendChild(iframe);
              });
          }
        );
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error,
            content: {},
            isLoading: false
          });
      });
  }

  render() {
    const { user } = this.props;
    const { content } = this.state;

    return (
      <div className="Main">
        <Header user={user} title={content ? content.title : "Inspirations"} />
        <Grid className="MainContent">
          <Row className="mt-sm mb-sm">
            <Col xs={12} md={8} className="Content">
              <div className="card card-emphasis  mt-md mb-md">
                {this.renderContent()}
              </div>
            </Col>
            <Col xs={12} md={4}>
              {this.renderRelatedContent()}
            </Col>
          </Row>
        </Grid>
        <Footer />
      </div>
    );
  }

  renderContent() {
    const { content, isLoading, error } = this.state;
    if (isLoading) return <Loader />;
    if (error) return <ErrorHandler error={error} />;

    return content ? (
      <div>
        {content.typeId === 1 && <ContentArticle {...content} />}
        {content.typeId === 2 && <ContentEvent {...content} />}
        {content.typeId === 4 && <ContentExternal {...content} />}
      </div>
    ) : (
      <div />
    );
  }

  renderRelatedContent() {
    const { categories, error, isLoading } = this.state;

    if (isLoading) return <Loader />;
    if (error) return <ErrorHandler error={error} />;
    const categoryId =
      categories && categories.length > 0 ? categories[0].categoryId : "";
    const isInspirations = categoryId === "5ba5d8327f832";

    return categories && categories.length > 0 ? (
      <div className="card card-warning  mt-md mb-md">
        <h3 className="text-uppercase">{pluralize(categoryId)}</h3>
        <hr />
        <ContentSummaryList
          category={categoryId}
          operand="or"
          URLPrefix="guides"
          limit={isInspirations ? 10 : null}
          rank={Moment().format("YYYY-MM-DD HH:mm:ss")}
        />

        {isInspirations && (
          <div>
            <hr />
            <Link className="hyperlink-1 text-capitalize" to={"/contents"}>
              {`More ${pluralize(
                categories.length > 0 ? categories[0].categoryId : "contents"
              )}`}
              <i className="material-icons md-18">arrow_forward</i>
            </Link>
          </div>
        )}
      </div>
    ) : (
      <div />
    );
  }
}

export default Guide;
