import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { Grid, Col, Row, Button, Alert, Image } from "react-bootstrap";

import "./ForgotPassword.css";
import { isLoggedIn, put } from "../../../utils/BeeApi";
import { FieldGroup } from "../../../utils/BootstrapUtils";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

import { isLoggedIn as isLoggedInDE } from "../../../utils/DeApi";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.subscribedPromises = [];
    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleEmailChange = this._handleEmailChange.bind(this);

    const loggedIn = isLoggedInDE() && isLoggedIn();
    let email = loggedIn ? loggedIn.email : "";

    this.state = {
      email: email,
      emailValid: !!email || "",
      error: "",
      loggedIn: loggedIn,
      success: false
    };
  }

  _handleEmailChange(event) {
    let email = event.target.value;
    this.setState({
      email: email,
      emailValid: email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
        ? true
        : false
    });
  }

  _handleSubmit(event) {
    event.preventDefault();
    if (this.state.emailValid) {
      this.setState({
        formSubmitted: true,
        error: "",
        isLoading: true
      });

      const createPromise = put("/login", {
        action: "forgot",
        email: this.state.email
      });
      createPromise.promise
        .then(response => {
          this.setState({
            isLoading: false,
            success: true,
            data: response
          });
        })
        .catch(error => {
          this.setState({
            error: error,
            isLoading: false
          });
        });

      this.subscribedPromises.push(createPromise);
    }
  }

  renderForgotPasswordForm() {
    const { email, emailValid, success, error, formSubmitted } = this.state;
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="mt-sm mb-md text-center">
          <h2>Reset your password</h2>
          <p>We&#x27;ll email you instructions to set your password.</p>
          <hr />
        </div>
        <FieldGroup
          label="Email address"
          type="email"
          value={email}
          onChange={this.handleEmailChange}
          validationState={formSubmitted && !emailValid ? "error" : null}
          placeholder="Enter email"
          help={
            <small>
              This should be the email address associated with your account.
            </small>
          }
          autoFocus
        />
        {error && this.renderError()}
        {success && (
          <Alert bsStyle="success">
            <h4>Success!</h4>
            <p>
              Please follow the instructions sent to the email you provided to
              reset your password.
            </p>
          </Alert>
        )}
        <Button type="submit" bsStyle="primary" block>
          Send Instructions
        </Button>
        <hr />
        <div className="mt-sm mb-sm text-center mt-xs mb-xs">
          <h5 className="text-center">
            <Link to="/login">Login</Link>
          </h5>
          <p className="sub-text">Contact Support at team@bookflow.pub</p>
        </div>
      </form>
    );
  }

  render() {
    const { loggedIn, isLoading } = this.state;
    if (loggedIn) {
      let { from } = this.props.location.state || {
        from: { pathname: "/" }
      };
      return <Redirect to={from} />;
    }

    if (isLoading) return <Loader />;

    return (
      <Grid fluid className="mt-lg mb-lg">
        <Row className="ForgotPassword">
          <Col xs={12} sm={6} smOffset={3} md={4} mdOffset={4}>
            <div className="mb-md text-center">
              <a href="http://about.bookflow.pub">
                <Image src="/bookflow-logo.png" width="300px" />
              </a>
            </div>
            <div className="card card-neutral">
              {this.renderForgotPasswordForm()}
            </div>
            <ul className="list-inline text-center">
              <li>
                <a href="http://about.bookflow.pub">About</a>
              </li>
              <li>&copy; Bookflow 2021</li>
            </ul>
          </Col>
        </Row>
      </Grid>
    );
  }

  renderError() {
    const { error } = this.state;

    let message = "";
    if (error.status === 410) {
      error.statusText = "Account cannot be found.";
      message =
        "Please contact support at team@bookflow.pub about your account.";
    }

    return <ErrorHandler error={error} message={message} />;
  }
}

export default ForgotPassword;
