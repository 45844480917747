import React, { Component, Fragment } from "react";
import { Redirect, NavLink } from "react-router-dom";
import moment from "moment";
import {
  Grid,
  Col,
  Row,
  Label,
  Tabs,
  Tab,
  Button,
  OverlayTrigger,
  Tooltip,
  Panel,
  Badge
} from "react-bootstrap";
import debounce from "lodash/debounce";
import snakeCase from "lodash/snakeCase";
import remove from "lodash/remove";
import unionBy from "lodash/unionBy";
import { Link } from "react-router-dom";

import { get, put, download } from "../../../utils/DeApi";
import {
  publish,
  subscribe,
  unsubscribe,
  presence
} from "../../../utils/Broadcast";

import "./Character.css";
import NoteList from "../NoteList/NoteList";
import CharacterCreate from "../CharacterCreate/CharacterCreate";
import CharacterEdit from "../CharacterEdit/CharacterEdit";
import CharacterDelete from "../CharacterDelete/CharacterDelete";
import RichTextEditor from "../../RichTextEditor/RichTextEditor";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import ReAuth from "../../App/ReAuth/ReAuth";
import PageVisibility from "../../App/PageVisibility/PageVisibility";
import { FocusModeContext } from "../FocusModeContext/FocusModeContext";

/**
 * Handles rendering of writing view for a character
 */
class Character extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
      reAuthenticate: false,
      syncing: false,
      isMobile: /mobi/i.test(navigator.userAgent.toLowerCase()),
      connections: [],

      matchCase: true,
      wholeWord: true,
      activePillarSelections: [],
      wordMatch: [],
      characterMatchCount: 0
    };

    this.handleCharacterCreated = this._handleCharacterCreated.bind(this);
    this.handleCharacterDeleted = this._handleCharacterDeleted.bind(this);
    this.handleCharacterUpdated = this._handleCharacterUpdated.bind(this);
    this.handleReauthenticated = this._handleReauthenticated.bind(this);
    this.handleCharacterExport = this._handleCharacterExport.bind(this);
    this.handleRichTextUpdated = this._handleRichTextUpdated.bind(this);
    this.handleCharacterEditing = this._handleCharacterEditing.bind(this);
    this.findReplaceStatus = this._findReplaceStatus.bind(this);

    this.handleVisibilityChange = debounce(
      visibilty => {
        this._handleVisibilityChange(visibilty);
      },
      60000,
      {
        leading: true,
        trailing: false
      }
    );

    this.handleCharacterBodyUpdated = debounce(
      (body, success, error) => {
        this.updateCharacterBody(body, success, error);
      },
      1000,
      {
        maxWait: 10000
      }
    );

    this.handleCharacterStatsUpdated = debounce((stats, success, error) => {
      this.updateCharacterStats(stats, success, error);
    }, 1000);

    this.handleSearchWordMatch = debounce((params, success, error) => {
      this.searchWordMatch(params, success, error);
    }, 300);
  }

  _handleCharacterDeleted() {
    this.setState({ deleted: true });
  }

  _handleCharacterCreated(character) {
    this.setState(function(prevState, props) {
      prevState.characters.push(character);
      return {
        themes: prevState.characters
      };
    });
  }

  _handleCharacterUpdated(character) {
    this.setState(function(prevState, props) {
      return {
        characters: prevState.characters.map(item => {
          if (item.id === character.id) return character;
          else return item;
        }),
        character: character
      };
    });
  }

  _handleRichTextUpdated(body, successCallback, errorCallback) {
    const { character, connections } = this.state;
    const channel = `Character.${character.id}`;

    if (connections.length > 1) {
      publish(channel, "typing", { status: true });
      this.handleCharacterBodyUpdated(
        body,
        response => {
          publish(channel, "typing", { status: false });
          successCallback(response);
        },
        error => {
          publish(channel, "typing", { status: false });
          errorCallback(error);
        }
      );
    } else {
      this.handleCharacterBodyUpdated(body, successCallback, errorCallback);
    }
  }

  _handleReauthenticated() {
    this.setState({ reAuthenticate: false });
  }

  _handleCharacterExport() {
    const { project } = this.props;
    const { character } = this.state;

    let exportPromise = download(`/manuscript/${project.id}/characters`, {
      params: {
        characters: [character.id]
      }
    });

    const fileName = snakeCase(character.title) + ".docx";

    exportPromise.promise
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute("download", fileName);
        document.body.appendChild(link);

        link.click();
        // success
      })
      .catch(error => {
        // error
      });
    this.subscribedPromises.push(exportPromise);
  }

  _handleCharacterEditing(editing) {
    this.setState({ editing: editing });
  }

  _handleVisibilityChange(visibilty) {
    const { editing, character } = this.state;
    if (
      moment().diff(moment.utc(character.updatedAt), "minutes") > 30 &&
      visibilty &&
      !editing
    ) {
      this.setState({ character: "", characters: [] });
      this.fetchCharacter();
      this.fetchCharacters();
      this.fetchCharacterTypes();
    }
  }

  _findReplaceStatus(status) {
    this.setState({
      activePillarSelections: status.activePillarSelections,
      characterMatchCount: status.activePillarSelections.length > 0 ? 1 : 0,
      isOpen: status.isOpen,
      wordMatch: []
    });

    if (status.isOpen && status.find && status.find.length > 0) {
      this.handleSearchWordMatch({
        query: status.find,
        matchCase: status.matchCase,
        wholeWord: status.wholeWord,
        pillar: "character"
      });
    }
  }

  componentDidMount() {
    this.fetchCharacter();
    this.fetchCharacters();
    this.fetchCharacterTypes();
    this.registerBroadcasting();
  }

  componentDidUpdate(prevProps) {
    if (this.props.characterId !== prevProps.characterId) {
      this.setState({
        character: "",
        characters: [],
        error: ""
      });
      this.fetchCharacter();
      this.fetchCharacters();
      this.fetchCharacterTypes();
    }
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
    this.handleCharacterBodyUpdated.cancel();
    unsubscribe(`Character.${this.props.characterId}`);
  }

  registerBroadcasting() {
    let channel = `Character.${this.props.characterId}`;
    subscribe(channel, "typing", event => {
      if (event.status) {
        this.setState({ syncing: true });
      } else {
        this.setState({ character: "", syncing: false });
        this.fetchCharacter();
      }
    });
    presence(channel)
      .here(connections => {
        this.setState({ connections: connections || [] });
      })
      .joining(connection => {
        this.setState((state, props) => {
          return {
            connections: unionBy(state.connections, [connection], "name") || []
          };
        });
      })
      .leaving(connection => {
        this.setState((state, props) => {
          return {
            connections: remove(state.connections, item => {
              return connection.name !== item.name;
            })
          };
        });
      });
  }

  fetchCharacter() {
    let characterPromise = get("/characters/" + this.props.characterId);
    characterPromise.promise
      .then(response => {
        this.setState({
          character: response.data,
          error: ""
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error
          });
      });
    this.subscribedPromises.push(characterPromise);
  }

  fetchCharacters() {
    let charactersPromise = get("/characters?project=" + this.props.project.id);
    charactersPromise.promise
      .then(response => {
        this.setState({
          characters: response.data,
          error: ""
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error
          });
      });
    this.subscribedPromises.push(charactersPromise);
  }

  fetchCharacterTypes() {
    let characterTypesPromise = get("/character-types");

    characterTypesPromise.promise
      .then(response => {
        this.setState({
          characterTypes: response.data
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error
          });
      });
    this.subscribedPromises.push(characterTypesPromise);
  }

  searchWordMatch = params => {
    this.setState({ wordMatchLoading: true });
    let charactersPromise = get(
      `/projects/${this.props.project.id}/pillar-search`,
      {
        params: params
      }
    );
    charactersPromise.promise
      .then(response => {
        const wordMatch = response.data;
        let count = this.state.characterMatchCount;
        wordMatch.forEach(item => {
          if (item.match > 0 && item.characterId !== this.props.characterId)
            count++;
        });

        this.setState({
          wordMatch: wordMatch,
          characterMatchCount: count,
          wordMatchLoading: false,
          matchError: ""
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            wordMatchLoading: false,
            matchError: error
          });
      });

    this.subscribedPromises.push(charactersPromise);
  };

  updateCharacterBody(body, successCallback, errorCallback) {
    const { character } = this.state;
    let updateBodyPromise = put("/characters/" + character.id + "/body", {
      body: JSON.stringify(body)
    });

    this.setState({ reAuthenticate: false });
    updateBodyPromise.promise
      .then(response => {
        this.setState(
          { reAuthenticate: false, character: response.data },
          () => {
            successCallback(response);
          }
        );
      })
      .catch(error => {
        if (!error.isCanceled) {
          if (error.status === 401) this.setState({ reAuthenticate: true });
          errorCallback(error);
        }
      });
    this.subscribedPromises.push(updateBodyPromise);
  }

  updateCharacterStats(stats, successCallback, errorCallback) {
    if (stats) {
      let updateStatsPromise = put(
        "/characters/" + this.state.character.id + "/stats",
        stats
      );
      this.setState({ reAuthenticate: false });
      updateStatsPromise.promise
        .then(response => {
          successCallback(response);
        })
        .catch(error => {
          if (!error.isCanceled) {
            if (error.status === 401) this.setState({ reAuthenticate: true });
            errorCallback(error);
          }
        });
      this.subscribedPromises.push(updateStatsPromise);
    }
  }

  render() {
    const {
      deleted,
      error,
      character,
      characters,
      characterTypes,
      syncing,
      reAuthenticate,
      isMobile,

      isOpen,
      characterMatchCount,
      wordMatchLoading
    } = this.state;
    const { project } = this.props;

    if (deleted)
      return <Redirect to={"/projects/" + project.id + "/characters"} />;
    if (error) return <ErrorHandler error={error} />;
    if (!character || !characters) return <Loader />;

    let previous = characters[parseInt(character.orderId) - 2];
    let next = characters[parseInt(character.orderId)];
    return (
      <FocusModeContext.Consumer>
        {({ isFocused }) => (
          <Grid fluid className="PillarView">
            <Row className="PillarCanvas">
              <Panel
                id="collapsible-panel-example-2"
                expanded={this.state.toggle}
                onToggle={() => {}}
                className="mb-0 hidden-md hidden-lg"
              >
                <Panel.Heading>
                  <div className="panel-title">
                    <Row>
                      <Col xs={2}>
                        {previous ? (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip">
                                Go to previous character
                              </Tooltip>
                            }
                          >
                            <Link
                              className="pull-left text-left"
                              style={{ width: 40 }}
                              to={`/projects/${project.id}/characters/${previous.id}`}
                            >
                              <i className="material-icons md-24">
                                arrow_backward
                              </i>
                            </Link>
                          </OverlayTrigger>
                        ) : (
                          <span />
                        )}
                      </Col>
                      <Col
                        xs={8}
                        onClick={() =>
                          this.setState({
                            toggle: !this.state.toggle
                          })
                        }
                      >
                        <h4 className="pt-0 pb-0 mb-0 text-center">
                          {character.title}
                        </h4>
                      </Col>
                      <Col xs={2}>
                        {next ? (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip">
                                Go to next character
                              </Tooltip>
                            }
                          >
                            <Link
                              className="pull-right text-right"
                              style={{ width: 40 }}
                              to={`/projects/${project.id}/characters/${next.id}`}
                            >
                              <i className="material-icons md-24">
                                arrow_forward
                              </i>
                            </Link>
                          </OverlayTrigger>
                        ) : (
                          <span />
                        )}
                      </Col>
                    </Row>
                  </div>
                </Panel.Heading>
                <Panel.Collapse>
                  <Panel.Body>
                    <p className="text-right">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-edit">
                            Edit title and summary
                          </Tooltip>
                        }
                      >
                        <span>
                          <CharacterEdit
                            character={character}
                            characterTypes={characterTypes}
                            onCharacterUpdated={this.handleCharacterUpdated}
                            onCharacterEditing={this.handleCharacterEditing}
                          />
                        </span>
                      </OverlayTrigger>
                    </p>
                    {character.description ? (
                      <p className="PillarDescription mt-xs mb-sm">
                        {character.description}
                      </p>
                    ) : (
                      ""
                    )}
                    <ul className="list-inline">
                      <li>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-export">
                              Export this character
                            </Tooltip>
                          }
                        >
                          <Button
                            className="btn-secondary"
                            bsSize="xs"
                            onClick={this.handleCharacterExport}
                          >
                            Export
                          </Button>
                        </OverlayTrigger>
                      </li>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-delete">
                            Delete this character
                          </Tooltip>
                        }
                      >
                        <li>
                          <CharacterDelete
                            character={character}
                            onCharacterDeleted={this.handleCharacterDeleted}
                          />
                        </li>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-new">
                            Create a new character
                          </Tooltip>
                        }
                      >
                        <li>
                          <CharacterCreate
                            project={project}
                            onCharacterCreated={this.handleCharacterCreated}
                            label="New"
                            className="btn-secondary btn-xs"
                          />
                        </li>
                      </OverlayTrigger>
                    </ul>
                  </Panel.Body>
                </Panel.Collapse>
              </Panel>
              <Col xs={12} md={3} className="PillarLeft hidden-xs hidden-sm">
                <div className={`mt-md mb-md ${isFocused ? "hidden" : ""}`}>
                  <h4 className="pt-xs pb-xs">{character.title}</h4>

                  <Tabs defaultActiveKey={1} id="characters-meta-data-tabs">
                    <Tab eventKey={1} title="Summary">
                      <p className="text-right">
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-edit">
                              Edit title, type, and summary
                            </Tooltip>
                          }
                        >
                          <span>
                            <CharacterEdit
                              character={character}
                              characterTypes={characterTypes}
                              onCharacterUpdated={this.handleCharacterUpdated}
                              onCharacterEditing={this.handleCharacterEditing}
                              bsStyle="default"
                              bsSize="xs"
                            />
                          </span>
                        </OverlayTrigger>
                      </p>
                      {character.description ? (
                        <p className="PillarDescription mt-xs mb-sm">
                          {character.description}
                        </p>
                      ) : (
                        <p className="sub-text mt-xs mb-sm">...</p>
                      )}
                      <Label bsStyle="default" className="tag text-capitalize">
                        {character.characterType.name}
                      </Label>
                    </Tab>
                    <Tab
                      eventKey={2}
                      title={
                        <span>
                          All Characters+{" "}
                          {isOpen && (
                            <Fragment>
                              {wordMatchLoading ? (
                                <div class="find-loader"></div>
                              ) : (
                                <Fragment>
                                  {characterMatchCount > 0 && (
                                    <Badge>{characterMatchCount}</Badge>
                                  )}
                                </Fragment>
                              )}
                            </Fragment>
                          )}
                        </span>
                      }
                    >
                      {this.renderCharacters()}
                    </Tab>
                  </Tabs>

                  <div className="mt-sm mb-sm">
                    <ul className="list-inline">
                      <li>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-export">
                              Export this character+
                            </Tooltip>
                          }
                        >
                          <Button
                            className="btn-secondary"
                            bsSize="xs"
                            onClick={this.handleCharacterExport}
                          >
                            Export
                          </Button>
                        </OverlayTrigger>
                      </li>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-delete">
                            Delete this character+
                          </Tooltip>
                        }
                      >
                        <li>
                          <CharacterDelete
                            character={character}
                            onCharacterDeleted={this.handleCharacterDeleted}
                          />
                        </li>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-new">
                            Create a new character+
                          </Tooltip>
                        }
                      >
                        <li>
                          <CharacterCreate
                            project={project}
                            onCharacterCreated={this.handleCharacterCreated}
                            label="New"
                            className="btn-secondary btn-xs"
                          />
                        </li>
                      </OverlayTrigger>
                    </ul>
                    <ul className="list-inline">
                      <li>
                        {previous ? (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip-previous">
                                Go to previous character+
                              </Tooltip>
                            }
                          >
                            <Button
                              className="btn-secondary"
                              bsSize="xs"
                              componentClass={Link}
                              to={`/projects/${project.id}/characters/${previous.id}`}
                              href={`/projects/${project.id}/characters/${previous.id}`}
                            >
                              Previous
                            </Button>
                          </OverlayTrigger>
                        ) : (
                          <Button
                            disabled
                            bsSize="xs"
                            className="btn-secondary"
                          >
                            Previous
                          </Button>
                        )}
                      </li>
                      <li>
                        {next ? (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip-next">
                                Go to next character+
                              </Tooltip>
                            }
                          >
                            <Button
                              className="btn-secondary"
                              bsSize="xs"
                              componentClass={Link}
                              to={`/projects/${project.id}/characters/${next.id}`}
                              href={`/projects/${project.id}/characters/${next.id}`}
                            >
                              Next
                            </Button>
                          </OverlayTrigger>
                        ) : (
                          <Button
                            disabled
                            bsSize="xs"
                            className="btn-secondary"
                          >
                            Next
                          </Button>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6} className="PillarCenter">
                <div className={!isMobile ? "mt-md mb-xl" : ""}>
                  <Row className="PillarEditor">
                    <Col xs={12}>
                      <RichTextEditor
                        richText={character.body}
                        controls={!isMobile}
                        counter={true}
                        focusMode={true}
                        findReplaceStatus={this.findReplaceStatus}
                        onRichTextUpdated={this.handleRichTextUpdated}
                        onStatsUpdated={this.handleCharacterStatsUpdated}
                        placeholder="Explore your character..."
                        syncing={syncing}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xs={12} md={3} className="PillarRight">
                <div className={`mt-md mb-xl ${isFocused ? "hidden" : ""}`}>
                  <NoteList pillarId={character.id} pillarType="character" />
                </div>
              </Col>
              {!!reAuthenticate && (
                <ReAuth onReauthenticated={this.handleReauthenticated} />
              )}
              <PageVisibility
                onVisibilityChange={this.handleVisibilityChange}
              />
            </Row>
          </Grid>
        )}
      </FocusModeContext.Consumer>
    );
  }

  renderCharacters() {
    const { project } = this.props;
    const {
      characters,
      activePillarSelections,
      isOpen,
      wordMatch
    } = this.state;

    return (
      <div className="list-group numbered-list-group styled">
        {characters.map((character, index) => {
          return (
            <NavLink
              key={character.id}
              to={"/projects/" + project.id + "/characters/" + character.id}
              activeClassName="active"
              className="list-group-item"
            >
              {(isOpen && wordMatch.length > 0) ||
              (activePillarSelections &&
                isOpen &&
                activePillarSelections.length > 0) ? (
                <Fragment>
                  {character.title && character.title.length > 28
                    ? character.title.slice(0, 25) + "..."
                    : character.title}
                </Fragment>
              ) : (
                character.title
              )}

              {this.props.characterId === character.id ? (
                <Fragment>
                  {activePillarSelections &&
                    isOpen &&
                    activePillarSelections.length > 0 && (
                      <Badge className="pull-right">
                        {activePillarSelections.length}
                      </Badge>
                    )}
                </Fragment>
              ) : (
                <Fragment>
                  {wordMatch &&
                    isOpen &&
                    wordMatch.length > 0 &&
                    wordMatch.map((item, index) => {
                      if (item.characterId === character.id && item.match > 0)
                        return (
                          <Badge key={index} className="pull-right">
                            {item.match}
                          </Badge>
                        );
                      else return <Fragment key={index} />;
                    })}
                </Fragment>
              )}
            </NavLink>
          );
        })}
      </div>
    );
  }
}

export default Character;
