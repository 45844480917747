import React, { Component, Fragment } from "react";
import { Elements } from "react-stripe-elements";
import { Button, Modal, Alert, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import Moment from "moment";

import { post, isLoggedIn } from "../../../utils/BeeApi";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import Card from "../Card/Card";
import Coupon from "../Coupon/Coupon";

class SubscriptionCreate extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};

    this.handleShowModal = this._handleShowModal.bind(this);
    this.handleCloseModal = this._handleCloseModal.bind(this);

    this.handleCCSubmitted = this._handleCCSubmitted.bind(this);
    this.handleCouponSubmitted = this._handleCouponSubmitted.bind(this);
  }

  _handleCloseModal() {
    this.setState({ show: false });
  }

  _handleShowModal() {
    this.setState({ show: true });
  }

  _handleCouponSubmitted(coupon) {
    this.setState({ coupon: coupon });
  }

  _handleCCSubmitted(token) {
    this.createSubscription(token);
  }

  createSubscription(token) {
    const { plan } = this.props;
    const { coupon } = this.state;

    this.setState({ isLoading: true, error: "" });
    const createPromise = post("/subscriptions", {
      planId: plan.planId,
      stripeToken: token.id,
      coupon: coupon && coupon.couponId
    });
    createPromise.promise
      .then((response) => {
        this.setState({
          subscription: response.data,
          error: "",
          isLoading: false
        });
      })
      .catch((error) => {
        !error.isCanceled &&
          this.setState({
            error: error,
            isLoading: false
          });
      });
    this.subscribedPromises.push(createPromise);
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  render() {
    const { isLoading, subscription, show } = this.state;
    const { plan } = this.props;
    return (
      <div className="SubscriptionCreate">
        <Button bsStyle="primary" bsSize="sm" onClick={this.handleShowModal}>
          Subscribe
        </Button>
        <Modal
          show={show}
          onHide={this.handleCloseModal}
          backdrop="static"
          bsSize={"lg"}
        >
          <Modal.Header closeButton={!subscription}>
            <Modal.Title className="mt-0 text-capitalize text-center">
              {`Subscribe to ${plan.nickname} Plan Now`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isLoading ? (
              <Loader />
            ) : subscription ? (
              this.renderSuccess()
            ) : (
              this.renderCheckout()
            )}{" "}
          </Modal.Body>
        </Modal>
      </div>
    );
  }

  renderSuccess() {
    const { plan, onSubscribing } = this.props;
    const { coupon, subscription } = this.state;

    const planSavings = coupon
      ? coupon.amountOff
        ? coupon.amountOff
        : (coupon.percentOff * plan.amount) / 100
      : 0;
    const planTotal = planSavings ? plan.amount - planSavings : plan.amount;

    return (
      <Fragment>
        <Alert
          bsStyle="success"
          className="text-center SubscriptionCreateSuccess"
        >
          <h3 className="tet-uppercase mb-0">
            <strong>Success!</strong>
          </h3>
          <hr className="mt-xs mb-xs" />
          <h5>
            {`You are on the ${
              plan.nickname
            } Plan. You will be charged a total of $${planTotal /
              100} on ${Moment(subscription.billingCycleAnchor).format("LL")}.`}
          </h5>
          {!isLoggedIn().isSubscribed && (
            <h5>
              Please login again so that your subscription status is updated.
            </h5>
          )}
        </Alert>

        {!isLoggedIn().isSubscribed ? (
          <Button
            componentClass={Link}
            to="/logout"
            from="/account"
            bsStyle="primary"
            block
          >
            Login
          </Button>
        ) : (
          <Button
            bsStyle="primary"
            onClick={() => {
              onSubscribing(subscription);
            }}
            block
          >
            Close
          </Button>
        )}
      </Fragment>
    );
  }

  renderCheckout() {
    const { plan, subscriber } = this.props;
    const { error, coupon } = this.state;

    return (
      <Fragment>
        {error && <ErrorHandler error={error} />}
        <p className="text-center mt-sm">
          Please review your subscription summary and complete the form below to
          add a credit card.
        </p>
        <Row>
          <Col xs={12} sm={4} className="mt-sm mb-sm">
            <Coupon
              plan={plan}
              coupon={coupon}
              onCouponSubmitted={this.handleCouponSubmitted}
            />
          </Col>
          <Col xs={12} sm={8} className="mt-sm mb-sm">
            <h4 className="mb-xs">Add Payment Details</h4>
            <div className="card">
              <Elements>
                <Card
                  plan={plan}
                  subscriber={subscriber}
                  onCCSubmitted={this.handleCCSubmitted}
                />
              </Elements>
            </div>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default SubscriptionCreate;
