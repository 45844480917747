import React, { Component, Fragment } from "react";
import { Modal, Button, Alert, NavItem } from "react-bootstrap";
import "./Feedback.css";
import { post } from "../../../utils/BeeApi";
import { FieldGroup } from "../../../utils/BootstrapUtils";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class Feedback extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];

    this.state = {
      show: false,
      body: "",
      error: ""
    };

    this.handleShowModal = this._handleShowModal.bind(this);
    this.handleCloseModal = this._handleCloseModal.bind(this);
    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleInputChange = this._handleInputChange.bind(this);
  }

  _handleCloseModal() {
    this.setState({
      show: false,
      success: false,
      body: "",
      bodyError: "",
      error: ""
    });
  }

  _handleShowModal() {
    this.setState({ show: true });
  }

  _handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }

  _handleSubmit(event) {
    event.preventDefault();
    const { body } = this.state;
    const { user } = this.props;

    this.setState({
      error: "",
      bodyError: "",
      isLoading: true
    });

    if (!body) {
      this.setState({
        bodyError: true,
        isLoading: false
      });
      return;
    }

    let message = `${body} \r\n \r\n Page URL: ${window.location.href}`;
    const contactPromise = post("/forms", {
      name: user && user.firstName,
      email: user && user.email,
      subject: `Feedback - ${window.location.pathname.split("/")[1] ||
        "dashboard"}`,
      body: message
    });

    contactPromise.promise
      .then(data => {
        this.setState({
          success: true,
          isLoading: false
        });
      })
      .catch(error => {
        this.setState({
          error: error,
          isLoading: false
        });
      });
  }

  render() {
    const { body, bodyError, show, error, isLoading, success } = this.state;

    const form = (
      <form
        className="FeedbackForm"
        onSubmit={this.handleSubmit}
        autoComplete="off"
      >
        <Modal.Header closeButton>
          <Modal.Title>Send us your feedback!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Do you have a question, comment, or suggestion? Let us know.</p>
          <FieldGroup
            id="body"
            name="body"
            label=""
            componentClass="textarea"
            rows="5"
            onChange={this.handleInputChange}
            value={body}
            validationState={bodyError ? "error" : null}
            placeholder="Describe your experience here..."
            required
          />
          {error && <ErrorHandler error={error} />}
          {isLoading && <Loader error={isLoading} />}
          {success && (
            <Alert bsStyle="success" className="mt-md mb-md">
              <h4>Thanks</h4>
              <p>
                We appreciate that you’ve taken the time to help us improve
                Bookflow.
              </p>
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          {success ? (
            <Button bsStyle="success" onClick={this.handleCloseModal}>
              Close
            </Button>
          ) : (
            <span>
              <Button bsStyle="link" onClick={this.handleCloseModal}>
                Cancel
              </Button>
              <Button
                bsStyle="success"
                type="submit"
                onClick={this.handleSubmit}
              >
                Submit
              </Button>
            </span>
          )}
        </Modal.Footer>
      </form>
    );

    return (
      <Fragment>
        <NavItem className="Feedback" onClick={this.handleShowModal}>
          <i className="material-icons">comment</i>{" "}
          <span className="hidden-xs hidden-sm">Feedback</span>
        </NavItem>
        <Modal show={show} onHide={this.handleCloseModal} backdrop="static">
          {form}
        </Modal>
      </Fragment>
    );
  }
}

export default Feedback;
