import React, { Component } from "react";
import { Button, Label } from "react-bootstrap";
import Moment from "moment";
import { Link, Redirect } from "react-router-dom";
import TextExtractionUtil from "../../RichTextEditor/Controls/TextExtractionUtil";
import "./DailyPrompt.css";

import { get } from "../../../utils/BeeApi";
import { post, get as getDE } from "../../../utils/DeApi";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class DailyPrompt extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.subscribedPromises = [];
  }

  componentDidMount() {
    this.getPromptCategories();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  getPromptCategories() {
    this.setState({
      error: "",
      isLoading: true
    });

    const options = {
      group: this.props.group || "5c2bf09497053" //Prompts group
    };
    const categoriesPromise = get("/categories", { params: options });

    categoriesPromise.promise
      .then(response => {
        this.setState(
          {
            categories: response.data
          },
          () => this.getPrompts()
        );
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            isLoading: false,
            error: error
          });
      });

    this.subscribedPromises.push(categoriesPromise);
  }

  getPrompts() {
    this.setState({
      error: "",
      isLoading: true
    });
    const categories = this.state.categories || [];
    const categoryIds = categories.map(category => {
      return category.categoryId;
    });

    const options = {
      limit: 1,
      offset: 0,
      operand: "or",
      rank: Moment().format("YYYY-MM-DD HH:mm:ss"),
      category: categoryIds
    };
    const promptPromise = get("/contents", { params: options });

    promptPromise.promise
      .then(response => {
        this.setState(
          {
            dailyPrompt: response.data.length ? response.data[0] : null,
            error: "",
            isLoading: false
          },
          () => this.getPromptResponse()
        );
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            isLoading: false,
            error: error
          });
      });

    this.subscribedPromises.push(promptPromise);
  }

  getPromptResponse() {
    const dailyPrompt = this.state.dailyPrompt;
    if (!dailyPrompt) return;

    this.setState({
      responseError: "",
      responseIsLoading: true
    });

    const options = {
      contentIds: [dailyPrompt.contentId],
      limit: 20
    };
    const promptResponsePromise = getDE("/prompts", { params: options });

    promptResponsePromise.promise
      .then(response => {
        // @TODO us the api to fetch by contentId
        let promptResponse = response.data.find(item => {
          return item.contentId === dailyPrompt.contentId;
        });
        this.setState({
          promptResponse: promptResponse,
          responseError: "",
          responseIsLoading: false
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            responseError: error,
            responseIsLoading: false
          });
      });

    this.subscribedPromises.push(promptResponsePromise);
  }

  createPromptResponse() {
    this.setState({
      createResponseError: "",
      responseIsCreating: false
    });

    const { dailyPrompt } = this.state;
    const createResponsPromise = post("/prompts", {
      contentId: dailyPrompt.contentId
    });

    createResponsPromise.promise
      .then(response => {
        this.setState({
          createdPromptResponse: response.data,
          createResponseError: "",
          responseIsCreating: false
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            createResponseError: error,
            responseIsCreating: false
          });
      });

    this.subscribedPromises.push(createResponsPromise);
  }

  render() {
    const { isLoading, error, dailyPrompt, promptResponse } = this.state;

    if (error) return <ErrorHandler error={error} />;
    if (isLoading) return <Loader />;
    if (!dailyPrompt) return <div />;

    return (
      <div className="DailyPrompt">
        <div>
          <span className="pull-right">
            {dailyPrompt.categories.map((category, index) => {
              return (
                <Label
                  key={category.categoryId}
                  className="label-success tag ml-xs"
                >
                  {category.categoryName}
                </Label>
              );
            })}
          </span>
          {promptResponse ? (
            <h4>
              <Link
                to={`/prompts/${promptResponse.id}/${Moment(
                  prompt.rankDate
                ).format("YYYY-MM-DD")}`}
              >
                {dailyPrompt.title}
              </Link>
            </h4>
          ) : (
            <h4>{dailyPrompt.title}</h4>
          )}
        </div>
        {this.renderPromptResponse()}
      </div>
    );
  }

  renderPromptResponse() {
    const {
      promptResponse,
      responseError,
      responseIsLoading,
      createResponseError,
      responseIsCreating,
      createdPromptResponse
    } = this.state;

    if (createResponseError)
      return <ErrorHandler error={createResponseError} />;
    if (responseIsCreating) return <Loader />;
    if (createdPromptResponse) {
      return (
        <Redirect
          to={`/prompts/${createdPromptResponse.id}/${Moment(
            prompt.rankDate
          ).format("YYYY-MM-DD")}`}
          push
        />
      );
    }

    if (responseError) return <ErrorHandler error={responseError} />;
    if (responseIsLoading) return <p className="text-muted text-center">...</p>;

    if (!promptResponse) {
      return (
        <div className="pt-xs">
          <Button
            bsStyle="primary"
            bsSize="sm"
            onClick={() => this.createPromptResponse()}
            block
          >
            Take a look
          </Button>
        </div>
      );
    }

    let plainText = TextExtractionUtil.extractPlainText(
      promptResponse.body
    ).substring(0, 120);
    plainText =
      plainText.length > 123 ? plainText : plainText.substring(0, 120) + "...";

    return (
      <div>
        <p>
          <small>{plainText}</small>
        </p>
      </div>
    );
  }
}

export default DailyPrompt;
