import React, { Component, Fragment } from "react";
import { Button, Col, Row } from "react-bootstrap";
import "./NoteList.css";
import { get, post, put } from "../../../utils/DeApi";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import Loader from "../../Loader/Loader";

import DnDWrapper from "../DnDWrapper/DnDWrapper";
import Note from "../Note/Note";

class NoteList extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};

    this.handleNoteDeleted = this._handleNoteDeleted.bind(this);
    this.handleCreateNote = this._handleCreateNote.bind(this);
    this.handleNoteUpdated = this._handleNoteUpdated.bind(this);
    this.handleOrderChange = this._handleOrderChange.bind(this);
  }

  _handleCreateNote() {
    this.createNote();
  }

  _handleNoteDeleted() {
    this.fetchNotes();
  }

  componentDidMount() {
    this.fetchNotes();
  }

  _handleNoteUpdated(currNote) {
    const pillarId = this.props.pillarId;

    const notes = this.state.notes.map(note => {
      return currNote.id === note.id ? currNote : note;
    });
    this.setState({ notes: notes });
    window.localStorage.setItem(pillarId, JSON.stringify(notes));
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });

    window.localStorage.removeItem(this.props.pillarId);
  }

  fetchNotes() {
    const pillarId = this.props.pillarId;
    const pillarType = this.props.pillarType;

    let notesPromise = get(
      `/notes?pillar=${pillarId}&pillarType=${pillarType}`
    );

    notesPromise.promise
      .then(response => {
        this.setState(
          {
            notes: response.data
          },
          () => {
            window.localStorage.setItem(
              pillarId,
              JSON.stringify(this.state.notes)
            );
          }
        );
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error
          });
      });

    this.subscribedPromises.push(notesPromise);
  }

  isJSONString(text) {
    return /^[\],:{}\s]*$/.test(
      text
        .replace(/\\["\\/bfnrtu]/g, "@")
        .replace(
          /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g,
          "]"
        )
        .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
    );
  }

  createNote() {
    const pillarId = this.props.pillarId;
    const pillarType = this.props.pillarType;

    this.setState({ isCreating: true, error: "" });

    const createPromise = post("/notes", {
      pillar: pillarId,
      pillarType: pillarType
    });
    createPromise.promise
      .then(response => {
        const note = response.data;
        this.setState(function(prevState, props) {
          prevState.notes.unshift(note);

          const pillarId = this.props.pillarId;
          const rawNotes = window.localStorage.getItem(pillarId);
          const notes = this.isJSONString(rawNotes) ? JSON.parse(rawNotes) : [];

          const orderedNotes = prevState.notes.map(lineItem => {
            let orderNote = notes.find(item => {
              return item.id === lineItem.id;
            });

            return !!orderNote ? orderNote : note;
          });

          this.setState({ notes: orderedNotes }, () => {
            window.localStorage.setItem(
              pillarId,
              JSON.stringify(this.state.notes)
            );
          });

          return {
            notes: orderedNotes,
            activeNote: note.id,
            isCreating: false,
            error: ""
          };
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            isCreating: false,
            error: error
          });
      });
    this.subscribedPromises.push(createPromise);
  }

  render() {
    const { notes, isCreating, error } = this.state;

    if (!notes) return <Loader />;

    return (
      <div>
        <h4>
          <span className="pull-right">
            <Button
              bsStyle="primary"
              bsSize="xs"
              className="pl-xs pr-xs"
              onClick={this.handleCreateNote}
            >
              New Note
            </Button>
          </span>
          Notes
        </h4>
        {isCreating && <Loader />}
        {error && <ErrorHandler error={error} />}
        <div className="mt-md mb-md">
          {!!notes.length && (
            <DnDWrapper
              items={notes}
              onOrderChange={this.handleOrderChange}
              useDragHandle={true}
              renderItem={(note, index, dragHandle) => (
                <Fragment>
                  <Col xs={12} className="movable" key={index}>
                    <Row>
                      <Col xs={1} className="text-right">
                        {dragHandle && dragHandle}
                      </Col>
                      <Col xs={11}>
                        <Note
                          note={note}
                          onNoteUpdated={this.handleNoteUpdated}
                          onNoteDeleted={this.handleNoteDeleted}
                          focused={this.state.activeNote === note.id}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Fragment>
              )}
              dragHandle={true}
            />
          )}
        </div>
      </div>
    );
  }

  _handleOrderChange(item, newIndex, newList) {
    const pillarId = this.props.pillarId;
    const rawNotes = window.localStorage.getItem(pillarId);
    const notes = this.isJSONString(rawNotes) ? JSON.parse(rawNotes) : [];

    const orderedNotes = newList.map(lineItem => {
      let orderNote = notes.find(item => {
        return item.id === lineItem.id;
      });

      return {
        ...orderNote,
        orderId: lineItem.orderId
      };
    });

    this.setState({ notes: orderedNotes }, () => {
      window.localStorage.setItem(pillarId, JSON.stringify(this.state.notes));
    });

    this.reOrderList(item, newIndex, orderedNotes);
  }

  reOrderList(item, newIndex) {
    this.setState({ error: "", activeNote: "" });
    let reOrderPromise = put("/notes/" + item.id + "/order?curr=" + newIndex);
    reOrderPromise.promise
      .then(response => {
        this.setState({ error: "" });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error
          });
      });
    this.subscribedPromises.push(reOrderPromise);
  }
}

export default NoteList;
