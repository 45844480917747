import React, { Component } from "react";
import { Button, Table } from "react-bootstrap";
import moment from "moment";
import "./ProjectImportList.css";

import { get } from "../../../utils/DeApi";

import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import Loader from "../../Loader/Loader";

/**
 * Handles rendering of list of projects
 */
class ProjectImportList extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};

    this.handleProjectImportSelected = this._handleProjectImportSelected.bind(
      this
    );
  }

  _handleProjectImportSelected(projectImport) {
    const { onProjectImportSelected } = this.props;
    if (onProjectImportSelected) return onProjectImportSelected(projectImport);
  }

  componentDidMount() {
    this.fetchProjectImports();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  fetchProjectImports() {
    let projectsPromise = get("/project-imports", {
      params: { created: 0 }
    });

    this.setState({ isLoading: true });
    projectsPromise.promise
      .then(response => {
        this.setState({
          projectImports: response.data,
          isLoading: false,
          error: ""
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            isLoading: false,
            error: error
          });
      });

    this.subscribedPromises.push(projectsPromise);
  }

  render() {
    const { projectImports, isLoading, error } = this.state;

    if (isLoading) return <Loader />;
    if (error) return <ErrorHandler error={error} />;

    return projectImports && projectImports.length ? (
      <div className="mt-sm mb-sm">
        <hr />
        <h4>Incomplete Imports</h4>
        <h5>
          Please review your incomplete imports below to complete or discard
          importing the project.
        </h5>
        <div className="table-responsive">
          <Table hover>
            <tbody>
              {projectImports.map(projectImport => {
                return (
                  <tr key={projectImport.id}>
                    <td>{projectImport.projectName}</td>
                    <td>
                      <small>
                        Imported{" "}
                        {moment
                          .utc(projectImport.updatedAt)
                          .local()
                          .calendar()}
                      </small>
                    </td>
                    <td>
                      <Button
                        bsStyle="default"
                        bsSize="sm"
                        className="pull-right ml-xs"
                        onClick={() =>
                          this.handleProjectImportSelected(projectImport)
                        }
                      >
                        Review
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    ) : (
      <div />
    );
  }
}

export default ProjectImportList;
