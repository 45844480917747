import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Moment from "moment";

import "./PromptList.css";

import { get } from "../../../utils/BeeApi";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import PromptItem from "../PromptItem/PromptItem";

class PromptList extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};

    this.handleBookend = this._handleBookend.bind(this);
  }

  componentDidMount() {
    this.fetchPromptsCategories();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  componentDidUpdate(prevProps) {
    const { rank } = this.props;
    const prevRank = prevProps.rank;

    if (rank !== prevRank) {
      if (Moment(rank).month() !== Moment(prevRank).month()) {
        this.setState(
          {
            prompts: []
          },
          () => this.fetchPrompts()
        );
      }
    }
  }

  fetchPromptsCategories() {
    this.setState({
      error: "",
      isLoading: true
    });

    const categoriesPromise = get("/categories", {
      params: {
        group: this.props.group || "5c2bf09497053" //Prompts group
      }
    });

    categoriesPromise.promise
      .then(response => {
        this.setState(
          {
            categories: response.data
          },
          () => this.fetchPrompts()
        );
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            isLoading: false,
            error: error
          });
      });

    this.subscribedPromises.push(categoriesPromise);
  }

  fetchPrompts() {
    this.setState({
      error: "",
      isLoading: true
    });

    const { limit, offset, rank } = this.props;
    const categories = this.state.categories || [];

    let categoryIds = categories.map(category => {
      return category.categoryId;
    });

    const promptsPromise = get("/contents", {
      params: {
        limit: limit || 5,
        offset: offset || 0,
        operand: "or",
        rank:
          Moment(rank).format("YYYY-MM-DD HH:mm:ss") ||
          Moment().format("YYYY-MM-DD HH:mm:ss"),
        category: categoryIds
      }
    });
    promptsPromise.promise
      .then(response => {
        this.setState({
          prompts: response.data,
          error: "",
          isLoading: false
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            isLoading: false,
            error: error
          });
      });

    this.subscribedPromises.push(promptsPromise);
  }

  expandPrompts(offset) {
    this.setState({
      expandingError: "",
      isExpanding: true
    });

    const { limit, rank } = this.props;
    const categories = this.state.categories || [];

    let categoryIds = categories.map(category => {
      return category.categoryId;
    });

    const promptsPromise = get("/contents", {
      params: {
        limit: limit || 5,
        offset: offset || 0,
        operand: "or",
        rank:
          Moment(rank).format("YYYY-MM-DD HH:mm:ss") ||
          Moment().format("YYYY-MM-DD HH:mm:ss"),
        category: categoryIds
      }
    });
    promptsPromise.promise
      .then(response => {
        this.setState(prevState => {
          return {
            prompts: prevState.prompts
              ? prevState.prompts.concat(...response.data)
              : response.data,
            expandingError: "",
            isExpanding: false
          };
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            isExpanding: false,
            expandingError: error
          });
      });

    this.subscribedPromises.push(promptsPromise);
  }

  render() {
    const { isLoading, error, prompts } = this.state;
    const { bookend, selectedDate } = this.props;

    if (error) return <ErrorHandler error={error} />;
    if (isLoading) return <Loader />;
    if (!prompts || !prompts.length) return <div />;

    return (
      <div>
        {prompts.map((item, index) => {
          return (
            <div key={item.contentId}>
              <PromptItem prompt={item} />
              <hr />
            </div>
          );
        })}
        {bookend && this.renderBookend()}
        {!!selectedDate && this.scrollIntoView(selectedDate)}
      </div>
    );
  }

  scrollIntoView(date) {
    if (!date) return;
    const scrollPosition = `prompt-of-${Moment(date)
      .startOf("day")
      .add(1, "days")
      .valueOf()}`;

    const element = document.getElementById(scrollPosition);

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    } else {
      const topOfPage = document.getElementsByClassName("PromptDashboard")[0];
      if (topOfPage) {
        topOfPage.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest"
        });
      }
    }
  }

  _handleBookend() {
    const { prompts } = this.state;
    let offset = this.props.offset
      ? this.props.offset + prompts.length
      : prompts.length;
    this.expandPrompts(offset);
  }

  renderBookend() {
    const { isExpanding, expandingError, prompts } = this.state;
    const { bookend, limit } = this.props;

    if (expandingError) return <ErrorHandler error={expandingError} />;
    if (isExpanding) return <Loader />;
    if (prompts.length < 0 && !bookend) return <div />;

    return (
      prompts.length % limit === 0 && (
        <div className="text-center mt-lg mb-lg">
          <Button onClick={this.handleBookend}>Show more prompts</Button>
        </div>
      )
    );
  }
}

export default PromptList;
