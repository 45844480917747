import React, { Component, Fragment } from "react";
import { Row, Col, Label } from "react-bootstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import "./ProjectSummary.css";

import ProjectProgress from "../ProjectProgress/ProjectProgress";
import RewardSharer from "../ProjectRewards/RewardSharer/RewardSharer";

import { get } from "../../../utils/DeApi";
import { get as getDE } from "../../../utils/DeApi";

/**
 * Handles rendering of list of projects
 */
class ProjectSummary extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};
  }

  componentDidMount() {
    // this.fetchProjectStats();
    // this.fetchProjectRewards();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  fetchProjectStats() {
    let statsPromise = get("/projects/" + this.props.project.id + "/stats");

    statsPromise.promise
      .then(response => {
        this.setState({
          stats: response.data
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error
          });
      });
    this.subscribedPromises.push(statsPromise);
  }

  fetchProjectRewards() {
    const { project } = this.props;

    this.setState({
      isLoading: true,
      error: ""
    });

    const projectRewardsPromise = getDE("/projects/" + project.id + "/rewards");
    projectRewardsPromise.promise
      .then(response => {
        let rewards = response.data || [];
        this.setState({
          rewards: rewards.reverse(),
          isLoading: false,
          error: ""
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            isLoading: false,
            error: error
          });
      });
    this.subscribedPromises.push(projectRewardsPromise);
  }

  render() {
    const { project } = this.props;
    const { stats, rewards } = this.state;

    return (
      <Row className="ProjectSummary">
        <Col xs={9}>
          <h4>
            <Link to={"/projects/" + project.id}>{project.name}</Link>
          </h4>
          <p className="sub-text">
            Updated{" "}
            {moment
              .utc(project.updatedAt)
              .local()
              .calendar()}
          </p>
          {/* <p className="mb-0">
            <span>{stats && this.renderStats()}</span>
            <span>{rewards && this.renderRewards()}</span>
          </p> */}
        </Col>
        <Col xs={3}>
          <div className="pull-right">{stats && this.renderProgress()}</div>
        </Col>
      </Row>
    );
  }

  renderStats() {
    const { stats } = this.state;
    const words = stats.sceneStats.reduce(function(memo, sceneStats) {
      return memo + (sceneStats.words || 0);
    }, 0);
    return (
      <Label className="mr-xs">
        {new Intl.NumberFormat().format(words)} words
      </Label>
    );
  }

  renderProgress() {
    const { project } = this.props;
    const { stats } = this.state;
    const goal = project.words;

    if (!goal) return <div />;

    return <ProjectProgress project={project} stats={stats} />;
  }

  renderRewards() {
    const { rewards, showReward } = this.state;
    if (!rewards) return <span />;

    return (
      <Fragment>
        {rewards.map(reward => (
          <span key={reward.id} className="ProjectReward">
            <button
              className="btn btn-link btn-sm"
              onClick={() => this.setState({ showReward: reward.id })}
            >
              {reward.contentId === "5e986e587d1a6" ? (
                <i className="material-icons goal">stars</i>
              ) : (
                <i className="material-icons">stars</i>
              )}
            </button>
            <RewardSharer
              show={showReward === reward.id}
              contentId={reward.contentId}
              onHide={() => this.setState({ showReward: false })}
            />
          </span>
        ))}
      </Fragment>
    );
  }
}

export default ProjectSummary;
