import React, { Component } from "react";
import "./ContentCrafts.css";
import Moment from "moment";

import { get } from "../../../utils/BeeApi";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import { Link } from "react-router-dom";
import { slugify } from "../../../utils/StringUtils";

class ContentCrafts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentSummaryList: [],
      error: "",
      rank: Moment().format("YYYY-MM-DD HH:mm:ss")
    };

    this.subscribedPromises = [];
    this.handleBookend = this._handleBookend.bind(this);
  }

  componentDidMount() {
    this.getContentSummaryList();
  }

  getContentSummaryList() {
    this.setState({
      error: "",
      isLoading: true
    });
    const { category, limit, offset, rank, operand } = this.props;
    const options = {
      limit: limit || 4,
      offset: offset || 0,
      operand: operand || "or",
      category: category,
      rank: rank || null
    };

    const fetchCSLPromise = get("/contents", { params: options });
    fetchCSLPromise.promise
      .then(response => {
        let data = response.data || [];

        this.setState(prevState => {
          return {
            contentSummaryList: prevState.contentSummaryList.concat(...data),
            error: "",
            isLoading: false
          };
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            isLoading: false,
            error: error
          });
      });

    this.subscribedPromises.push(fetchCSLPromise);
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  render() {
    const { isLoading, error, contentSummaryList } = this.state;

    if (error) return <ErrorHandler error={error} />;
    if (isLoading) return <Loader />;

    return (
      <div className="ContentCrafts">
        {contentSummaryList.map((item, index) => {
          return this.renderItem(item);
        })}
      </div>
    );
  }

  renderItem(item) {
    return (
      <div key={item.contentId} className="mt-sm mb-sm">
        <Link
          className="hyperlink-3"
          to={"/writing-craft/" + item.contentId + "/" + slugify(item.title)}
        >
          <div className="img-wrapper">
            <img src={item.contentThumbnail} alt={item.title} />
          </div>
          <h4>{item.title}</h4>
        </Link>
        {/* <hr /> */}
      </div>
    );
  }

  _handleBookend() {
    this.options.offset = this.state.contentSummaryList.length;
    this.getContentSummaryList();
  }
}

export default ContentCrafts;
