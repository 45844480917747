import React, { Component } from "react";
import {
  Grid,
  Col,
  Row,
  ButtonGroup,
  ButtonToolbar,
  Button,
  Tooltip,
  OverlayTrigger
} from "react-bootstrap";
import DnDWrapper from "../DnDWrapper/DnDWrapper";
import { Link } from "react-router-dom";
import snakeCase from "lodash/snakeCase";
import moment from "moment";

import { get, put, download } from "../../../utils/DeApi";

import ThemeCreate from "../ThemeCreate/ThemeCreate";
import ThemeEdit from "../ThemeEdit/ThemeEdit";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import ContentGuideList from "../../Content/ContentGuideList/ContentGuideList";
import EmptyState from "../EmptyState/EmptyState";
import { setLayout, getLayout } from "../../../utils/LayoutUtils";

/**
 * Handles rendering of themes outline view
 * Uses drag and drop
 */
class ThemesOutline extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
      layout: getLayout() || "grid"
    };

    this.handleThemeCreated = this._handleThemeCreated.bind(this);
    this.handleThemeUpdated = this._handleThemeUpdated.bind(this);
    this.handleOrderChange = this._handleOrderChange.bind(this);
    this.handleViewList = this._handleViewList.bind(this);
    this.handleViewGrid = this._handleViewGrid.bind(this);
    this.handleViewTable = this._handleViewTable.bind(this);
    this.handleDownloadOutline = this._handleDownloadOutline.bind(this);

    this.renderTableItem = this._renderTableItem.bind(this);
    this.renderGridItem = this._renderGridItem.bind(this);
  }

  _handleThemeCreated(theme) {
    this.setState(function(prevState, props) {
      prevState.themes.push(theme);
      return {
        themes: prevState.themes
      };
    });
  }

  _handleThemeUpdated(theme) {
    this.setState(function(prevState, props) {
      return {
        themes: prevState.themes.map(item => {
          if (item.id === theme.id) return theme;
          else return item;
        }),
        theme: theme
      };
    });
  }

  _handleViewTable() {
    this.setState({ layout: "table" }, () => setLayout(this.state.layout));
  }

  _handleViewList() {
    this.setState({ layout: "list" }, () => setLayout(this.state.layout));
  }

  _handleViewGrid() {
    this.setState({ layout: "grid" }, () => setLayout(this.state.layout));
  }

  _handleDownloadOutline() {
    const { project } = this.props;
    this.setState({ outlineDownloading: true });

    const outlinePromise = download(
      `manuscript/${project.id}/outline?pillar=themes`
    );
    const fileName = `outline-themes-${snakeCase(project.name)}.docx`;

    outlinePromise.promise
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute("download", fileName);
        document.body.appendChild(link);

        link.click();
        this.setState({
          outlineDownloading: false
        });
      })
      .catch(error => {
        this.setState({
          outlineDownloading: false
        });
      });
    this.subscribedPromises.push(outlinePromise);
  }

  componentDidMount() {
    this.fetchThemes();
    this.fetchThemeTypes();
    this.setMobileLayout();
  }

  setMobileLayout() {
    const isMobile = /mobi/i.test(navigator.userAgent.toLowerCase());

    if (isMobile && !getLayout())
      this.setState({
        layout: "list"
      });
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  fetchThemes() {
    let themesPromise = get("/themes?project=" + this.props.project.id);
    themesPromise.promise
      .then(response => {
        this.setState({
          themes: response.data,
          error: ""
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error
          });
      });
    this.subscribedPromises.push(themesPromise);
  }

  fetchThemeTypes() {
    let themeTypesPromise = get("/theme-types");

    themeTypesPromise.promise
      .then(response => {
        this.setState({
          themeTypes: response.data
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error
          });
      });
    this.subscribedPromises.push(themeTypesPromise);
  }

  render() {
    const {
      themes,
      themeTypes,
      layout,
      outlineDownloading,
      error
    } = this.state;
    const { project } = this.props;

    const themeOptions = {
      category: "5ba5d800d5983" // Theme categoryId
    };

    const themesList = themes && themeTypes ? this.renderThemes() : <Loader />;

    return (
      <Grid>
        <Row className="PillarOutline">
          <Col md={3} mdPush={9} xs={12} className="PillarOutlineSidebar">
            <div className="mt-md mb-md pt-sm pb-sm">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip">Create a new theme</Tooltip>}
              >
                <span className="mt-sm mb-sm">
                  <ThemeCreate
                    project={project}
                    onThemeCreated={this.handleThemeCreated}
                  />
                </span>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip">Export themes outline</Tooltip>}
              >
                <Button
                  onClick={this.handleDownloadOutline}
                  className="btn-secondary mt-sm mb-sm"
                  block
                >
                  {outlineDownloading ? "Exporting...." : "Export"}
                </Button>
              </OverlayTrigger>

              <div className="card">
                <h3 className="text-uppercase">Themes Guides</h3>
                <hr />
                <ContentGuideList {...themeOptions} />
              </div>
            </div>
          </Col>
          <Col md={9} mdPull={3} xs={12}>
            <Row className="mt-md mb-md">
              <Col xs={12} sm={4}>
                <h3 className="text-uppercase mt-md">{this.renderTitle()}</h3>
              </Col>
              <Col xs={12} sm={8}>
                <ButtonToolbar className="pull-right">
                  <ButtonGroup>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip">
                          View outline in list layout
                        </Tooltip>
                      }
                    >
                      <Button
                        className="btn-secondary mt-sm mb-sm"
                        onClick={this.handleViewTable}
                        active={layout === "table"}
                      >
                        <i className="material-icons md-24">list</i>
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip">
                          View outline in grid layout
                        </Tooltip>
                      }
                    >
                      <Button
                        className="btn-secondary mt-sm mb-sm"
                        onClick={this.handleViewGrid}
                        active={layout === "grid"}
                      >
                        <i className="material-icons md-24">view_module</i>
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip">
                          View outline with full summaries
                        </Tooltip>
                      }
                    >
                      <Button
                        className="btn-secondary mt-sm mb-sm"
                        onClick={this.handleViewList}
                        active={layout === "list"}
                      >
                        <i className="material-icons md-24">view_stream</i>
                      </Button>
                    </OverlayTrigger>
                  </ButtonGroup>
                </ButtonToolbar>
              </Col>
              <Col xs={12}>
                {error && <ErrorHandler error={error} />}
                {themesList}
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid>
    );
  }

  renderTitle() {
    const { layout, themes } = this.state;

    if (!themes) return "";
    else if (!themes.length) return <span>OUTLINE</span>;
    else if (layout === "list") return <span>OUTLINE: Summary View</span>;
    else if (layout === "grid") return <span>OUTLINE: Grid View</span>;
    else if (layout === "table") return <span>OUTLINE: List View</span>;
    return "";
  }

  renderThemes() {
    const { layout, themes } = this.state;
    if (themes && themes.length) {
      if (layout === "grid") {
        return this.renderGrid();
      } else if (layout === "list") {
        return this.renderList();
      } else {
        return this.renderTable();
      }
    } else {
      return (
        <div className="card card-info pt-sm pb-md">
          <EmptyState contentId={"60c7acc51e7aa"} />
        </div>
      );
    }
  }

  renderTable() {
    const { themes } = this.state;

    return (
      <div className="card">
        <Row className="mb-xs">
          <Col xs={2} md={1} className="text-right" />
          <Col xs={4} md={5}>
            <h5 className="mt-0 mb-0">Name</h5>
          </Col>
          <Col xs={3} md={3}>
            <h5 className="mt-0 mb-0">Theme Type</h5>
          </Col>
          <Col xs={3} md={3}>
            <h5 className="mt-0 mb-0">Last Modified</h5>
          </Col>
        </Row>
        <DnDWrapper
          items={themes}
          onOrderChange={this.handleOrderChange}
          renderItem={this.renderTableItem}
          dragHandle={true}
        />
      </div>
    );
  }

  renderGrid() {
    const { themes } = this.state;
    return (
      <DnDWrapper
        items={themes}
        onOrderChange={this.handleOrderChange}
        renderItem={this.renderGridItem}
      />
    );
  }

  renderList() {
    const { themes, themeTypes } = this.state;
    const { project } = this.props;

    return (
      <div className="card">
        {themes.map((theme, index) => {
          let themeURL = "/projects/" + project.id + "/themes/" + theme.id;

          return (
            <div key={theme.id}>
              <div className="mt-sm mb-md">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip">Edit title, type and summary</Tooltip>
                  }
                >
                  <span className="pl-xs pull-right">
                    <ThemeEdit
                      theme={theme}
                      themeTypes={themeTypes}
                      onThemeUpdated={this.handleThemeUpdated}
                    />
                  </span>
                </OverlayTrigger>
                <h4>
                  <span className="text-muted body-2 pr-xs">{++index}</span>
                  <Link to={themeURL} title="Go to character+">
                    {theme.title}
                  </Link>
                </h4>
              </div>
              <p className="PillarDescription">{theme.description}</p>
              <hr className="mt-md mb-md" />
            </div>
          );
        })}
      </div>
    );
  }

  _renderTableItem(theme, index, dragHandle) {
    const { project } = this.props;

    return (
      <Col xs={12} className="DnDRowContent pb-0">
        <div className="card card-table movable">
          <Row>
            <Col xs={2} md={1} className="text-right">
              {dragHandle && dragHandle}
              <small className="text-muted">{++index}</small>
            </Col>
            <Col xs={4} md={5}>
              <p className="mt-0 mb-0">
                <Link to={"/projects/" + project.id + "/themes/" + theme.id}>
                  {theme.title &&
                    (theme.title.length < 35
                      ? theme.title
                      : theme.title.substring(0, 32) + "...")}
                </Link>
              </p>
            </Col>
            <Col xs={3} md={3}>
              <small className="tag text-capitalize">
                {theme.themeType.name}
              </small>
            </Col>
            <Col xs={3} md={3}>
              <small className="text-muted mb-0">
                {moment
                  .utc(theme.updatedAt)
                  .local()
                  .calendar()}
              </small>
            </Col>
          </Row>
        </div>
      </Col>
    );
  }

  _renderGridItem(theme, index) {
    const { project } = this.props;
    const { themeTypes } = this.state;
    const icons = {
      "5bb22abc1f8f9": "wb_incandescent", // Big Ideas
      "5bb22abc313d1": "work_outline", // Marketing Ideas
      "5bb22abc32b82": "face", // Symbols/Motifs
      "5c8f627c5347c": "bubble_chart" // Other
    };

    const { id, title, description, themeType } = theme;
    const themeURL = "/projects/" + project.id + "/themes/" + id;

    return (
      <Col md={4} xs={12} className="DnDColContent">
        <div className="card card-grid movable">
          <div className="body">
            {icons[themeType.id] && (
              <i className="material-icons pull-right">{icons[themeType.id]}</i>
            )}
            <h4>
              <Link to={themeURL}>
                {title &&
                  (title.length < 35 ? title : title.substring(0, 32) + "...")}
              </Link>
            </h4>
            <hr />
            <p>
              {description &&
                (description.length < 110
                  ? description
                  : description.substring(0, 108) + "...")}
            </p>
          </div>
          <div className="footer">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip">Edit title, type and summary</Tooltip>
              }
            >
              <span className="pl-xs pull-right">
                <ThemeEdit
                  theme={theme}
                  themeTypes={themeTypes}
                  onThemeUpdated={this.handleThemeUpdated}
                />
              </span>
            </OverlayTrigger>
            <p className="body-2">
              <span>{++index}</span>
            </p>
          </div>
        </div>
      </Col>
    );
  }

  _handleOrderChange(item, newIndex, newList) {
    this.setState({ themes: newList });
    this.reOrderList(item, newIndex, newList);
  }

  reOrderList(item, newIndex, newList) {
    let reOrderPromise = put("/themes/" + item.id + "/order?curr=" + newIndex);
    reOrderPromise.promise
      .then(response => {
        this.setState({ error: "" });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error
          });
      });
    this.subscribedPromises.push(reOrderPromise);
  }
}

export default ThemesOutline;
