import React, { Component } from "react";
import { Alert } from "react-bootstrap";
import { Offline } from "react-detect-offline";
import { Redirect } from "react-router-dom";

import { logout } from "../../utils/BeeApi";
import { logout as logoutDE } from "../../utils/DeApi";

import "./ErrorHandler.css";

class ErrorHandler extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};
  }

  componentDidMount() {
    const error = this.props.error;
    if (error && error.status === 401) {
      this.triggerLogout();
    }
  }

  render() {
    const { logOut } = this.state;
    const error = this.props.error;
    // let status = "";
    let statusText = "Oh snap! You got an error!";
    let message = "Something unexpected happened. Please try again.";
    if (error) {
      // status = error.status || status;
      statusText = error.statusText || statusText;
      if (error.data && error.data.error) {
        message = error.data.error.message || message;
      }
    }
    if (logOut) return <Redirect to="/login" />;
    return (
      <div className="ErrorView mt-lg mb-lg center-block">
        <Alert bsStyle="warning" className="mt-md mb-md">
          <h4>{statusText}</h4>
          <p>{this.props.message || message}</p>
          <Offline polling={{ url: "https://bookflow.pixeledge.io" }}>
            <p className="mt-sm md-sm">
              <small>
                Cannot connect to the internet. Please check your connection.
              </small>
            </p>
          </Offline>
        </Alert>
      </div>
    );
  }

  triggerLogout(event) {
    logout()
      .then(data => {
        return logoutDE();
      })
      .then(data => {
        this.setState({
          logOut: true
        });
      })
      .catch(error => {
        this.setState({
          logoutError: error.status + ": " + error.statusText,
          logOut: false
        });
      });
  }
}

export default ErrorHandler;
