import React from "react";
import "./ContentExternal.css";
import { getURLDomain } from "../../../utils/StringUtils";

const ContentExternal = ({ title, contentBody, contentExternalLink }) => (
  <div className="mt-sm mb-sm">
    <h1>{title}</h1>
    <div
      className="body-section"
      dangerouslySetInnerHTML={{ __html: contentBody }}
    />
    <div className="pt-sm pb-sm">
      <a
        rel="noopener noreferrer"
        target="_blank"
        className="btn btn-default"
        href={contentExternalLink}
      >
        View on &nbsp;
        {getURLDomain(contentExternalLink)}
      </a>
    </div>
  </div>
);

export default ContentExternal;
