import React, { Component, Fragment } from "react";
import { Prompt, Redirect } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";

class NavigationGuard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      leave: false,
      nextLocation: null
    };

    this.handleBlockedNavigation = this._handleBlockedNavigation.bind(this);
    this.handleLeave = this._handleLeave.bind(this);
    this.handleStay = this._handleStay.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { show, nextLocation } = this.state;
    if (this.props.when !== prevProps.when && !this.props.when) {
      if (show && nextLocation) setTimeout(() => this.handleLeave(), 1000);
    }
  }

  _handleBlockedNavigation(nextLocation) {
    const { leave } = this.state;
    const { when } = this.props;
    if (!leave && when) {
      this.setState({ show: true, nextLocation: nextLocation });
      return false;
    }
  }

  _handleLeave() {
    this.setState({ show: false, leave: true });
  }

  _handleStay() {
    this.setState({ show: false, leave: false });
  }

  render() {
    const { when } = this.props;
    const { show, leave, nextLocation } = this.state;
    return (
      <Fragment>
        <Prompt when={when} message={this.handleBlockedNavigation} />
        {leave && <Redirect to={nextLocation} push />}
        <Modal show={show} backdrop="static">
          <Modal.Body className="text-center">
            <h2>Saving...</h2>
            <h5>Please wait while we save your changes.</h5>
            <p className="text-center">
              <Button bsStyle="success" bsSize="xs" onClick={this.handleStay}>
                Stay
              </Button>{" "}
              <Button bsStyle="warning" bsSize="xs" onClick={this.handleLeave}>
                Leave
              </Button>
            </p>
          </Modal.Body>
        </Modal>
      </Fragment>
    );
  }
}

export default NavigationGuard;
