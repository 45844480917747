import React, { Component } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import { Button, Alert } from "react-bootstrap";

import { FieldGroup } from "../../../utils/BootstrapUtils";
import Loader from "../../Loader/Loader";

class Card extends Component {
  constructor(props) {
    super(props);
    this.state = { name: "" };

    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleInputChange = this._handleInputChange.bind(this);
  }

  _handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }

  _handleSubmit(ev) {
    const { name } = this.state;
    if (!name) {
      this.setState({ formSubmitted: true });
      return;
    }
    this.setState({ formSubmitted: true, isLoading: true });

    const { subscriber, onCCSubmitted, stripe, elements } = this.props;
    const card = elements.getElement("card");

    stripe
      .createPaymentMethod({
        type: "card",
        card: card,
        billing_details: { name: name, email: subscriber.email }
      })
      .then((results) => {
        if (results.paymentMethod) {
          this.setState({ isLoading: false, error: false });
          onCCSubmitted(results.paymentMethod);
        } else {
          this.setState({ isLoading: false, error: results.error || {} });
        }
      })
      .catch((results) => {
        this.setState({ isLoading: false, error: results.error || {} });
      });
  }

  render() {
    const { name, formSubmitted, isLoading, error } = this.state;

    return (
      <div className="CheckoutForm">
        {isLoading && <Loader />}
        {!!error && (
          <div className="center-block">
            <Alert bsStyle="warning" className="mt-md mb-md">
              <p>
                {error.message ||
                  "An error occurred while processing the card. Please check the card details are correct or use a different card."}
              </p>
            </Alert>
          </div>
        )}
        <FieldGroup
          id="name"
          name="name"
          label="Cardholder Name"
          type="text"
          placeholder="Cardholder name"
          value={name}
          onChange={this.handleInputChange}
          validationState={formSubmitted && !name ? "error" : null}
          required
          autoFocus
        />

        <div className="form-group">
          <label className="control-label">Card Number</label>
          <CardElement
            classes={{ base: "form-control" }}
            style={{
              base: {
                fontFamily: '"Open Sans", sans-serif',
                color: "#8798ad",
                fontSize: "14px",
                lineHeight: "1.5",
                ":focus": {
                  color: "#2e384d"
                },
                "::placeholder": {
                  fontFamily: '"Open Sans", sans-serif',
                  fontSize: "14px",
                  color: "#999"
                }
              }
            }}
          />
        </div>
        <p className="text-right">
          <Button bsStyle="primary" onClick={this.handleSubmit} block>
            Add Payment Method
          </Button>
        </p>
      </div>
    );
  }
}

export default injectStripe(Card);
