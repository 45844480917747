import {
  EditorState,
  ContentState,
  convertFromRaw,
  convertToRaw
} from "draft-js";
import { convertFromHTML } from "draft-convert";

const TextExtractionUtil = {
  extractPlainText(richText) {
    let plainText = "";

    if (richText) {
      let editorState = EditorState.createEmpty();

      if (typeof richText === "object") {
        editorState = EditorState.createWithContent(convertFromRaw(richText));
      } else {
        try {
          editorState = EditorState.createWithContent(
            convertFromRaw(JSON.parse(richText))
          );
        } catch (e) {
          editorState = EditorState.createWithContent(
            ContentState.createFromText(richText)
          );
        }
      }
      let contentState = editorState.getCurrentContent();
      plainText = contentState.getPlainText();
    }
    return plainText;
  },

  convertToRaw(contentState) {
    return contentState && contentState.hasText()
      ? convertToRaw(contentState)
      : null;
  },

  getWordCount(contentState) {
    if (contentState && contentState.hasText()) {
      var blocks = contentState.getBlocksAsArray();
      var words = 0;
      var characters = 0;

      blocks.forEach(function(block) {
        var text = block.text || "";
        // new line, carriage return, line feed
        const regex = /(?:\r\n|\r|\n)/g;
        // replace above characters w/ space
        const cleanString = text.replace(regex, " ").trim();
        characters += cleanString.length;
        // matches words according to whitespace
        const wordArray = cleanString.match(/\S+/g);
        words += wordArray ? wordArray.length : 0;
      });
      return {
        characters: characters,
        words: words,
        blocks: blocks.length
      };
    } else {
      return {
        characters: 0,
        words: 0,
        blocks: 0
      };
    }
  },

  htmlToContentState(html) {
    // Spliting blocks using rich utils.
    const ALIGNMENT_DATA_KEY = "textAlignment";
    const ALIGNMENT_MAP = {
      left: "LEFT",
      center: "CENTER",
      right: "RIGHT",
      justify: "JUSTIFY"
    };

    return convertFromHTML({
      htmlToStyle: (nodeName, node, currentStyle) => {
        let fontWeight = node && node.style ? node.style.fontWeight : null;

        currentStyle.forEach(style => {
          if (
            style === "BOLD" &&
            (fontWeight === "100" ||
              fontWeight === "200" ||
              fontWeight === "300" ||
              fontWeight === "400" ||
              fontWeight === "500" ||
              fontWeight === "normal" ||
              fontWeight === "NORMAL")
          ) {
            currentStyle = currentStyle.delete(style);
          }
        });
        return currentStyle;
      },
      htmlToBlock: (nodeName, node) => {
        var alignment = node && node.style ? node.style.textAlign : null;
        if (alignment === "left") {
          return {
            data: { [ALIGNMENT_DATA_KEY]: ALIGNMENT_MAP["left"] }
          };
        } else if (alignment === "right") {
          return {
            data: { [ALIGNMENT_DATA_KEY]: ALIGNMENT_MAP["right"] }
          };
        } else if (alignment === "center") {
          return {
            data: { [ALIGNMENT_DATA_KEY]: ALIGNMENT_MAP["center"] }
          };
        } else if (alignment === "justify") {
          return {
            data: { [ALIGNMENT_DATA_KEY]: ALIGNMENT_MAP["justify"] }
          };
        }
      }
    })(html, { flat: true });
  }
};

export default TextExtractionUtil;
