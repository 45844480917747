import React, { Component } from "react";

import { get } from "../../../utils/DeApi";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import RichTextEditor from "../../RichTextEditor/RichTextEditor";

/**
 * Handles read only view for a single scene.
 * Writer sees list of scenes body back to back.
 */
class SceneReadOnly extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};
  }

  componentDidMount() {
    this.fetchScene();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  fetchScene() {
    let scenePromise = get("/scenes/" + this.props.scene.id);

    scenePromise.promise
      .then(response => {
        this.setState({
          scene: response.data,
          error: ""
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error
          });
      });
    this.subscribedPromises.push(scenePromise);
  }

  render() {
    const { error, scene } = this.state;
    if (error) return <ErrorHandler error={error} />;
    if (!scene) return <Loader />;
    return (
      <RichTextEditor richText={scene.body} controls={false} readOnly={true} />
    );
  }
}

export default SceneReadOnly;
