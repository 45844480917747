import React, { Component, Fragment } from "react";
import { Button, Modal, Radio, Alert } from "react-bootstrap";

import { put, get } from "../../../utils/BeeApi";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class Onboarding extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = { show: props.onboard, question: 1 };

    this.handleShowModal = this._handleShowModal.bind(this);
    this.handleCloseModal = this._handleCloseModal.bind(this);
    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleInputChange = this._handleInputChange.bind(this);
  }

  _handleCloseModal() {
    this.setState({ show: false }, () => this.updateSubscriberCategories());
  }

  _handleShowModal() {
    this.setState({ show: true });
  }

  _handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value }, this.handleUpdated);
  }

  _handleSubmit(event) {
    this.updateSubscriberCategories();
  }

  componentDidMount() {
    this.fetchSubscriber();
    this.fetchSubscriberCategories();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(promise => {
      promise.cancel();
    });
  }

  fetchSubscriberCategories() {
    this.setState({ isLoading: true, error: false });

    let categoriesPromise = get("/categories", {
      params: { group: "5f80c762b96fb" }
    });

    categoriesPromise.promise
      .then(response => {
        this.setState({
          categories: response.data,
          isLoading: false,
          error: ""
        });
      })
      .catch(error => {
        if (!error.isCanceled) {
          this.setState({ isLoading: false, error: error });
        }
      });
    this.subscribedPromises.push(categoriesPromise);
  }

  fetchSubscriber() {
    let subscriberPromise = get("/user/anon");
    subscriberPromise.promise
      .then(response => {
        this.setState({
          subscriber: response.data,
          error: ""
        });
      })
      .catch(error => {
        if (!error.isCanceled) {
          this.setState({
            error: error
          });
        }
      });
    this.subscribedPromises.push(subscriberPromise);
  }

  updateSubscriberCategories() {
    let categories = [
      this.state.questionOneSelection ?? undefined,
      this.state.questionTwoSelection ?? undefined,
      this.state.questionThreeSelection ?? undefined
    ].filter(value => value);

    // Use default
    if (!categories.length) categories = ["5f8f2a3169618"];

    const { subscriber } = this.props;
    const updatePromise = put(`/user/${subscriber.id}/categories`, {
      categories: categories
    });
    this.setState({ isUpdating: true, error: null });

    updatePromise.promise
      .then(response => {
        this.setState({
          question: 4,
          error: null,
          isUpdating: false
        });
      })
      .catch(error => {
        if (!error.isCanceled) {
          this.setState({ error: error, isUpdating: false });
        }
      });
    this.subscribedPromises.push(updatePromise);
  }

  render() {
    const {
      show,
      categories,
      question,
      isLoading,
      isUpdating,
      subscriber,
      error
    } = this.state;

    if (!categories || !subscriber) return <span />;
    if (subscriber.categories.length) return <span />;

    return (
      <Modal show={show} onHide={this.handleCloseModal} backdrop="static">
        <Modal.Body>
          <p>
            <button className="close" onClick={this.handleCloseModal}>
              <span className="material-icons md-24">close</span>
            </button>
          </p>
          {isUpdating || isLoading ? (
            <Loader />
          ) : (
            <div className="pt-sm pb-sm">
              {question !== 4 && (
                <Fragment>
                  <h1>
                    Hey there,{" "}
                    <span className="text-capitalize">
                      {subscriber.firstName}
                    </span>
                    !
                  </h1>
                  <p>
                    Let us help you make the most of Bookflow by answering a few
                    questions.
                  </p>
                  <hr />
                </Fragment>
              )}

              {question === 1
                ? this.renderQuestionOne()
                : question === 2
                ? this.renderQuestionTwo()
                : this.renderConfirmation()}
            </div>
          )}
          {error && <ErrorHandler error={error} />}
        </Modal.Body>
      </Modal>
    );
  }

  renderQuestionOne() {
    const { questionOneSelection, validationState, categories } = this.state;
    const questionCategories = categories.filter(category =>
      [
        "5f80c7d280557",
        "5f80c794a59b5",
        "5f80c7b135d7c",
        "5f8874851df60"
      ].includes(category.categoryId)
    );

    return (
      <form autoComplete="off">
        <p> What is your main writing goal?</p>

        {validationState && (
          <Alert bsStyle="warning" className="pt-xs pb-xs">
            <small>Please select one of these options.</small>
          </Alert>
        )}
        {questionCategories.map(category => {
          return (
            <Radio
              name="questionOneSelection"
              onChange={this.handleInputChange}
              checked={questionOneSelection === category.categoryId}
              value={category.categoryId}
              key={category.categoryId}
              className="mt-xs mb-xs pl-md"
            >
              {category.categoryName}
            </Radio>
          );
        })}
        <hr />
        <p className="text-right">
          <small className="pull-left text-muted">1/2</small>
          <Button
            className="pl-md pr-md"
            bsStyle="link"
            onClick={() =>
              this.setState({ question: 2, validationState: null })
            }
          >
            Skip
          </Button>{" "}
          <Button
            bsStyle="success"
            className="pl-md pr-md"
            bsSize="sm"
            onClick={() => {
              questionOneSelection
                ? this.setState({ question: 2, validationState: null })
                : this.setState({ validationState: "error" });
            }}
          >
            Submit
          </Button>{" "}
        </p>
      </form>
    );
  }

  renderQuestionTwo() {
    const { questionTwoSelection, validationState, categories } = this.state;
    const questionCategories = categories.filter(category =>
      [
        "5f88735862700",
        "5f88736851af4",
        "5f887bc8705ce",
        "5f8873a11af3f",
        "5f8873917d680"
      ].includes(category.categoryId)
    );

    return (
      <form autoComplete="off">
        <p>What is your primary writing form?</p>
        {validationState && (
          <Alert bsStyle="warning" className="pt-xs pb-xs">
            <small>Please select one of these options.</small>
          </Alert>
        )}
        {questionCategories.map(category => {
          return (
            <Radio
              name="questionTwoSelection"
              onChange={this.handleInputChange}
              checked={questionTwoSelection === category.categoryId}
              value={category.categoryId}
              key={category.categoryId}
              className="mt-xs mb-xs pl-md"
            >
              {category.categoryName}
            </Radio>
          );
        })}
        <hr />
        <p className="text-right">
          <small className="pull-left text-muted">2/2</small>
          <Button
            className="pl-md pr-md"
            bsStyle="link"
            onClick={this.handleCloseModal}
          >
            Skip
          </Button>{" "}
          <Button
            className="btn-secondary pl-md pr-md"
            bsSize="sm"
            onClick={() =>
              this.setState({ question: 1, validationState: null })
            }
          >
            Previous
          </Button>{" "}
          <Button
            bsStyle="success"
            className="pl-md pr-md"
            bsSize="sm"
            onClick={() => {
              questionTwoSelection
                ? this.setState({ validationState: null }, this.handleSubmit)
                : this.setState({ validationState: "error" });
            }}
          >
            Submit
          </Button>{" "}
        </p>
      </form>
    );
  }

  renderConfirmation() {
    return (
      <Alert bsStyle="success" className="text-center">
        <h4>Thank you</h4>
        <p>
          Check your inbox to see how Bookflow can bring more joy to your
          writing life.
        </p>
        <hr />
        <p className="text-center">
          <Button
            bsStyle="success"
            className="pl-md pr-md"
            bsSize="sm"
            onClick={() => this.setState({ show: false })}
          >
            Close
          </Button>{" "}
        </p>
      </Alert>
    );
  }
}

export default Onboarding;
