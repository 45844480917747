import React from "react";
import Twitter from "./Twitter/Twitter";
import Linkedin from "./Linkedin/Linkedin";
import Facebook from "./Facebook/Facebook";
import Email from "./Email/Email";
import Download from "./Download/Download";
import "./SocialSharing.css";

const SocialSharing = ({ content, options }) => {
  let title = content.title;

  let summary = content.description;
  let image = content.contentThumbnail;
  let link = "http://www.bookflow.pub/rewards/" + content.contentId;
  let download = options.download;
  return (
    <div className="SocialSharing">
      <ul className="soc list-inline text-center">
        <Facebook link={link} />
        <Twitter title={title} link={link} summary={summary} />
        <Linkedin title={title} summary={summary} image={image} link={link} />
        <Email title={title} summary={summary} link={link} />
        {download && <Download content={content} />}
      </ul>
    </div>
  );
};

export default SocialSharing;
