import { Modifier, EditorState } from "draft-js";
import { convertFromHTML } from "draft-convert";

// References:
// DraftJS Conductor: https://github.com/thibaudcolas/draftjs-conductor/blob/master/src/lib/api/copypaste.js
// HubSpot Conversion Utils: https://github.com/HubSpot/draft-convert#convertfromhtml
// Using flat:true: https://github.com/facebook/draft-js/issues/1082#issuecomment-289305592

// Copy pasting
// Spliting blocks using rich utils.
const ALIGNMENT_DATA_KEY = "textAlignment";
const ALIGNMENT_MAP = {
  left: "LEFT",
  center: "CENTER",
  right: "RIGHT",
  justify: "JUSTIFY"
};

const CopyPasteUtils = {
  handlePastedText(text, html, editorState) {
    // Plain-text pastes are better handled by Draft.js.
    if (!html) {
      return false;
    }

    // Handle the paste using convert to and from HTML
    // Handle alignment and set appropriate data properties.
    // Add option flat:true:  to force nested block elements to split into flat, separate blocks. For example, the HTML input <p>line one<br />linetwo</p> will produce two unstyled blocks in flat mode.
    const clipBoardContentState = convertFromHTML({
      htmlToStyle: (nodeName, node, currentStyle) => {
        let fontWeight = node && node.style ? node.style.fontWeight : null;

        currentStyle.forEach(style => {
          if (
            style === "BOLD" &&
            (fontWeight === "100" ||
              fontWeight === "200" ||
              fontWeight === "300" ||
              fontWeight === "400" ||
              fontWeight === "500" ||
              fontWeight === "normal" ||
              fontWeight === "NORMAL")
          ) {
            currentStyle = currentStyle.delete(style);
          }
        });
        return currentStyle;
      },
      htmlToBlock: (nodeName, node) => {
        var alignment = node && node.style ? node.style.textAlign : null;
        if (alignment === "left") {
          return {
            data: { [ALIGNMENT_DATA_KEY]: ALIGNMENT_MAP["left"] }
          };
        } else if (alignment === "right") {
          return {
            data: { [ALIGNMENT_DATA_KEY]: ALIGNMENT_MAP["right"] }
          };
        } else if (alignment === "center") {
          return {
            data: { [ALIGNMENT_DATA_KEY]: ALIGNMENT_MAP["center"] }
          };
        } else if (alignment === "justify") {
          return {
            data: { [ALIGNMENT_DATA_KEY]: ALIGNMENT_MAP["justify"] }
          };
        }
      }
    })(html, { flat: true });

    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const newContentState = Modifier.replaceWithFragment(
      contentState,
      selection,
      clipBoardContentState.getBlockMap()
    );

    return EditorState.push(editorState, newContentState, "insert-fragment");
  }
};

export default CopyPasteUtils;
