import React, { Component } from "react";
import { Offline } from "react-detect-offline";
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from "react-sortable-hoc";
import arrayMove from "array-move";
import { Row, Col, Label } from "react-bootstrap";

import "./DnDWrapper.css";

/**
 * Handles a sortable grid list with drag and drop feature.
 * @implements https://github.com/clauderic/react-sortable-hoc
 */
class DnDWrapper extends Component {
  constructor(props) {
    super(props);

    this.handleSortEnd = this._onSortEnd.bind(this);
    this.handleConnectionChange = this._onConnectionChange.bind(this);
    this.state = { list: this.props.items, isOnline: true };
  }

  _onConnectionChange(online) {
    this.setState({ isOnline: online });
  }

  _onSortEnd(result) {
    const { list, isOnline } = this.state;
    const { onOrderChange } = this.props;

    if (!isOnline) return;

    let newIndex = result.newIndex;
    let oldIndex = result.oldIndex;

    if (newIndex === oldIndex) return;
    if (newIndex > list.length) return;

    const sourceItem = list[oldIndex];
    const newList = arrayMove(list, oldIndex, newIndex);

    this.setState({ list: newList }, () => {
      onOrderChange(sourceItem, ++newIndex, newList);
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      list: nextProps.items
    };
  }

  render() {
    const { list, isOnline } = this.state;
    const { renderItem, useDragHandle } = this.props;
    const itemRender = renderItem ? renderItem : this.renderItem;

    const DragHandle = SortableHandle(() => (
      <div className="drag-handle">
        <i className="material-icons md-muted">drag_indicator</i>
      </div>
    ));
    const SortableItem = SortableElement(({ value, sortIndex }) =>
      itemRender(value, sortIndex, <DragHandle />)
    );

    const OfflineDector = (
      <Offline
        polling={{ url: "https://bookflow.pixeledge.io" }}
        onChange={this.handleConnectionChange}
        online={isOnline}
      >
        <Col xs={12} className="text-center">
          <p>
            <span className="label label-warning mr-sm">Offline</span>
          </p>
        </Col>
      </Offline>
    );

    const SortableList = SortableContainer(({ items }) => {
      return (
        <Row className="DnDContainer">
          {OfflineDector}
          {items.map((item, index) => (
            <SortableItem
              key={`item-${index}`}
              index={index}
              sortIndex={index}
              value={item}
              disabled={!isOnline}
            />
          ))}
        </Row>
      );
    });
    return (
      <SortableList
        items={list}
        onSortEnd={this.handleSortEnd}
        distance={10}
        axis="xy"
        useWindowAsScrollContainer={true}
        useDragHandle={!!useDragHandle}
        helperClass="DnDMoving"
      />
    );
  }

  renderItem(item, index) {
    return (
      <div className="card">
        <h4>
          {item.title}
          <Label bsStyle="default" className="ml-xs">
            {item.type}
          </Label>
        </h4>
        <p>{item.description}</p>
      </div>
    );
  }
}

export default DnDWrapper;
