import React from "react";
import StyleButton from "./StyleButton";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const BLOCK_TYPES = [
  {
    label: (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="heading-1-tooltip">Heading 1</Tooltip>}
      >
        <span>H1</span>
      </OverlayTrigger>
    ),
    style: "header-one"
  },
  {
    label: (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="heading-2-tooltip">Heading 2</Tooltip>}
      >
        <span>H2</span>
      </OverlayTrigger>
    ),
    style: "header-two"
  },
  {
    label: (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="heading-3-tooltip">Heading 3</Tooltip>}
      >
        <span>H3</span>
      </OverlayTrigger>
    ),
    style: "header-three"
  },
  // { label: "H5", style: "header-five" },
  // { label: "H6", style: "header-six" },
  {
    label: (
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip id="blockquote-tooltip">Centered text section</Tooltip>
        }
      >
        <i className="material-icons">format_quote</i>
      </OverlayTrigger>
    ),
    style: "blockquote"
  },
  {
    label: (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="bulleted-list-tooltip">Bulleted list</Tooltip>}
      >
        <i className="material-icons">format_list_bulleted</i>
      </OverlayTrigger>
    ),
    style: "unordered-list-item"
  },
  {
    label: (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="numbered-list-tooltip">Numbered list</Tooltip>}
      >
        <i className="material-icons">format_list_numbered</i>
      </OverlayTrigger>
    ),
    style: "ordered-list-item"
  }
  // { label: "Code Block", style: "code-block" }
];

class BlockStyleControls extends React.Component {
  constructor(props) {
    super(props);
    this.onToggle = e => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }

  render() {
    const { editorState } = this.props;
    const selection = editorState.getSelection();
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();
    return (
      <span className="RichEditor-controls">
        {BLOCK_TYPES.map(type => (
          <StyleButton
            key={type.style}
            active={type.style === blockType}
            label={type.label}
            onToggle={this.props.onToggle}
            style={type.style}
          />
        ))}
      </span>
    );
  }
}

export default BlockStyleControls;
