import React, { Component } from "react";
import { Grid, Col, Row, Image, Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import { get } from "../../../utils/BeeApi";
import "./Reward.css";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

/**
 * Handles manuscript view.
 * Writer sees list of scenes body back to back.
 */
class Reward extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};
  }

  componentDidMount() {
    this.fetchReward();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  fetchReward() {
    const { rewardId } = this.props;
    let rewardPromise = get("/contents/" + rewardId);

    this.setState({ isLoading: true, error: false });
    rewardPromise.promise
      .then(response => {
        this.setState({
          reward: response.data,
          isLoading: false,
          error: false
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error,
            isLoading: false
          });
      });
    this.subscribedPromises.push(rewardPromise);
  }

  render() {
    const { error, isLoading, reward } = this.state;

    if (error) return <ErrorHandler error={error} />;
    if (isLoading) return <Loader />;

    return reward ? (
      <div className="Reward">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Bookfow - {reward.title}</title>
        </Helmet>
        <Grid>
          <Row className=" mt-md mb-md text-center">
            <Col xs={12} sm={10} smOffset={1} mdOffset={2} md={8}>
              <Link to="/">
                <Image src="/bookflow-logo.png" width="240px" />
              </Link>
              <div className="card card-warning mt-sm mb-sm">
                <div className="RewardImage">
                  <Image src={reward.contentThumbnail} />
                </div>
                <hr className="mt-0" />
                <p className="text-muted mt-sm mb-sm">{reward.description}</p>
                <Button
                  bsStyle="primary"
                  bsSize="lg"
                  block
                  componentClass={Link}
                  href={"/"}
                  to="/"
                >
                  Get started now
                </Button>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    ) : (
      <div />
    );
  }
}

export default Reward;
