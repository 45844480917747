import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import "./ReAuth.css";

import { isLoggedIn, login } from "../../../utils/BeeApi";
import {
  isLoggedIn as isLoggedInDE,
  login as loginDE
} from "../../../utils/DeApi";

import { FieldGroup } from "../../../utils/BootstrapUtils";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class ReAuth extends Component {
  constructor(props, context) {
    super(props);

    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleEmailChange = this._handleEmailChange.bind(this);
    this.handlePasswordChange = this._handlePasswordChange.bind(this);
    this.handleShow = this._handleShow.bind(this);
    this.handleClose = this._handleClose.bind(this);

    const loggedIn = isLoggedInDE() && isLoggedIn();
    var email = loggedIn ? loggedIn.email : "";
    this.state = {
      email: email,
      password: "",
      emailValid: !!email || "",
      passwordValid: "",
      error: "",
      loggedIn: loggedIn,
      show: true
    };
  }

  _handleClose() {
    this.setState({ show: false });
  }

  _handleShow() {
    this.setState({ show: true });
  }

  _handleEmailChange(event) {
    let email = event.target.value;
    this.setState({
      email: email,
      emailValid: email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
        ? true
        : false
    });
  }

  _handlePasswordChange(event) {
    let password = event.target.value;
    this.setState({
      password: password,
      passwordValid: password.length ? true : false
    });
  }

  _handleSubmit(event) {
    event.preventDefault();
    if (this.state.emailValid && this.state.passwordValid) {
      this.setState({
        error: "",
        isLoading: true
      });
      login({
        email: this.state.email,
        password: this.state.password
      })
        .then(data => {
          return loginDE();
        })
        .then(data => {
          this.setState({
            loggedIn: true,
            isLoading: false
          });
          this.props.onReauthenticated(true);
        })
        .catch(error => {
          this.setState({
            error: error,
            isLoading: false
          });
        });
    }
  }

  renderLoginForm() {
    const { email, emailValid, password, passwordValid, error } = this.state;
    return (
      <form onSubmit={this.handleSubmit}>
        <FieldGroup
          id="loginEmail"
          label="Email address"
          type="email"
          value={email}
          onChange={this.handleEmailChange}
          validationState={emailValid || emailValid === "" ? null : "error"}
          placeholder="Enter email"
          autoFocus
        />
        <FieldGroup
          id="loginPassword"
          label="Password"
          type="password"
          value={password}
          onChange={this.handlePasswordChange}
          validationState={
            passwordValid || passwordValid === "" ? null : "error"
          }
          placeholder="Password"
        />
        {error && <ErrorHandler error={error} />}
        <Button type="submit" bsStyle="primary" block>
          Reauthenticate
        </Button>
        <hr />
        <h5 className="text-center">
          <Link to="/login-help" target="_blank">
            Forgot Password?
          </Link>
        </h5>
      </form>
    );
  }

  render() {
    const { isLoading, show } = this.state;

    return (
      <Modal
        show={show}
        onHide={this.handleClose}
        backdrop={"static"}
        backdropClassName="opaque"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="mt-sm mb-md text-center">
              <h2>Your session has expired. Login to continue.</h2>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? <Loader /> : this.renderLoginForm()}
        </Modal.Body>
      </Modal>
    );
  }
}

export default ReAuth;
