import React, { Component } from "react";
import "./Project.css";
import { Redirect, Route, Switch } from "react-router-dom";

import { get } from "../../utils/DeApi";

import ProjectNav from "./ProjectNav/ProjectNav";
import ProjectDashboard from "./ProjectDashboard/ProjectDashboard";
import ScenesOutline from "./ScenesOutline/ScenesOutline";
import ThemesOutline from "./ThemesOutline/ThemesOutline";
import CharactersOutline from "./CharactersOutline/CharactersOutline";
import Manuscript from "./Manuscript/Manuscript";
import Theme from "./Theme/Theme";
import Character from "./Character/Character";
import Scene from "./Scene/Scene";
import Loader from "../Loader/Loader";
import ErrorHandler from "../ErrorHandler/ErrorHandler";
import {
  exitFullScreen,
  FocusModeContext,
  openFullScreen
} from "./FocusModeContext/FocusModeContext";

/**
 * Handles project level routing.
 */
class Project extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
      isFocused: false
    };

    this.handleProjectUpdated = this._handleProjectUpdated.bind(this);
    this.handleProjectCloned = this._handleProjectCloned.bind(this);
    this.handleProjectDeleted = this._handleProjectDeleted.bind(this);
  }

  render() {
    const { user, location } = this.props;
    const { error, deleted, project, isFocused } = this.state;

    if (error) return <ErrorHandler error={error} />;
    if (deleted) return <Redirect to="/" />;

    if (project)
      return (
        <FocusModeContext.Provider
          value={{
            isFocused: isFocused,
            toggleFocusMode: () => {
              this.setState({ isFocused: !isFocused }, () => {
                if (this.state.isFocused) openFullScreen();
                else exitFullScreen();
              });
            }
          }}
        >
          <div className="Project">
            <FocusModeContext.Consumer>
              {({ isFocused }) => (
                <div className={`${isFocused ? "hidden" : ""}`}>
                  <ProjectNav
                    user={user}
                    project={project}
                    location={location}
                  />
                </div>
              )}
            </FocusModeContext.Consumer>
            <Switch>
              <Route
                exact
                path="/projects/:id/scenes"
                render={() => <ScenesOutline project={project} />}
              />
              <Route
                exact
                path="/projects/:id/scenes/:sceneId"
                component={props => (
                  <Scene
                    project={project}
                    sceneId={props.match.params.sceneId}
                  />
                )}
              />
              <Route
                exact
                path="/projects/:id/themes"
                render={() => <ThemesOutline project={project} />}
              />
              <Route
                exact
                path="/projects/:id/themes/:themeId"
                component={props => (
                  <Theme
                    project={project}
                    themeId={props.match.params.themeId}
                  />
                )}
              />
              <Route
                exact
                path="/projects/:id/characters"
                render={() => <CharactersOutline project={project} />}
              />
              <Route
                exact
                path="/projects/:id/characters/:characterId"
                component={props => (
                  <Character
                    project={project}
                    characterId={props.match.params.characterId}
                  />
                )}
              />
              <Route
                exact
                path="/projects/:id/manuscript"
                render={props => (
                  <Manuscript project={project} location={props.location} />
                )}
              />
              <Route
                exact
                path="/projects/:id"
                render={() => (
                  <ProjectDashboard
                    project={project}
                    location={location}
                    onProjectCloned={this.handleProjectCloned}
                    onProjectUpdated={this.handleProjectUpdated}
                    onProjectDeleted={this.handleProjectDeleted}
                  />
                )}
              />
            </Switch>
          </div>
        </FocusModeContext.Provider>
      );
    return <Loader />;
  }

  _handleProjectUpdated(project) {
    this.setState({ project: project });
  }

  _handleProjectCloned(project) {
    this.setState(
      {
        project: ""
      },
      () => {
        window.history.pushState({}, project.name, "/projects/" + project.id);
        this.fetchProject(project.id);
      }
    );
  }

  _handleProjectDeleted() {
    this.setState({ deleted: true });
  }

  componentDidMount() {
    let projectId = this.props.match.params.id;
    this.fetchProject(projectId);
  }

  fetchProject(projectId) {
    let projectPromise = get("/projects/" + projectId);
    projectPromise.promise
      .then(response => {
        this.setState({ project: response.data, error: "" });
      })
      .catch(error => {
        !error.isCanceled && this.setState({ error: error });
      });
    this.subscribedPromises.push(projectPromise);
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }
}

export default Project;
