import React, { Component } from "react";
import {
  Grid,
  Col,
  Row,
  ButtonGroup,
  ButtonToolbar,
  Button,
  Tooltip,
  OverlayTrigger
} from "react-bootstrap";
import DnDWrapper from "../DnDWrapper/DnDWrapper";
import { Link } from "react-router-dom";
import snakeCase from "lodash/snakeCase";
import moment from "moment";

import { get, put, download } from "../../../utils/DeApi";

import CharacterCreate from "../CharacterCreate/CharacterCreate";
import CharacterEdit from "../CharacterEdit/CharacterEdit";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import ContentGuideList from "../../Content/ContentGuideList/ContentGuideList";
import EmptyState from "../EmptyState/EmptyState";
import { setLayout, getLayout } from "../../../utils/LayoutUtils";

/**
 * Handles rendering of characters outline view
 * Uses drag and drop
 */
class CharactersOutline extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = { layout: getLayout() || "grid" };

    this.handleCharacterCreated = this._handleCharacterCreated.bind(this);
    this.handleCharacterUpdated = this._handleCharacterUpdated.bind(this);
    this.handleOrderChange = this._handleOrderChange.bind(this);
    this.handleViewList = this._handleViewList.bind(this);
    this.handleViewGrid = this._handleViewGrid.bind(this);
    this.handleViewTable = this._handleViewTable.bind(this);
    this.handleDownloadOutline = this._handleDownloadOutline.bind(this);

    this.renderTableItem = this._renderTableItem.bind(this);
    this.renderGridItem = this._renderGridItem.bind(this);
  }

  _handleCharacterCreated(character) {
    this.setState(function(prevState, props) {
      prevState.characters.push(character);
      return {
        characters: prevState.characters
      };
    });
  }

  _handleCharacterUpdated(character) {
    this.setState(function(prevState, props) {
      return {
        characters: prevState.characters.map(item => {
          if (item.id === character.id) return character;
          else return item;
        }),
        character: character
      };
    });
  }

  _handleViewTable() {
    this.setState({ layout: "table" }, () => setLayout(this.state.layout));
  }

  _handleViewList() {
    this.setState({ layout: "list" }, () => setLayout(this.state.layout));
  }

  _handleViewGrid() {
    this.setState({ layout: "grid" }, () => setLayout(this.state.layout));
  }

  _handleDownloadOutline() {
    const { project } = this.props;
    this.setState({ outlineDownloading: true });

    const outlinePromise = download(
      `manuscript/${project.id}/outline?pillar=characters`
    );
    const fileName = `outline-characters-${snakeCase(project.name)}.docx`;

    outlinePromise.promise
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute("download", fileName);
        document.body.appendChild(link);

        link.click();
        this.setState({
          outlineDownloading: false
        });
      })
      .catch(error => {
        this.setState({
          outlineDownloading: false
        });
      });
    this.subscribedPromises.push(outlinePromise);
  }

  componentDidMount() {
    this.fetchCharacters();
    this.fetchCharacterTypes();
    this.setMobileLayout();
  }

  setMobileLayout() {
    const isMobile = /mobi/i.test(navigator.userAgent.toLowerCase());

    if (isMobile && !getLayout())
      this.setState({
        layout: "list"
      });
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  fetchCharacters() {
    const charactersPromise = get(
      "/characters?project=" + this.props.project.id
    );
    charactersPromise.promise
      .then(response => {
        this.setState({
          characters: response.data,
          error: ""
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error
          });
      });
    this.subscribedPromises.push(charactersPromise);
  }

  fetchCharacterTypes() {
    let characterTypesPromise = get("/character-types");

    characterTypesPromise.promise
      .then(response => {
        this.setState({
          characterTypes: response.data
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error
          });
      });
    this.subscribedPromises.push(characterTypesPromise);
  }

  render() {
    const {
      characters,
      characterTypes,
      layout,
      outlineDownloading,
      error
    } = this.state;
    const { project } = this.props;

    const characterOptions = {
      category: "5ba5d8232a8f7" // Character categoryId
    };

    const charactersList =
      characters && characterTypes ? this.renderCharacters() : <Loader />;

    return (
      <Grid>
        <Row className="PillarOutline">
          <Col md={3} mdPush={9} xs={12} className="PillarOutlineSidebar">
            <div className="mt-md mb-md pt-sm pb-sm">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip">Create a new character+</Tooltip>
                }
              >
                <span className="mt-sm mb-sm">
                  <CharacterCreate
                    project={project}
                    onCharacterCreated={this.handleCharacterCreated}
                  />
                </span>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip">Export characters+ outline</Tooltip>
                }
              >
                <Button
                  onClick={this.handleDownloadOutline}
                  className="btn-secondary mt-sm mb-sm"
                  block
                >
                  {outlineDownloading ? "Exporting...." : "Export"}
                </Button>
              </OverlayTrigger>

              <div className="card">
                <h3 className="text-uppercase">Characters+ Guides</h3>
                <hr />
                <ContentGuideList {...characterOptions} />
              </div>
            </div>
          </Col>
          <Col md={9} mdPull={3} xs={12}>
            <Row className="mt-md mb-md">
              <Col xs={12} sm={4}>
                <h3 className="text-uppercase mt-md">{this.renderTitle()}</h3>
              </Col>
              <Col xs={12} sm={8}>
                <ButtonToolbar className="pull-right">
                  <ButtonGroup>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip">
                          View outline in list layout
                        </Tooltip>
                      }
                    >
                      <Button
                        className="btn-secondary mt-sm mb-sm"
                        onClick={this.handleViewTable}
                        active={layout === "table"}
                      >
                        <i className="material-icons md-24">list</i>
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip">
                          View outline in grid layout
                        </Tooltip>
                      }
                    >
                      <Button
                        className="btn-secondary mt-sm mb-sm"
                        onClick={this.handleViewGrid}
                        active={layout === "grid"}
                      >
                        <i className="material-icons md-24">view_module</i>
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip">
                          View outline with full summaries
                        </Tooltip>
                      }
                    >
                      <Button
                        className="btn-secondary mt-sm mb-sm"
                        onClick={this.handleViewList}
                        active={layout === "list"}
                      >
                        <i className="material-icons md-24">view_stream</i>
                      </Button>
                    </OverlayTrigger>
                  </ButtonGroup>
                </ButtonToolbar>
              </Col>

              <Col xs={12}>
                {error && <ErrorHandler error={error} />}
                {charactersList}
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid>
    );
  }

  renderTitle() {
    const { layout, characters } = this.state;

    if (!characters) return "";
    else if (!characters.length) return <span>OUTLINE</span>;
    else if (layout === "list") return <span>OUTLINE: Summary View</span>;
    else if (layout === "grid") return <span>OUTLINE: Grid View</span>;
    else if (layout === "table") return <span>OUTLINE: List View</span>;
    return "";
  }

  renderCharacters() {
    const { layout, characters } = this.state;

    if (characters && characters.length) {
      if (layout === "grid") {
        return this.renderGrid();
      } else if (layout === "list") {
        return this.renderList();
      } else {
        return this.renderTable();
      }
    } else {
      return (
        <div className="card card-info pt-sm pb-md">
          <EmptyState contentId={"60c7ab75a9bac"} />
        </div>
      );
    }
  }

  renderTable() {
    const { characters } = this.state;

    return (
      <div className="card">
        <Row className="mb-xs">
          <Col xs={2} md={1} className="text-right" />
          <Col xs={4} md={5}>
            <h5 className="mt-0 mb-0">Name</h5>
          </Col>
          <Col xs={3} md={3}>
            <h5 className="mt-0 mb-0">Character Type</h5>
          </Col>
          <Col xs={3} md={3}>
            <h5 className="mt-0 mb-0">Last Modified</h5>
          </Col>
        </Row>
        <DnDWrapper
          items={characters}
          onOrderChange={this.handleOrderChange}
          renderItem={this.renderTableItem}
          dragHandle={true}
        />
      </div>
    );
  }

  renderGrid() {
    const { characters } = this.state;
    return (
      <DnDWrapper
        items={characters}
        onOrderChange={this.handleOrderChange}
        renderItem={this.renderGridItem}
      />
    );
  }

  renderList() {
    const { characters, characterTypes } = this.state;
    const { project } = this.props;

    return (
      <div className="card">
        {characters.map((character, index) => {
          let characterURL =
            "/projects/" + project.id + "/characters/" + character.id;

          return (
            <div key={character.id}>
              <div className="mt-sm mb-md">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip">Edit title, type and summary</Tooltip>
                  }
                >
                  <span className="pl-xs pull-right">
                    <CharacterEdit
                      character={character}
                      characterTypes={characterTypes}
                      onCharacterUpdated={this.handleCharacterUpdated}
                    />
                  </span>
                </OverlayTrigger>
                <h4>
                  <span className="text-muted body-2 pr-xs">{++index}</span>
                  <Link to={characterURL} title="Go to character+">
                    {character.title}
                  </Link>
                </h4>
              </div>
              <p className="PillarDescription">{character.description}</p>
              <hr className="mt-md mb-md" />
            </div>
          );
        })}
      </div>
    );
  }

  _renderTableItem(character, index, dragHandle) {
    const { project } = this.props;

    return (
      <Col xs={12} className="DnDRowContent pb-0">
        <div className="card card-table movable">
          <Row>
            <Col xs={2} md={1} className="text-right">
              {dragHandle && dragHandle}
              <small className="text-muted">{++index}</small>
            </Col>
            <Col xs={4} md={5}>
              <p className="mt-0 mb-0">
                <Link to={`/projects/${project.id}/characters/${character.id}`}>
                  {character.title &&
                    (character.title.length < 35
                      ? character.title
                      : character.title.substring(0, 32) + "...")}
                </Link>
              </p>
            </Col>
            <Col xs={3} md={3}>
              <small className="tag text-capitalize">
                {character.characterType.name}
              </small>
            </Col>
            <Col xs={3} md={3}>
              <small className="text-muted mb-0">
                {moment
                  .utc(character.updatedAt)
                  .local()
                  .calendar()}
              </small>
            </Col>
          </Row>
        </div>
      </Col>
    );
  }

  _renderGridItem(character, index) {
    const { project } = this.props;
    const { characterTypes } = this.state;
    const icons = {
      person: "person",
      place: "place",
      object: "category",
      animal: "pets"
    };
    const characterURL =
      "/projects/" + project.id + "/characters/" + character.id;

    return (
      <Col xs={12} sm={6} md={4} className="DnDColContent">
        <div className="card card-grid movable">
          <div className="body">
            {icons[character.characterType.name.toLowerCase()] && (
              <i className="material-icons pull-right">
                {icons[character.characterType.name.toLowerCase()]}
              </i>
            )}
            <h4>
              <Link to={characterURL}>
                {character.title &&
                  (character.title.length < 35
                    ? character.title
                    : character.title.substring(0, 32) + "...")}
              </Link>
            </h4>
            <hr />
            <p>
              {character.description &&
                (character.description.length < 110
                  ? character.description
                  : character.description.substring(0, 108) + "...")}{" "}
            </p>
          </div>
          <div className="footer">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip">Edit title, type and summary</Tooltip>
              }
            >
              <span className="pl-xs pull-right">
                <CharacterEdit
                  character={character}
                  characterTypes={characterTypes}
                  onCharacterUpdated={this.handleCharacterUpdated}
                />
              </span>
            </OverlayTrigger>
            <p className="body-2">
              <span>{++index}</span>
            </p>
          </div>
        </div>
      </Col>
    );
  }

  _handleOrderChange(item, newIndex, newList) {
    this.setState({ characters: newList });
    this.reOrderList(item, newIndex, newList);
  }

  reOrderList(item, newIndex, newList) {
    let reOrderPromise = put(
      "/characters/" + item.id + "/order?curr=" + newIndex
    );
    reOrderPromise.promise
      .then(response => {
        this.setState({ error: "" });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            error: error
          });
      });
    this.subscribedPromises.push(reOrderPromise);
  }
}

export default CharactersOutline;
