import React, { Component } from "react";
import "./HeaderAccount.css";
import { Link } from "react-router-dom";
import { NavDropdown, MenuItem } from "react-bootstrap";

import Timer from "../../Timer/Timer";

class HeaderAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { user, onTimerChange, timerChange } = this.props;
    return (
      <NavDropdown
        eventKey={3}
        title={<i className="material-icons">account_circle</i>}
        id="profile-nav-dropdown"
      >
        <MenuItem header disabled>
          <span>{user.email}</span>
        </MenuItem>
        <MenuItem componentClass={Link} href={"/account"} to={"/account"}>
          Account
        </MenuItem>
        <MenuItem divider />
        <MenuItem componentClass={Link} href={"/"} to={"/"}>
          Home
        </MenuItem>
        <MenuItem componentClass={Link} href={"/prompts"} to={"/prompts"}>
          Prompts
        </MenuItem>
        <MenuItem componentClass={Link} href={"/projects"} to={"/projects"}>
          Projects
        </MenuItem>
        <MenuItem
          componentClass={Link}
          href={"/inspiration"}
          to={"/inspiration"}
        >
          Inspirations
        </MenuItem>
        <MenuItem
          componentClass={Link}
          href={"/writing-craft"}
          to={"/writing-craft"}
        >
          Writing Craft
        </MenuItem>
        <MenuItem divider />
        <MenuItem>
          <Timer onTimerChange={onTimerChange} timerChange={timerChange} />
        </MenuItem>
        <MenuItem
          componentClass={Link}
          href={"/contents/5ca50577ad02f/faq"}
          to={"/contents/5ca50577ad02f/faq"}
        >
          Help
        </MenuItem>
        <MenuItem componentClass={Link} href={"/contact"} to={"/contact"}>
          Contact Bookflow
        </MenuItem>
        <MenuItem divider />

        <MenuItem componentClass={Link} href="/logout" to="/logout">
          Logout
        </MenuItem>
      </NavDropdown>
    );
  }
}

export default HeaderAccount;
