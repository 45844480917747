import React, { Component } from "react";
import Moment from "moment";
import { StripeProvider } from "react-stripe-elements";

import { Col, Row, Alert } from "react-bootstrap";

import { get } from "../../../utils/BeeApi";
import {
  getSubscriptionStatus,
  getSubscriptionViewType
} from "../../../utils/SubscriptionUtils";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import SubscriptionCreate from "../SubscriptionCreate/SubscriptionCreate";
import SubscriptionResume from "../SubscriptionResume/SubscriptionResume";
import SubscriptionActivePlan from "../SubscriptionActivePlan/SubscriptionActivePlan";

/**
 * Handles writers page.
 */
class Subscription extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};

    this.handleSubscriptionUpdated = this._handleSubscriptionUpdated.bind(this);
    this.handlePaymentUpdated = this._handlePaymentUpdated.bind(this);
  }

  _handleSubscriptionUpdated(subscription) {
    this.setState({ subscriptions: [subscription] });
  }

  _handlePaymentUpdated() {
    this.setState({ subscriptions: null }, () => this.fetchSubscriptions());
  }

  componentDidMount() {
    this.initializeStripe();
    this.fetchSubscriptions();
    this.fetchPlans();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  initializeStripe() {
    const PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
    if (window.Stripe) {
      console.log(PUBLIC_KEY);
      this.setState({
        stripe: window.Stripe(PUBLIC_KEY)
      });
    } else {
      document.querySelector("#stripe-js").addEventListener("load", () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({
          stripe: window.Stripe(PUBLIC_KEY)
        });
      });
    }
  }

  fetchSubscriptions() {
    let subscriptionsPromise = get("/subscriptions");
    subscriptionsPromise.promise
      .then(response => {
        this.setState({
          subscriptions: response.data,
          error: ""
        });
      })
      .catch(error => {
        if (!error.isCanceled) {
          this.setState({
            error: error
          });
        }
      });
    this.subscribedPromises.push(subscriptionsPromise);
  }

  fetchPlans() {
    let plansPromise = get("/plans");
    plansPromise.promise
      .then(response => {
        this.setState({
          plans: response.data,
          error: ""
        });
      })
      .catch(error => {
        if (!error.isCanceled) {
          this.setState({
            error: error
          });
        }
      });
    this.subscribedPromises.push(plansPromise);
  }

  render() {
    const { error, subscriptions, plans, stripe } = this.state;
    const { subscriber } = this.props;

    if (error) return <ErrorHandler error={error} />;
    if (!subscriptions || !plans) return <Loader />;

    const subscriptionStatus = getSubscriptionStatus(subscriptions, subscriber);
    const subscriptionViewType = getSubscriptionViewType(
      subscriptions,
      subscriptionStatus
    );

    let subscriptionView = "";

    // Active
    if (subscriptionViewType === "active") {
      subscriptionView = this.renderActive();
    }

    // Trialing
    else if (subscriptionViewType === "trialing") {
      subscriptionView = this.renderTrialing();
    }

    // Early Adopter, No Subscriptions
    else if (subscriptionViewType === "early-adopter") {
      subscriptionView = this.renderEarlyAdopter();
    }

    // In Active
    else if (subscriptionViewType === "inactive") {
      subscriptionView = this.renderExpired();
    }

    // Default
    else {
      subscriptionView = (
        <Alert className="text-center" bsStyle="warning">
          <h4>Oops! We encountered a problem.</h4>
          <hr />
          <p>
            Something unexpected happened. Please contact support at
            team@bookflow.pub
          </p>
        </Alert>
      );
    }

    return <StripeProvider stripe={stripe}>{subscriptionView}</StripeProvider>;
  }

  renderEarlyAdopter() {
    const { subscriber } = this.props;

    let trialEndsAt = subscriber.trialEndsAt
      ? subscriber.trialEndsAt
      : "2020-01-01";

    return (
      <div className="Subscription">
        <div className="card card-info text-center bg-info">
          <h3 className="text-uppercase">Early Adopter</h3>
          <hr />
          <h4>
            {`All Features, No Fee until ${Moment(trialEndsAt).format("LL")}`}
          </h4>
          <h5>{`${Moment(trialEndsAt).diff(
            Moment(),
            "days"
          )} Days Remaining`}</h5>
          <h5 className="pt-sm pb-sm">
            As a thank you for being an Early Adopter, we hope you will continue
            to use Bookflow at no cost until {Moment(trialEndsAt).format("LL")}.{" "}
            Please keep sharing your feedback to help us improve!
          </h5>
        </div>
        <div className="text-center mt-md mb-md">{this.renderChoosePlan()}</div>
      </div>
    );
  }

  renderTrialing() {
    const { subscriber } = this.props;

    let trialEndsAt = subscriber.trialEndsAt
      ? subscriber.trialEndsAt
      : "2020-01-01";

    return (
      <div>
        <div className="card card-info text-center bg-info">
          <h3 className="text-uppercase">trialing</h3>
          <hr />
          <h4>{`${Moment(trialEndsAt).diff(
            Moment(),
            "days"
          )} Days Remaining`}</h4>
          <h5>
            We hope you are enjoying Bookflow! Your free trial will end on{" "}
            <span>{Moment(trialEndsAt).format("LL")}</span>.
          </h5>
        </div>
        <div className="mt-md mb-md">{this.renderChoosePlan()}</div>
      </div>
    );
  }

  renderActive() {
    const { subscriber } = this.props;
    const { plans } = this.state;
    const subscriptions = this.state.subscriptions[0];
    const plan = plans.find(plan => plan.planId === subscriptions.stripePlan);

    // If current plan is no longer exists.
    if (!plan) {
      return (
        <div className="card card-danger text-center bg-info">
          <h3>Oops! We encountered a problem.</h3>
          <hr />
          <h5>
            Your plan seems to be no longer available. Please reach out to
            support at <a href="mailto:team@bookflow.pub">team@bookflow.pub</a>.
          </h5>
        </div>
      );
    }

    // Canceled and set to end at end of period.
    if (subscriptions.cancelAtPeriodEnd) {
      return (
        <div className="card card-success text-center bg-info">
          <h3 className="text-uppercase">{`${
            plan ? plan.nickname : ""
          } subscription plan canceled`}</h3>
          <hr />
          <h5>{`You canceled your ${
            plan.nickname
          } Subscription Plan on ${Moment(subscriptions.canceledAt).format(
            "LL"
          )}. You may continue enjoying Bookflow until ${Moment(
            subscriptions.endsAt
          ).format("LL")}.`}</h5>
          <div className="mt-sm mb-sm">
            {plan && (
              <SubscriptionResume
                plan={plan}
                subscriber={subscriber}
                subscription={subscriptions}
                onSubscriptionUpdated={this.handleSubscriptionUpdated}
              />
            )}
          </div>
        </div>
      );
    }

    // Active
    return (
      <SubscriptionActivePlan
        subscription={subscriptions}
        plan={plan}
        subscriber={subscriber}
        onSubscriptionUpdated={this.handleSubscriptionUpdated}
      />
    );
  }

  renderExpired() {
    return (
      <div>
        <div className="card card-warning text-center bg-info">
          <h3 className="text-uppercase">
            Please subscribe to unlock editing features
          </h3>
          <hr />
          <h5>
            Please select a subscription plan and add a payment method to unlock
            editing features on Bookflow.
          </h5>
        </div>
        <div className="mt-md mb-md">{this.renderChoosePlan()}</div>
      </div>
    );
  }

  renderChoosePlan() {
    const { plans } = this.state;
    const { subscriber } = this.props;

    if (!plans || !plans.length) return <div />;

    const annualPlan = plans.find(
      plan => plan.active && plan.interval === "year"
    );
    const monthlyPlan = plans.find(
      plan => plan.active && plan.interval === "month"
    );
    return (
      <Row>
        <Col xs={12} className="text-center">
          <hr />
          <h4 className="mt-md mb-0">Standard Pricing Plans</h4>
        </Col>
        <Col xs={12} sm={4} className="mt-lg mb-md">
          <div className="card card-emphasis text-center">
            <h3 className="text-uppercase">Trial Plan</h3>
            <hr />
            <h1 className="stats-block">
              <small>$</small>
              <big>0</big>
              <small>/mo</small>
            </h1>
            <h5>Try for free for 28 days.</h5>
          </div>
        </Col>
        <Col xs={12} sm={4} className="mt-md mb-md">
          <h5 className="bg-emphasis mb-0 pt-xs pb-xs text-center">
            Best Value
          </h5>
          <div className="card card-emphasis text-center">
            <h3 className="text-uppercase">Yearly Plan</h3>
            <hr />
            <h1 className="stats-block">
              <small>$</small>
              <big>{annualPlan.amount / (12 * 100)}</big>
              <small>/mo</small>
            </h1>
            <h5>
              Billed annually.
              <br />
              (${annualPlan.amount / 100} once per year)
            </h5>
            <SubscriptionCreate
              plan={annualPlan}
              subscriber={subscriber}
              onSubscribing={this.handleSubscriptionUpdated}
            />
          </div>
        </Col>
        <Col xs={12} sm={4} className="mt-lg mb-md">
          <div className="card card-emphasis text-center">
            <h5 className="text-uppercase">Monthly Plan</h5>
            <hr />
            <h1 className="stats-block">
              <small>$</small>
              <big>{monthlyPlan.amount / 100}</big>
              <small>/mo</small>
            </h1>
            <h5>Billed monthly.</h5>
            <SubscriptionCreate
              plan={monthlyPlan}
              subscriber={subscriber}
              onSubscribing={this.handleSubscriptionUpdated}
            />
          </div>
        </Col>
      </Row>
    );
  }
}

export default Subscription;
