import { Config } from "../Config";
import React, { Component } from "react";
import "./Linkedin.css";

class Linkedin extends Component {
  constructor(props) {
    super(props);
    this.handleLinkedinShare = this._handleLinkedinShare.bind(this);
  }

  _handleLinkedinShare() {
    const { title, summary, image, link } = this.props;

    window.open(
      `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
        link
      )}&title=${encodeURIComponent(
        title || Config.defaultSiteTitle
      )}&summary=${encodeURIComponent(
        summary || ""
      )}&image=${encodeURIComponent(image || Config.defaultSocialImage)}`,
      Config.title,
      "height=300,width=550,resizable=1"
    );
  }

  render() {
    return (
      <li onClick={this.handleLinkedinShare}>
        <span className="icon-linkedin">{}</span>
      </li>
    );
  }
}

export default Linkedin;
