import React, { Component } from "react";
import "./WhatIsNew.css";
import { Link, withRouter } from "react-router-dom";
import { get, put } from "../../../utils/BeeApi";
import { slugify } from "../../../utils/StringUtils";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import { NavDropdown, MenuItem } from "react-bootstrap";
import { Fragment } from "react";
import moment from "moment";

class WhatIsNew extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.subscribedPromises = [];
    this.handleBookmarks = this._handleBookmarks.bind(this);
    this.handleForceEventDispatch = this._handleForceEventDispatch.bind(this);
  }

  componentDidMount() {
    this.fetchContents();
  }

  fetchBookmarks() {
    const { offset, user } = this.props;

    this.setState({
      error: "",
      isLoading: true
    });

    const options = {
      limit: 4,
      offset: offset || 0
    };
    const createPromise = get(`user/${user.sub}/bookmarks`, {
      params: options
    });
    createPromise.promise
      .then(response => {
        const bookmarks = response.data;
        const bookmarkableIds = bookmarks.map(
          ({ bookmarkableId }) => bookmarkableId
        );

        const complement = this.state.contentSummaryList.filter(
          ({ contentId }) => !bookmarkableIds.includes(contentId)
        );

        this.setState({
          bookmarks: response.data,
          error: "",
          isLoading: false,
          showNotification: complement.length > 0
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            isLoading: false,
            error: error
          });
      });

    this.subscribedPromises.push(createPromise);
  }

  fetchContents() {
    const { limit, offset, operand, rank, category } = this.props;

    this.setState({
      error: "",
      isLoading: true
    });

    const options = {
      limit: limit || 3,
      offset: offset || 0,
      operand: operand || "or",
      rank: rank || null,
      category: category || "60abb2fee9213"
    };
    const createPromise = get("/contents", { params: options });
    createPromise.promise
      .then(response => {
        this.fetchBookmarks();
        this.setState({
          contentSummaryList: response.data,
          error: "",
          isLoading: false
        });
      })
      .catch(error => {
        !error.isCanceled &&
          this.setState({
            isLoading: false,
            error: error
          });
      });

    this.subscribedPromises.push(createPromise);
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  _handleBookmarks(event) {
    const { user } = this.props;
    const { contentSummaryList } = this.state;

    contentSummaryList.forEach(({ contentId }) => {
      const createPromise = put(`user/${user.sub}/bookmarks`, {
        bookmarkableId: contentId
      });
      createPromise.promise
        .then(response => {
          this.setState({
            showNotification: false
          });
        })
        .catch(error => {
          !error.isCanceled &&
            this.setState({
              isLoading: false,
              bookmarkError: error
            });
        });

      this.subscribedPromises.push(createPromise);
    });
  }

  _handleForceEventDispatch(URL) {
    if (this.props.location.pathname !== URL) this.props.history.push(URL);
    document.dispatchEvent(new MouseEvent("click"));
  }

  renderWhatIsNewCSL() {
    const { error, isLoading, contentSummaryList } = this.state;

    if (error) return <ErrorHandler error={error} />;
    if (isLoading) return <Loader />;

    return (
      <Fragment>
        {contentSummaryList &&
          contentSummaryList.map((content, index) => {
            let description = content.description || "";
            return (
              <Fragment key={content.contentId}>
                <MenuItem
                  header={false}
                  className="mt-xs mb-xs whatIsNewList"
                  onClick={this.handleForceEventDispatch.bind(
                    this,
                    `/what-is-new/${content.contentId}/${slugify(
                      content.title
                    )}`
                  )}
                >
                  <small>{moment(content.rankDate).format("ll")}</small>
                  <p className="mb-0">
                    <strong className="what-is-new-hyperlink">
                      {content.title}
                    </strong>
                  </p>
                  <p className="description mb-0">
                    {description.length < 150
                      ? description
                      : description.substring(0, 157) + "..."}
                  </p>
                </MenuItem>
                {index < contentSummaryList.length - 1 && <MenuItem divider />}
              </Fragment>
            );
          })}
      </Fragment>
    );
  }

  render() {
    return (
      <NavDropdown
        eventKey={4}
        title={
          <Fragment>
            What's New{" "}
            {this.state.showNotification && <span className="indicator"></span>}
          </Fragment>
        }
        onClick={this.handleBookmarks}
        className="WhatIsNew"
        id="what-is-new-nav-dropdown"
      >
        <MenuItem header>
          <h3 className=" mb-0 mt-xs" onClick={this.handleForceEventDispatch}>
            <span className="text-uppercase">What's New</span>
            <small style={{ letterSpacing: 0 }}>
              <Link className="hyperlink-1 pull-right" to={`/what-is-new`}>
                View all updates
              </Link>
            </small>
          </h3>
        </MenuItem>
        <MenuItem divider />
        {this.renderWhatIsNewCSL()}
      </NavDropdown>
    );
  }
}

export default withRouter(WhatIsNew);
