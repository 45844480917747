import React, { Component } from "react";
import "./PromptNav.css";
import { Helmet } from "react-helmet";

import { Link } from "react-router-dom";
import { Navbar, Nav, NavItem } from "react-bootstrap";

import MonitorTimer from "../../Timer/MonitorTimer/MonitorTimer";
import Feedback from "../../App/Feedback/Feedback";
import HeaderAccount from "../../App/HeaderAccount/HeaderAccount";
import WhatIsNew from "../../App/WhatIsNew/WhatIsNew";

class PromptNav extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleTimerChange = this._handlerTimerChange.bind(this);
  }

  _handlerTimerChange(countDown) {
    this.setState({ timerChange: countDown });
  }

  render() {
    const { user, location } = this.props;
    const { timerChange } = this.state;
    const pathname = location ? location.pathname : "";

    return (
      <div className="MainHeader">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Bookflow - Prompts History</title>
        </Helmet>
        <Navbar default fluid fixedTop collapseOnSelect>
          <Navbar.Header>
            <Navbar.Brand>
              <Link to="/">
                <img alt="B" src="/bookflow-icon.png" />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav>
              <NavItem
                componentClass={Link}
                href={"/prompts"}
                to={"/prompts"}
                active={pathname === "/prompts"}
                className="projectName"
              >
                Prompts History
              </NavItem>
            </Nav>
            <Nav pullRight>
              <Feedback user={user} />
              <MonitorTimer
                onTimerChange={this.handleTimerChange}
                timerChange={timerChange}
              />
              <WhatIsNew user={user} />
              <HeaderAccount
                user={user}
                onTimerChange={this.handleTimerChange}
                timerChange={timerChange}
              />
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

export default PromptNav;
